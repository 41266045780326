import { ORG_UNITS_VOCAB_ID, FIELD_OF_RESEARCH_VOCAB_ID, AIATSIS_CODES_VOCAB_ID, SUSTAINABLE_DEVELOPMENT_GOAL_VOCAB_ID } from 'config/general';

export const vocabulariesList = {
    [ORG_UNITS_VOCAB_ID]: {
        "total": 304,
        "data": [
            {
                "cvr_id": 4819,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453704,
                "controlled_vocab": {
                    "cvo_id": 453704,
                    "cvo_title": "Office of the Vice-Chancellor",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4820,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453705,
                "controlled_vocab": {
                    "cvo_id": 453705,
                    "cvo_title": "Office of Provost",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4821,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453706,
                "controlled_vocab": {
                    "cvo_id": 453706,
                    "cvo_title": "Library Corporate Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4822,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453707,
                "controlled_vocab": {
                    "cvo_id": 453707,
                    "cvo_title": "Office of Chief Operating Officer",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4823,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453708,
                "controlled_vocab": {
                    "cvo_id": 453708,
                    "cvo_title": "Legal Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4824,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453709,
                "controlled_vocab": {
                    "cvo_id": 453709,
                    "cvo_title": "Academic Services Division",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4825,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453710,
                "controlled_vocab": {
                    "cvo_id": 453710,
                    "cvo_title": "Academic Administration Directorate",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4826,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453711,
                "controlled_vocab": {
                    "cvo_id": 453711,
                    "cvo_title": "Student Administration Directorate",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4827,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453712,
                "controlled_vocab": {
                    "cvo_id": 453712,
                    "cvo_title": "Finance and Business Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4828,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453713,
                "controlled_vocab": {
                    "cvo_id": 453713,
                    "cvo_title": "Collections and Receivables",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4829,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453714,
                "controlled_vocab": {
                    "cvo_id": 453714,
                    "cvo_title": "Transport Systems (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4830,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453715,
                "controlled_vocab": {
                    "cvo_id": 453715,
                    "cvo_title": "Contract and Grants",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4831,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453716,
                "controlled_vocab": {
                    "cvo_id": 453716,
                    "cvo_title": "Accounts Payable",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4832,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453717,
                "controlled_vocab": {
                    "cvo_id": 453717,
                    "cvo_title": "Human Resources Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4833,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453718,
                "controlled_vocab": {
                    "cvo_id": 453718,
                    "cvo_title": "Occupational Health and Safety",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4834,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453719,
                "controlled_vocab": {
                    "cvo_id": 453719,
                    "cvo_title": "Office of President, Academic Board",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4835,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453720,
                "controlled_vocab": {
                    "cvo_id": 453720,
                    "cvo_title": "Employee Relations",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4836,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453721,
                "controlled_vocab": {
                    "cvo_id": 453721,
                    "cvo_title": "School of Communication and Arts",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4837,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453722,
                "controlled_vocab": {
                    "cvo_id": 453722,
                    "cvo_title": "School of Music",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4838,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453723,
                "controlled_vocab": {
                    "cvo_id": 453723,
                    "cvo_title": "The University of Queensland Art Museum",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4839,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453724,
                "controlled_vocab": {
                    "cvo_id": 453724,
                    "cvo_title": "Faculty of Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4840,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453725,
                "controlled_vocab": {
                    "cvo_id": 453725,
                    "cvo_title": "Centre for Microscopy and Microanalysis",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4841,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453726,
                "controlled_vocab": {
                    "cvo_id": 453726,
                    "cvo_title": "Faculty of Business, Economics & Law",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4842,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453727,
                "controlled_vocab": {
                    "cvo_id": 453727,
                    "cvo_title": "School of Economics",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4843,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453728,
                "controlled_vocab": {
                    "cvo_id": 453728,
                    "cvo_title": "T.C. Beirne School of Law",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4844,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453729,
                "controlled_vocab": {
                    "cvo_id": 453729,
                    "cvo_title": "Faculty Of Engineering, Architecture & Info Tech",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4845,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453730,
                "controlled_vocab": {
                    "cvo_id": 453730,
                    "cvo_title": "School of Mathematics & Physics",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4846,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453731,
                "controlled_vocab": {
                    "cvo_id": 453731,
                    "cvo_title": "School of Dentistry",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4847,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453732,
                "controlled_vocab": {
                    "cvo_id": 453732,
                    "cvo_title": "School of Health & Rehabilitation Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4848,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453733,
                "controlled_vocab": {
                    "cvo_id": 453733,
                    "cvo_title": "School of Pharmacy",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4849,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453734,
                "controlled_vocab": {
                    "cvo_id": 453734,
                    "cvo_title": "School of Veterinary Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4850,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453735,
                "controlled_vocab": {
                    "cvo_id": 453735,
                    "cvo_title": "School of Social Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4851,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453736,
                "controlled_vocab": {
                    "cvo_id": 453736,
                    "cvo_title": "School of Political Science & Internat'l Studies",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4852,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453737,
                "controlled_vocab": {
                    "cvo_id": 453737,
                    "cvo_title": "School of Education",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4853,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453738,
                "controlled_vocab": {
                    "cvo_id": 453738,
                    "cvo_title": "School of Psychology",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4854,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453739,
                "controlled_vocab": {
                    "cvo_id": 453739,
                    "cvo_title": "Central Glasshouse Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4855,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453740,
                "controlled_vocab": {
                    "cvo_id": 453740,
                    "cvo_title": "Business Intelligence",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4856,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453741,
                "controlled_vocab": {
                    "cvo_id": 453741,
                    "cvo_title": "CRCMining",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4857,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453742,
                "controlled_vocab": {
                    "cvo_id": 453742,
                    "cvo_title": "Health Service",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4858,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453743,
                "controlled_vocab": {
                    "cvo_id": 453743,
                    "cvo_title": "Student Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4859,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453744,
                "controlled_vocab": {
                    "cvo_id": 453744,
                    "cvo_title": "Halls of Residence, Gatton",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4860,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453745,
                "controlled_vocab": {
                    "cvo_id": 453745,
                    "cvo_title": "Financial Planning and Analysis",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4861,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453746,
                "controlled_vocab": {
                    "cvo_id": 453746,
                    "cvo_title": "Information Technology Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4862,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453747,
                "controlled_vocab": {
                    "cvo_id": 453747,
                    "cvo_title": "University of Queensland Press",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4863,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453748,
                "controlled_vocab": {
                    "cvo_id": 453748,
                    "cvo_title": "Corporate Finance",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4864,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453749,
                "controlled_vocab": {
                    "cvo_id": 453749,
                    "cvo_title": "Superannuees",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4865,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453750,
                "controlled_vocab": {
                    "cvo_id": 453750,
                    "cvo_title": "Aboriginal and Torres Strait Islander Studies Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4866,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453751,
                "controlled_vocab": {
                    "cvo_id": 453751,
                    "cvo_title": "Institute of Continuing & TESOL Education",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4867,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453752,
                "controlled_vocab": {
                    "cvo_id": 453752,
                    "cvo_title": "School of Biomedical Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4868,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453753,
                "controlled_vocab": {
                    "cvo_id": 453753,
                    "cvo_title": "School of Biological Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4869,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453754,
                "controlled_vocab": {
                    "cvo_id": 453754,
                    "cvo_title": "School of Chemistry & Molecular Biosciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4870,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453755,
                "controlled_vocab": {
                    "cvo_id": 453755,
                    "cvo_title": "Planning and Business Intelligence",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4871,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453756,
                "controlled_vocab": {
                    "cvo_id": 453756,
                    "cvo_title": "School of Architecture",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4872,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453757,
                "controlled_vocab": {
                    "cvo_id": 453757,
                    "cvo_title": "School of Chemical Engineering",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4873,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453758,
                "controlled_vocab": {
                    "cvo_id": 453758,
                    "cvo_title": "School of Civil Engineering",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4874,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453759,
                "controlled_vocab": {
                    "cvo_id": 453759,
                    "cvo_title": "School of Mechanical and Mining Engineering",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4875,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453760,
                "controlled_vocab": {
                    "cvo_id": 453760,
                    "cvo_title": "Physics",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4876,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453761,
                "controlled_vocab": {
                    "cvo_id": 453761,
                    "cvo_title": "Mathematics",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4877,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453762,
                "controlled_vocab": {
                    "cvo_id": 453762,
                    "cvo_title": "School of Public Health",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4878,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453763,
                "controlled_vocab": {
                    "cvo_id": 453763,
                    "cvo_title": "School of Languages and Cultures",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4879,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453764,
                "controlled_vocab": {
                    "cvo_id": 453764,
                    "cvo_title": "School of Historical and Philosophical Inquiry",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4880,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453765,
                "controlled_vocab": {
                    "cvo_id": 453765,
                    "cvo_title": "Institute of Modern Languages",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4881,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453766,
                "controlled_vocab": {
                    "cvo_id": 453766,
                    "cvo_title": "School of Human Movement and Nutrition Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4882,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453767,
                "controlled_vocab": {
                    "cvo_id": 453767,
                    "cvo_title": "Workplace Injury Management",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4883,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453768,
                "controlled_vocab": {
                    "cvo_id": 453768,
                    "cvo_title": "The University of Queensland Advancement",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4884,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453769,
                "controlled_vocab": {
                    "cvo_id": 453769,
                    "cvo_title": "Office of Deputy Vice-Chancellor (Research)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4885,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453770,
                "controlled_vocab": {
                    "cvo_id": 453770,
                    "cvo_title": "Advanced Water Management Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4886,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453771,
                "controlled_vocab": {
                    "cvo_id": 453771,
                    "cvo_title": "School of Information Technol and Elec Engineering",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4887,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453772,
                "controlled_vocab": {
                    "cvo_id": 453772,
                    "cvo_title": "Sustainable Minerals Institute",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4888,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453773,
                "controlled_vocab": {
                    "cvo_id": 453773,
                    "cvo_title": "Minerals Industry Safety and Health Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4889,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453774,
                "controlled_vocab": {
                    "cvo_id": 453774,
                    "cvo_title": "Centre for Social Responsibility in Mining",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4890,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453775,
                "controlled_vocab": {
                    "cvo_id": 453775,
                    "cvo_title": "WH Bryan Mining and Geology Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4891,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453776,
                "controlled_vocab": {
                    "cvo_id": 453776,
                    "cvo_title": "Centre For Mined Land Rehabilitation",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4892,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453777,
                "controlled_vocab": {
                    "cvo_id": 453777,
                    "cvo_title": "Julius Kruttschnitt Mineral Research Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4893,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453778,
                "controlled_vocab": {
                    "cvo_id": 453778,
                    "cvo_title": "UQ Business School",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4894,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453779,
                "controlled_vocab": {
                    "cvo_id": 453779,
                    "cvo_title": "Institute for Molecular Bioscience",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4895,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453780,
                "controlled_vocab": {
                    "cvo_id": 453780,
                    "cvo_title": "UQ International",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4896,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453781,
                "controlled_vocab": {
                    "cvo_id": 453781,
                    "cvo_title": "Heron Island Research Station",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4897,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453782,
                "controlled_vocab": {
                    "cvo_id": 453782,
                    "cvo_title": "Academic Policy and Programs",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4898,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453783,
                "controlled_vocab": {
                    "cvo_id": 453783,
                    "cvo_title": "University of Queensland Graduate School",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4899,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453784,
                "controlled_vocab": {
                    "cvo_id": 453784,
                    "cvo_title": "Office of Marketing and Communication",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4900,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453785,
                "controlled_vocab": {
                    "cvo_id": 453785,
                    "cvo_title": "Protocol Office",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4901,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453786,
                "controlled_vocab": {
                    "cvo_id": 453786,
                    "cvo_title": "Design and Desktop Publishing",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4902,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453787,
                "controlled_vocab": {
                    "cvo_id": 453787,
                    "cvo_title": "Customs House",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4903,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453788,
                "controlled_vocab": {
                    "cvo_id": 453788,
                    "cvo_title": "Communications",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4904,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453789,
                "controlled_vocab": {
                    "cvo_id": 453789,
                    "cvo_title": "Marketing",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4905,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453790,
                "controlled_vocab": {
                    "cvo_id": 453790,
                    "cvo_title": "Property and Facilities Division",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4906,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453791,
                "controlled_vocab": {
                    "cvo_id": 453791,
                    "cvo_title": "Cleaning (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4907,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453792,
                "controlled_vocab": {
                    "cvo_id": 453792,
                    "cvo_title": "Construction (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4908,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453793,
                "controlled_vocab": {
                    "cvo_id": 453793,
                    "cvo_title": "Engineering Services (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4909,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453794,
                "controlled_vocab": {
                    "cvo_id": 453794,
                    "cvo_title": "Grounds (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4910,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453795,
                "controlled_vocab": {
                    "cvo_id": 453795,
                    "cvo_title": "Mail Room (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4911,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453796,
                "controlled_vocab": {
                    "cvo_id": 453796,
                    "cvo_title": "Printery (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4912,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453797,
                "controlled_vocab": {
                    "cvo_id": 453797,
                    "cvo_title": "Security (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4913,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453798,
                "controlled_vocab": {
                    "cvo_id": 453798,
                    "cvo_title": "Store (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4914,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453799,
                "controlled_vocab": {
                    "cvo_id": 453799,
                    "cvo_title": "Fleet Services (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4915,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453800,
                "controlled_vocab": {
                    "cvo_id": 453800,
                    "cvo_title": "Centre for Nutrition and Food Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4916,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453801,
                "controlled_vocab": {
                    "cvo_id": 453801,
                    "cvo_title": "Student Administration",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4917,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453802,
                "controlled_vocab": {
                    "cvo_id": 453802,
                    "cvo_title": "Aust Institute for Bioengineering & Nanotechnology",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4918,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453803,
                "controlled_vocab": {
                    "cvo_id": 453803,
                    "cvo_title": "Queensland Brain Institute",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4919,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453804,
                "controlled_vocab": {
                    "cvo_id": 453804,
                    "cvo_title": "Centre for Water in the Minerals Industry",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4920,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453805,
                "controlled_vocab": {
                    "cvo_id": 453805,
                    "cvo_title": "Centre for Animal Welfare and Ethics",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4921,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453806,
                "controlled_vocab": {
                    "cvo_id": 453806,
                    "cvo_title": "UQ Chemical Store",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4922,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453807,
                "controlled_vocab": {
                    "cvo_id": 453807,
                    "cvo_title": "Office of Pro-Vice-Chancellor",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4923,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453808,
                "controlled_vocab": {
                    "cvo_id": 453808,
                    "cvo_title": "School of Nursing, Midwifery and Social Work",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4924,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453809,
                "controlled_vocab": {
                    "cvo_id": 453809,
                    "cvo_title": "SI-net Projects",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4925,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453810,
                "controlled_vocab": {
                    "cvo_id": 453810,
                    "cvo_title": "Payroll and Superannuation",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4926,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453811,
                "controlled_vocab": {
                    "cvo_id": 453811,
                    "cvo_title": "HR Client Services & Remuneration",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4927,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453812,
                "controlled_vocab": {
                    "cvo_id": 453812,
                    "cvo_title": "Organisational Development &Leadership Development",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4928,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453813,
                "controlled_vocab": {
                    "cvo_id": 453813,
                    "cvo_title": "Student Recruitment",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4929,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453814,
                "controlled_vocab": {
                    "cvo_id": 453814,
                    "cvo_title": "UQ Biological Resources",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4930,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453815,
                "controlled_vocab": {
                    "cvo_id": 453815,
                    "cvo_title": "UQ Diamantina Institute",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4931,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453816,
                "controlled_vocab": {
                    "cvo_id": 453816,
                    "cvo_title": "Institute for Social Science Research",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4932,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453817,
                "controlled_vocab": {
                    "cvo_id": 453817,
                    "cvo_title": "Aust Equine Genetics Research Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4933,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453818,
                "controlled_vocab": {
                    "cvo_id": 453818,
                    "cvo_title": "Office of Deputy Vice-Chancellor (Academic)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4934,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453819,
                "controlled_vocab": {
                    "cvo_id": 453819,
                    "cvo_title": "Office of Sponsored Research",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4935,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453820,
                "controlled_vocab": {
                    "cvo_id": 453820,
                    "cvo_title": "Accommodation Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4936,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453821,
                "controlled_vocab": {
                    "cvo_id": 453821,
                    "cvo_title": "UQ Multifaith Chaplaincy Service",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4937,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453822,
                "controlled_vocab": {
                    "cvo_id": 453822,
                    "cvo_title": "UQ Centre for Clinical Research",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4938,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453823,
                "controlled_vocab": {
                    "cvo_id": 453823,
                    "cvo_title": "Admissions",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4939,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453824,
                "controlled_vocab": {
                    "cvo_id": 453824,
                    "cvo_title": "Student Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4940,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453825,
                "controlled_vocab": {
                    "cvo_id": 453825,
                    "cvo_title": "Student Progression Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4941,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453826,
                "controlled_vocab": {
                    "cvo_id": 453826,
                    "cvo_title": "Examinations",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4942,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453827,
                "controlled_vocab": {
                    "cvo_id": 453827,
                    "cvo_title": "Careers and Graduate Employment",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4943,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453828,
                "controlled_vocab": {
                    "cvo_id": 453828,
                    "cvo_title": "Learning Hub",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4944,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453829,
                "controlled_vocab": {
                    "cvo_id": 453829,
                    "cvo_title": "Student Counselling",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4945,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453830,
                "controlled_vocab": {
                    "cvo_id": 453830,
                    "cvo_title": "Student Equity",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4946,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453831,
                "controlled_vocab": {
                    "cvo_id": 453831,
                    "cvo_title": "Copying and General Office Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4947,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453832,
                "controlled_vocab": {
                    "cvo_id": 453832,
                    "cvo_title": "Teaching Space Management",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4948,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453833,
                "controlled_vocab": {
                    "cvo_id": 453833,
                    "cvo_title": "Moreton Bay Research Station",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4949,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453834,
                "controlled_vocab": {
                    "cvo_id": 453834,
                    "cvo_title": "Prospective Students and Student Equity",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4950,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453835,
                "controlled_vocab": {
                    "cvo_id": 453835,
                    "cvo_title": "Student Employability Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4951,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453836,
                "controlled_vocab": {
                    "cvo_id": 453836,
                    "cvo_title": "Corporate Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4952,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453837,
                "controlled_vocab": {
                    "cvo_id": 453837,
                    "cvo_title": "Global Change Institute",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4953,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453838,
                "controlled_vocab": {
                    "cvo_id": 453838,
                    "cvo_title": "Confucius Institute",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4954,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453839,
                "controlled_vocab": {
                    "cvo_id": 453839,
                    "cvo_title": "Terrestrial Ecosystem Research Network",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4955,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453840,
                "controlled_vocab": {
                    "cvo_id": 453840,
                    "cvo_title": "Centre for Advanced Imaging",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4956,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453841,
                "controlled_vocab": {
                    "cvo_id": 453841,
                    "cvo_title": "Qld Alliance for Agriculture and Food Innovation",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4957,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453842,
                "controlled_vocab": {
                    "cvo_id": 453842,
                    "cvo_title": "Workplace Relations",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4958,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453843,
                "controlled_vocab": {
                    "cvo_id": 453843,
                    "cvo_title": "Client Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4959,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453844,
                "controlled_vocab": {
                    "cvo_id": 453844,
                    "cvo_title": "Rural Clinical School",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4960,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453845,
                "controlled_vocab": {
                    "cvo_id": 453845,
                    "cvo_title": "Centre for Youth Substance Abuse",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4961,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453846,
                "controlled_vocab": {
                    "cvo_id": 453846,
                    "cvo_title": "Child Health Research Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4962,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453847,
                "controlled_vocab": {
                    "cvo_id": 453847,
                    "cvo_title": "School of Agriculture and Food Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4963,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453848,
                "controlled_vocab": {
                    "cvo_id": 453848,
                    "cvo_title": "Office of Director Gatton Campus",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4964,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453849,
                "controlled_vocab": {
                    "cvo_id": 453849,
                    "cvo_title": "Engineered Quantum Systems",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4965,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453850,
                "controlled_vocab": {
                    "cvo_id": 453850,
                    "cvo_title": "Quantum Computation and Communication Technology",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4966,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453851,
                "controlled_vocab": {
                    "cvo_id": 453851,
                    "cvo_title": "Research Computing Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4967,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453852,
                "controlled_vocab": {
                    "cvo_id": 453852,
                    "cvo_title": "ARC Ctr of Exc for the History of Emotions",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4968,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453853,
                "controlled_vocab": {
                    "cvo_id": 453853,
                    "cvo_title": "Centre for Coal Seam Gas",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4969,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453854,
                "controlled_vocab": {
                    "cvo_id": 453854,
                    "cvo_title": "Office of Pro-Vice-Chancellor (Indigenous Engmt)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4970,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453855,
                "controlled_vocab": {
                    "cvo_id": 453855,
                    "cvo_title": "UQ Energy Initiative",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4971,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453856,
                "controlled_vocab": {
                    "cvo_id": 453856,
                    "cvo_title": "Corporate Publications",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4972,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453857,
                "controlled_vocab": {
                    "cvo_id": 453857,
                    "cvo_title": "Office of Pro-Vice Chancellor (Advancement)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4973,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453858,
                "controlled_vocab": {
                    "cvo_id": 453858,
                    "cvo_title": "Clem Jones Centre for Ageing Dementia Research",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4974,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453859,
                "controlled_vocab": {
                    "cvo_id": 453859,
                    "cvo_title": "Centre for Animal Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4975,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453860,
                "controlled_vocab": {
                    "cvo_id": 453860,
                    "cvo_title": "Centre for Plant Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4976,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453861,
                "controlled_vocab": {
                    "cvo_id": 453861,
                    "cvo_title": "Research Partnerships Office",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4977,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453862,
                "controlled_vocab": {
                    "cvo_id": 453862,
                    "cvo_title": "Student Complaints & Grievance Resolution",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4978,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453863,
                "controlled_vocab": {
                    "cvo_id": 453863,
                    "cvo_title": "Student Affairs Division",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4979,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453864,
                "controlled_vocab": {
                    "cvo_id": 453864,
                    "cvo_title": "Biodiversity and Conservation Science Ctr",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4980,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453865,
                "controlled_vocab": {
                    "cvo_id": 453865,
                    "cvo_title": "Internal Audit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4981,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453866,
                "controlled_vocab": {
                    "cvo_id": 453866,
                    "cvo_title": "Enterprise Risk Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4982,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453867,
                "controlled_vocab": {
                    "cvo_id": 453867,
                    "cvo_title": "Integrity and Investigations Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4983,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453868,
                "controlled_vocab": {
                    "cvo_id": 453868,
                    "cvo_title": "Campaign and Principal Gifts",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4984,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453869,
                "controlled_vocab": {
                    "cvo_id": 453869,
                    "cvo_title": "Alumni and Community Relations",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4985,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453870,
                "controlled_vocab": {
                    "cvo_id": 453870,
                    "cvo_title": "Advancement Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4986,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453871,
                "controlled_vocab": {
                    "cvo_id": 453871,
                    "cvo_title": "Office of Deputy Director (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4987,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453872,
                "controlled_vocab": {
                    "cvo_id": 453872,
                    "cvo_title": "Sustainability Office (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4988,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453873,
                "controlled_vocab": {
                    "cvo_id": 453873,
                    "cvo_title": "Energy Management (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4989,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453874,
                "controlled_vocab": {
                    "cvo_id": 453874,
                    "cvo_title": "Asset Services (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4990,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453875,
                "controlled_vocab": {
                    "cvo_id": 453875,
                    "cvo_title": "PF Assist (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4991,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453876,
                "controlled_vocab": {
                    "cvo_id": 453876,
                    "cvo_title": "Maintenance (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4992,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453877,
                "controlled_vocab": {
                    "cvo_id": 453877,
                    "cvo_title": "Fire Safety (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4993,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453878,
                "controlled_vocab": {
                    "cvo_id": 453878,
                    "cvo_title": "UQ Centre (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4994,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453879,
                "controlled_vocab": {
                    "cvo_id": 453879,
                    "cvo_title": "Occ Health and Safety (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4995,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453880,
                "controlled_vocab": {
                    "cvo_id": 453880,
                    "cvo_title": "Campus Services (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4996,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453881,
                "controlled_vocab": {
                    "cvo_id": 453881,
                    "cvo_title": "Post Office Gatton (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4997,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453882,
                "controlled_vocab": {
                    "cvo_id": 453882,
                    "cvo_title": "Furniture (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4998,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453883,
                "controlled_vocab": {
                    "cvo_id": 453883,
                    "cvo_title": "Planning (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4999,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453884,
                "controlled_vocab": {
                    "cvo_id": 453884,
                    "cvo_title": "Property (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5000,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453885,
                "controlled_vocab": {
                    "cvo_id": 453885,
                    "cvo_title": "Space Management (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5001,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453886,
                "controlled_vocab": {
                    "cvo_id": 453886,
                    "cvo_title": "Mater Research Institute-UQ",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5002,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453887,
                "controlled_vocab": {
                    "cvo_id": 453887,
                    "cvo_title": "Faculty of Medicine",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5003,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453888,
                "controlled_vocab": {
                    "cvo_id": 453888,
                    "cvo_title": "Faculty of Humanities and Social Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5004,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453889,
                "controlled_vocab": {
                    "cvo_id": 453889,
                    "cvo_title": "Faculty of Health and Behavioural Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5005,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453890,
                "controlled_vocab": {
                    "cvo_id": 453890,
                    "cvo_title": "Aust Inst for Business and Economics",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5006,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453891,
                "controlled_vocab": {
                    "cvo_id": 453891,
                    "cvo_title": "Financial Operations",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5007,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453892,
                "controlled_vocab": {
                    "cvo_id": 453892,
                    "cvo_title": "Insurance Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5008,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453893,
                "controlled_vocab": {
                    "cvo_id": 453893,
                    "cvo_title": "Finance Client Services (FBS)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5009,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453894,
                "controlled_vocab": {
                    "cvo_id": 453894,
                    "cvo_title": "Procurement",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5010,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453895,
                "controlled_vocab": {
                    "cvo_id": 453895,
                    "cvo_title": "Office of the PVC (Teaching & Learning)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5011,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453896,
                "controlled_vocab": {
                    "cvo_id": 453896,
                    "cvo_title": "Institute for Teaching and Learning Innovation",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5012,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453897,
                "controlled_vocab": {
                    "cvo_id": 453897,
                    "cvo_title": "Qld Ctr Intellectual and Developmental Disability",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5013,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453898,
                "controlled_vocab": {
                    "cvo_id": 453898,
                    "cvo_title": "Learning and Research Services (UQ Library)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5014,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453899,
                "controlled_vocab": {
                    "cvo_id": 453899,
                    "cvo_title": "Information Systems and Resource Services, Library",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5015,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453900,
                "controlled_vocab": {
                    "cvo_id": 453900,
                    "cvo_title": "Institute for Advanced Studies in the Humanities",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5016,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453901,
                "controlled_vocab": {
                    "cvo_id": 453901,
                    "cvo_title": "RECOVER Injury Research Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5017,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453902,
                "controlled_vocab": {
                    "cvo_id": 453902,
                    "cvo_title": "TetraQ Research Infrastructure Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5018,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453903,
                "controlled_vocab": {
                    "cvo_id": 453903,
                    "cvo_title": "Asia-Pacific Centre for Neuromodulation",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5019,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453904,
                "controlled_vocab": {
                    "cvo_id": 453904,
                    "cvo_title": "Enhancing Systems and Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5020,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453905,
                "controlled_vocab": {
                    "cvo_id": 453905,
                    "cvo_title": "Student Fees and Scholarships",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5021,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453906,
                "controlled_vocab": {
                    "cvo_id": 453906,
                    "cvo_title": "Office of Deputy Vice-Chancellor (External Engage)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5022,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453907,
                "controlled_vocab": {
                    "cvo_id": 453907,
                    "cvo_title": "UQ Poche Centre for Indigenous Health",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5023,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453908,
                "controlled_vocab": {
                    "cvo_id": 453908,
                    "cvo_title": "Office of Medical Education",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5024,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453909,
                "controlled_vocab": {
                    "cvo_id": 453909,
                    "cvo_title": "School of Clinical Medicine",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5025,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453910,
                "controlled_vocab": {
                    "cvo_id": 453910,
                    "cvo_title": "St Lucia Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5026,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453911,
                "controlled_vocab": {
                    "cvo_id": 453911,
                    "cvo_title": "Greenslopes Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5027,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453912,
                "controlled_vocab": {
                    "cvo_id": 453912,
                    "cvo_title": "Ipswich Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5028,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453913,
                "controlled_vocab": {
                    "cvo_id": 453913,
                    "cvo_title": "Mater Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5029,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453914,
                "controlled_vocab": {
                    "cvo_id": 453914,
                    "cvo_title": "PAH-Southside Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5030,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453915,
                "controlled_vocab": {
                    "cvo_id": 453915,
                    "cvo_title": "Children's Health Queensland Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5031,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453916,
                "controlled_vocab": {
                    "cvo_id": 453916,
                    "cvo_title": "PCH-Northside Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5032,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453917,
                "controlled_vocab": {
                    "cvo_id": 453917,
                    "cvo_title": "Royal Brisbane Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5033,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453918,
                "controlled_vocab": {
                    "cvo_id": 453918,
                    "cvo_title": "Sunshine Coast Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5034,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453919,
                "controlled_vocab": {
                    "cvo_id": 453919,
                    "cvo_title": "UnitingCare Health Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5035,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453920,
                "controlled_vocab": {
                    "cvo_id": 453920,
                    "cvo_title": "Ochsner Clinical School",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5036,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453921,
                "controlled_vocab": {
                    "cvo_id": 453921,
                    "cvo_title": "Centre for the Business & Economics of Health",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5037,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453922,
                "controlled_vocab": {
                    "cvo_id": 453922,
                    "cvo_title": "Primary Care Clinical Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5038,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453923,
                "controlled_vocab": {
                    "cvo_id": 453923,
                    "cvo_title": "Infrastructure and Research Operations (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5039,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453924,
                "controlled_vocab": {
                    "cvo_id": 453924,
                    "cvo_title": "Advancement (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5040,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453925,
                "controlled_vocab": {
                    "cvo_id": 453925,
                    "cvo_title": "International (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5041,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453926,
                "controlled_vocab": {
                    "cvo_id": 453926,
                    "cvo_title": "Faculty Operations (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5042,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453927,
                "controlled_vocab": {
                    "cvo_id": 453927,
                    "cvo_title": "Research Strategy and Support (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5043,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453928,
                "controlled_vocab": {
                    "cvo_id": 453928,
                    "cvo_title": "Curriculum and Client Services (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5044,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453929,
                "controlled_vocab": {
                    "cvo_id": 453929,
                    "cvo_title": "HR Professional Services - BEL",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5045,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453930,
                "controlled_vocab": {
                    "cvo_id": 453930,
                    "cvo_title": "HR Professional Services - Central",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5046,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453931,
                "controlled_vocab": {
                    "cvo_id": 453931,
                    "cvo_title": "HR Professional Services - EAIT",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5047,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453932,
                "controlled_vocab": {
                    "cvo_id": 453932,
                    "cvo_title": "HR Professional Services - HABS",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5048,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453933,
                "controlled_vocab": {
                    "cvo_id": 453933,
                    "cvo_title": "HR Professional Services - HASS",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5049,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453934,
                "controlled_vocab": {
                    "cvo_id": 453934,
                    "cvo_title": "HR Professional Services - Institutes",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5050,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453935,
                "controlled_vocab": {
                    "cvo_id": 453935,
                    "cvo_title": "HR Professional Services - Medicine",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5051,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453936,
                "controlled_vocab": {
                    "cvo_id": 453936,
                    "cvo_title": "HR Professional Services - Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5052,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453937,
                "controlled_vocab": {
                    "cvo_id": 453937,
                    "cvo_title": "Finance Professional Services - BEL",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5053,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453938,
                "controlled_vocab": {
                    "cvo_id": 453938,
                    "cvo_title": "Finance Professional Services - Central",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5054,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453939,
                "controlled_vocab": {
                    "cvo_id": 453939,
                    "cvo_title": "Finance Professional Services - EAIT",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5055,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453940,
                "controlled_vocab": {
                    "cvo_id": 453940,
                    "cvo_title": "Finance Professional Services - HABS",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5056,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453941,
                "controlled_vocab": {
                    "cvo_id": 453941,
                    "cvo_title": "Finance Professional Services - HASS",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5057,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453942,
                "controlled_vocab": {
                    "cvo_id": 453942,
                    "cvo_title": "Finance Professional Services - Institutes",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5058,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453943,
                "controlled_vocab": {
                    "cvo_id": 453943,
                    "cvo_title": "Finance Professional Services - Medicine",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5059,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453944,
                "controlled_vocab": {
                    "cvo_id": 453944,
                    "cvo_title": "Finance Professional Services - Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5060,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453945,
                "controlled_vocab": {
                    "cvo_id": 453945,
                    "cvo_title": "Workplace Diversity and Inclusion",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5061,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453946,
                "controlled_vocab": {
                    "cvo_id": 453946,
                    "cvo_title": "Centre for Health Services Research",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5062,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453947,
                "controlled_vocab": {
                    "cvo_id": 453947,
                    "cvo_title": "Qld Alliance for Environmental Health Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5063,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453948,
                "controlled_vocab": {
                    "cvo_id": 453948,
                    "cvo_title": "School of Earth and Environmental Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5064,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453949,
                "controlled_vocab": {
                    "cvo_id": 453949,
                    "cvo_title": "Centre for Policy Futures",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5065,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453950,
                "controlled_vocab": {
                    "cvo_id": 453950,
                    "cvo_title": "Office of Pro-Vice-Chancellor (Research)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5066,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453951,
                "controlled_vocab": {
                    "cvo_id": 453951,
                    "cvo_title": "Office of Research Ethics",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5067,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453952,
                "controlled_vocab": {
                    "cvo_id": 453952,
                    "cvo_title": "Office of Research Integrity",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5068,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453953,
                "controlled_vocab": {
                    "cvo_id": 453953,
                    "cvo_title": "Office of Pro-Vice-Chancellor (Res Infrastructure)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5070,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453955,
                "controlled_vocab": {
                    "cvo_id": 453955,
                    "cvo_title": "Employee Benefits",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5071,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453956,
                "controlled_vocab": {
                    "cvo_id": 453956,
                    "cvo_title": "Business and Systems (Property & Facilities)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5072,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453957,
                "controlled_vocab": {
                    "cvo_id": 453957,
                    "cvo_title": "Office of Pro-Vice-Chancellor (Res Partnerships)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5073,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453958,
                "controlled_vocab": {
                    "cvo_id": 453958,
                    "cvo_title": "Office of Pro-Vice-Chancellor (Research Training)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5074,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453959,
                "controlled_vocab": {
                    "cvo_id": 453959,
                    "cvo_title": "eLearning Innovations & Partnerships in Sci & Eng",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5075,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453960,
                "controlled_vocab": {
                    "cvo_id": 453960,
                    "cvo_title": "Student and Academic Administration (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5076,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453961,
                "controlled_vocab": {
                    "cvo_id": 453961,
                    "cvo_title": "Domestic Student Recruitment",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5077,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453962,
                "controlled_vocab": {
                    "cvo_id": 453962,
                    "cvo_title": "Centre for Crop Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5078,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453963,
                "controlled_vocab": {
                    "cvo_id": 453963,
                    "cvo_title": "UQ Protein Expression Facility",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5079,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453964,
                "controlled_vocab": {
                    "cvo_id": 453964,
                    "cvo_title": "Centre of Horticultural Science",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5080,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453965,
                "controlled_vocab": {
                    "cvo_id": 453965,
                    "cvo_title": "International Marketing, Recruitment and Admission",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5081,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453966,
                "controlled_vocab": {
                    "cvo_id": 453966,
                    "cvo_title": "Future Students Contact Centre",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5082,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453967,
                "controlled_vocab": {
                    "cvo_id": 453967,
                    "cvo_title": "Enterprise",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5083,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453968,
                "controlled_vocab": {
                    "cvo_id": 453968,
                    "cvo_title": "Health Safety & Wellness",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5084,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453969,
                "controlled_vocab": {
                    "cvo_id": 453969,
                    "cvo_title": "Global Engagement",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5085,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453970,
                "controlled_vocab": {
                    "cvo_id": 453970,
                    "cvo_title": "UQ Careers",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5086,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453971,
                "controlled_vocab": {
                    "cvo_id": 453971,
                    "cvo_title": "Office of Pro-Vice-Chancellor (Future Students)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5087,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453972,
                "controlled_vocab": {
                    "cvo_id": 453972,
                    "cvo_title": "Centre for Restorative Neuroscience",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5088,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453973,
                "controlled_vocab": {
                    "cvo_id": 453973,
                    "cvo_title": "Strategic Program Office",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5089,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453974,
                "controlled_vocab": {
                    "cvo_id": 453974,
                    "cvo_title": "Marketing and Communications (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5090,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453975,
                "controlled_vocab": {
                    "cvo_id": 453975,
                    "cvo_title": "SOCM Admin (Medicine)",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5091,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453976,
                "controlled_vocab": {
                    "cvo_id": 453976,
                    "cvo_title": "Global Engagement & Entrepreneurship",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5092,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453977,
                "controlled_vocab": {
                    "cvo_id": 453977,
                    "cvo_title": "UQ Consulting and Research Expertise",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5093,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453978,
                "controlled_vocab": {
                    "cvo_id": 453978,
                    "cvo_title": "Medicine - Royal Brisbane and Women's Hospital",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5094,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453979,
                "controlled_vocab": {
                    "cvo_id": 453979,
                    "cvo_title": "Corporate Operations",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5095,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453980,
                "controlled_vocab": {
                    "cvo_id": 453980,
                    "cvo_title": "Southern Queensland Rural Health",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5096,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 453981,
                "controlled_vocab": {
                    "cvo_id": 453981,
                    "cvo_title": "People Services Unit",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5150,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454041,
                "controlled_vocab": {
                    "cvo_id": 454041,
                    "cvo_title": "Counselling",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5151,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454042,
                "controlled_vocab": {
                    "cvo_id": 454042,
                    "cvo_title": "Research Data Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5152,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454043,
                "controlled_vocab": {
                    "cvo_id": 454043,
                    "cvo_title": "Enterprise Compliance",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5153,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454044,
                "controlled_vocab": {
                    "cvo_id": 454044,
                    "cvo_title": "Student Experience and Engagement",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5155,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454046,
                "controlled_vocab": {
                    "cvo_id": 454046,
                    "cvo_title": "Academic Services Directorate",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5156,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454047,
                "controlled_vocab": {
                    "cvo_id": 454047,
                    "cvo_title": "Student Interface",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5157,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454048,
                "controlled_vocab": {
                    "cvo_id": 454048,
                    "cvo_title": "Divertisty, Disability and Inclusion",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5158,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454049,
                "controlled_vocab": {
                    "cvo_id": 454049,
                    "cvo_title": "Student Services - Gatton Campus",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5159,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454050,
                "controlled_vocab": {
                    "cvo_id": 454050,
                    "cvo_title": "Wellbeing & International Student Advice",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5160,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454051,
                "controlled_vocab": {
                    "cvo_id": 454051,
                    "cvo_title": "Learning Skills Development & Services Projects",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5161,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454052,
                "controlled_vocab": {
                    "cvo_id": 454052,
                    "cvo_title": "Business Enhancement Projects",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5162,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454053,
                "controlled_vocab": {
                    "cvo_id": 454053,
                    "cvo_title": "Development",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5163,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454054,
                "controlled_vocab": {
                    "cvo_id": 454054,
                    "cvo_title": "Alumni Relations and Engagement",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5164,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454055,
                "controlled_vocab": {
                    "cvo_id": 454055,
                    "cvo_title": "Advancement Services and Operations",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5165,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454056,
                "controlled_vocab": {
                    "cvo_id": 454056,
                    "cvo_title": "Diversity, Disability and Inclusion",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5166,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454057,
                "controlled_vocab": {
                    "cvo_id": 454057,
                    "cvo_title": "School of Earth Sciences",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5194,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454093,
                "controlled_vocab": {
                    "cvo_id": 454093,
                    "cvo_title": "Future Students Directorate",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5195,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454094,
                "controlled_vocab": {
                    "cvo_id": 454094,
                    "cvo_title": "Consulting and Research Expertise",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5196,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454095,
                "controlled_vocab": {
                    "cvo_id": 454095,
                    "cvo_title": "Philanthropic Programs",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5197,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454096,
                "controlled_vocab": {
                    "cvo_id": 454096,
                    "cvo_title": "Human Resources",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5198,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454097,
                "controlled_vocab": {
                    "cvo_id": 454097,
                    "cvo_title": "HR Client Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5199,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454098,
                "controlled_vocab": {
                    "cvo_id": 454098,
                    "cvo_title": "People Services",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5200,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454099,
                "controlled_vocab": {
                    "cvo_id": 454099,
                    "cvo_title": "Future Student Recruitment",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5201,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454100,
                "controlled_vocab": {
                    "cvo_id": 454100,
                    "cvo_title": "International Admissions",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5202,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454101,
                "controlled_vocab": {
                    "cvo_id": 454101,
                    "cvo_title": "International Marketing and Communication",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5203,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454102,
                "controlled_vocab": {
                    "cvo_id": 454102,
                    "cvo_title": "Natural Gas",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5204,
                "cvr_parent_cvo_id": 453703,
                "cvr_child_cvo_id": 454103,
                "controlled_vocab": {
                    "cvo_id": 454103,
                    "cvo_title": "Marketing and Communication",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": null,
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            }
        ],
        "filters": []
    },
    [FIELD_OF_RESEARCH_VOCAB_ID]: {
        "total": 22,
        "data": [
            {
                "cvr_id": 2931,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 451799,
                "controlled_vocab": {
                    "cvo_id": 451799,
                    "cvo_title": "01 Mathematical Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "01",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 2932,
                            "cvr_parent_cvo_id": 451799,
                            "cvr_child_cvo_id": 451800,
                            "controlled_vocab": {
                                "cvo_id": 451800,
                                "cvo_title": "0101 Pure Mathematics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0101",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2933,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451801,
                                        "controlled_vocab": {
                                            "cvo_id": 451801,
                                            "cvo_title": "010101 Algebra and Number Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2934,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451802,
                                        "controlled_vocab": {
                                            "cvo_id": 451802,
                                            "cvo_title": "010102 Algebraic and Differential Geometry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2935,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451803,
                                        "controlled_vocab": {
                                            "cvo_id": 451803,
                                            "cvo_title": "010103 Category Theory, K Theory, Homological Algebra",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2936,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451804,
                                        "controlled_vocab": {
                                            "cvo_id": 451804,
                                            "cvo_title": "010104 Combinatorics and Discrete Mathematics (excl. Physical Combinatorics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2937,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451805,
                                        "controlled_vocab": {
                                            "cvo_id": 451805,
                                            "cvo_title": "010105 Group Theory and Generalisations",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2938,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451806,
                                        "controlled_vocab": {
                                            "cvo_id": 451806,
                                            "cvo_title": "010106 Lie Groups, Harmonic and Fourier Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2939,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451807,
                                        "controlled_vocab": {
                                            "cvo_id": 451807,
                                            "cvo_title": "010107 Mathematical Logic, Set Theory, Lattices and Universal Algebra",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2940,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451808,
                                        "controlled_vocab": {
                                            "cvo_id": 451808,
                                            "cvo_title": "010108 Operator Algebras and Functional Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2941,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451809,
                                        "controlled_vocab": {
                                            "cvo_id": 451809,
                                            "cvo_title": "010109 Ordinary Differential Equations, Difference Equations and Dynamical Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010109",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2942,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451810,
                                        "controlled_vocab": {
                                            "cvo_id": 451810,
                                            "cvo_title": "010110 Partial Differential Equations",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010110",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2943,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451811,
                                        "controlled_vocab": {
                                            "cvo_id": 451811,
                                            "cvo_title": "010111 Real and Complex Functions (incl. Several Variables)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010111",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2944,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451812,
                                        "controlled_vocab": {
                                            "cvo_id": 451812,
                                            "cvo_title": "010112 Topology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010112",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2945,
                                        "cvr_parent_cvo_id": 451800,
                                        "cvr_child_cvo_id": 451813,
                                        "controlled_vocab": {
                                            "cvo_id": 451813,
                                            "cvo_title": "010199 Pure Mathematics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 2946,
                            "cvr_parent_cvo_id": 451799,
                            "cvr_child_cvo_id": 451814,
                            "controlled_vocab": {
                                "cvo_id": 451814,
                                "cvo_title": "0102 Applied Mathematics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0102",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2947,
                                        "cvr_parent_cvo_id": 451814,
                                        "cvr_child_cvo_id": 451815,
                                        "controlled_vocab": {
                                            "cvo_id": 451815,
                                            "cvo_title": "010201 Approximation Theory and Asymptotic Methods",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2948,
                                        "cvr_parent_cvo_id": 451814,
                                        "cvr_child_cvo_id": 451816,
                                        "controlled_vocab": {
                                            "cvo_id": 451816,
                                            "cvo_title": "010202 Biological Mathematics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2949,
                                        "cvr_parent_cvo_id": 451814,
                                        "cvr_child_cvo_id": 451817,
                                        "controlled_vocab": {
                                            "cvo_id": 451817,
                                            "cvo_title": "010203 Calculus of Variations, Systems Theory and Control Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2950,
                                        "cvr_parent_cvo_id": 451814,
                                        "cvr_child_cvo_id": 451818,
                                        "controlled_vocab": {
                                            "cvo_id": 451818,
                                            "cvo_title": "010204 Dynamical Systems in Applications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2951,
                                        "cvr_parent_cvo_id": 451814,
                                        "cvr_child_cvo_id": 451819,
                                        "controlled_vocab": {
                                            "cvo_id": 451819,
                                            "cvo_title": "010205 Financial Mathematics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2952,
                                        "cvr_parent_cvo_id": 451814,
                                        "cvr_child_cvo_id": 451820,
                                        "controlled_vocab": {
                                            "cvo_id": 451820,
                                            "cvo_title": "010206 Operations Research",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2953,
                                        "cvr_parent_cvo_id": 451814,
                                        "cvr_child_cvo_id": 451821,
                                        "controlled_vocab": {
                                            "cvo_id": 451821,
                                            "cvo_title": "010207 Theoretical and Applied Mechanics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2954,
                                        "cvr_parent_cvo_id": 451814,
                                        "cvr_child_cvo_id": 451822,
                                        "controlled_vocab": {
                                            "cvo_id": 451822,
                                            "cvo_title": "010299 Applied Mathematics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 2955,
                            "cvr_parent_cvo_id": 451799,
                            "cvr_child_cvo_id": 451823,
                            "controlled_vocab": {
                                "cvo_id": 451823,
                                "cvo_title": "0103 Numerical and Computational Mathematics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0103",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2956,
                                        "cvr_parent_cvo_id": 451823,
                                        "cvr_child_cvo_id": 451824,
                                        "controlled_vocab": {
                                            "cvo_id": 451824,
                                            "cvo_title": "010301 Numerical Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2957,
                                        "cvr_parent_cvo_id": 451823,
                                        "cvr_child_cvo_id": 451825,
                                        "controlled_vocab": {
                                            "cvo_id": 451825,
                                            "cvo_title": "010302 Numerical Solution of Differential and Integral Equations",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2958,
                                        "cvr_parent_cvo_id": 451823,
                                        "cvr_child_cvo_id": 451826,
                                        "controlled_vocab": {
                                            "cvo_id": 451826,
                                            "cvo_title": "010303 Optimisation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2959,
                                        "cvr_parent_cvo_id": 451823,
                                        "cvr_child_cvo_id": 451827,
                                        "controlled_vocab": {
                                            "cvo_id": 451827,
                                            "cvo_title": "010399 Numerical and Computational Mathematics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 2960,
                            "cvr_parent_cvo_id": 451799,
                            "cvr_child_cvo_id": 451828,
                            "controlled_vocab": {
                                "cvo_id": 451828,
                                "cvo_title": "0104 Statistics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0104",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2961,
                                        "cvr_parent_cvo_id": 451828,
                                        "cvr_child_cvo_id": 451829,
                                        "controlled_vocab": {
                                            "cvo_id": 451829,
                                            "cvo_title": "010401 Applied Statistics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2962,
                                        "cvr_parent_cvo_id": 451828,
                                        "cvr_child_cvo_id": 451830,
                                        "controlled_vocab": {
                                            "cvo_id": 451830,
                                            "cvo_title": "010402 Biostatistics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2963,
                                        "cvr_parent_cvo_id": 451828,
                                        "cvr_child_cvo_id": 451831,
                                        "controlled_vocab": {
                                            "cvo_id": 451831,
                                            "cvo_title": "010403 Forensic Statistics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2964,
                                        "cvr_parent_cvo_id": 451828,
                                        "cvr_child_cvo_id": 451832,
                                        "controlled_vocab": {
                                            "cvo_id": 451832,
                                            "cvo_title": "010404 Probability Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2965,
                                        "cvr_parent_cvo_id": 451828,
                                        "cvr_child_cvo_id": 451833,
                                        "controlled_vocab": {
                                            "cvo_id": 451833,
                                            "cvo_title": "010405 Statistical Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2966,
                                        "cvr_parent_cvo_id": 451828,
                                        "cvr_child_cvo_id": 451834,
                                        "controlled_vocab": {
                                            "cvo_id": 451834,
                                            "cvo_title": "010406 Stochastic Analysis and Modelling",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2967,
                                        "cvr_parent_cvo_id": 451828,
                                        "cvr_child_cvo_id": 451835,
                                        "controlled_vocab": {
                                            "cvo_id": 451835,
                                            "cvo_title": "010499 Statistics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 2968,
                            "cvr_parent_cvo_id": 451799,
                            "cvr_child_cvo_id": 451836,
                            "controlled_vocab": {
                                "cvo_id": 451836,
                                "cvo_title": "0105 Mathematical Physics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0105",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2969,
                                        "cvr_parent_cvo_id": 451836,
                                        "cvr_child_cvo_id": 451837,
                                        "controlled_vocab": {
                                            "cvo_id": 451837,
                                            "cvo_title": "010501 Algebraic Structures in Mathematical Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2970,
                                        "cvr_parent_cvo_id": 451836,
                                        "cvr_child_cvo_id": 451838,
                                        "controlled_vocab": {
                                            "cvo_id": 451838,
                                            "cvo_title": "010502 Integrable Systems (Classical and Quantum)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2971,
                                        "cvr_parent_cvo_id": 451836,
                                        "cvr_child_cvo_id": 451839,
                                        "controlled_vocab": {
                                            "cvo_id": 451839,
                                            "cvo_title": "010503 Mathematical Aspects of Classical Mechanics, Quantum Mechanics and Quantum Information Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2972,
                                        "cvr_parent_cvo_id": 451836,
                                        "cvr_child_cvo_id": 451840,
                                        "controlled_vocab": {
                                            "cvo_id": 451840,
                                            "cvo_title": "010504 Mathematical Aspects of General Relativity",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2973,
                                        "cvr_parent_cvo_id": 451836,
                                        "cvr_child_cvo_id": 451841,
                                        "controlled_vocab": {
                                            "cvo_id": 451841,
                                            "cvo_title": "010505 Mathematical Aspects of Quantum and Conformal Field Theory, Quantum Gravity and String Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2974,
                                        "cvr_parent_cvo_id": 451836,
                                        "cvr_child_cvo_id": 451842,
                                        "controlled_vocab": {
                                            "cvo_id": 451842,
                                            "cvo_title": "010506 Statistical Mechanics, Physical Combinatorics and Mathematical Aspects of Condensed Matter",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2975,
                                        "cvr_parent_cvo_id": 451836,
                                        "cvr_child_cvo_id": 451843,
                                        "controlled_vocab": {
                                            "cvo_id": 451843,
                                            "cvo_title": "010599 Mathematical Physics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "010599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 2976,
                            "cvr_parent_cvo_id": 451799,
                            "cvr_child_cvo_id": 451844,
                            "controlled_vocab": {
                                "cvo_id": 451844,
                                "cvo_title": "0199 Other Mathematical Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0199",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2977,
                                        "cvr_parent_cvo_id": 451844,
                                        "cvr_child_cvo_id": 451845,
                                        "controlled_vocab": {
                                            "cvo_id": 451845,
                                            "cvo_title": "019999 Mathematical Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "019999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 2978,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 451846,
                "controlled_vocab": {
                    "cvo_id": 451846,
                    "cvo_title": "02 Physical Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "02",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 2979,
                            "cvr_parent_cvo_id": 451846,
                            "cvr_child_cvo_id": 451847,
                            "controlled_vocab": {
                                "cvo_id": 451847,
                                "cvo_title": "0201 Astronomical and Space Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0201",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2980,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451848,
                                        "controlled_vocab": {
                                            "cvo_id": 451848,
                                            "cvo_title": "020101 Astrobiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2981,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451849,
                                        "controlled_vocab": {
                                            "cvo_id": 451849,
                                            "cvo_title": "020102 Astronomical and Space Instrumentation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2982,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451850,
                                        "controlled_vocab": {
                                            "cvo_id": 451850,
                                            "cvo_title": "020103 Cosmology and Extragalactic Astronomy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2983,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451851,
                                        "controlled_vocab": {
                                            "cvo_id": 451851,
                                            "cvo_title": "020104 Galactic Astronomy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2984,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451852,
                                        "controlled_vocab": {
                                            "cvo_id": 451852,
                                            "cvo_title": "020105 General Relativity and Gravitational Waves",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2985,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451853,
                                        "controlled_vocab": {
                                            "cvo_id": 451853,
                                            "cvo_title": "020106 High Energy Astrophysics; Cosmic Rays",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2986,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451854,
                                        "controlled_vocab": {
                                            "cvo_id": 451854,
                                            "cvo_title": "020107 Mesospheric, Ionospheric and Magnetospheric Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2987,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451855,
                                        "controlled_vocab": {
                                            "cvo_id": 451855,
                                            "cvo_title": "020108 Planetary Science (excl. Extraterrestrial Geology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2988,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451856,
                                        "controlled_vocab": {
                                            "cvo_id": 451856,
                                            "cvo_title": "020109 Space and Solar Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020109",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2989,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451857,
                                        "controlled_vocab": {
                                            "cvo_id": 451857,
                                            "cvo_title": "020110 Stellar Astronomy and Planetary Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020110",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2990,
                                        "cvr_parent_cvo_id": 451847,
                                        "cvr_child_cvo_id": 451858,
                                        "controlled_vocab": {
                                            "cvo_id": 451858,
                                            "cvo_title": "020199 Astronomical and Space Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 2991,
                            "cvr_parent_cvo_id": 451846,
                            "cvr_child_cvo_id": 451859,
                            "controlled_vocab": {
                                "cvo_id": 451859,
                                "cvo_title": "0202 Atomic, Molecular, Nuclear, Particle and Plasma Physics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0202",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2992,
                                        "cvr_parent_cvo_id": 451859,
                                        "cvr_child_cvo_id": 451860,
                                        "controlled_vocab": {
                                            "cvo_id": 451860,
                                            "cvo_title": "020201 Atomic and Molecular Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2993,
                                        "cvr_parent_cvo_id": 451859,
                                        "cvr_child_cvo_id": 451861,
                                        "controlled_vocab": {
                                            "cvo_id": 451861,
                                            "cvo_title": "020202 Nuclear Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2994,
                                        "cvr_parent_cvo_id": 451859,
                                        "cvr_child_cvo_id": 451862,
                                        "controlled_vocab": {
                                            "cvo_id": 451862,
                                            "cvo_title": "020203 Particle Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2995,
                                        "cvr_parent_cvo_id": 451859,
                                        "cvr_child_cvo_id": 451863,
                                        "controlled_vocab": {
                                            "cvo_id": 451863,
                                            "cvo_title": "020204 Plasma Physics; Fusion Plasmas; Electrical Discharges",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2996,
                                        "cvr_parent_cvo_id": 451859,
                                        "cvr_child_cvo_id": 451864,
                                        "controlled_vocab": {
                                            "cvo_id": 451864,
                                            "cvo_title": "020299 Atomic, Molecular, Nuclear, Particle and Plasma Physics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 2997,
                            "cvr_parent_cvo_id": 451846,
                            "cvr_child_cvo_id": 451865,
                            "controlled_vocab": {
                                "cvo_id": 451865,
                                "cvo_title": "0203 Classical Physics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0203",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 2998,
                                        "cvr_parent_cvo_id": 451865,
                                        "cvr_child_cvo_id": 451866,
                                        "controlled_vocab": {
                                            "cvo_id": 451866,
                                            "cvo_title": "020301 Acoustics and Acoustical Devices; Waves",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 2999,
                                        "cvr_parent_cvo_id": 451865,
                                        "cvr_child_cvo_id": 451867,
                                        "controlled_vocab": {
                                            "cvo_id": 451867,
                                            "cvo_title": "020302 Electrostatics and Electrodynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3000,
                                        "cvr_parent_cvo_id": 451865,
                                        "cvr_child_cvo_id": 451868,
                                        "controlled_vocab": {
                                            "cvo_id": 451868,
                                            "cvo_title": "020303 Fluid Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3001,
                                        "cvr_parent_cvo_id": 451865,
                                        "cvr_child_cvo_id": 451869,
                                        "controlled_vocab": {
                                            "cvo_id": 451869,
                                            "cvo_title": "020304 Thermodynamics and Statistical Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3002,
                                        "cvr_parent_cvo_id": 451865,
                                        "cvr_child_cvo_id": 451870,
                                        "controlled_vocab": {
                                            "cvo_id": 451870,
                                            "cvo_title": "020399 Classical Physics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3003,
                            "cvr_parent_cvo_id": 451846,
                            "cvr_child_cvo_id": 451871,
                            "controlled_vocab": {
                                "cvo_id": 451871,
                                "cvo_title": "0204 Condensed Matter Physics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0204",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3004,
                                        "cvr_parent_cvo_id": 451871,
                                        "cvr_child_cvo_id": 451872,
                                        "controlled_vocab": {
                                            "cvo_id": 451872,
                                            "cvo_title": "020401 Condensed Matter Characterisation Technique Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3005,
                                        "cvr_parent_cvo_id": 451871,
                                        "cvr_child_cvo_id": 451873,
                                        "controlled_vocab": {
                                            "cvo_id": 451873,
                                            "cvo_title": "020402 Condensed Matter Imaging",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3006,
                                        "cvr_parent_cvo_id": 451871,
                                        "cvr_child_cvo_id": 451874,
                                        "controlled_vocab": {
                                            "cvo_id": 451874,
                                            "cvo_title": "020403 Condensed Matter Modelling and Density Functional Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3007,
                                        "cvr_parent_cvo_id": 451871,
                                        "cvr_child_cvo_id": 451875,
                                        "controlled_vocab": {
                                            "cvo_id": 451875,
                                            "cvo_title": "020404 Electronic and Magnetic Properties of Condensed Matter; Superconductivity",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3008,
                                        "cvr_parent_cvo_id": 451871,
                                        "cvr_child_cvo_id": 451876,
                                        "controlled_vocab": {
                                            "cvo_id": 451876,
                                            "cvo_title": "020405 Soft Condensed Matter",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3009,
                                        "cvr_parent_cvo_id": 451871,
                                        "cvr_child_cvo_id": 451877,
                                        "controlled_vocab": {
                                            "cvo_id": 451877,
                                            "cvo_title": "020406 Surfaces and Structural Properties of Condensed Matter",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3010,
                                        "cvr_parent_cvo_id": 451871,
                                        "cvr_child_cvo_id": 451878,
                                        "controlled_vocab": {
                                            "cvo_id": 451878,
                                            "cvo_title": "020499 Condensed Matter Physics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3011,
                            "cvr_parent_cvo_id": 451846,
                            "cvr_child_cvo_id": 451879,
                            "controlled_vocab": {
                                "cvo_id": 451879,
                                "cvo_title": "0205 Optical Physics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0205",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3012,
                                        "cvr_parent_cvo_id": 451879,
                                        "cvr_child_cvo_id": 451880,
                                        "controlled_vocab": {
                                            "cvo_id": 451880,
                                            "cvo_title": "020501 Classical and Physical Optics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3013,
                                        "cvr_parent_cvo_id": 451879,
                                        "cvr_child_cvo_id": 451881,
                                        "controlled_vocab": {
                                            "cvo_id": 451881,
                                            "cvo_title": "020502 Lasers and Quantum Electronics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3014,
                                        "cvr_parent_cvo_id": 451879,
                                        "cvr_child_cvo_id": 451882,
                                        "controlled_vocab": {
                                            "cvo_id": 451882,
                                            "cvo_title": "020503 Nonlinear Optics and Spectroscopy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3015,
                                        "cvr_parent_cvo_id": 451879,
                                        "cvr_child_cvo_id": 451883,
                                        "controlled_vocab": {
                                            "cvo_id": 451883,
                                            "cvo_title": "020504 Photonics, Optoelectronics and Optical Communications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3016,
                                        "cvr_parent_cvo_id": 451879,
                                        "cvr_child_cvo_id": 451884,
                                        "controlled_vocab": {
                                            "cvo_id": 451884,
                                            "cvo_title": "020599 Optical Physics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3017,
                            "cvr_parent_cvo_id": 451846,
                            "cvr_child_cvo_id": 451885,
                            "controlled_vocab": {
                                "cvo_id": 451885,
                                "cvo_title": "0206 Quantum Physics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0206",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3018,
                                        "cvr_parent_cvo_id": 451885,
                                        "cvr_child_cvo_id": 451886,
                                        "controlled_vocab": {
                                            "cvo_id": 451886,
                                            "cvo_title": "020601 Degenerate Quantum Gases and Atom Optics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3019,
                                        "cvr_parent_cvo_id": 451885,
                                        "cvr_child_cvo_id": 451887,
                                        "controlled_vocab": {
                                            "cvo_id": 451887,
                                            "cvo_title": "020602 Field Theory and String Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3020,
                                        "cvr_parent_cvo_id": 451885,
                                        "cvr_child_cvo_id": 451888,
                                        "controlled_vocab": {
                                            "cvo_id": 451888,
                                            "cvo_title": "020603 Quantum Information, Computation and Communication",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3021,
                                        "cvr_parent_cvo_id": 451885,
                                        "cvr_child_cvo_id": 451889,
                                        "controlled_vocab": {
                                            "cvo_id": 451889,
                                            "cvo_title": "020604 Quantum Optics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3022,
                                        "cvr_parent_cvo_id": 451885,
                                        "cvr_child_cvo_id": 451890,
                                        "controlled_vocab": {
                                            "cvo_id": 451890,
                                            "cvo_title": "020699 Quantum Physics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "020699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3023,
                            "cvr_parent_cvo_id": 451846,
                            "cvr_child_cvo_id": 451891,
                            "controlled_vocab": {
                                "cvo_id": 451891,
                                "cvo_title": "0299 Other Physical Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0299",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3024,
                                        "cvr_parent_cvo_id": 451891,
                                        "cvr_child_cvo_id": 451892,
                                        "controlled_vocab": {
                                            "cvo_id": 451892,
                                            "cvo_title": "029901 Biological Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "029901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3025,
                                        "cvr_parent_cvo_id": 451891,
                                        "cvr_child_cvo_id": 451893,
                                        "controlled_vocab": {
                                            "cvo_id": 451893,
                                            "cvo_title": "029902 Complex Physical Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "029902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3026,
                                        "cvr_parent_cvo_id": 451891,
                                        "cvr_child_cvo_id": 451894,
                                        "controlled_vocab": {
                                            "cvo_id": 451894,
                                            "cvo_title": "029903 Medical Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "029903",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3027,
                                        "cvr_parent_cvo_id": 451891,
                                        "cvr_child_cvo_id": 451895,
                                        "controlled_vocab": {
                                            "cvo_id": 451895,
                                            "cvo_title": "029904 Synchrotrons; Accelerators; Instruments and Techniques",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "029904",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3028,
                                        "cvr_parent_cvo_id": 451891,
                                        "cvr_child_cvo_id": 451896,
                                        "controlled_vocab": {
                                            "cvo_id": 451896,
                                            "cvo_title": "029999 Physical Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "029999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3029,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 451897,
                "controlled_vocab": {
                    "cvo_id": 451897,
                    "cvo_title": "03 Chemical Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "03",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3030,
                            "cvr_parent_cvo_id": 451897,
                            "cvr_child_cvo_id": 451898,
                            "controlled_vocab": {
                                "cvo_id": 451898,
                                "cvo_title": "0301 Analytical Chemistry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0301",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3031,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451899,
                                        "controlled_vocab": {
                                            "cvo_id": 451899,
                                            "cvo_title": "030101 Analytical Spectrometry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3032,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451900,
                                        "controlled_vocab": {
                                            "cvo_id": 451900,
                                            "cvo_title": "030102 Electroanalytical Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3033,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451901,
                                        "controlled_vocab": {
                                            "cvo_id": 451901,
                                            "cvo_title": "030103 Flow Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3034,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451902,
                                        "controlled_vocab": {
                                            "cvo_id": 451902,
                                            "cvo_title": "030104 Immunological and Bioassay Methods",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3035,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451903,
                                        "controlled_vocab": {
                                            "cvo_id": 451903,
                                            "cvo_title": "030105 Instrumental Methods (excl. Immunological and Bioassay Methods)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3036,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451904,
                                        "controlled_vocab": {
                                            "cvo_id": 451904,
                                            "cvo_title": "030106 Quality Assurance, Chemometrics, Traceability and Metrological Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3037,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451905,
                                        "controlled_vocab": {
                                            "cvo_id": 451905,
                                            "cvo_title": "030107 Sensor Technology (Chemical aspects)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3038,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451906,
                                        "controlled_vocab": {
                                            "cvo_id": 451906,
                                            "cvo_title": "030108 Separation Science",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3039,
                                        "cvr_parent_cvo_id": 451898,
                                        "cvr_child_cvo_id": 451907,
                                        "controlled_vocab": {
                                            "cvo_id": 451907,
                                            "cvo_title": "030199 Analytical Chemistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3040,
                            "cvr_parent_cvo_id": 451897,
                            "cvr_child_cvo_id": 451908,
                            "controlled_vocab": {
                                "cvo_id": 451908,
                                "cvo_title": "0302 Inorganic Chemistry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0302",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3041,
                                        "cvr_parent_cvo_id": 451908,
                                        "cvr_child_cvo_id": 451909,
                                        "controlled_vocab": {
                                            "cvo_id": 451909,
                                            "cvo_title": "030201 Bioinorganic Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3042,
                                        "cvr_parent_cvo_id": 451908,
                                        "cvr_child_cvo_id": 451910,
                                        "controlled_vocab": {
                                            "cvo_id": 451910,
                                            "cvo_title": "030202 f-Block Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3043,
                                        "cvr_parent_cvo_id": 451908,
                                        "cvr_child_cvo_id": 451911,
                                        "controlled_vocab": {
                                            "cvo_id": 451911,
                                            "cvo_title": "030203 Inorganic Green Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3044,
                                        "cvr_parent_cvo_id": 451908,
                                        "cvr_child_cvo_id": 451912,
                                        "controlled_vocab": {
                                            "cvo_id": 451912,
                                            "cvo_title": "030204 Main Group Metal Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3045,
                                        "cvr_parent_cvo_id": 451908,
                                        "cvr_child_cvo_id": 451913,
                                        "controlled_vocab": {
                                            "cvo_id": 451913,
                                            "cvo_title": "030205 Non-metal Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3046,
                                        "cvr_parent_cvo_id": 451908,
                                        "cvr_child_cvo_id": 451914,
                                        "controlled_vocab": {
                                            "cvo_id": 451914,
                                            "cvo_title": "030206 Solid State Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3047,
                                        "cvr_parent_cvo_id": 451908,
                                        "cvr_child_cvo_id": 451915,
                                        "controlled_vocab": {
                                            "cvo_id": 451915,
                                            "cvo_title": "030207 Transition Metal Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3048,
                                        "cvr_parent_cvo_id": 451908,
                                        "cvr_child_cvo_id": 451916,
                                        "controlled_vocab": {
                                            "cvo_id": 451916,
                                            "cvo_title": "030299 Inorganic Chemistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3049,
                            "cvr_parent_cvo_id": 451897,
                            "cvr_child_cvo_id": 451917,
                            "controlled_vocab": {
                                "cvo_id": 451917,
                                "cvo_title": "0303 Macromolecular and Materials Chemistry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0303",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3050,
                                        "cvr_parent_cvo_id": 451917,
                                        "cvr_child_cvo_id": 451918,
                                        "controlled_vocab": {
                                            "cvo_id": 451918,
                                            "cvo_title": "030301 Chemical Characterisation of Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3051,
                                        "cvr_parent_cvo_id": 451917,
                                        "cvr_child_cvo_id": 451919,
                                        "controlled_vocab": {
                                            "cvo_id": 451919,
                                            "cvo_title": "030302 Nanochemistry and Supramolecular Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3052,
                                        "cvr_parent_cvo_id": 451917,
                                        "cvr_child_cvo_id": 451920,
                                        "controlled_vocab": {
                                            "cvo_id": 451920,
                                            "cvo_title": "030303 Optical Properties of Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3053,
                                        "cvr_parent_cvo_id": 451917,
                                        "cvr_child_cvo_id": 451921,
                                        "controlled_vocab": {
                                            "cvo_id": 451921,
                                            "cvo_title": "030304 Physical Chemistry of Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3054,
                                        "cvr_parent_cvo_id": 451917,
                                        "cvr_child_cvo_id": 451922,
                                        "controlled_vocab": {
                                            "cvo_id": 451922,
                                            "cvo_title": "030305 Polymerisation Mechanisms",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3055,
                                        "cvr_parent_cvo_id": 451917,
                                        "cvr_child_cvo_id": 451923,
                                        "controlled_vocab": {
                                            "cvo_id": 451923,
                                            "cvo_title": "030306 Synthesis of Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3056,
                                        "cvr_parent_cvo_id": 451917,
                                        "cvr_child_cvo_id": 451924,
                                        "controlled_vocab": {
                                            "cvo_id": 451924,
                                            "cvo_title": "030307 Theory and Design of Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3057,
                                        "cvr_parent_cvo_id": 451917,
                                        "cvr_child_cvo_id": 451925,
                                        "controlled_vocab": {
                                            "cvo_id": 451925,
                                            "cvo_title": "030399 Macromolecular and Materials Chemistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3058,
                            "cvr_parent_cvo_id": 451897,
                            "cvr_child_cvo_id": 451926,
                            "controlled_vocab": {
                                "cvo_id": 451926,
                                "cvo_title": "0304 Medicinal and Biomolecular Chemistry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0304",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3059,
                                        "cvr_parent_cvo_id": 451926,
                                        "cvr_child_cvo_id": 451927,
                                        "controlled_vocab": {
                                            "cvo_id": 451927,
                                            "cvo_title": "030401 Biologically Active Molecules",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3060,
                                        "cvr_parent_cvo_id": 451926,
                                        "cvr_child_cvo_id": 451928,
                                        "controlled_vocab": {
                                            "cvo_id": 451928,
                                            "cvo_title": "030402 Biomolecular Modelling and Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3061,
                                        "cvr_parent_cvo_id": 451926,
                                        "cvr_child_cvo_id": 451929,
                                        "controlled_vocab": {
                                            "cvo_id": 451929,
                                            "cvo_title": "030403 Characterisation of Biological Macromolecules",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3062,
                                        "cvr_parent_cvo_id": 451926,
                                        "cvr_child_cvo_id": 451930,
                                        "controlled_vocab": {
                                            "cvo_id": 451930,
                                            "cvo_title": "030404 Cheminformatics and Quantitative Structure-Activity Relationships",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3063,
                                        "cvr_parent_cvo_id": 451926,
                                        "cvr_child_cvo_id": 451931,
                                        "controlled_vocab": {
                                            "cvo_id": 451931,
                                            "cvo_title": "030405 Molecular Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3064,
                                        "cvr_parent_cvo_id": 451926,
                                        "cvr_child_cvo_id": 451932,
                                        "controlled_vocab": {
                                            "cvo_id": 451932,
                                            "cvo_title": "030406 Proteins and Peptides",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3065,
                                        "cvr_parent_cvo_id": 451926,
                                        "cvr_child_cvo_id": 451933,
                                        "controlled_vocab": {
                                            "cvo_id": 451933,
                                            "cvo_title": "030499 Medicinal and Biomolecular Chemistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3066,
                            "cvr_parent_cvo_id": 451897,
                            "cvr_child_cvo_id": 451934,
                            "controlled_vocab": {
                                "cvo_id": 451934,
                                "cvo_title": "0305 Organic Chemistry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0305",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3067,
                                        "cvr_parent_cvo_id": 451934,
                                        "cvr_child_cvo_id": 451935,
                                        "controlled_vocab": {
                                            "cvo_id": 451935,
                                            "cvo_title": "030501 Free Radical Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3068,
                                        "cvr_parent_cvo_id": 451934,
                                        "cvr_child_cvo_id": 451936,
                                        "controlled_vocab": {
                                            "cvo_id": 451936,
                                            "cvo_title": "030502 Natural Products Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3069,
                                        "cvr_parent_cvo_id": 451934,
                                        "cvr_child_cvo_id": 451937,
                                        "controlled_vocab": {
                                            "cvo_id": 451937,
                                            "cvo_title": "030503 Organic Chemical Synthesis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3070,
                                        "cvr_parent_cvo_id": 451934,
                                        "cvr_child_cvo_id": 451938,
                                        "controlled_vocab": {
                                            "cvo_id": 451938,
                                            "cvo_title": "030504 Organic Green Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3071,
                                        "cvr_parent_cvo_id": 451934,
                                        "cvr_child_cvo_id": 451939,
                                        "controlled_vocab": {
                                            "cvo_id": 451939,
                                            "cvo_title": "030505 Physical Organic Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3072,
                                        "cvr_parent_cvo_id": 451934,
                                        "cvr_child_cvo_id": 451940,
                                        "controlled_vocab": {
                                            "cvo_id": 451940,
                                            "cvo_title": "030599 Organic Chemistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3073,
                            "cvr_parent_cvo_id": 451897,
                            "cvr_child_cvo_id": 451941,
                            "controlled_vocab": {
                                "cvo_id": 451941,
                                "cvo_title": "0306 Physical Chemistry (incl. Structural)",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0306",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3074,
                                        "cvr_parent_cvo_id": 451941,
                                        "cvr_child_cvo_id": 451942,
                                        "controlled_vocab": {
                                            "cvo_id": 451942,
                                            "cvo_title": "030601 Catalysis and Mechanisms of Reactions",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3075,
                                        "cvr_parent_cvo_id": 451941,
                                        "cvr_child_cvo_id": 451943,
                                        "controlled_vocab": {
                                            "cvo_id": 451943,
                                            "cvo_title": "030602 Chemical Thermodynamics and Energetics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3076,
                                        "cvr_parent_cvo_id": 451941,
                                        "cvr_child_cvo_id": 451944,
                                        "controlled_vocab": {
                                            "cvo_id": 451944,
                                            "cvo_title": "030603 Colloid and Surface Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3077,
                                        "cvr_parent_cvo_id": 451941,
                                        "cvr_child_cvo_id": 451945,
                                        "controlled_vocab": {
                                            "cvo_id": 451945,
                                            "cvo_title": "030604 Electrochemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3078,
                                        "cvr_parent_cvo_id": 451941,
                                        "cvr_child_cvo_id": 451946,
                                        "controlled_vocab": {
                                            "cvo_id": 451946,
                                            "cvo_title": "030605 Solution Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030605",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3079,
                                        "cvr_parent_cvo_id": 451941,
                                        "cvr_child_cvo_id": 451947,
                                        "controlled_vocab": {
                                            "cvo_id": 451947,
                                            "cvo_title": "030606 Structural Chemistry and Spectroscopy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030606",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3080,
                                        "cvr_parent_cvo_id": 451941,
                                        "cvr_child_cvo_id": 451948,
                                        "controlled_vocab": {
                                            "cvo_id": 451948,
                                            "cvo_title": "030607 Transport Properties and Non-equilibrium Processes",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030607",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3081,
                                        "cvr_parent_cvo_id": 451941,
                                        "cvr_child_cvo_id": 451949,
                                        "controlled_vocab": {
                                            "cvo_id": 451949,
                                            "cvo_title": "030699 Physical Chemistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3082,
                            "cvr_parent_cvo_id": 451897,
                            "cvr_child_cvo_id": 451950,
                            "controlled_vocab": {
                                "cvo_id": 451950,
                                "cvo_title": "0307 Theoretical and Computational Chemistry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0307",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3083,
                                        "cvr_parent_cvo_id": 451950,
                                        "cvr_child_cvo_id": 451951,
                                        "controlled_vocab": {
                                            "cvo_id": 451951,
                                            "cvo_title": "030701 Quantum Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3084,
                                        "cvr_parent_cvo_id": 451950,
                                        "cvr_child_cvo_id": 451952,
                                        "controlled_vocab": {
                                            "cvo_id": 451952,
                                            "cvo_title": "030702 Radiation and Matter",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3085,
                                        "cvr_parent_cvo_id": 451950,
                                        "cvr_child_cvo_id": 451953,
                                        "controlled_vocab": {
                                            "cvo_id": 451953,
                                            "cvo_title": "030703 Reaction Kinetics and Dynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3086,
                                        "cvr_parent_cvo_id": 451950,
                                        "cvr_child_cvo_id": 451954,
                                        "controlled_vocab": {
                                            "cvo_id": 451954,
                                            "cvo_title": "030704 Statistical Mechanics in Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030704",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3087,
                                        "cvr_parent_cvo_id": 451950,
                                        "cvr_child_cvo_id": 451955,
                                        "controlled_vocab": {
                                            "cvo_id": 451955,
                                            "cvo_title": "030799 Theoretical and Computational Chemistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "030799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3088,
                            "cvr_parent_cvo_id": 451897,
                            "cvr_child_cvo_id": 451956,
                            "controlled_vocab": {
                                "cvo_id": 451956,
                                "cvo_title": "0399 Other Chemical Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0399",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3089,
                                        "cvr_parent_cvo_id": 451956,
                                        "cvr_child_cvo_id": 451957,
                                        "controlled_vocab": {
                                            "cvo_id": 451957,
                                            "cvo_title": "039901 Environmental Chemistry (incl. Atmospheric Chemistry)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "039901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3090,
                                        "cvr_parent_cvo_id": 451956,
                                        "cvr_child_cvo_id": 451958,
                                        "controlled_vocab": {
                                            "cvo_id": 451958,
                                            "cvo_title": "039902 Forensic Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "039902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3091,
                                        "cvr_parent_cvo_id": 451956,
                                        "cvr_child_cvo_id": 451959,
                                        "controlled_vocab": {
                                            "cvo_id": 451959,
                                            "cvo_title": "039903 Industrial Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "039903",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3092,
                                        "cvr_parent_cvo_id": 451956,
                                        "cvr_child_cvo_id": 451960,
                                        "controlled_vocab": {
                                            "cvo_id": 451960,
                                            "cvo_title": "039904 Organometallic Chemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "039904",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3093,
                                        "cvr_parent_cvo_id": 451956,
                                        "cvr_child_cvo_id": 451961,
                                        "controlled_vocab": {
                                            "cvo_id": 451961,
                                            "cvo_title": "039999 Chemical Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "039999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3094,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 451962,
                "controlled_vocab": {
                    "cvo_id": 451962,
                    "cvo_title": "04 Earth Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "04",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3095,
                            "cvr_parent_cvo_id": 451962,
                            "cvr_child_cvo_id": 451963,
                            "controlled_vocab": {
                                "cvo_id": 451963,
                                "cvo_title": "0401 Atmospheric Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0401",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3096,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451964,
                                        "controlled_vocab": {
                                            "cvo_id": 451964,
                                            "cvo_title": "040101 Atmospheric Aerosols",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3097,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451965,
                                        "controlled_vocab": {
                                            "cvo_id": 451965,
                                            "cvo_title": "040102 Atmospheric Dynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3098,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451966,
                                        "controlled_vocab": {
                                            "cvo_id": 451966,
                                            "cvo_title": "040103 Atmospheric Radiation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3099,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451967,
                                        "controlled_vocab": {
                                            "cvo_id": 451967,
                                            "cvo_title": "040104 Climate Change Processes",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3100,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451968,
                                        "controlled_vocab": {
                                            "cvo_id": 451968,
                                            "cvo_title": "040105 Climatology (excl.Climate Change Processes)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3101,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451969,
                                        "controlled_vocab": {
                                            "cvo_id": 451969,
                                            "cvo_title": "040106 Cloud Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3102,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451970,
                                        "controlled_vocab": {
                                            "cvo_id": 451970,
                                            "cvo_title": "040107 Meteorology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3103,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451971,
                                        "controlled_vocab": {
                                            "cvo_id": 451971,
                                            "cvo_title": "040108 Tropospheric and Stratospheric Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3104,
                                        "cvr_parent_cvo_id": 451963,
                                        "cvr_child_cvo_id": 451972,
                                        "controlled_vocab": {
                                            "cvo_id": 451972,
                                            "cvo_title": "040199 Atmospheric Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3105,
                            "cvr_parent_cvo_id": 451962,
                            "cvr_child_cvo_id": 451973,
                            "controlled_vocab": {
                                "cvo_id": 451973,
                                "cvo_title": "0402 Geochemistry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0402",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3106,
                                        "cvr_parent_cvo_id": 451973,
                                        "cvr_child_cvo_id": 451974,
                                        "controlled_vocab": {
                                            "cvo_id": 451974,
                                            "cvo_title": "040201 Exploration Geochemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3107,
                                        "cvr_parent_cvo_id": 451973,
                                        "cvr_child_cvo_id": 451975,
                                        "controlled_vocab": {
                                            "cvo_id": 451975,
                                            "cvo_title": "040202 Inorganic Geochemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3108,
                                        "cvr_parent_cvo_id": 451973,
                                        "cvr_child_cvo_id": 451976,
                                        "controlled_vocab": {
                                            "cvo_id": 451976,
                                            "cvo_title": "040203 Isotope Geochemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3109,
                                        "cvr_parent_cvo_id": 451973,
                                        "cvr_child_cvo_id": 451977,
                                        "controlled_vocab": {
                                            "cvo_id": 451977,
                                            "cvo_title": "040204 Organic Geochemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3110,
                                        "cvr_parent_cvo_id": 451973,
                                        "cvr_child_cvo_id": 451978,
                                        "controlled_vocab": {
                                            "cvo_id": 451978,
                                            "cvo_title": "040299 Geochemistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3111,
                            "cvr_parent_cvo_id": 451962,
                            "cvr_child_cvo_id": 451979,
                            "controlled_vocab": {
                                "cvo_id": 451979,
                                "cvo_title": "0403 Geology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0403",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3112,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451980,
                                        "controlled_vocab": {
                                            "cvo_id": 451980,
                                            "cvo_title": "040301 Basin Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3113,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451981,
                                        "controlled_vocab": {
                                            "cvo_id": 451981,
                                            "cvo_title": "040302 Extraterrestrial Geology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3114,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451982,
                                        "controlled_vocab": {
                                            "cvo_id": 451982,
                                            "cvo_title": "040303 Geochronology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3115,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451983,
                                        "controlled_vocab": {
                                            "cvo_id": 451983,
                                            "cvo_title": "040304 Igneous and Metamorphic Petrology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3116,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451984,
                                        "controlled_vocab": {
                                            "cvo_id": 451984,
                                            "cvo_title": "040305 Marine Geoscience",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3117,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451985,
                                        "controlled_vocab": {
                                            "cvo_id": 451985,
                                            "cvo_title": "040306 Mineralogy and Crystallography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3118,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451986,
                                        "controlled_vocab": {
                                            "cvo_id": 451986,
                                            "cvo_title": "040307 Ore Deposit Petrology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3119,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451987,
                                        "controlled_vocab": {
                                            "cvo_id": 451987,
                                            "cvo_title": "040308 Palaeontology (incl.Palynology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3120,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451988,
                                        "controlled_vocab": {
                                            "cvo_id": 451988,
                                            "cvo_title": "040309 Petroleum and Coal Geology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3121,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451989,
                                        "controlled_vocab": {
                                            "cvo_id": 451989,
                                            "cvo_title": "040310 Sedimentology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040310",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3122,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451990,
                                        "controlled_vocab": {
                                            "cvo_id": 451990,
                                            "cvo_title": "040311 Stratigraphy (incl. Biostratigraphy and Sequence Stratigraphy)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040311",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3123,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451991,
                                        "controlled_vocab": {
                                            "cvo_id": 451991,
                                            "cvo_title": "040312 Structural Geology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040312",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3124,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451992,
                                        "controlled_vocab": {
                                            "cvo_id": 451992,
                                            "cvo_title": "040313 Tectonics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040313",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3125,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451993,
                                        "controlled_vocab": {
                                            "cvo_id": 451993,
                                            "cvo_title": "040314 Volcanology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040314",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3126,
                                        "cvr_parent_cvo_id": 451979,
                                        "cvr_child_cvo_id": 451994,
                                        "controlled_vocab": {
                                            "cvo_id": 451994,
                                            "cvo_title": "040399 Geology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3127,
                            "cvr_parent_cvo_id": 451962,
                            "cvr_child_cvo_id": 451995,
                            "controlled_vocab": {
                                "cvo_id": 451995,
                                "cvo_title": "0404 Geophysics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0404",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3128,
                                        "cvr_parent_cvo_id": 451995,
                                        "cvr_child_cvo_id": 451996,
                                        "controlled_vocab": {
                                            "cvo_id": 451996,
                                            "cvo_title": "040401 Electrical and Electromagnetic Methods in Geophysics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3129,
                                        "cvr_parent_cvo_id": 451995,
                                        "cvr_child_cvo_id": 451997,
                                        "controlled_vocab": {
                                            "cvo_id": 451997,
                                            "cvo_title": "040402 Geodynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3130,
                                        "cvr_parent_cvo_id": 451995,
                                        "cvr_child_cvo_id": 451998,
                                        "controlled_vocab": {
                                            "cvo_id": 451998,
                                            "cvo_title": "040403 Geophysical Fluid Dynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3131,
                                        "cvr_parent_cvo_id": 451995,
                                        "cvr_child_cvo_id": 451999,
                                        "controlled_vocab": {
                                            "cvo_id": 451999,
                                            "cvo_title": "040404 Geothermics and Radiometrics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3132,
                                        "cvr_parent_cvo_id": 451995,
                                        "cvr_child_cvo_id": 452000,
                                        "controlled_vocab": {
                                            "cvo_id": 452000,
                                            "cvo_title": "040405 Gravimetrics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3133,
                                        "cvr_parent_cvo_id": 451995,
                                        "cvr_child_cvo_id": 452001,
                                        "controlled_vocab": {
                                            "cvo_id": 452001,
                                            "cvo_title": "040406 Magnetism and Palaeomagnetism",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3134,
                                        "cvr_parent_cvo_id": 451995,
                                        "cvr_child_cvo_id": 452002,
                                        "controlled_vocab": {
                                            "cvo_id": 452002,
                                            "cvo_title": "040407 Seismology and Seismic Exploration",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040407",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3135,
                                        "cvr_parent_cvo_id": 451995,
                                        "cvr_child_cvo_id": 452003,
                                        "controlled_vocab": {
                                            "cvo_id": 452003,
                                            "cvo_title": "040499 Geophysics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3136,
                            "cvr_parent_cvo_id": 451962,
                            "cvr_child_cvo_id": 452004,
                            "controlled_vocab": {
                                "cvo_id": 452004,
                                "cvo_title": "0405 Oceanography",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0405",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3137,
                                        "cvr_parent_cvo_id": 452004,
                                        "cvr_child_cvo_id": 452005,
                                        "controlled_vocab": {
                                            "cvo_id": 452005,
                                            "cvo_title": "040501 Biological Oceanography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3138,
                                        "cvr_parent_cvo_id": 452004,
                                        "cvr_child_cvo_id": 452006,
                                        "controlled_vocab": {
                                            "cvo_id": 452006,
                                            "cvo_title": "040502 Chemical Oceanography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3139,
                                        "cvr_parent_cvo_id": 452004,
                                        "cvr_child_cvo_id": 452007,
                                        "controlled_vocab": {
                                            "cvo_id": 452007,
                                            "cvo_title": "040503 Physical Oceanography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3140,
                                        "cvr_parent_cvo_id": 452004,
                                        "cvr_child_cvo_id": 452008,
                                        "controlled_vocab": {
                                            "cvo_id": 452008,
                                            "cvo_title": "040599 Oceanography not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3141,
                            "cvr_parent_cvo_id": 451962,
                            "cvr_child_cvo_id": 452009,
                            "controlled_vocab": {
                                "cvo_id": 452009,
                                "cvo_title": "0406 Physical Geography and Environmental Geoscience",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0406",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3142,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452010,
                                        "controlled_vocab": {
                                            "cvo_id": 452010,
                                            "cvo_title": "040601 Geomorphology and Regolith and Landscape Evolution",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3143,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452011,
                                        "controlled_vocab": {
                                            "cvo_id": 452011,
                                            "cvo_title": "040602 Glaciology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3144,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452012,
                                        "controlled_vocab": {
                                            "cvo_id": 452012,
                                            "cvo_title": "040603 Hydrogeology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3145,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452013,
                                        "controlled_vocab": {
                                            "cvo_id": 452013,
                                            "cvo_title": "040604 Natural Hazards",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3146,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452014,
                                        "controlled_vocab": {
                                            "cvo_id": 452014,
                                            "cvo_title": "040605 Palaeoclimatology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040605",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3147,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452015,
                                        "controlled_vocab": {
                                            "cvo_id": 452015,
                                            "cvo_title": "040606 Quaternary Environments",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040606",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3148,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452016,
                                        "controlled_vocab": {
                                            "cvo_id": 452016,
                                            "cvo_title": "040607 Surface Processes",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040607",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3149,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452017,
                                        "controlled_vocab": {
                                            "cvo_id": 452017,
                                            "cvo_title": "040608 Surfacewater Hydrology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040608",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3150,
                                        "cvr_parent_cvo_id": 452009,
                                        "cvr_child_cvo_id": 452018,
                                        "controlled_vocab": {
                                            "cvo_id": 452018,
                                            "cvo_title": "040699 Physical Geography and Environmental Geoscience not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "040699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3151,
                            "cvr_parent_cvo_id": 451962,
                            "cvr_child_cvo_id": 452019,
                            "controlled_vocab": {
                                "cvo_id": 452019,
                                "cvo_title": "0499 Other Earth Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0499",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3152,
                                        "cvr_parent_cvo_id": 452019,
                                        "cvr_child_cvo_id": 452020,
                                        "controlled_vocab": {
                                            "cvo_id": 452020,
                                            "cvo_title": "049999 Earth Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "049999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3153,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452021,
                "controlled_vocab": {
                    "cvo_id": 452021,
                    "cvo_title": "05 Environmental Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "05",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3154,
                            "cvr_parent_cvo_id": 452021,
                            "cvr_child_cvo_id": 452022,
                            "controlled_vocab": {
                                "cvo_id": 452022,
                                "cvo_title": "0501 Ecological Applications",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0501",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3155,
                                        "cvr_parent_cvo_id": 452022,
                                        "cvr_child_cvo_id": 452023,
                                        "controlled_vocab": {
                                            "cvo_id": 452023,
                                            "cvo_title": "050101 Ecological Impacts of Climate Change",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3156,
                                        "cvr_parent_cvo_id": 452022,
                                        "cvr_child_cvo_id": 452024,
                                        "controlled_vocab": {
                                            "cvo_id": 452024,
                                            "cvo_title": "050102 Ecosystem Function",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3157,
                                        "cvr_parent_cvo_id": 452022,
                                        "cvr_child_cvo_id": 452025,
                                        "controlled_vocab": {
                                            "cvo_id": 452025,
                                            "cvo_title": "050103 Invasive Species Ecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3158,
                                        "cvr_parent_cvo_id": 452022,
                                        "cvr_child_cvo_id": 452026,
                                        "controlled_vocab": {
                                            "cvo_id": 452026,
                                            "cvo_title": "050104 Landscape Ecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3159,
                                        "cvr_parent_cvo_id": 452022,
                                        "cvr_child_cvo_id": 452027,
                                        "controlled_vocab": {
                                            "cvo_id": 452027,
                                            "cvo_title": "050199 Ecological Applications not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3160,
                            "cvr_parent_cvo_id": 452021,
                            "cvr_child_cvo_id": 452028,
                            "controlled_vocab": {
                                "cvo_id": 452028,
                                "cvo_title": "0502 Environmental Science and Management",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0502",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3161,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452029,
                                        "controlled_vocab": {
                                            "cvo_id": 452029,
                                            "cvo_title": "050201 Aboriginal and Torres Strait Islander Environmental Knowledge",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3162,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452030,
                                        "controlled_vocab": {
                                            "cvo_id": 452030,
                                            "cvo_title": "050202 Conservation and Biodiversity",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3163,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452031,
                                        "controlled_vocab": {
                                            "cvo_id": 452031,
                                            "cvo_title": "050203 Environmental Education and Extension",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3164,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452032,
                                        "controlled_vocab": {
                                            "cvo_id": 452032,
                                            "cvo_title": "050204 Environmental Impact Assessment",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3165,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452033,
                                        "controlled_vocab": {
                                            "cvo_id": 452033,
                                            "cvo_title": "050205 Environmental Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3166,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452034,
                                        "controlled_vocab": {
                                            "cvo_id": 452034,
                                            "cvo_title": "050206 Environmental Monitoring",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3167,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452035,
                                        "controlled_vocab": {
                                            "cvo_id": 452035,
                                            "cvo_title": "050207 Environmental Rehabilitation (excl. Bioremediation)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3168,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452036,
                                        "controlled_vocab": {
                                            "cvo_id": 452036,
                                            "cvo_title": "050208 Maori Environmental Knowledge",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050208",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3169,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452037,
                                        "controlled_vocab": {
                                            "cvo_id": 452037,
                                            "cvo_title": "050209 Natural Resource Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050209",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3170,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452038,
                                        "controlled_vocab": {
                                            "cvo_id": 452038,
                                            "cvo_title": "050210 Pacific Peoples Environmental Knowledge",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050210",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3171,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452039,
                                        "controlled_vocab": {
                                            "cvo_id": 452039,
                                            "cvo_title": "050211 Wildlife and Habitat Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050211",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3172,
                                        "cvr_parent_cvo_id": 452028,
                                        "cvr_child_cvo_id": 452040,
                                        "controlled_vocab": {
                                            "cvo_id": 452040,
                                            "cvo_title": "050299 Environmental Science and Management not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3173,
                            "cvr_parent_cvo_id": 452021,
                            "cvr_child_cvo_id": 452041,
                            "controlled_vocab": {
                                "cvo_id": 452041,
                                "cvo_title": "0503 Soil Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0503",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3174,
                                        "cvr_parent_cvo_id": 452041,
                                        "cvr_child_cvo_id": 452042,
                                        "controlled_vocab": {
                                            "cvo_id": 452042,
                                            "cvo_title": "050301 Carbon Sequestration Science",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3175,
                                        "cvr_parent_cvo_id": 452041,
                                        "cvr_child_cvo_id": 452043,
                                        "controlled_vocab": {
                                            "cvo_id": 452043,
                                            "cvo_title": "050302 Land Capability and Soil Degradation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3176,
                                        "cvr_parent_cvo_id": 452041,
                                        "cvr_child_cvo_id": 452044,
                                        "controlled_vocab": {
                                            "cvo_id": 452044,
                                            "cvo_title": "050303 Soil Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3177,
                                        "cvr_parent_cvo_id": 452041,
                                        "cvr_child_cvo_id": 452045,
                                        "controlled_vocab": {
                                            "cvo_id": 452045,
                                            "cvo_title": "050304 Soil Chemistry (excl. Carbon Sequestration Science)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3178,
                                        "cvr_parent_cvo_id": 452041,
                                        "cvr_child_cvo_id": 452046,
                                        "controlled_vocab": {
                                            "cvo_id": 452046,
                                            "cvo_title": "050305 Soil Physics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3179,
                                        "cvr_parent_cvo_id": 452041,
                                        "cvr_child_cvo_id": 452047,
                                        "controlled_vocab": {
                                            "cvo_id": 452047,
                                            "cvo_title": "050399 Soil Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "050399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3180,
                            "cvr_parent_cvo_id": 452021,
                            "cvr_child_cvo_id": 452048,
                            "controlled_vocab": {
                                "cvo_id": 452048,
                                "cvo_title": "0599 Other Environmental Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0599",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3181,
                                        "cvr_parent_cvo_id": 452048,
                                        "cvr_child_cvo_id": 452049,
                                        "controlled_vocab": {
                                            "cvo_id": 452049,
                                            "cvo_title": "059999 Environmental Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "059999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3182,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452050,
                "controlled_vocab": {
                    "cvo_id": 452050,
                    "cvo_title": "06 Biological Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "06",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3183,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452051,
                            "controlled_vocab": {
                                "cvo_id": 452051,
                                "cvo_title": "0601 Biochemistry and Cell Biology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0601",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3184,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452052,
                                        "controlled_vocab": {
                                            "cvo_id": 452052,
                                            "cvo_title": "060101 Analytical Biochemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3185,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452053,
                                        "controlled_vocab": {
                                            "cvo_id": 452053,
                                            "cvo_title": "060102 Bioinformatics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3186,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452054,
                                        "controlled_vocab": {
                                            "cvo_id": 452054,
                                            "cvo_title": "060103 Cell Development, Proliferation and Death",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3187,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452055,
                                        "controlled_vocab": {
                                            "cvo_id": 452055,
                                            "cvo_title": "060104 Cell Metabolism",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3188,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452056,
                                        "controlled_vocab": {
                                            "cvo_id": 452056,
                                            "cvo_title": "060105 Cell Neurochemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3189,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452057,
                                        "controlled_vocab": {
                                            "cvo_id": 452057,
                                            "cvo_title": "060106 Cellular Interactions (incl. Adhesion, Matrix, Cell Wall)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3190,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452058,
                                        "controlled_vocab": {
                                            "cvo_id": 452058,
                                            "cvo_title": "060107 Enzymes",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3191,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452059,
                                        "controlled_vocab": {
                                            "cvo_id": 452059,
                                            "cvo_title": "060108 Protein Trafficking",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3192,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452060,
                                        "controlled_vocab": {
                                            "cvo_id": 452060,
                                            "cvo_title": "060109 Proteomics and Intermolecular Interactions (excl. Medical Proteomics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060109",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3193,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452061,
                                        "controlled_vocab": {
                                            "cvo_id": 452061,
                                            "cvo_title": "060110 Receptors and Membrane Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060110",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3194,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452062,
                                        "controlled_vocab": {
                                            "cvo_id": 452062,
                                            "cvo_title": "060111 Signal Transduction",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060111",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3195,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452063,
                                        "controlled_vocab": {
                                            "cvo_id": 452063,
                                            "cvo_title": "060112 Structural Biology (incl. Macromolecular Modelling)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060112",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3196,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452064,
                                        "controlled_vocab": {
                                            "cvo_id": 452064,
                                            "cvo_title": "060113 Synthetic Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060113",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3197,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452065,
                                        "controlled_vocab": {
                                            "cvo_id": 452065,
                                            "cvo_title": "060114 Systems Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060114",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3198,
                                        "cvr_parent_cvo_id": 452051,
                                        "cvr_child_cvo_id": 452066,
                                        "controlled_vocab": {
                                            "cvo_id": 452066,
                                            "cvo_title": "060199 Biochemistry and Cell Biology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3199,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452067,
                            "controlled_vocab": {
                                "cvo_id": 452067,
                                "cvo_title": "0602 Ecology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0602",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3200,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452068,
                                        "controlled_vocab": {
                                            "cvo_id": 452068,
                                            "cvo_title": "060201 Behavioural Ecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3201,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452069,
                                        "controlled_vocab": {
                                            "cvo_id": 452069,
                                            "cvo_title": "060202 Community Ecology(excl. Invasive Species Ecology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3202,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452070,
                                        "controlled_vocab": {
                                            "cvo_id": 452070,
                                            "cvo_title": "060203 Ecological Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3203,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452071,
                                        "controlled_vocab": {
                                            "cvo_id": 452071,
                                            "cvo_title": "060204 Freshwater Ecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3204,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452072,
                                        "controlled_vocab": {
                                            "cvo_id": 452072,
                                            "cvo_title": "060205 Marine and Estuarine Ecology (incl. Marine Ichthyology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3205,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452073,
                                        "controlled_vocab": {
                                            "cvo_id": 452073,
                                            "cvo_title": "060206 Palaeoecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3206,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452074,
                                        "controlled_vocab": {
                                            "cvo_id": 452074,
                                            "cvo_title": "060207 Population Ecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3207,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452075,
                                        "controlled_vocab": {
                                            "cvo_id": 452075,
                                            "cvo_title": "060208 Terrestrial Ecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060208",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3208,
                                        "cvr_parent_cvo_id": 452067,
                                        "cvr_child_cvo_id": 452076,
                                        "controlled_vocab": {
                                            "cvo_id": 452076,
                                            "cvo_title": "060299 Ecology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3209,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452077,
                            "controlled_vocab": {
                                "cvo_id": 452077,
                                "cvo_title": "0603 Evolutionary Biology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0603",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3210,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452078,
                                        "controlled_vocab": {
                                            "cvo_id": 452078,
                                            "cvo_title": "060301 Animal Systematics and Taxonomy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3211,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452079,
                                        "controlled_vocab": {
                                            "cvo_id": 452079,
                                            "cvo_title": "060302 Biogeography and Phylogeography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3212,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452080,
                                        "controlled_vocab": {
                                            "cvo_id": 452080,
                                            "cvo_title": "060303 Biological Adaptation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3213,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452081,
                                        "controlled_vocab": {
                                            "cvo_id": 452081,
                                            "cvo_title": "060304 Ethology and Sociobiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3214,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452082,
                                        "controlled_vocab": {
                                            "cvo_id": 452082,
                                            "cvo_title": "060305 Evolution of Developmental Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3215,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452083,
                                        "controlled_vocab": {
                                            "cvo_id": 452083,
                                            "cvo_title": "060306 Evolutionary Impacts of Climate Change",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3216,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452084,
                                        "controlled_vocab": {
                                            "cvo_id": 452084,
                                            "cvo_title": "060307 Host-Parasite Interactions",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3217,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452085,
                                        "controlled_vocab": {
                                            "cvo_id": 452085,
                                            "cvo_title": "060308 Life Histories",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3218,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452086,
                                        "controlled_vocab": {
                                            "cvo_id": 452086,
                                            "cvo_title": "060309 Phylogeny and Comparative Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3219,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452087,
                                        "controlled_vocab": {
                                            "cvo_id": 452087,
                                            "cvo_title": "060310 Plant Systematics and Taxonomy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060310",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3220,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452088,
                                        "controlled_vocab": {
                                            "cvo_id": 452088,
                                            "cvo_title": "060311 Speciation and Extinction",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060311",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3221,
                                        "cvr_parent_cvo_id": 452077,
                                        "cvr_child_cvo_id": 452089,
                                        "controlled_vocab": {
                                            "cvo_id": 452089,
                                            "cvo_title": "060399 Evolutionary Biology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3222,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452090,
                            "controlled_vocab": {
                                "cvo_id": 452090,
                                "cvo_title": "0604 Genetics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0604",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3224,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452092,
                                        "controlled_vocab": {
                                            "cvo_id": 452092,
                                            "cvo_title": "060401 Anthropological Genetics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3225,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452093,
                                        "controlled_vocab": {
                                            "cvo_id": 452093,
                                            "cvo_title": "060402 Cell and Nuclear Division",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3226,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452094,
                                        "controlled_vocab": {
                                            "cvo_id": 452094,
                                            "cvo_title": "060403 Developmental Genetics (incl. Sex Determination)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3227,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452095,
                                        "controlled_vocab": {
                                            "cvo_id": 452095,
                                            "cvo_title": "060404 Epigenetics (incl.Genome Methylation and Epigenomics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3228,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452096,
                                        "controlled_vocab": {
                                            "cvo_id": 452096,
                                            "cvo_title": "060405 Gene Expression (incl. Microarray and other genome-wide approaches)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3229,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452097,
                                        "controlled_vocab": {
                                            "cvo_id": 452097,
                                            "cvo_title": "060406 Genetic Immunology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3230,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452098,
                                        "controlled_vocab": {
                                            "cvo_id": 452098,
                                            "cvo_title": "060407 Genome Structure and Regulation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060407",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3231,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452099,
                                        "controlled_vocab": {
                                            "cvo_id": 452099,
                                            "cvo_title": "060408 Genomics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060408",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3232,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452100,
                                        "controlled_vocab": {
                                            "cvo_id": 452100,
                                            "cvo_title": "060409 Molecular Evolution",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060409",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3233,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452101,
                                        "controlled_vocab": {
                                            "cvo_id": 452101,
                                            "cvo_title": "060410 Neurogenetics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060410",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3234,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452102,
                                        "controlled_vocab": {
                                            "cvo_id": 452102,
                                            "cvo_title": "060411 Population, Ecological and Evolutionary Genetics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060411",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3235,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452103,
                                        "controlled_vocab": {
                                            "cvo_id": 452103,
                                            "cvo_title": "060412 Quantitative Genetics (incl. Disease and Trait Mapping Genetics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060412",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3236,
                                        "cvr_parent_cvo_id": 452090,
                                        "cvr_child_cvo_id": 452104,
                                        "controlled_vocab": {
                                            "cvo_id": 452104,
                                            "cvo_title": "060499 Genetics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3237,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452105,
                            "controlled_vocab": {
                                "cvo_id": 452105,
                                "cvo_title": "0605 Microbiology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0605",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3238,
                                        "cvr_parent_cvo_id": 452105,
                                        "cvr_child_cvo_id": 452106,
                                        "controlled_vocab": {
                                            "cvo_id": 452106,
                                            "cvo_title": "060501 Bacteriology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3239,
                                        "cvr_parent_cvo_id": 452105,
                                        "cvr_child_cvo_id": 452107,
                                        "controlled_vocab": {
                                            "cvo_id": 452107,
                                            "cvo_title": "060502 Infectious Agents",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3240,
                                        "cvr_parent_cvo_id": 452105,
                                        "cvr_child_cvo_id": 452108,
                                        "controlled_vocab": {
                                            "cvo_id": 452108,
                                            "cvo_title": "060503 Microbial Genetics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3241,
                                        "cvr_parent_cvo_id": 452105,
                                        "cvr_child_cvo_id": 452109,
                                        "controlled_vocab": {
                                            "cvo_id": 452109,
                                            "cvo_title": "060504 Microbial Ecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3242,
                                        "cvr_parent_cvo_id": 452105,
                                        "cvr_child_cvo_id": 452110,
                                        "controlled_vocab": {
                                            "cvo_id": 452110,
                                            "cvo_title": "060505 Mycology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3243,
                                        "cvr_parent_cvo_id": 452105,
                                        "cvr_child_cvo_id": 452111,
                                        "controlled_vocab": {
                                            "cvo_id": 452111,
                                            "cvo_title": "060506 Virology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3244,
                                        "cvr_parent_cvo_id": 452105,
                                        "cvr_child_cvo_id": 452112,
                                        "controlled_vocab": {
                                            "cvo_id": 452112,
                                            "cvo_title": "060599 Microbiology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3245,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452113,
                            "controlled_vocab": {
                                "cvo_id": 452113,
                                "cvo_title": "0606 Physiology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0606",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3247,
                                        "cvr_parent_cvo_id": 452113,
                                        "cvr_child_cvo_id": 452115,
                                        "controlled_vocab": {
                                            "cvo_id": 452115,
                                            "cvo_title": "060601 Animal Physiology - Biophysics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3248,
                                        "cvr_parent_cvo_id": 452113,
                                        "cvr_child_cvo_id": 452116,
                                        "controlled_vocab": {
                                            "cvo_id": 452116,
                                            "cvo_title": "060602 Animal Physiology - Cell",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3249,
                                        "cvr_parent_cvo_id": 452113,
                                        "cvr_child_cvo_id": 452117,
                                        "controlled_vocab": {
                                            "cvo_id": 452117,
                                            "cvo_title": "060603 Animal Physiology - Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3250,
                                        "cvr_parent_cvo_id": 452113,
                                        "cvr_child_cvo_id": 452118,
                                        "controlled_vocab": {
                                            "cvo_id": 452118,
                                            "cvo_title": "060604 Comparative Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3251,
                                        "cvr_parent_cvo_id": 452113,
                                        "cvr_child_cvo_id": 452119,
                                        "controlled_vocab": {
                                            "cvo_id": 452119,
                                            "cvo_title": "060699 Physiology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3252,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452120,
                            "controlled_vocab": {
                                "cvo_id": 452120,
                                "cvo_title": "0607 Plant Biology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0607",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3253,
                                        "cvr_parent_cvo_id": 452120,
                                        "cvr_child_cvo_id": 452121,
                                        "controlled_vocab": {
                                            "cvo_id": 452121,
                                            "cvo_title": "060701 Phycology (incl. Marine Grasses)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3254,
                                        "cvr_parent_cvo_id": 452120,
                                        "cvr_child_cvo_id": 452122,
                                        "controlled_vocab": {
                                            "cvo_id": 452122,
                                            "cvo_title": "060702 Plant Cell and Molecular Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3255,
                                        "cvr_parent_cvo_id": 452120,
                                        "cvr_child_cvo_id": 452123,
                                        "controlled_vocab": {
                                            "cvo_id": 452123,
                                            "cvo_title": "060703 Plant Developmental and Reproductive Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3256,
                                        "cvr_parent_cvo_id": 452120,
                                        "cvr_child_cvo_id": 452124,
                                        "controlled_vocab": {
                                            "cvo_id": 452124,
                                            "cvo_title": "060704 Plant Pathology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060704",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3257,
                                        "cvr_parent_cvo_id": 452120,
                                        "cvr_child_cvo_id": 452125,
                                        "controlled_vocab": {
                                            "cvo_id": 452125,
                                            "cvo_title": "060705 Plant Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060705",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3258,
                                        "cvr_parent_cvo_id": 452120,
                                        "cvr_child_cvo_id": 452126,
                                        "controlled_vocab": {
                                            "cvo_id": 452126,
                                            "cvo_title": "060799 Plant Biology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3259,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452127,
                            "controlled_vocab": {
                                "cvo_id": 452127,
                                "cvo_title": "0608 Zoology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0608",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3260,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452128,
                                        "controlled_vocab": {
                                            "cvo_id": 452128,
                                            "cvo_title": "060801 Animal Behaviour",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060801",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3261,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452129,
                                        "controlled_vocab": {
                                            "cvo_id": 452129,
                                            "cvo_title": "060802 Animal Cell and Molecular Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060802",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3262,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452130,
                                        "controlled_vocab": {
                                            "cvo_id": 452130,
                                            "cvo_title": "060803 Animal Developmental and Reproductive Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060803",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3263,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452131,
                                        "controlled_vocab": {
                                            "cvo_id": 452131,
                                            "cvo_title": "060804 Animal Immunology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060804",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3264,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452132,
                                        "controlled_vocab": {
                                            "cvo_id": 452132,
                                            "cvo_title": "060805 Animal Neurobiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060805",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3265,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452133,
                                        "controlled_vocab": {
                                            "cvo_id": 452133,
                                            "cvo_title": "060806 Animal Physiological Ecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060806",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3266,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452134,
                                        "controlled_vocab": {
                                            "cvo_id": 452134,
                                            "cvo_title": "060807 Animal Structure and Function",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060807",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3267,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452135,
                                        "controlled_vocab": {
                                            "cvo_id": 452135,
                                            "cvo_title": "060808 Invertebrate Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060808",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3268,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452136,
                                        "controlled_vocab": {
                                            "cvo_id": 452136,
                                            "cvo_title": "060809 Vertebrate Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060809",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3269,
                                        "cvr_parent_cvo_id": 452127,
                                        "cvr_child_cvo_id": 452137,
                                        "controlled_vocab": {
                                            "cvo_id": 452137,
                                            "cvo_title": "060899 Zoology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "060899",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3270,
                            "cvr_parent_cvo_id": 452050,
                            "cvr_child_cvo_id": 452138,
                            "controlled_vocab": {
                                "cvo_id": 452138,
                                "cvo_title": "0699 Other Biological Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0699",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3271,
                                        "cvr_parent_cvo_id": 452138,
                                        "cvr_child_cvo_id": 452139,
                                        "controlled_vocab": {
                                            "cvo_id": 452139,
                                            "cvo_title": "069901 Forensic Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "069901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3272,
                                        "cvr_parent_cvo_id": 452138,
                                        "cvr_child_cvo_id": 452140,
                                        "controlled_vocab": {
                                            "cvo_id": 452140,
                                            "cvo_title": "069902 Global Change Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "069902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3273,
                                        "cvr_parent_cvo_id": 452138,
                                        "cvr_child_cvo_id": 452141,
                                        "controlled_vocab": {
                                            "cvo_id": 452141,
                                            "cvo_title": "069999 Biological Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "069999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3274,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452142,
                "controlled_vocab": {
                    "cvo_id": 452142,
                    "cvo_title": "07 Agricultural and Veterinary Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "07",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3275,
                            "cvr_parent_cvo_id": 452142,
                            "cvr_child_cvo_id": 452143,
                            "controlled_vocab": {
                                "cvo_id": 452143,
                                "cvo_title": "0701 Agriculture, Land and Farm Management",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0701",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3276,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452144,
                                        "controlled_vocab": {
                                            "cvo_id": 452144,
                                            "cvo_title": "070101 Agricultural Land Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3277,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452145,
                                        "controlled_vocab": {
                                            "cvo_id": 452145,
                                            "cvo_title": "070102 Agricultural Land Planning",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3278,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452146,
                                        "controlled_vocab": {
                                            "cvo_id": 452146,
                                            "cvo_title": "070103 Agricultural Production Systems Simulation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3279,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452147,
                                        "controlled_vocab": {
                                            "cvo_id": 452147,
                                            "cvo_title": "070104 Agricultural Spatial Analysis and Modelling",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3280,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452148,
                                        "controlled_vocab": {
                                            "cvo_id": 452148,
                                            "cvo_title": "070105 Agricultural Systems Analysis and Modelling",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3281,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452149,
                                        "controlled_vocab": {
                                            "cvo_id": 452149,
                                            "cvo_title": "070106 Farm Management, Rural Management and Agribusiness",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3282,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452150,
                                        "controlled_vocab": {
                                            "cvo_id": 452150,
                                            "cvo_title": "070107 Farming Systems Research",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3283,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452151,
                                        "controlled_vocab": {
                                            "cvo_id": 452151,
                                            "cvo_title": "070108 Sustainable Agricultural Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3284,
                                        "cvr_parent_cvo_id": 452143,
                                        "cvr_child_cvo_id": 452152,
                                        "controlled_vocab": {
                                            "cvo_id": 452152,
                                            "cvo_title": "070199 Agriculture, Land and Farm Management not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3285,
                            "cvr_parent_cvo_id": 452142,
                            "cvr_child_cvo_id": 452153,
                            "controlled_vocab": {
                                "cvo_id": 452153,
                                "cvo_title": "0702 Animal Production",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0702",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3286,
                                        "cvr_parent_cvo_id": 452153,
                                        "cvr_child_cvo_id": 452154,
                                        "controlled_vocab": {
                                            "cvo_id": 452154,
                                            "cvo_title": "070201 Animal Breeding",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3287,
                                        "cvr_parent_cvo_id": 452153,
                                        "cvr_child_cvo_id": 452155,
                                        "controlled_vocab": {
                                            "cvo_id": 452155,
                                            "cvo_title": "070202 Animal Growth and Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3288,
                                        "cvr_parent_cvo_id": 452153,
                                        "cvr_child_cvo_id": 452156,
                                        "controlled_vocab": {
                                            "cvo_id": 452156,
                                            "cvo_title": "070203 Animal Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3289,
                                        "cvr_parent_cvo_id": 452153,
                                        "cvr_child_cvo_id": 452157,
                                        "controlled_vocab": {
                                            "cvo_id": 452157,
                                            "cvo_title": "070204 Animal Nutrition",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3290,
                                        "cvr_parent_cvo_id": 452153,
                                        "cvr_child_cvo_id": 452158,
                                        "controlled_vocab": {
                                            "cvo_id": 452158,
                                            "cvo_title": "070205 Animal Protection (Pests and Pathogens)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3291,
                                        "cvr_parent_cvo_id": 452153,
                                        "cvr_child_cvo_id": 452159,
                                        "controlled_vocab": {
                                            "cvo_id": 452159,
                                            "cvo_title": "070206 Animal Reproduction",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3292,
                                        "cvr_parent_cvo_id": 452153,
                                        "cvr_child_cvo_id": 452160,
                                        "controlled_vocab": {
                                            "cvo_id": 452160,
                                            "cvo_title": "070207 Humane Animal Treatment",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3293,
                                        "cvr_parent_cvo_id": 452153,
                                        "cvr_child_cvo_id": 452161,
                                        "controlled_vocab": {
                                            "cvo_id": 452161,
                                            "cvo_title": "070299 Animal Production not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3294,
                            "cvr_parent_cvo_id": 452142,
                            "cvr_child_cvo_id": 452162,
                            "controlled_vocab": {
                                "cvo_id": 452162,
                                "cvo_title": "0703 Crop and Pasture Production",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0703",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3296,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452164,
                                        "controlled_vocab": {
                                            "cvo_id": 452164,
                                            "cvo_title": "070301 Agro-ecosystem Functionand Prediction",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3297,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452165,
                                        "controlled_vocab": {
                                            "cvo_id": 452165,
                                            "cvo_title": "070302 Agronomy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3298,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452166,
                                        "controlled_vocab": {
                                            "cvo_id": 452166,
                                            "cvo_title": "070303 Crop and Pasture Biochemistry and Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3299,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452167,
                                        "controlled_vocab": {
                                            "cvo_id": 452167,
                                            "cvo_title": "070304 Crop and Pasture Biomass and Bioproducts",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3300,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452168,
                                        "controlled_vocab": {
                                            "cvo_id": 452168,
                                            "cvo_title": "070305 Crop and Pasture Improvement (Selection and Breeding)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3301,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452169,
                                        "controlled_vocab": {
                                            "cvo_id": 452169,
                                            "cvo_title": "070306 Crop and Pasture Nutrition",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3302,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452170,
                                        "controlled_vocab": {
                                            "cvo_id": 452170,
                                            "cvo_title": "070307 Crop and Pasture Post Harvest Technologies (incl. Transportation and Storage)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3303,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452171,
                                        "controlled_vocab": {
                                            "cvo_id": 452171,
                                            "cvo_title": "070308 Crop and Pasture Protection (Pests, Diseases and Weeds)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3304,
                                        "cvr_parent_cvo_id": 452162,
                                        "cvr_child_cvo_id": 452172,
                                        "controlled_vocab": {
                                            "cvo_id": 452172,
                                            "cvo_title": "070399 Crop and Pasture Production not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3305,
                            "cvr_parent_cvo_id": 452142,
                            "cvr_child_cvo_id": 452173,
                            "controlled_vocab": {
                                "cvo_id": 452173,
                                "cvo_title": "0704 Fisheries Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0704",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3306,
                                        "cvr_parent_cvo_id": 452173,
                                        "cvr_child_cvo_id": 452174,
                                        "controlled_vocab": {
                                            "cvo_id": 452174,
                                            "cvo_title": "070401 Aquaculture",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3307,
                                        "cvr_parent_cvo_id": 452173,
                                        "cvr_child_cvo_id": 452175,
                                        "controlled_vocab": {
                                            "cvo_id": 452175,
                                            "cvo_title": "070402 Aquatic Ecosystem Studies and Stock Assessment",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3308,
                                        "cvr_parent_cvo_id": 452173,
                                        "cvr_child_cvo_id": 452176,
                                        "controlled_vocab": {
                                            "cvo_id": 452176,
                                            "cvo_title": "070403 Fisheries Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3309,
                                        "cvr_parent_cvo_id": 452173,
                                        "cvr_child_cvo_id": 452177,
                                        "controlled_vocab": {
                                            "cvo_id": 452177,
                                            "cvo_title": "070404 Fish Pests and Diseases",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3310,
                                        "cvr_parent_cvo_id": 452173,
                                        "cvr_child_cvo_id": 452178,
                                        "controlled_vocab": {
                                            "cvo_id": 452178,
                                            "cvo_title": "070405 Fish Physiology and Genetics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3311,
                                        "cvr_parent_cvo_id": 452173,
                                        "cvr_child_cvo_id": 452179,
                                        "controlled_vocab": {
                                            "cvo_id": 452179,
                                            "cvo_title": "070406 Post-Harvest Fisheries Technologies (incl. Transportation)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3312,
                                        "cvr_parent_cvo_id": 452173,
                                        "cvr_child_cvo_id": 452180,
                                        "controlled_vocab": {
                                            "cvo_id": 452180,
                                            "cvo_title": "070499 Fisheries Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3313,
                            "cvr_parent_cvo_id": 452142,
                            "cvr_child_cvo_id": 452181,
                            "controlled_vocab": {
                                "cvo_id": 452181,
                                "cvo_title": "0705 Forestry Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0705",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3314,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452182,
                                        "controlled_vocab": {
                                            "cvo_id": 452182,
                                            "cvo_title": "070501 Agroforestry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3315,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452183,
                                        "controlled_vocab": {
                                            "cvo_id": 452183,
                                            "cvo_title": "070502 Forestry Biomass and Bioproducts",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3316,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452184,
                                        "controlled_vocab": {
                                            "cvo_id": 452184,
                                            "cvo_title": "070503 Forestry Fire Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3317,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452185,
                                        "controlled_vocab": {
                                            "cvo_id": 452185,
                                            "cvo_title": "070504 Forestry Management and Environment",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3318,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452186,
                                        "controlled_vocab": {
                                            "cvo_id": 452186,
                                            "cvo_title": "070505 Forestry Pests, Health and Diseases",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3319,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452187,
                                        "controlled_vocab": {
                                            "cvo_id": 452187,
                                            "cvo_title": "070506 Forestry Product Quality Assessment",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3320,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452188,
                                        "controlled_vocab": {
                                            "cvo_id": 452188,
                                            "cvo_title": "070507 Tree Improvement (Selection and Breeding)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3321,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452189,
                                        "controlled_vocab": {
                                            "cvo_id": 452189,
                                            "cvo_title": "070508 Tree Nutrition and Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070508",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3322,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452190,
                                        "controlled_vocab": {
                                            "cvo_id": 452190,
                                            "cvo_title": "070509 Wood Fibre Processing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070509",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3323,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452191,
                                        "controlled_vocab": {
                                            "cvo_id": 452191,
                                            "cvo_title": "070510 Wood Processing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070510",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3324,
                                        "cvr_parent_cvo_id": 452181,
                                        "cvr_child_cvo_id": 452192,
                                        "controlled_vocab": {
                                            "cvo_id": 452192,
                                            "cvo_title": "070599 Forestry Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3325,
                            "cvr_parent_cvo_id": 452142,
                            "cvr_child_cvo_id": 452193,
                            "controlled_vocab": {
                                "cvo_id": 452193,
                                "cvo_title": "0706 Horticultural Production",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0706",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3326,
                                        "cvr_parent_cvo_id": 452193,
                                        "cvr_child_cvo_id": 452194,
                                        "controlled_vocab": {
                                            "cvo_id": 452194,
                                            "cvo_title": "070601 Horticultural Crop Growth and Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3327,
                                        "cvr_parent_cvo_id": 452193,
                                        "cvr_child_cvo_id": 452195,
                                        "controlled_vocab": {
                                            "cvo_id": 452195,
                                            "cvo_title": "070602 Horticultural Crop Improvement (Selection and Breeding)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3328,
                                        "cvr_parent_cvo_id": 452193,
                                        "cvr_child_cvo_id": 452196,
                                        "controlled_vocab": {
                                            "cvo_id": 452196,
                                            "cvo_title": "070603 Horticultural Crop Protection (Pests, Diseases and Weeds)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3329,
                                        "cvr_parent_cvo_id": 452193,
                                        "cvr_child_cvo_id": 452197,
                                        "controlled_vocab": {
                                            "cvo_id": 452197,
                                            "cvo_title": "070604 Oenology and Viticulture",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3330,
                                        "cvr_parent_cvo_id": 452193,
                                        "cvr_child_cvo_id": 452198,
                                        "controlled_vocab": {
                                            "cvo_id": 452198,
                                            "cvo_title": "070605 Post Harvest Horticultural Technologies (incl. Transportation and Storage)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070605",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3331,
                                        "cvr_parent_cvo_id": 452193,
                                        "cvr_child_cvo_id": 452199,
                                        "controlled_vocab": {
                                            "cvo_id": 452199,
                                            "cvo_title": "070699 Horticultural Production not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3332,
                            "cvr_parent_cvo_id": 452142,
                            "cvr_child_cvo_id": 452200,
                            "controlled_vocab": {
                                "cvo_id": 452200,
                                "cvo_title": "0707 Veterinary Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0707",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3333,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452201,
                                        "controlled_vocab": {
                                            "cvo_id": 452201,
                                            "cvo_title": "070701 Veterinary Anaesthesiology and Intensive Care",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3334,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452202,
                                        "controlled_vocab": {
                                            "cvo_id": 452202,
                                            "cvo_title": "070702 Veterinary Anatomy and Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3335,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452203,
                                        "controlled_vocab": {
                                            "cvo_id": 452203,
                                            "cvo_title": "070703 Veterinary Diagnosis and Diagnostics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3336,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452204,
                                        "controlled_vocab": {
                                            "cvo_id": 452204,
                                            "cvo_title": "070704 Veterinary Epidemiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070704",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3337,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452205,
                                        "controlled_vocab": {
                                            "cvo_id": 452205,
                                            "cvo_title": "070705 Veterinary Immunology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070705",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3338,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452206,
                                        "controlled_vocab": {
                                            "cvo_id": 452206,
                                            "cvo_title": "070706 Veterinary Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070706",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3339,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452207,
                                        "controlled_vocab": {
                                            "cvo_id": 452207,
                                            "cvo_title": "070707 Veterinary Microbiology (excl. Virology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070707",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3340,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452208,
                                        "controlled_vocab": {
                                            "cvo_id": 452208,
                                            "cvo_title": "070708 Veterinary Parasitology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070708",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3341,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452209,
                                        "controlled_vocab": {
                                            "cvo_id": 452209,
                                            "cvo_title": "070709 Veterinary Pathology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070709",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3342,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452210,
                                        "controlled_vocab": {
                                            "cvo_id": 452210,
                                            "cvo_title": "070710 Veterinary Pharmacology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070710",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3343,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452211,
                                        "controlled_vocab": {
                                            "cvo_id": 452211,
                                            "cvo_title": "070711 Veterinary Surgery",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070711",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3344,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452212,
                                        "controlled_vocab": {
                                            "cvo_id": 452212,
                                            "cvo_title": "070712 Veterinary Virology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070712",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3345,
                                        "cvr_parent_cvo_id": 452200,
                                        "cvr_child_cvo_id": 452213,
                                        "controlled_vocab": {
                                            "cvo_id": 452213,
                                            "cvo_title": "070799 Veterinary Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "070799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3346,
                            "cvr_parent_cvo_id": 452142,
                            "cvr_child_cvo_id": 452214,
                            "controlled_vocab": {
                                "cvo_id": 452214,
                                "cvo_title": "0799 Other Agricultural and Veterinary Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0799",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3347,
                                        "cvr_parent_cvo_id": 452214,
                                        "cvr_child_cvo_id": 452215,
                                        "controlled_vocab": {
                                            "cvo_id": 452215,
                                            "cvo_title": "079901 Agricultural Hydrology (Drainage, Flooding, Irrigation, Quality, etc.)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "079901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3348,
                                        "cvr_parent_cvo_id": 452214,
                                        "cvr_child_cvo_id": 452216,
                                        "controlled_vocab": {
                                            "cvo_id": 452216,
                                            "cvo_title": "079902 Fertilisers and Agrochemicals (incl. Application)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "079902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3349,
                                        "cvr_parent_cvo_id": 452214,
                                        "cvr_child_cvo_id": 452217,
                                        "controlled_vocab": {
                                            "cvo_id": 452217,
                                            "cvo_title": "079999 Agricultural and Veterinary Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "079999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3350,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452218,
                "controlled_vocab": {
                    "cvo_id": 452218,
                    "cvo_title": "08 Information and Computing Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "08",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3351,
                            "cvr_parent_cvo_id": 452218,
                            "cvr_child_cvo_id": 452219,
                            "controlled_vocab": {
                                "cvo_id": 452219,
                                "cvo_title": "0801 Artificial Intelligence and Image Processing",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0801",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3352,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452220,
                                        "controlled_vocab": {
                                            "cvo_id": 452220,
                                            "cvo_title": "080101 Adaptive Agents and Intelligent Robotics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3353,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452221,
                                        "controlled_vocab": {
                                            "cvo_id": 452221,
                                            "cvo_title": "080102 Artificial Life",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3354,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452222,
                                        "controlled_vocab": {
                                            "cvo_id": 452222,
                                            "cvo_title": "080103 Computer Graphics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3355,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452223,
                                        "controlled_vocab": {
                                            "cvo_id": 452223,
                                            "cvo_title": "080104 Computer Vision",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3356,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452224,
                                        "controlled_vocab": {
                                            "cvo_id": 452224,
                                            "cvo_title": "080105 Expert Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3357,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452225,
                                        "controlled_vocab": {
                                            "cvo_id": 452225,
                                            "cvo_title": "080106 Image Processing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3358,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452226,
                                        "controlled_vocab": {
                                            "cvo_id": 452226,
                                            "cvo_title": "080107 Natural Language Processing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3359,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452227,
                                        "controlled_vocab": {
                                            "cvo_id": 452227,
                                            "cvo_title": "080108 Neural, Evolutionary and Fuzzy Computation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3360,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452228,
                                        "controlled_vocab": {
                                            "cvo_id": 452228,
                                            "cvo_title": "080109 Pattern Recognition and Data Mining",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080109",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3361,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452229,
                                        "controlled_vocab": {
                                            "cvo_id": 452229,
                                            "cvo_title": "080110 Simulation and Modelling",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080110",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3362,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452230,
                                        "controlled_vocab": {
                                            "cvo_id": 452230,
                                            "cvo_title": "080111 Virtual Reality and Related Simulation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080111",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3363,
                                        "cvr_parent_cvo_id": 452219,
                                        "cvr_child_cvo_id": 452231,
                                        "controlled_vocab": {
                                            "cvo_id": 452231,
                                            "cvo_title": "080199 Artificial Intelligence and Image Processing not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3364,
                            "cvr_parent_cvo_id": 452218,
                            "cvr_child_cvo_id": 452232,
                            "controlled_vocab": {
                                "cvo_id": 452232,
                                "cvo_title": "0802 Computation Theory and Mathematics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0802",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3365,
                                        "cvr_parent_cvo_id": 452232,
                                        "cvr_child_cvo_id": 452233,
                                        "controlled_vocab": {
                                            "cvo_id": 452233,
                                            "cvo_title": "080201 Analysis of Algorithms and Complexity",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3366,
                                        "cvr_parent_cvo_id": 452232,
                                        "cvr_child_cvo_id": 452234,
                                        "controlled_vocab": {
                                            "cvo_id": 452234,
                                            "cvo_title": "080202 Applied Discrete Mathematics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3367,
                                        "cvr_parent_cvo_id": 452232,
                                        "cvr_child_cvo_id": 452235,
                                        "controlled_vocab": {
                                            "cvo_id": 452235,
                                            "cvo_title": "080203 Computational Logic and Formal Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3368,
                                        "cvr_parent_cvo_id": 452232,
                                        "cvr_child_cvo_id": 452236,
                                        "controlled_vocab": {
                                            "cvo_id": 452236,
                                            "cvo_title": "080204 Mathematical Software",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3369,
                                        "cvr_parent_cvo_id": 452232,
                                        "cvr_child_cvo_id": 452237,
                                        "controlled_vocab": {
                                            "cvo_id": 452237,
                                            "cvo_title": "080205 Numerical Computation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3370,
                                        "cvr_parent_cvo_id": 452232,
                                        "cvr_child_cvo_id": 452238,
                                        "controlled_vocab": {
                                            "cvo_id": 452238,
                                            "cvo_title": "080299 Computation Theory and Mathematics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3371,
                            "cvr_parent_cvo_id": 452218,
                            "cvr_child_cvo_id": 452239,
                            "controlled_vocab": {
                                "cvo_id": 452239,
                                "cvo_title": "0803 Computer Software",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0803",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3372,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452240,
                                        "controlled_vocab": {
                                            "cvo_id": 452240,
                                            "cvo_title": "080301 Bioinformatics Software",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3373,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452241,
                                        "controlled_vocab": {
                                            "cvo_id": 452241,
                                            "cvo_title": "080302 Computer System Architecture",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3374,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452242,
                                        "controlled_vocab": {
                                            "cvo_id": 452242,
                                            "cvo_title": "080303 Computer System Security",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3375,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452243,
                                        "controlled_vocab": {
                                            "cvo_id": 452243,
                                            "cvo_title": "080304 Concurrent Programming",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3376,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452244,
                                        "controlled_vocab": {
                                            "cvo_id": 452244,
                                            "cvo_title": "080305 Multimedia Programming",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3377,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452245,
                                        "controlled_vocab": {
                                            "cvo_id": 452245,
                                            "cvo_title": "080306 Open Software",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3378,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452246,
                                        "controlled_vocab": {
                                            "cvo_id": 452246,
                                            "cvo_title": "080307 Operating Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3379,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452247,
                                        "controlled_vocab": {
                                            "cvo_id": 452247,
                                            "cvo_title": "080308 Programming Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3380,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452248,
                                        "controlled_vocab": {
                                            "cvo_id": 452248,
                                            "cvo_title": "080309 Software Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3381,
                                        "cvr_parent_cvo_id": 452239,
                                        "cvr_child_cvo_id": 452249,
                                        "controlled_vocab": {
                                            "cvo_id": 452249,
                                            "cvo_title": "080399 Computer Software not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3382,
                            "cvr_parent_cvo_id": 452218,
                            "cvr_child_cvo_id": 452250,
                            "controlled_vocab": {
                                "cvo_id": 452250,
                                "cvo_title": "0804 Data Format",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0804",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3383,
                                        "cvr_parent_cvo_id": 452250,
                                        "cvr_child_cvo_id": 452251,
                                        "controlled_vocab": {
                                            "cvo_id": 452251,
                                            "cvo_title": "080401 Coding and Information Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3384,
                                        "cvr_parent_cvo_id": 452250,
                                        "cvr_child_cvo_id": 452252,
                                        "controlled_vocab": {
                                            "cvo_id": 452252,
                                            "cvo_title": "080402 Data Encryption",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3385,
                                        "cvr_parent_cvo_id": 452250,
                                        "cvr_child_cvo_id": 452253,
                                        "controlled_vocab": {
                                            "cvo_id": 452253,
                                            "cvo_title": "080403 Data Structures",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3386,
                                        "cvr_parent_cvo_id": 452250,
                                        "cvr_child_cvo_id": 452254,
                                        "controlled_vocab": {
                                            "cvo_id": 452254,
                                            "cvo_title": "080404 Markup Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3387,
                                        "cvr_parent_cvo_id": 452250,
                                        "cvr_child_cvo_id": 452255,
                                        "controlled_vocab": {
                                            "cvo_id": 452255,
                                            "cvo_title": "080499 Data Format not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3388,
                            "cvr_parent_cvo_id": 452218,
                            "cvr_child_cvo_id": 452256,
                            "controlled_vocab": {
                                "cvo_id": 452256,
                                "cvo_title": "0805 Distributed Computing",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0805",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3389,
                                        "cvr_parent_cvo_id": 452256,
                                        "cvr_child_cvo_id": 452257,
                                        "controlled_vocab": {
                                            "cvo_id": 452257,
                                            "cvo_title": "080501 Distributed and Grid Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3390,
                                        "cvr_parent_cvo_id": 452256,
                                        "cvr_child_cvo_id": 452258,
                                        "controlled_vocab": {
                                            "cvo_id": 452258,
                                            "cvo_title": "080502 Mobile Technologies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3391,
                                        "cvr_parent_cvo_id": 452256,
                                        "cvr_child_cvo_id": 452259,
                                        "controlled_vocab": {
                                            "cvo_id": 452259,
                                            "cvo_title": "080503 Networking and Communications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3392,
                                        "cvr_parent_cvo_id": 452256,
                                        "cvr_child_cvo_id": 452260,
                                        "controlled_vocab": {
                                            "cvo_id": 452260,
                                            "cvo_title": "080504 Ubiquitous Computing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3393,
                                        "cvr_parent_cvo_id": 452256,
                                        "cvr_child_cvo_id": 452261,
                                        "controlled_vocab": {
                                            "cvo_id": 452261,
                                            "cvo_title": "080505 Web Technologies (excl. Web Search)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3394,
                                        "cvr_parent_cvo_id": 452256,
                                        "cvr_child_cvo_id": 452262,
                                        "controlled_vocab": {
                                            "cvo_id": 452262,
                                            "cvo_title": "080599 Distributed Computing not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3395,
                            "cvr_parent_cvo_id": 452218,
                            "cvr_child_cvo_id": 452263,
                            "controlled_vocab": {
                                "cvo_id": 452263,
                                "cvo_title": "0806 Information Systems",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0806",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3396,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452264,
                                        "controlled_vocab": {
                                            "cvo_id": 452264,
                                            "cvo_title": "080601 Aboriginal and Torres Strait Islander Information and Knowledge Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3397,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452265,
                                        "controlled_vocab": {
                                            "cvo_id": 452265,
                                            "cvo_title": "080602 Computer-Human Interaction",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3398,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452266,
                                        "controlled_vocab": {
                                            "cvo_id": 452266,
                                            "cvo_title": "080603 Conceptual Modelling",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3399,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452267,
                                        "controlled_vocab": {
                                            "cvo_id": 452267,
                                            "cvo_title": "080604 Database Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3400,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452268,
                                        "controlled_vocab": {
                                            "cvo_id": 452268,
                                            "cvo_title": "080605 Decision Support and Group Support Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080605",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3401,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452269,
                                        "controlled_vocab": {
                                            "cvo_id": 452269,
                                            "cvo_title": "080606 Global Information Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080606",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3402,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452270,
                                        "controlled_vocab": {
                                            "cvo_id": 452270,
                                            "cvo_title": "080607 Information Engineering and Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080607",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3403,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452271,
                                        "controlled_vocab": {
                                            "cvo_id": 452271,
                                            "cvo_title": "080608 Information Systems Development Methodologies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080608",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3404,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452272,
                                        "controlled_vocab": {
                                            "cvo_id": 452272,
                                            "cvo_title": "080609 Information Systems Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080609",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3405,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452273,
                                        "controlled_vocab": {
                                            "cvo_id": 452273,
                                            "cvo_title": "080610 Information Systems Organisation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080610",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3406,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452274,
                                        "controlled_vocab": {
                                            "cvo_id": 452274,
                                            "cvo_title": "080611 Information Systems Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080611",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3407,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452275,
                                        "controlled_vocab": {
                                            "cvo_id": 452275,
                                            "cvo_title": "080612 Interorganisational Information Systems and Web Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080612",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3408,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452276,
                                        "controlled_vocab": {
                                            "cvo_id": 452276,
                                            "cvo_title": "080613 Maori Information and Knowledge Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080613",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3409,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452277,
                                        "controlled_vocab": {
                                            "cvo_id": 452277,
                                            "cvo_title": "080614 Pacific Peoples Information and Knowledge Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080614",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3410,
                                        "cvr_parent_cvo_id": 452263,
                                        "cvr_child_cvo_id": 452278,
                                        "controlled_vocab": {
                                            "cvo_id": 452278,
                                            "cvo_title": "080699 Information Systems not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3411,
                            "cvr_parent_cvo_id": 452218,
                            "cvr_child_cvo_id": 452279,
                            "controlled_vocab": {
                                "cvo_id": 452279,
                                "cvo_title": "0807 Library and Information Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0807",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3412,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452280,
                                        "controlled_vocab": {
                                            "cvo_id": 452280,
                                            "cvo_title": "080701 Aboriginal and Torres Strait Islander Knowledge Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3413,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452281,
                                        "controlled_vocab": {
                                            "cvo_id": 452281,
                                            "cvo_title": "080702 Health Informatics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3414,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452282,
                                        "controlled_vocab": {
                                            "cvo_id": 452282,
                                            "cvo_title": "080703 Human Information Behaviour",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3415,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452283,
                                        "controlled_vocab": {
                                            "cvo_id": 452283,
                                            "cvo_title": "080704 Information Retrieval and Web Search",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080704",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3416,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452284,
                                        "controlled_vocab": {
                                            "cvo_id": 452284,
                                            "cvo_title": "080705 Informetrics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080705",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3417,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452285,
                                        "controlled_vocab": {
                                            "cvo_id": 452285,
                                            "cvo_title": "080706 Librarianship",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080706",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3418,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452286,
                                        "controlled_vocab": {
                                            "cvo_id": 452286,
                                            "cvo_title": "080707 Organisation of Information and Knowledge Resources",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080707",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3419,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452287,
                                        "controlled_vocab": {
                                            "cvo_id": 452287,
                                            "cvo_title": "080708 Records and Information Management (excl. Business Records and Information Management)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080708",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3420,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452288,
                                        "controlled_vocab": {
                                            "cvo_id": 452288,
                                            "cvo_title": "080709 Social and Community Informatics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080709",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3421,
                                        "cvr_parent_cvo_id": 452279,
                                        "cvr_child_cvo_id": 452289,
                                        "controlled_vocab": {
                                            "cvo_id": 452289,
                                            "cvo_title": "080799 Library and Information Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "080799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3422,
                            "cvr_parent_cvo_id": 452218,
                            "cvr_child_cvo_id": 452290,
                            "controlled_vocab": {
                                "cvo_id": 452290,
                                "cvo_title": "0899 Other Information and Computing Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0899",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3423,
                                        "cvr_parent_cvo_id": 452290,
                                        "cvr_child_cvo_id": 452291,
                                        "controlled_vocab": {
                                            "cvo_id": 452291,
                                            "cvo_title": "089999 Information and Computing Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "089999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3424,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452292,
                "controlled_vocab": {
                    "cvo_id": 452292,
                    "cvo_title": "09 Engineering",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "09",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3425,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452293,
                            "controlled_vocab": {
                                "cvo_id": 452293,
                                "cvo_title": "0901 Aerospace Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0901",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3426,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452294,
                                        "controlled_vocab": {
                                            "cvo_id": 452294,
                                            "cvo_title": "090101 Aerodynamics (excl. Hypersonic Aerodynamics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3427,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452295,
                                        "controlled_vocab": {
                                            "cvo_id": 452295,
                                            "cvo_title": "090102 Aerospace Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3428,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452296,
                                        "controlled_vocab": {
                                            "cvo_id": 452296,
                                            "cvo_title": "090103 Aerospace Structures",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3429,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452297,
                                        "controlled_vocab": {
                                            "cvo_id": 452297,
                                            "cvo_title": "090104 Aircraft Performance and Flight Control Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3430,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452298,
                                        "controlled_vocab": {
                                            "cvo_id": 452298,
                                            "cvo_title": "090105 Avionics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3431,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452299,
                                        "controlled_vocab": {
                                            "cvo_id": 452299,
                                            "cvo_title": "090106 Flight Dynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3432,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452300,
                                        "controlled_vocab": {
                                            "cvo_id": 452300,
                                            "cvo_title": "090107 Hypersonic Propulsion and Hypersonic Aerodynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3433,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452301,
                                        "controlled_vocab": {
                                            "cvo_id": 452301,
                                            "cvo_title": "090108 Satellite, Space Vehicle and Missile Design and Testing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3434,
                                        "cvr_parent_cvo_id": 452293,
                                        "cvr_child_cvo_id": 452302,
                                        "controlled_vocab": {
                                            "cvo_id": 452302,
                                            "cvo_title": "090199 Aerospace Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3435,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452303,
                            "controlled_vocab": {
                                "cvo_id": 452303,
                                "cvo_title": "0902 Automotive Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0902",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3436,
                                        "cvr_parent_cvo_id": 452303,
                                        "cvr_child_cvo_id": 452304,
                                        "controlled_vocab": {
                                            "cvo_id": 452304,
                                            "cvo_title": "090201 Automotive Combustion and Fuel Engineering (incl. Alternative/Renewable Fuels)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3437,
                                        "cvr_parent_cvo_id": 452303,
                                        "cvr_child_cvo_id": 452305,
                                        "controlled_vocab": {
                                            "cvo_id": 452305,
                                            "cvo_title": "090202 Automotive Engineering Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3438,
                                        "cvr_parent_cvo_id": 452303,
                                        "cvr_child_cvo_id": 452306,
                                        "controlled_vocab": {
                                            "cvo_id": 452306,
                                            "cvo_title": "090203 Automotive Mechatronics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3439,
                                        "cvr_parent_cvo_id": 452303,
                                        "cvr_child_cvo_id": 452307,
                                        "controlled_vocab": {
                                            "cvo_id": 452307,
                                            "cvo_title": "090204 Automotive Safety Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3440,
                                        "cvr_parent_cvo_id": 452303,
                                        "cvr_child_cvo_id": 452308,
                                        "controlled_vocab": {
                                            "cvo_id": 452308,
                                            "cvo_title": "090205 Hybrid Vehicles and Powertrains",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3441,
                                        "cvr_parent_cvo_id": 452303,
                                        "cvr_child_cvo_id": 452309,
                                        "controlled_vocab": {
                                            "cvo_id": 452309,
                                            "cvo_title": "090299 Automotive Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3442,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452310,
                            "controlled_vocab": {
                                "cvo_id": 452310,
                                "cvo_title": "0903 Biomedical Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0903",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3443,
                                        "cvr_parent_cvo_id": 452310,
                                        "cvr_child_cvo_id": 452311,
                                        "controlled_vocab": {
                                            "cvo_id": 452311,
                                            "cvo_title": "090301 Biomaterials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3444,
                                        "cvr_parent_cvo_id": 452310,
                                        "cvr_child_cvo_id": 452312,
                                        "controlled_vocab": {
                                            "cvo_id": 452312,
                                            "cvo_title": "090302 Biomechanical Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3445,
                                        "cvr_parent_cvo_id": 452310,
                                        "cvr_child_cvo_id": 452313,
                                        "controlled_vocab": {
                                            "cvo_id": 452313,
                                            "cvo_title": "090303 Biomedical Instrumentation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3446,
                                        "cvr_parent_cvo_id": 452310,
                                        "cvr_child_cvo_id": 452314,
                                        "controlled_vocab": {
                                            "cvo_id": 452314,
                                            "cvo_title": "090304 Medical Devices",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3447,
                                        "cvr_parent_cvo_id": 452310,
                                        "cvr_child_cvo_id": 452315,
                                        "controlled_vocab": {
                                            "cvo_id": 452315,
                                            "cvo_title": "090305 Rehabilitation Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3448,
                                        "cvr_parent_cvo_id": 452310,
                                        "cvr_child_cvo_id": 452316,
                                        "controlled_vocab": {
                                            "cvo_id": 452316,
                                            "cvo_title": "090399 Biomedical Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3449,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452317,
                            "controlled_vocab": {
                                "cvo_id": 452317,
                                "cvo_title": "0904 Chemical Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0904",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3450,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452318,
                                        "controlled_vocab": {
                                            "cvo_id": 452318,
                                            "cvo_title": "090401 Carbon Capture Engineering (excl. Sequestration)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3451,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452319,
                                        "controlled_vocab": {
                                            "cvo_id": 452319,
                                            "cvo_title": "090402 Catalytic Process Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3452,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452320,
                                        "controlled_vocab": {
                                            "cvo_id": 452320,
                                            "cvo_title": "090403 Chemical Engineering Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3453,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452321,
                                        "controlled_vocab": {
                                            "cvo_id": 452321,
                                            "cvo_title": "090404 Membrane and Separation Technologies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3454,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452322,
                                        "controlled_vocab": {
                                            "cvo_id": 452322,
                                            "cvo_title": "090405 Non-automotive Combustion and Fuel Engineering (incl. Alternative/Renewable Fuels)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3455,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452323,
                                        "controlled_vocab": {
                                            "cvo_id": 452323,
                                            "cvo_title": "090406 Powder and Particle Technology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3456,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452324,
                                        "controlled_vocab": {
                                            "cvo_id": 452324,
                                            "cvo_title": "090407 Process Control and Simulation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090407",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3457,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452325,
                                        "controlled_vocab": {
                                            "cvo_id": 452325,
                                            "cvo_title": "090408 Rheology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090408",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3458,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452326,
                                        "controlled_vocab": {
                                            "cvo_id": 452326,
                                            "cvo_title": "090409 Wastewater Treatment Processes",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090409",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3459,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452327,
                                        "controlled_vocab": {
                                            "cvo_id": 452327,
                                            "cvo_title": "090410 Water Treatment Processes",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090410",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3460,
                                        "cvr_parent_cvo_id": 452317,
                                        "cvr_child_cvo_id": 452328,
                                        "controlled_vocab": {
                                            "cvo_id": 452328,
                                            "cvo_title": "090499 Chemical Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3461,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452329,
                            "controlled_vocab": {
                                "cvo_id": 452329,
                                "cvo_title": "0905 Civil Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0905",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3462,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452330,
                                        "controlled_vocab": {
                                            "cvo_id": 452330,
                                            "cvo_title": "090501 Civil Geotechnical Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3463,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452331,
                                        "controlled_vocab": {
                                            "cvo_id": 452331,
                                            "cvo_title": "090502 Construction Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3464,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452332,
                                        "controlled_vocab": {
                                            "cvo_id": 452332,
                                            "cvo_title": "090503 Construction Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3465,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452333,
                                        "controlled_vocab": {
                                            "cvo_id": 452333,
                                            "cvo_title": "090504 Earthquake Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3466,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452334,
                                        "controlled_vocab": {
                                            "cvo_id": 452334,
                                            "cvo_title": "090505 Infrastructure Engineering and Asset Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3467,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452335,
                                        "controlled_vocab": {
                                            "cvo_id": 452335,
                                            "cvo_title": "090506 Structural Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3468,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452336,
                                        "controlled_vocab": {
                                            "cvo_id": 452336,
                                            "cvo_title": "090507 Transport Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3469,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452337,
                                        "controlled_vocab": {
                                            "cvo_id": 452337,
                                            "cvo_title": "090508 Water Quality Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090508",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3470,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452338,
                                        "controlled_vocab": {
                                            "cvo_id": 452338,
                                            "cvo_title": "090509 Water Resources Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090509",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3471,
                                        "cvr_parent_cvo_id": 452329,
                                        "cvr_child_cvo_id": 452339,
                                        "controlled_vocab": {
                                            "cvo_id": 452339,
                                            "cvo_title": "090599 Civil Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3472,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452340,
                            "controlled_vocab": {
                                "cvo_id": 452340,
                                "cvo_title": "0906 Electrical and Electronic Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0906",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3473,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452341,
                                        "controlled_vocab": {
                                            "cvo_id": 452341,
                                            "cvo_title": "090601 Circuits and Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3474,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452342,
                                        "controlled_vocab": {
                                            "cvo_id": 452342,
                                            "cvo_title": "090602 Control Systems, Robotics and Automation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3475,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452343,
                                        "controlled_vocab": {
                                            "cvo_id": 452343,
                                            "cvo_title": "090603 Industrial Electronics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3476,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452344,
                                        "controlled_vocab": {
                                            "cvo_id": 452344,
                                            "cvo_title": "090604 Microelectronics and Integrated Circuits",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3477,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452345,
                                        "controlled_vocab": {
                                            "cvo_id": 452345,
                                            "cvo_title": "090605 Photodetectors, Optical Sensors and Solar Cells",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090605",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3478,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452346,
                                        "controlled_vocab": {
                                            "cvo_id": 452346,
                                            "cvo_title": "090606 Photonics and Electro-Optical Engineering (excl. Communications)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090606",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3479,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452347,
                                        "controlled_vocab": {
                                            "cvo_id": 452347,
                                            "cvo_title": "090607 Power and Energy Systems Engineering (excl. Renewable Power)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090607",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3480,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452348,
                                        "controlled_vocab": {
                                            "cvo_id": 452348,
                                            "cvo_title": "090608 Renewable Power and Energy Systems Engineering (excl. Solar Cells)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090608",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3481,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452349,
                                        "controlled_vocab": {
                                            "cvo_id": 452349,
                                            "cvo_title": "090609 Signal Processing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090609",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3482,
                                        "cvr_parent_cvo_id": 452340,
                                        "cvr_child_cvo_id": 452350,
                                        "controlled_vocab": {
                                            "cvo_id": 452350,
                                            "cvo_title": "090699 Electrical and Electronic Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3483,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452351,
                            "controlled_vocab": {
                                "cvo_id": 452351,
                                "cvo_title": "0907 Environmental Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0907",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3484,
                                        "cvr_parent_cvo_id": 452351,
                                        "cvr_child_cvo_id": 452352,
                                        "controlled_vocab": {
                                            "cvo_id": 452352,
                                            "cvo_title": "090701 Environmental Engineering Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3485,
                                        "cvr_parent_cvo_id": 452351,
                                        "cvr_child_cvo_id": 452353,
                                        "controlled_vocab": {
                                            "cvo_id": 452353,
                                            "cvo_title": "090702 Environmental Engineering Modelling",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3486,
                                        "cvr_parent_cvo_id": 452351,
                                        "cvr_child_cvo_id": 452354,
                                        "controlled_vocab": {
                                            "cvo_id": 452354,
                                            "cvo_title": "090703 Environmental Technologies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3487,
                                        "cvr_parent_cvo_id": 452351,
                                        "cvr_child_cvo_id": 452355,
                                        "controlled_vocab": {
                                            "cvo_id": 452355,
                                            "cvo_title": "090799 Environmental Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3488,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452356,
                            "controlled_vocab": {
                                "cvo_id": 452356,
                                "cvo_title": "0908 Food Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0908",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3489,
                                        "cvr_parent_cvo_id": 452356,
                                        "cvr_child_cvo_id": 452357,
                                        "controlled_vocab": {
                                            "cvo_id": 452357,
                                            "cvo_title": "090801 Food Chemistry and Molecular Gastronomy (excl. Wine)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090801",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3490,
                                        "cvr_parent_cvo_id": 452356,
                                        "cvr_child_cvo_id": 452358,
                                        "controlled_vocab": {
                                            "cvo_id": 452358,
                                            "cvo_title": "090802 Food Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090802",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3491,
                                        "cvr_parent_cvo_id": 452356,
                                        "cvr_child_cvo_id": 452359,
                                        "controlled_vocab": {
                                            "cvo_id": 452359,
                                            "cvo_title": "090803 Food Nutritional Balance",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090803",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3492,
                                        "cvr_parent_cvo_id": 452356,
                                        "cvr_child_cvo_id": 452360,
                                        "controlled_vocab": {
                                            "cvo_id": 452360,
                                            "cvo_title": "090804 Food Packaging, Preservation and Safety",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090804",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3493,
                                        "cvr_parent_cvo_id": 452356,
                                        "cvr_child_cvo_id": 452361,
                                        "controlled_vocab": {
                                            "cvo_id": 452361,
                                            "cvo_title": "090805 Food Processing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090805",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3494,
                                        "cvr_parent_cvo_id": 452356,
                                        "cvr_child_cvo_id": 452362,
                                        "controlled_vocab": {
                                            "cvo_id": 452362,
                                            "cvo_title": "090806 Wine Chemistry and Wine Sensory Science",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090806",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3495,
                                        "cvr_parent_cvo_id": 452356,
                                        "cvr_child_cvo_id": 452363,
                                        "controlled_vocab": {
                                            "cvo_id": 452363,
                                            "cvo_title": "090899 Food Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090899",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3496,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452364,
                            "controlled_vocab": {
                                "cvo_id": 452364,
                                "cvo_title": "0909 Geomatic Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0909",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3497,
                                        "cvr_parent_cvo_id": 452364,
                                        "cvr_child_cvo_id": 452365,
                                        "controlled_vocab": {
                                            "cvo_id": 452365,
                                            "cvo_title": "090901 Cartography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3498,
                                        "cvr_parent_cvo_id": 452364,
                                        "cvr_child_cvo_id": 452366,
                                        "controlled_vocab": {
                                            "cvo_id": 452366,
                                            "cvo_title": "090902 Geodesy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3499,
                                        "cvr_parent_cvo_id": 452364,
                                        "cvr_child_cvo_id": 452367,
                                        "controlled_vocab": {
                                            "cvo_id": 452367,
                                            "cvo_title": "090903 Geospatial Information Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090903",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3500,
                                        "cvr_parent_cvo_id": 452364,
                                        "cvr_child_cvo_id": 452368,
                                        "controlled_vocab": {
                                            "cvo_id": 452368,
                                            "cvo_title": "090904 Navigation and Position Fixing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090904",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3501,
                                        "cvr_parent_cvo_id": 452364,
                                        "cvr_child_cvo_id": 452369,
                                        "controlled_vocab": {
                                            "cvo_id": 452369,
                                            "cvo_title": "090905 Photogrammetry and Remote Sensing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090905",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3502,
                                        "cvr_parent_cvo_id": 452364,
                                        "cvr_child_cvo_id": 452370,
                                        "controlled_vocab": {
                                            "cvo_id": 452370,
                                            "cvo_title": "090906 Surveying (incl. Hydrographic Surveying)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090906",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3503,
                                        "cvr_parent_cvo_id": 452364,
                                        "cvr_child_cvo_id": 452371,
                                        "controlled_vocab": {
                                            "cvo_id": 452371,
                                            "cvo_title": "090999 Geomatic Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "090999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3504,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452372,
                            "controlled_vocab": {
                                "cvo_id": 452372,
                                "cvo_title": "0910 Manufacturing Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0910",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3505,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452373,
                                        "controlled_vocab": {
                                            "cvo_id": 452373,
                                            "cvo_title": "091001 CAD/CAM Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091001",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3506,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452374,
                                        "controlled_vocab": {
                                            "cvo_id": 452374,
                                            "cvo_title": "091002 Flexible Manufacturing Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091002",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3507,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452375,
                                        "controlled_vocab": {
                                            "cvo_id": 452375,
                                            "cvo_title": "091003 Machine Tools",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091003",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3508,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452376,
                                        "controlled_vocab": {
                                            "cvo_id": 452376,
                                            "cvo_title": "091004 Machining",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091004",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3509,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452377,
                                        "controlled_vocab": {
                                            "cvo_id": 452377,
                                            "cvo_title": "091005 Manufacturing Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091005",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3510,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452378,
                                        "controlled_vocab": {
                                            "cvo_id": 452378,
                                            "cvo_title": "091006 Manufacturing Processes and Technologies (excl. Textiles)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091006",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3511,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452379,
                                        "controlled_vocab": {
                                            "cvo_id": 452379,
                                            "cvo_title": "091007 Manufacturing Robotics and Mechatronics (excl. Automotive Mechatronics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091007",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3512,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452380,
                                        "controlled_vocab": {
                                            "cvo_id": 452380,
                                            "cvo_title": "091008 Manufacturing Safety and Quality",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091008",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3513,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452381,
                                        "controlled_vocab": {
                                            "cvo_id": 452381,
                                            "cvo_title": "091009 Microtechnology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091009",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3514,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452382,
                                        "controlled_vocab": {
                                            "cvo_id": 452382,
                                            "cvo_title": "091010 Packaging, Storage and Transportation (excl. Food and Agricultural Products)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091010",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3515,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452383,
                                        "controlled_vocab": {
                                            "cvo_id": 452383,
                                            "cvo_title": "091011 Precision Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091011",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3516,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452384,
                                        "controlled_vocab": {
                                            "cvo_id": 452384,
                                            "cvo_title": "091012 Textile Technology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091012",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3517,
                                        "cvr_parent_cvo_id": 452372,
                                        "cvr_child_cvo_id": 452385,
                                        "controlled_vocab": {
                                            "cvo_id": 452385,
                                            "cvo_title": "091099 Manufacturing Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091099",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3518,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452386,
                            "controlled_vocab": {
                                "cvo_id": 452386,
                                "cvo_title": "0911 Maritime Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0911",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3519,
                                        "cvr_parent_cvo_id": 452386,
                                        "cvr_child_cvo_id": 452387,
                                        "controlled_vocab": {
                                            "cvo_id": 452387,
                                            "cvo_title": "091101 Marine Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3520,
                                        "cvr_parent_cvo_id": 452386,
                                        "cvr_child_cvo_id": 452388,
                                        "controlled_vocab": {
                                            "cvo_id": 452388,
                                            "cvo_title": "091102 Naval Architecture",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3521,
                                        "cvr_parent_cvo_id": 452386,
                                        "cvr_child_cvo_id": 452389,
                                        "controlled_vocab": {
                                            "cvo_id": 452389,
                                            "cvo_title": "091103 Ocean Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3522,
                                        "cvr_parent_cvo_id": 452386,
                                        "cvr_child_cvo_id": 452390,
                                        "controlled_vocab": {
                                            "cvo_id": 452390,
                                            "cvo_title": "091104 Ship and Platform Hydrodynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3523,
                                        "cvr_parent_cvo_id": 452386,
                                        "cvr_child_cvo_id": 452391,
                                        "controlled_vocab": {
                                            "cvo_id": 452391,
                                            "cvo_title": "091105 Ship and Platform Structures",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3524,
                                        "cvr_parent_cvo_id": 452386,
                                        "cvr_child_cvo_id": 452392,
                                        "controlled_vocab": {
                                            "cvo_id": 452392,
                                            "cvo_title": "091106 Special Vehicles",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3525,
                                        "cvr_parent_cvo_id": 452386,
                                        "cvr_child_cvo_id": 452393,
                                        "controlled_vocab": {
                                            "cvo_id": 452393,
                                            "cvo_title": "091199 Maritime Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3526,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452394,
                            "controlled_vocab": {
                                "cvo_id": 452394,
                                "cvo_title": "0912 Materials Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0912",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3527,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452395,
                                        "controlled_vocab": {
                                            "cvo_id": 452395,
                                            "cvo_title": "091201 Ceramics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3528,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452396,
                                        "controlled_vocab": {
                                            "cvo_id": 452396,
                                            "cvo_title": "091202 Composite and Hybrid Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3529,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452397,
                                        "controlled_vocab": {
                                            "cvo_id": 452397,
                                            "cvo_title": "091203 Compound Semiconductors",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3530,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452398,
                                        "controlled_vocab": {
                                            "cvo_id": 452398,
                                            "cvo_title": "091204 Elemental Semiconductors",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3531,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452399,
                                        "controlled_vocab": {
                                            "cvo_id": 452399,
                                            "cvo_title": "091205 Functional Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3532,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452400,
                                        "controlled_vocab": {
                                            "cvo_id": 452400,
                                            "cvo_title": "091206 Glass",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3533,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452401,
                                        "controlled_vocab": {
                                            "cvo_id": 452401,
                                            "cvo_title": "091207 Metals and Alloy Materials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3534,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452402,
                                        "controlled_vocab": {
                                            "cvo_id": 452402,
                                            "cvo_title": "091208 Organic Semiconductors",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091208",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3535,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452403,
                                        "controlled_vocab": {
                                            "cvo_id": 452403,
                                            "cvo_title": "091209 Polymers and Plastics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091209",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3536,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452404,
                                        "controlled_vocab": {
                                            "cvo_id": 452404,
                                            "cvo_title": "091210 Timber, Pulp and Paper",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091210",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3537,
                                        "cvr_parent_cvo_id": 452394,
                                        "cvr_child_cvo_id": 452405,
                                        "controlled_vocab": {
                                            "cvo_id": 452405,
                                            "cvo_title": "091299 Materials Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3538,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452406,
                            "controlled_vocab": {
                                "cvo_id": 452406,
                                "cvo_title": "0913 Mechanical Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0913",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3539,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452407,
                                        "controlled_vocab": {
                                            "cvo_id": 452407,
                                            "cvo_title": "091301 Acoustics and Noise Control (excl. Architectural Acoustics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3540,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452408,
                                        "controlled_vocab": {
                                            "cvo_id": 452408,
                                            "cvo_title": "091302 Automation and Control Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3541,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452409,
                                        "controlled_vocab": {
                                            "cvo_id": 452409,
                                            "cvo_title": "091303 Autonomous Vehicles",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3542,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452410,
                                        "controlled_vocab": {
                                            "cvo_id": 452410,
                                            "cvo_title": "091304 Dynamics, Vibration and Vibration Control",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3543,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452411,
                                        "controlled_vocab": {
                                            "cvo_id": 452411,
                                            "cvo_title": "091305 Energy Generation, Conversion and Storage Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3544,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452412,
                                        "controlled_vocab": {
                                            "cvo_id": 452412,
                                            "cvo_title": "091306 Microelectromechanical Systems (MEMS)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3545,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452413,
                                        "controlled_vocab": {
                                            "cvo_id": 452413,
                                            "cvo_title": "091307 Numerical Modelling and Mechanical Characterisation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3546,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452414,
                                        "controlled_vocab": {
                                            "cvo_id": 452414,
                                            "cvo_title": "091308 Solid Mechanics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3547,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452415,
                                        "controlled_vocab": {
                                            "cvo_id": 452415,
                                            "cvo_title": "091309 Tribology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3548,
                                        "cvr_parent_cvo_id": 452406,
                                        "cvr_child_cvo_id": 452416,
                                        "controlled_vocab": {
                                            "cvo_id": 452416,
                                            "cvo_title": "091399 Mechanical Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3549,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452417,
                            "controlled_vocab": {
                                "cvo_id": 452417,
                                "cvo_title": "0914 Resources Engineering and Extractive Metallurgy",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0914",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3550,
                                        "cvr_parent_cvo_id": 452417,
                                        "cvr_child_cvo_id": 452418,
                                        "controlled_vocab": {
                                            "cvo_id": 452418,
                                            "cvo_title": "091401 Electrometallurgy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3551,
                                        "cvr_parent_cvo_id": 452417,
                                        "cvr_child_cvo_id": 452419,
                                        "controlled_vocab": {
                                            "cvo_id": 452419,
                                            "cvo_title": "091402 Geomechanics and Resources Geotechnical Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3552,
                                        "cvr_parent_cvo_id": 452417,
                                        "cvr_child_cvo_id": 452420,
                                        "controlled_vocab": {
                                            "cvo_id": 452420,
                                            "cvo_title": "091403 Hydrometallurgy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3553,
                                        "cvr_parent_cvo_id": 452417,
                                        "cvr_child_cvo_id": 452421,
                                        "controlled_vocab": {
                                            "cvo_id": 452421,
                                            "cvo_title": "091404 Mineral Processing/Beneficiation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3554,
                                        "cvr_parent_cvo_id": 452417,
                                        "cvr_child_cvo_id": 452422,
                                        "controlled_vocab": {
                                            "cvo_id": 452422,
                                            "cvo_title": "091405 Mining Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3555,
                                        "cvr_parent_cvo_id": 452417,
                                        "cvr_child_cvo_id": 452423,
                                        "controlled_vocab": {
                                            "cvo_id": 452423,
                                            "cvo_title": "091406 Petroleum and Reservoir Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3556,
                                        "cvr_parent_cvo_id": 452417,
                                        "cvr_child_cvo_id": 452424,
                                        "controlled_vocab": {
                                            "cvo_id": 452424,
                                            "cvo_title": "091407 Pyrometallurgy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091407",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3557,
                                        "cvr_parent_cvo_id": 452417,
                                        "cvr_child_cvo_id": 452425,
                                        "controlled_vocab": {
                                            "cvo_id": 452425,
                                            "cvo_title": "091499 Resources Engineering and Extractive Metallurgy not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3558,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452426,
                            "controlled_vocab": {
                                "cvo_id": 452426,
                                "cvo_title": "0915 Interdisciplinary Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0915",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3559,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452427,
                                        "controlled_vocab": {
                                            "cvo_id": 452427,
                                            "cvo_title": "091501 Computational Fluid Dynamics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3560,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452428,
                                        "controlled_vocab": {
                                            "cvo_id": 452428,
                                            "cvo_title": "091502 Computational Heat Transfer",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3561,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452429,
                                        "controlled_vocab": {
                                            "cvo_id": 452429,
                                            "cvo_title": "091503 Engineering Practice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3562,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452430,
                                        "controlled_vocab": {
                                            "cvo_id": 452430,
                                            "cvo_title": "091504 Fluidisation and Fluid Mechanics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3563,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452431,
                                        "controlled_vocab": {
                                            "cvo_id": 452431,
                                            "cvo_title": "091505 Heat and Mass Transfer Operations",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3564,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452432,
                                        "controlled_vocab": {
                                            "cvo_id": 452432,
                                            "cvo_title": "091506 Nuclear Engineering (incl. Fuel Enrichment and Waste Processing and Storage)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3565,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452433,
                                        "controlled_vocab": {
                                            "cvo_id": 452433,
                                            "cvo_title": "091507 Risk Engineering (excl. Earthquake Engineering)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3566,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452434,
                                        "controlled_vocab": {
                                            "cvo_id": 452434,
                                            "cvo_title": "091508 Turbulent Flows",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091508",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3567,
                                        "cvr_parent_cvo_id": 452426,
                                        "cvr_child_cvo_id": 452435,
                                        "controlled_vocab": {
                                            "cvo_id": 452435,
                                            "cvo_title": "091599 Interdisciplinary Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "091599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3568,
                            "cvr_parent_cvo_id": 452292,
                            "cvr_child_cvo_id": 452436,
                            "controlled_vocab": {
                                "cvo_id": 452436,
                                "cvo_title": "0999 Other Engineering",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "0999",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3569,
                                        "cvr_parent_cvo_id": 452436,
                                        "cvr_child_cvo_id": 452437,
                                        "controlled_vocab": {
                                            "cvo_id": 452437,
                                            "cvo_title": "099901 Agricultural Engineering",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "099901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3570,
                                        "cvr_parent_cvo_id": 452436,
                                        "cvr_child_cvo_id": 452438,
                                        "controlled_vocab": {
                                            "cvo_id": 452438,
                                            "cvo_title": "099902 Engineering Instrumentation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "099902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3571,
                                        "cvr_parent_cvo_id": 452436,
                                        "cvr_child_cvo_id": 452439,
                                        "controlled_vocab": {
                                            "cvo_id": 452439,
                                            "cvo_title": "099999 Engineering not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "099999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3572,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452440,
                "controlled_vocab": {
                    "cvo_id": 452440,
                    "cvo_title": "10 Technology",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "10",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3573,
                            "cvr_parent_cvo_id": 452440,
                            "cvr_child_cvo_id": 452441,
                            "controlled_vocab": {
                                "cvo_id": 452441,
                                "cvo_title": "1001 Agricultural Biotechnology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1001",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3574,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452442,
                                        "controlled_vocab": {
                                            "cvo_id": 452442,
                                            "cvo_title": "100101 Agricultural Biotechnology Diagnostics (incl. Biosensors)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3575,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452443,
                                        "controlled_vocab": {
                                            "cvo_id": 452443,
                                            "cvo_title": "100102 Agricultural Marine Biotechnology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3576,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452444,
                                        "controlled_vocab": {
                                            "cvo_id": 452444,
                                            "cvo_title": "100103 Agricultural Molecular Engineering of Nucleic Acids and Proteins",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3577,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452445,
                                        "controlled_vocab": {
                                            "cvo_id": 452445,
                                            "cvo_title": "100104 Genetically Modified Animals",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3578,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452446,
                                        "controlled_vocab": {
                                            "cvo_id": 452446,
                                            "cvo_title": "100105 Genetically Modified Field Crops and Pasture",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3579,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452447,
                                        "controlled_vocab": {
                                            "cvo_id": 452447,
                                            "cvo_title": "100106 Genetically Modified Horticulture Plants",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3580,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452448,
                                        "controlled_vocab": {
                                            "cvo_id": 452448,
                                            "cvo_title": "100107 Genetically Modified Trees",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3581,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452449,
                                        "controlled_vocab": {
                                            "cvo_id": 452449,
                                            "cvo_title": "100108 Livestock Cloning",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3582,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452450,
                                        "controlled_vocab": {
                                            "cvo_id": 452450,
                                            "cvo_title": "100109 Transgenesis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100109",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3583,
                                        "cvr_parent_cvo_id": 452441,
                                        "cvr_child_cvo_id": 452451,
                                        "controlled_vocab": {
                                            "cvo_id": 452451,
                                            "cvo_title": "100199 Agricultural Biotechnology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3584,
                            "cvr_parent_cvo_id": 452440,
                            "cvr_child_cvo_id": 452452,
                            "controlled_vocab": {
                                "cvo_id": 452452,
                                "cvo_title": "1002 Environmental Biotechnology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1002",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3585,
                                        "cvr_parent_cvo_id": 452452,
                                        "cvr_child_cvo_id": 452453,
                                        "controlled_vocab": {
                                            "cvo_id": 452453,
                                            "cvo_title": "100201 Biodiscovery",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3586,
                                        "cvr_parent_cvo_id": 452452,
                                        "cvr_child_cvo_id": 452454,
                                        "controlled_vocab": {
                                            "cvo_id": 452454,
                                            "cvo_title": "100202 Biological Control",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3587,
                                        "cvr_parent_cvo_id": 452452,
                                        "cvr_child_cvo_id": 452455,
                                        "controlled_vocab": {
                                            "cvo_id": 452455,
                                            "cvo_title": "100203 Bioremediation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3588,
                                        "cvr_parent_cvo_id": 452452,
                                        "cvr_child_cvo_id": 452456,
                                        "controlled_vocab": {
                                            "cvo_id": 452456,
                                            "cvo_title": "100204 Environmental Biotechnology Diagnostics (incl. Biosensors)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3589,
                                        "cvr_parent_cvo_id": 452452,
                                        "cvr_child_cvo_id": 452457,
                                        "controlled_vocab": {
                                            "cvo_id": 452457,
                                            "cvo_title": "100205 Environmental Marine Biotechnology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3590,
                                        "cvr_parent_cvo_id": 452452,
                                        "cvr_child_cvo_id": 452458,
                                        "controlled_vocab": {
                                            "cvo_id": 452458,
                                            "cvo_title": "100206 Environmental Molecular Engineering of Nucleic Acids and Proteins",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3591,
                                        "cvr_parent_cvo_id": 452452,
                                        "cvr_child_cvo_id": 452459,
                                        "controlled_vocab": {
                                            "cvo_id": 452459,
                                            "cvo_title": "100299 Environmental Biotechnology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3592,
                            "cvr_parent_cvo_id": 452440,
                            "cvr_child_cvo_id": 452460,
                            "controlled_vocab": {
                                "cvo_id": 452460,
                                "cvo_title": "1003 Industrial Biotechnology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1003",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3593,
                                        "cvr_parent_cvo_id": 452460,
                                        "cvr_child_cvo_id": 452461,
                                        "controlled_vocab": {
                                            "cvo_id": 452461,
                                            "cvo_title": "100301 Biocatalysis and Enzyme Technology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3594,
                                        "cvr_parent_cvo_id": 452460,
                                        "cvr_child_cvo_id": 452462,
                                        "controlled_vocab": {
                                            "cvo_id": 452462,
                                            "cvo_title": "100302 Bioprocessing, Bioproduction and Bioproducts",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3595,
                                        "cvr_parent_cvo_id": 452460,
                                        "cvr_child_cvo_id": 452463,
                                        "controlled_vocab": {
                                            "cvo_id": 452463,
                                            "cvo_title": "100303 Fermentation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3596,
                                        "cvr_parent_cvo_id": 452460,
                                        "cvr_child_cvo_id": 452464,
                                        "controlled_vocab": {
                                            "cvo_id": 452464,
                                            "cvo_title": "100304 Industrial Biotechnology Diagnostics (incl. Biosensors)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3597,
                                        "cvr_parent_cvo_id": 452460,
                                        "cvr_child_cvo_id": 452465,
                                        "controlled_vocab": {
                                            "cvo_id": 452465,
                                            "cvo_title": "100305 Industrial Microbiology (incl. Biofeedstocks)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3598,
                                        "cvr_parent_cvo_id": 452460,
                                        "cvr_child_cvo_id": 452466,
                                        "controlled_vocab": {
                                            "cvo_id": 452466,
                                            "cvo_title": "100306 Industrial Molecular Engineering of Nucleic Acids and Proteins",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3599,
                                        "cvr_parent_cvo_id": 452460,
                                        "cvr_child_cvo_id": 452467,
                                        "controlled_vocab": {
                                            "cvo_id": 452467,
                                            "cvo_title": "100399 Industrial Biotechnology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3600,
                            "cvr_parent_cvo_id": 452440,
                            "cvr_child_cvo_id": 452468,
                            "controlled_vocab": {
                                "cvo_id": 452468,
                                "cvo_title": "1004 Medical Biotechnology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1004",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3601,
                                        "cvr_parent_cvo_id": 452468,
                                        "cvr_child_cvo_id": 452469,
                                        "controlled_vocab": {
                                            "cvo_id": 452469,
                                            "cvo_title": "100401 Gene and Molecular Therapy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3602,
                                        "cvr_parent_cvo_id": 452468,
                                        "cvr_child_cvo_id": 452470,
                                        "controlled_vocab": {
                                            "cvo_id": 452470,
                                            "cvo_title": "100402 Medical Biotechnology Diagnostics (incl. Biosensors)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3603,
                                        "cvr_parent_cvo_id": 452468,
                                        "cvr_child_cvo_id": 452471,
                                        "controlled_vocab": {
                                            "cvo_id": 452471,
                                            "cvo_title": "100403 Medical Molecular Engineering of Nucleic Acids and Proteins",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3604,
                                        "cvr_parent_cvo_id": 452468,
                                        "cvr_child_cvo_id": 452472,
                                        "controlled_vocab": {
                                            "cvo_id": 452472,
                                            "cvo_title": "100404 Regenerative Medicine (incl. Stem Cells and Tissue Engineering)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3605,
                                        "cvr_parent_cvo_id": 452468,
                                        "cvr_child_cvo_id": 452473,
                                        "controlled_vocab": {
                                            "cvo_id": 452473,
                                            "cvo_title": "100499 Medical Biotechnology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3606,
                            "cvr_parent_cvo_id": 452440,
                            "cvr_child_cvo_id": 452474,
                            "controlled_vocab": {
                                "cvo_id": 452474,
                                "cvo_title": "1005 Communications Technologies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1005",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3607,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452475,
                                        "controlled_vocab": {
                                            "cvo_id": 452475,
                                            "cvo_title": "100501 Antennas and Propagation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3608,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452476,
                                        "controlled_vocab": {
                                            "cvo_id": 452476,
                                            "cvo_title": "100502 Broadband and Modem Technology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3609,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452477,
                                        "controlled_vocab": {
                                            "cvo_id": 452477,
                                            "cvo_title": "100503 Computer Communications Networks",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3610,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452478,
                                        "controlled_vocab": {
                                            "cvo_id": 452478,
                                            "cvo_title": "100504 Data Communications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3611,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452479,
                                        "controlled_vocab": {
                                            "cvo_id": 452479,
                                            "cvo_title": "100505 Microwave and Millimetrewave Theory and Technology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3612,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452480,
                                        "controlled_vocab": {
                                            "cvo_id": 452480,
                                            "cvo_title": "100506 Optical Fibre Communications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3613,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452481,
                                        "controlled_vocab": {
                                            "cvo_id": 452481,
                                            "cvo_title": "100507 Optical Networks and Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3614,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452482,
                                        "controlled_vocab": {
                                            "cvo_id": 452482,
                                            "cvo_title": "100508 Satellite Communications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100508",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3615,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452483,
                                        "controlled_vocab": {
                                            "cvo_id": 452483,
                                            "cvo_title": "100509 Video Communications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100509",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3616,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452484,
                                        "controlled_vocab": {
                                            "cvo_id": 452484,
                                            "cvo_title": "100510 Wireless Communications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100510",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3617,
                                        "cvr_parent_cvo_id": 452474,
                                        "cvr_child_cvo_id": 452485,
                                        "controlled_vocab": {
                                            "cvo_id": 452485,
                                            "cvo_title": "100599 Communications Technologies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3618,
                            "cvr_parent_cvo_id": 452440,
                            "cvr_child_cvo_id": 452486,
                            "controlled_vocab": {
                                "cvo_id": 452486,
                                "cvo_title": "1006 Computer Hardware",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1006",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3619,
                                        "cvr_parent_cvo_id": 452486,
                                        "cvr_child_cvo_id": 452487,
                                        "controlled_vocab": {
                                            "cvo_id": 452487,
                                            "cvo_title": "100601 Arithmetic and Logic Structures",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3620,
                                        "cvr_parent_cvo_id": 452486,
                                        "cvr_child_cvo_id": 452488,
                                        "controlled_vocab": {
                                            "cvo_id": 452488,
                                            "cvo_title": "100602 Input, Output and Data Devices",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3621,
                                        "cvr_parent_cvo_id": 452486,
                                        "cvr_child_cvo_id": 452489,
                                        "controlled_vocab": {
                                            "cvo_id": 452489,
                                            "cvo_title": "100603 Logic Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3622,
                                        "cvr_parent_cvo_id": 452486,
                                        "cvr_child_cvo_id": 452490,
                                        "controlled_vocab": {
                                            "cvo_id": 452490,
                                            "cvo_title": "100604 Memory Structures",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3623,
                                        "cvr_parent_cvo_id": 452486,
                                        "cvr_child_cvo_id": 452491,
                                        "controlled_vocab": {
                                            "cvo_id": 452491,
                                            "cvo_title": "100605 Performance Evaluation; Testing and Simulation of Reliability",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100605",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3624,
                                        "cvr_parent_cvo_id": 452486,
                                        "cvr_child_cvo_id": 452492,
                                        "controlled_vocab": {
                                            "cvo_id": 452492,
                                            "cvo_title": "100606 Processor Architectures",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100606",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3625,
                                        "cvr_parent_cvo_id": 452486,
                                        "cvr_child_cvo_id": 452493,
                                        "controlled_vocab": {
                                            "cvo_id": 452493,
                                            "cvo_title": "100699 Computer Hardware not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3626,
                            "cvr_parent_cvo_id": 452440,
                            "cvr_child_cvo_id": 452494,
                            "controlled_vocab": {
                                "cvo_id": 452494,
                                "cvo_title": "1007 Nanotechnology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1007",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3627,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452495,
                                        "controlled_vocab": {
                                            "cvo_id": 452495,
                                            "cvo_title": "100701 Environmental Nanotechnology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3628,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452496,
                                        "controlled_vocab": {
                                            "cvo_id": 452496,
                                            "cvo_title": "100702 Molecular and Organic Electronics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3629,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452497,
                                        "controlled_vocab": {
                                            "cvo_id": 452497,
                                            "cvo_title": "100703 Nanobiotechnology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3630,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452498,
                                        "controlled_vocab": {
                                            "cvo_id": 452498,
                                            "cvo_title": "100704 Nanoelectromechanical Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100704",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3631,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452499,
                                        "controlled_vocab": {
                                            "cvo_id": 452499,
                                            "cvo_title": "100705 Nanoelectronics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100705",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3632,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452500,
                                        "controlled_vocab": {
                                            "cvo_id": 452500,
                                            "cvo_title": "100706 Nanofabrication, Growth and Self Assembly",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100706",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3633,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452501,
                                        "controlled_vocab": {
                                            "cvo_id": 452501,
                                            "cvo_title": "100707 Nanomanufacturing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100707",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3634,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452502,
                                        "controlled_vocab": {
                                            "cvo_id": 452502,
                                            "cvo_title": "100708 Nanomaterials",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100708",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3635,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452503,
                                        "controlled_vocab": {
                                            "cvo_id": 452503,
                                            "cvo_title": "100709 Nanomedicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100709",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3636,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452504,
                                        "controlled_vocab": {
                                            "cvo_id": 452504,
                                            "cvo_title": "100710 Nanometrology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100710",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3637,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452505,
                                        "controlled_vocab": {
                                            "cvo_id": 452505,
                                            "cvo_title": "100711 Nanophotonics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100711",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3638,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452506,
                                        "controlled_vocab": {
                                            "cvo_id": 452506,
                                            "cvo_title": "100712 Nanoscale Characterisation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100712",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3639,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452507,
                                        "controlled_vocab": {
                                            "cvo_id": 452507,
                                            "cvo_title": "100713 Nanotoxicology, Health and Safety",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100713",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3640,
                                        "cvr_parent_cvo_id": 452494,
                                        "cvr_child_cvo_id": 452508,
                                        "controlled_vocab": {
                                            "cvo_id": 452508,
                                            "cvo_title": "100799 Nanotechnology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "100799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3641,
                            "cvr_parent_cvo_id": 452440,
                            "cvr_child_cvo_id": 452509,
                            "controlled_vocab": {
                                "cvo_id": 452509,
                                "cvo_title": "1099 Other Technology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1099",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3642,
                                        "cvr_parent_cvo_id": 452509,
                                        "cvr_child_cvo_id": 452510,
                                        "controlled_vocab": {
                                            "cvo_id": 452510,
                                            "cvo_title": "109999 Technology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "109999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3643,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452511,
                "controlled_vocab": {
                    "cvo_id": 452511,
                    "cvo_title": "11 Medical and Health Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "11",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3644,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452512,
                            "controlled_vocab": {
                                "cvo_id": 452512,
                                "cvo_title": "1101 Medical Biochemistry and Metabolomics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1101",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3645,
                                        "cvr_parent_cvo_id": 452512,
                                        "cvr_child_cvo_id": 452513,
                                        "controlled_vocab": {
                                            "cvo_id": 452513,
                                            "cvo_title": "110101 Medical Biochemistry: Amino Acids and Metabolites",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3646,
                                        "cvr_parent_cvo_id": 452512,
                                        "cvr_child_cvo_id": 452514,
                                        "controlled_vocab": {
                                            "cvo_id": 452514,
                                            "cvo_title": "110102 Medical Biochemistry: Carbohydrates",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3647,
                                        "cvr_parent_cvo_id": 452512,
                                        "cvr_child_cvo_id": 452515,
                                        "controlled_vocab": {
                                            "cvo_id": 452515,
                                            "cvo_title": "110103 Medical Biochemistry: Inorganic Elements and Compounds",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3648,
                                        "cvr_parent_cvo_id": 452512,
                                        "cvr_child_cvo_id": 452516,
                                        "controlled_vocab": {
                                            "cvo_id": 452516,
                                            "cvo_title": "110104 Medical Biochemistry: Lipids",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3649,
                                        "cvr_parent_cvo_id": 452512,
                                        "cvr_child_cvo_id": 452517,
                                        "controlled_vocab": {
                                            "cvo_id": 452517,
                                            "cvo_title": "110105 Medical Biochemistry: Nucleic Acids",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3650,
                                        "cvr_parent_cvo_id": 452512,
                                        "cvr_child_cvo_id": 452518,
                                        "controlled_vocab": {
                                            "cvo_id": 452518,
                                            "cvo_title": "110106 Medical Biochemistry: Proteins and Peptides (incl. Medical Proteomics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3651,
                                        "cvr_parent_cvo_id": 452512,
                                        "cvr_child_cvo_id": 452519,
                                        "controlled_vocab": {
                                            "cvo_id": 452519,
                                            "cvo_title": "110107 Metabolic Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3652,
                                        "cvr_parent_cvo_id": 452512,
                                        "cvr_child_cvo_id": 452520,
                                        "controlled_vocab": {
                                            "cvo_id": 452520,
                                            "cvo_title": "110199 Medical Biochemistry and Metabolomics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3653,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452521,
                            "controlled_vocab": {
                                "cvo_id": 452521,
                                "cvo_title": "1102 Cardiovascular Medicine and Haematology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1102",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3654,
                                        "cvr_parent_cvo_id": 452521,
                                        "cvr_child_cvo_id": 452522,
                                        "controlled_vocab": {
                                            "cvo_id": 452522,
                                            "cvo_title": "110201 Cardiology (incl. Cardiovascular Diseases)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3655,
                                        "cvr_parent_cvo_id": 452521,
                                        "cvr_child_cvo_id": 452523,
                                        "controlled_vocab": {
                                            "cvo_id": 452523,
                                            "cvo_title": "110202 Haematology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3656,
                                        "cvr_parent_cvo_id": 452521,
                                        "cvr_child_cvo_id": 452524,
                                        "controlled_vocab": {
                                            "cvo_id": 452524,
                                            "cvo_title": "110203 Respiratory Diseases",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3657,
                                        "cvr_parent_cvo_id": 452521,
                                        "cvr_child_cvo_id": 452525,
                                        "controlled_vocab": {
                                            "cvo_id": 452525,
                                            "cvo_title": "110299 Cardiovascular Medicine and Haematology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3658,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452526,
                            "controlled_vocab": {
                                "cvo_id": 452526,
                                "cvo_title": "1103 Clinical Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1103",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3659,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452527,
                                        "controlled_vocab": {
                                            "cvo_id": 452527,
                                            "cvo_title": "110301 Anaesthesiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3660,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452528,
                                        "controlled_vocab": {
                                            "cvo_id": 452528,
                                            "cvo_title": "110302 Clinical Chemistry (diagnostics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3661,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452529,
                                        "controlled_vocab": {
                                            "cvo_id": 452529,
                                            "cvo_title": "110303 Clinical Microbiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3662,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452530,
                                        "controlled_vocab": {
                                            "cvo_id": 452530,
                                            "cvo_title": "110304 Dermatology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3663,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452531,
                                        "controlled_vocab": {
                                            "cvo_id": 452531,
                                            "cvo_title": "110305 Emergency Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3664,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452532,
                                        "controlled_vocab": {
                                            "cvo_id": 452532,
                                            "cvo_title": "110306 Endocrinology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3665,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452533,
                                        "controlled_vocab": {
                                            "cvo_id": 452533,
                                            "cvo_title": "110307 Gastroenterology and Hepatology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3666,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452534,
                                        "controlled_vocab": {
                                            "cvo_id": 452534,
                                            "cvo_title": "110308 Geriatrics and Gerontology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3667,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452535,
                                        "controlled_vocab": {
                                            "cvo_id": 452535,
                                            "cvo_title": "110309 Infectious Diseases",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3668,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452536,
                                        "controlled_vocab": {
                                            "cvo_id": 452536,
                                            "cvo_title": "110310 Intensive Care",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110310",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3669,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452537,
                                        "controlled_vocab": {
                                            "cvo_id": 452537,
                                            "cvo_title": "110311 Medical Genetics (excl. Cancer Genetics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110311",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3670,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452538,
                                        "controlled_vocab": {
                                            "cvo_id": 452538,
                                            "cvo_title": "110312 Nephrology and Urology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110312",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3671,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452539,
                                        "controlled_vocab": {
                                            "cvo_id": 452539,
                                            "cvo_title": "110313 Nuclear Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110313",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3672,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452540,
                                        "controlled_vocab": {
                                            "cvo_id": 452540,
                                            "cvo_title": "110314 Orthopaedics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110314",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3673,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452541,
                                        "controlled_vocab": {
                                            "cvo_id": 452541,
                                            "cvo_title": "110315 Otorhinolaryngology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110315",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3674,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452542,
                                        "controlled_vocab": {
                                            "cvo_id": 452542,
                                            "cvo_title": "110316 Pathology (excl. Oral Pathology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110316",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3675,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452543,
                                        "controlled_vocab": {
                                            "cvo_id": 452543,
                                            "cvo_title": "110317 Physiotherapy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110317",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3676,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452544,
                                        "controlled_vocab": {
                                            "cvo_id": 452544,
                                            "cvo_title": "110318 Podiatry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110318",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3677,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452545,
                                        "controlled_vocab": {
                                            "cvo_id": 452545,
                                            "cvo_title": "110319 Psychiatry (incl. Psychotherapy)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110319",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3678,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452546,
                                        "controlled_vocab": {
                                            "cvo_id": 452546,
                                            "cvo_title": "110320 Radiology and Organ Imaging",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110320",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3679,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452547,
                                        "controlled_vocab": {
                                            "cvo_id": 452547,
                                            "cvo_title": "110321 Rehabilitation and Therapy (excl. Physiotherapy)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110321",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3680,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452548,
                                        "controlled_vocab": {
                                            "cvo_id": 452548,
                                            "cvo_title": "110322 Rheumatology and Arthritis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110322",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3681,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452549,
                                        "controlled_vocab": {
                                            "cvo_id": 452549,
                                            "cvo_title": "110323 Surgery",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110323",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3682,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452550,
                                        "controlled_vocab": {
                                            "cvo_id": 452550,
                                            "cvo_title": "110324 Venereology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110324",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3683,
                                        "cvr_parent_cvo_id": 452526,
                                        "cvr_child_cvo_id": 452551,
                                        "controlled_vocab": {
                                            "cvo_id": 452551,
                                            "cvo_title": "110399 Clinical Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3684,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452552,
                            "controlled_vocab": {
                                "cvo_id": 452552,
                                "cvo_title": "1104 Complementary and Alternative Medicine",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1104",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3685,
                                        "cvr_parent_cvo_id": 452552,
                                        "cvr_child_cvo_id": 452553,
                                        "controlled_vocab": {
                                            "cvo_id": 452553,
                                            "cvo_title": "110401 Chiropractic",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3686,
                                        "cvr_parent_cvo_id": 452552,
                                        "cvr_child_cvo_id": 452554,
                                        "controlled_vocab": {
                                            "cvo_id": 452554,
                                            "cvo_title": "110402 Naturopathy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3687,
                                        "cvr_parent_cvo_id": 452552,
                                        "cvr_child_cvo_id": 452555,
                                        "controlled_vocab": {
                                            "cvo_id": 452555,
                                            "cvo_title": "110403 Traditional Aboriginal and Torres Strait Islander Medicine and Treatments",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3688,
                                        "cvr_parent_cvo_id": 452552,
                                        "cvr_child_cvo_id": 452556,
                                        "controlled_vocab": {
                                            "cvo_id": 452556,
                                            "cvo_title": "110404 Traditional Chinese Medicine and Treatments",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3689,
                                        "cvr_parent_cvo_id": 452552,
                                        "cvr_child_cvo_id": 452557,
                                        "controlled_vocab": {
                                            "cvo_id": 452557,
                                            "cvo_title": "110405 Traditional Maori Medicine and Treatments",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3690,
                                        "cvr_parent_cvo_id": 452552,
                                        "cvr_child_cvo_id": 452558,
                                        "controlled_vocab": {
                                            "cvo_id": 452558,
                                            "cvo_title": "110499 Complementary and Alternative Medicine not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3691,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452559,
                            "controlled_vocab": {
                                "cvo_id": 452559,
                                "cvo_title": "1105 Dentistry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1105",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3692,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452560,
                                        "controlled_vocab": {
                                            "cvo_id": 452560,
                                            "cvo_title": "110501 Dental Materials and Equipment",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3693,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452561,
                                        "controlled_vocab": {
                                            "cvo_id": 452561,
                                            "cvo_title": "110502 Dental Therapeutics, Pharmacology and Toxicology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3694,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452562,
                                        "controlled_vocab": {
                                            "cvo_id": 452562,
                                            "cvo_title": "110503 Endodontics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3695,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452563,
                                        "controlled_vocab": {
                                            "cvo_id": 452563,
                                            "cvo_title": "110504 Oral and Maxillofacial Surgery",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3696,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452564,
                                        "controlled_vocab": {
                                            "cvo_id": 452564,
                                            "cvo_title": "110505 Oral Medicine and Pathology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3697,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452565,
                                        "controlled_vocab": {
                                            "cvo_id": 452565,
                                            "cvo_title": "110506 Orthodontics and Dentofacial Orthopaedics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3698,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452566,
                                        "controlled_vocab": {
                                            "cvo_id": 452566,
                                            "cvo_title": "110507 Paedodontics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3699,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452567,
                                        "controlled_vocab": {
                                            "cvo_id": 452567,
                                            "cvo_title": "110508 Periodontics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110508",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3700,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452568,
                                        "controlled_vocab": {
                                            "cvo_id": 452568,
                                            "cvo_title": "110509 Special Needs Dentistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110509",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3701,
                                        "cvr_parent_cvo_id": 452559,
                                        "cvr_child_cvo_id": 452569,
                                        "controlled_vocab": {
                                            "cvo_id": 452569,
                                            "cvo_title": "110599 Dentistry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3702,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452570,
                            "controlled_vocab": {
                                "cvo_id": 452570,
                                "cvo_title": "1106 Human Movement and Sports Science",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1106",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3703,
                                        "cvr_parent_cvo_id": 452570,
                                        "cvr_child_cvo_id": 452571,
                                        "controlled_vocab": {
                                            "cvo_id": 452571,
                                            "cvo_title": "110601 Biomechanics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3704,
                                        "cvr_parent_cvo_id": 452570,
                                        "cvr_child_cvo_id": 452572,
                                        "controlled_vocab": {
                                            "cvo_id": 452572,
                                            "cvo_title": "110602 Exercise Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3705,
                                        "cvr_parent_cvo_id": 452570,
                                        "cvr_child_cvo_id": 452573,
                                        "controlled_vocab": {
                                            "cvo_id": 452573,
                                            "cvo_title": "110603 Motor Control",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3706,
                                        "cvr_parent_cvo_id": 452570,
                                        "cvr_child_cvo_id": 452574,
                                        "controlled_vocab": {
                                            "cvo_id": 452574,
                                            "cvo_title": "110604 Sports Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3707,
                                        "cvr_parent_cvo_id": 452570,
                                        "cvr_child_cvo_id": 452575,
                                        "controlled_vocab": {
                                            "cvo_id": 452575,
                                            "cvo_title": "110699 Human Movement and Sports Science not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3708,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452576,
                            "controlled_vocab": {
                                "cvo_id": 452576,
                                "cvo_title": "1107 Immunology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1107",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3709,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452577,
                                        "controlled_vocab": {
                                            "cvo_id": 452577,
                                            "cvo_title": "110701 Allergy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3710,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452578,
                                        "controlled_vocab": {
                                            "cvo_id": 452578,
                                            "cvo_title": "110702 Applied Immunology (incl. Antibody Engineering, Xenotransplantation and T-cell Therapies)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3711,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452579,
                                        "controlled_vocab": {
                                            "cvo_id": 452579,
                                            "cvo_title": "110703 Autoimmunity",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3712,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452580,
                                        "controlled_vocab": {
                                            "cvo_id": 452580,
                                            "cvo_title": "110704 Cellular Immunology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110704",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3713,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452581,
                                        "controlled_vocab": {
                                            "cvo_id": 452581,
                                            "cvo_title": "110705 Humoural Immunology and Immunochemistry",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110705",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3714,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452582,
                                        "controlled_vocab": {
                                            "cvo_id": 452582,
                                            "cvo_title": "110706 Immunogenetics (incl. Genetic Immunology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110706",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3715,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452583,
                                        "controlled_vocab": {
                                            "cvo_id": 452583,
                                            "cvo_title": "110707 Innate Immunity",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110707",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3716,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452584,
                                        "controlled_vocab": {
                                            "cvo_id": 452584,
                                            "cvo_title": "110708 Transplantation Immunology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110708",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3717,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452585,
                                        "controlled_vocab": {
                                            "cvo_id": 452585,
                                            "cvo_title": "110709 Tumour Immunology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110709",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3718,
                                        "cvr_parent_cvo_id": 452576,
                                        "cvr_child_cvo_id": 452586,
                                        "controlled_vocab": {
                                            "cvo_id": 452586,
                                            "cvo_title": "110799 Immunology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3719,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452587,
                            "controlled_vocab": {
                                "cvo_id": 452587,
                                "cvo_title": "1108 Medical Microbiology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1108",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3720,
                                        "cvr_parent_cvo_id": 452587,
                                        "cvr_child_cvo_id": 452588,
                                        "controlled_vocab": {
                                            "cvo_id": 452588,
                                            "cvo_title": "110801 Medical Bacteriology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110801",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3721,
                                        "cvr_parent_cvo_id": 452587,
                                        "cvr_child_cvo_id": 452589,
                                        "controlled_vocab": {
                                            "cvo_id": 452589,
                                            "cvo_title": "110802 Medical Infection Agents (incl. Prions)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110802",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3722,
                                        "cvr_parent_cvo_id": 452587,
                                        "cvr_child_cvo_id": 452590,
                                        "controlled_vocab": {
                                            "cvo_id": 452590,
                                            "cvo_title": "110803 Medical Parasitology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110803",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3723,
                                        "cvr_parent_cvo_id": 452587,
                                        "cvr_child_cvo_id": 452591,
                                        "controlled_vocab": {
                                            "cvo_id": 452591,
                                            "cvo_title": "110804 Medical Virology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110804",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3724,
                                        "cvr_parent_cvo_id": 452587,
                                        "cvr_child_cvo_id": 452592,
                                        "controlled_vocab": {
                                            "cvo_id": 452592,
                                            "cvo_title": "110899 Medical Microbiology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110899",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3725,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452593,
                            "controlled_vocab": {
                                "cvo_id": 452593,
                                "cvo_title": "1109 Neurosciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1109",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3726,
                                        "cvr_parent_cvo_id": 452593,
                                        "cvr_child_cvo_id": 452594,
                                        "controlled_vocab": {
                                            "cvo_id": 452594,
                                            "cvo_title": "110901 Autonomic Nervous System",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3727,
                                        "cvr_parent_cvo_id": 452593,
                                        "cvr_child_cvo_id": 452595,
                                        "controlled_vocab": {
                                            "cvo_id": 452595,
                                            "cvo_title": "110902 Cellular Nervous System",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3728,
                                        "cvr_parent_cvo_id": 452593,
                                        "cvr_child_cvo_id": 452596,
                                        "controlled_vocab": {
                                            "cvo_id": 452596,
                                            "cvo_title": "110903 Central Nervous System",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110903",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3729,
                                        "cvr_parent_cvo_id": 452593,
                                        "cvr_child_cvo_id": 452597,
                                        "controlled_vocab": {
                                            "cvo_id": 452597,
                                            "cvo_title": "110904 Neurology and Neuromuscular Diseases",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110904",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3730,
                                        "cvr_parent_cvo_id": 452593,
                                        "cvr_child_cvo_id": 452598,
                                        "controlled_vocab": {
                                            "cvo_id": 452598,
                                            "cvo_title": "110905 Peripheral Nervous System",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110905",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3731,
                                        "cvr_parent_cvo_id": 452593,
                                        "cvr_child_cvo_id": 452599,
                                        "controlled_vocab": {
                                            "cvo_id": 452599,
                                            "cvo_title": "110906 Sensory Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110906",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3732,
                                        "cvr_parent_cvo_id": 452593,
                                        "cvr_child_cvo_id": 452600,
                                        "controlled_vocab": {
                                            "cvo_id": 452600,
                                            "cvo_title": "110999 Neurosciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "110999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3733,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452601,
                            "controlled_vocab": {
                                "cvo_id": 452601,
                                "cvo_title": "1110 Nursing",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1110",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3734,
                                        "cvr_parent_cvo_id": 452601,
                                        "cvr_child_cvo_id": 452602,
                                        "controlled_vocab": {
                                            "cvo_id": 452602,
                                            "cvo_title": "111001 Aged Care Nursing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111001",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3735,
                                        "cvr_parent_cvo_id": 452601,
                                        "cvr_child_cvo_id": 452603,
                                        "controlled_vocab": {
                                            "cvo_id": 452603,
                                            "cvo_title": "111002 Clinical Nursing: Primary (Preventative)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111002",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3736,
                                        "cvr_parent_cvo_id": 452601,
                                        "cvr_child_cvo_id": 452604,
                                        "controlled_vocab": {
                                            "cvo_id": 452604,
                                            "cvo_title": "111003 Clinical Nursing: Secondary (Acute Care)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111003",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3737,
                                        "cvr_parent_cvo_id": 452601,
                                        "cvr_child_cvo_id": 452605,
                                        "controlled_vocab": {
                                            "cvo_id": 452605,
                                            "cvo_title": "111004 Clinical Nursing: Tertiary (Rehabilitative)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111004",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3738,
                                        "cvr_parent_cvo_id": 452601,
                                        "cvr_child_cvo_id": 452606,
                                        "controlled_vocab": {
                                            "cvo_id": 452606,
                                            "cvo_title": "111005 Mental Health Nursing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111005",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3739,
                                        "cvr_parent_cvo_id": 452601,
                                        "cvr_child_cvo_id": 452607,
                                        "controlled_vocab": {
                                            "cvo_id": 452607,
                                            "cvo_title": "111006 Midwifery",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111006",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3740,
                                        "cvr_parent_cvo_id": 452601,
                                        "cvr_child_cvo_id": 452608,
                                        "controlled_vocab": {
                                            "cvo_id": 452608,
                                            "cvo_title": "111099 Nursing not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111099",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3741,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452609,
                            "controlled_vocab": {
                                "cvo_id": 452609,
                                "cvo_title": "1111 Nutrition and Dietetics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1111",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3742,
                                        "cvr_parent_cvo_id": 452609,
                                        "cvr_child_cvo_id": 452610,
                                        "controlled_vocab": {
                                            "cvo_id": 452610,
                                            "cvo_title": "111101 Clinical and Sports Nutrition",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3743,
                                        "cvr_parent_cvo_id": 452609,
                                        "cvr_child_cvo_id": 452611,
                                        "controlled_vocab": {
                                            "cvo_id": 452611,
                                            "cvo_title": "111102 Dietetics and Nutrigenomics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3744,
                                        "cvr_parent_cvo_id": 452609,
                                        "cvr_child_cvo_id": 452612,
                                        "controlled_vocab": {
                                            "cvo_id": 452612,
                                            "cvo_title": "111103 Nutritional Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3745,
                                        "cvr_parent_cvo_id": 452609,
                                        "cvr_child_cvo_id": 452613,
                                        "controlled_vocab": {
                                            "cvo_id": 452613,
                                            "cvo_title": "111104 Public Nutrition Intervention",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3746,
                                        "cvr_parent_cvo_id": 452609,
                                        "cvr_child_cvo_id": 452614,
                                        "controlled_vocab": {
                                            "cvo_id": 452614,
                                            "cvo_title": "111199 Nutrition and Dietetics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3747,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452615,
                            "controlled_vocab": {
                                "cvo_id": 452615,
                                "cvo_title": "1112 Oncology and Carcinogenesis",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1112",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3748,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452616,
                                        "controlled_vocab": {
                                            "cvo_id": 452616,
                                            "cvo_title": "111201 Cancer Cell Biology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3749,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452617,
                                        "controlled_vocab": {
                                            "cvo_id": 452617,
                                            "cvo_title": "111202 Cancer Diagnosis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3750,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452618,
                                        "controlled_vocab": {
                                            "cvo_id": 452618,
                                            "cvo_title": "111203 Cancer Genetics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3751,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452619,
                                        "controlled_vocab": {
                                            "cvo_id": 452619,
                                            "cvo_title": "111204 Cancer Therapy (excl. Chemotherapy and Radiation Therapy)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3752,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452620,
                                        "controlled_vocab": {
                                            "cvo_id": 452620,
                                            "cvo_title": "111205 Chemotherapy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3753,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452621,
                                        "controlled_vocab": {
                                            "cvo_id": 452621,
                                            "cvo_title": "111206 Haematological Tumours",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3754,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452622,
                                        "controlled_vocab": {
                                            "cvo_id": 452622,
                                            "cvo_title": "111207 Molecular Targets",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3755,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452623,
                                        "controlled_vocab": {
                                            "cvo_id": 452623,
                                            "cvo_title": "111208 Radiation Therapy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111208",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3756,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452624,
                                        "controlled_vocab": {
                                            "cvo_id": 452624,
                                            "cvo_title": "111209 Solid Tumours",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111209",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3757,
                                        "cvr_parent_cvo_id": 452615,
                                        "cvr_child_cvo_id": 452625,
                                        "controlled_vocab": {
                                            "cvo_id": 452625,
                                            "cvo_title": "111299 Oncology and Carcinogenesis not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3758,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452626,
                            "controlled_vocab": {
                                "cvo_id": 452626,
                                "cvo_title": "1113 Ophthalmology and Optometry",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1113",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3759,
                                        "cvr_parent_cvo_id": 452626,
                                        "cvr_child_cvo_id": 452627,
                                        "controlled_vocab": {
                                            "cvo_id": 452627,
                                            "cvo_title": "111301 Ophthalmology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3760,
                                        "cvr_parent_cvo_id": 452626,
                                        "cvr_child_cvo_id": 452628,
                                        "controlled_vocab": {
                                            "cvo_id": 452628,
                                            "cvo_title": "111302 Optical Technology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3761,
                                        "cvr_parent_cvo_id": 452626,
                                        "cvr_child_cvo_id": 452629,
                                        "controlled_vocab": {
                                            "cvo_id": 452629,
                                            "cvo_title": "111303 Vision Science",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3762,
                                        "cvr_parent_cvo_id": 452626,
                                        "cvr_child_cvo_id": 452630,
                                        "controlled_vocab": {
                                            "cvo_id": 452630,
                                            "cvo_title": "111399 Ophthalmology and Optometry not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3763,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452631,
                            "controlled_vocab": {
                                "cvo_id": 452631,
                                "cvo_title": "1114 Paediatrics and Reproductive Medicine",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1114",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3764,
                                        "cvr_parent_cvo_id": 452631,
                                        "cvr_child_cvo_id": 452632,
                                        "controlled_vocab": {
                                            "cvo_id": 452632,
                                            "cvo_title": "111401 Foetal Development and Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3765,
                                        "cvr_parent_cvo_id": 452631,
                                        "cvr_child_cvo_id": 452633,
                                        "controlled_vocab": {
                                            "cvo_id": 452633,
                                            "cvo_title": "111402 Obstetrics and Gynaecology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3766,
                                        "cvr_parent_cvo_id": 452631,
                                        "cvr_child_cvo_id": 452634,
                                        "controlled_vocab": {
                                            "cvo_id": 452634,
                                            "cvo_title": "111403 Paediatrics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3767,
                                        "cvr_parent_cvo_id": 452631,
                                        "cvr_child_cvo_id": 452635,
                                        "controlled_vocab": {
                                            "cvo_id": 452635,
                                            "cvo_title": "111404 Reproduction",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3768,
                                        "cvr_parent_cvo_id": 452631,
                                        "cvr_child_cvo_id": 452636,
                                        "controlled_vocab": {
                                            "cvo_id": 452636,
                                            "cvo_title": "111499 Paediatrics and Reproductive Medicine not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3769,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452637,
                            "controlled_vocab": {
                                "cvo_id": 452637,
                                "cvo_title": "1115 Pharmacology and Pharmaceutical Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1115",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3770,
                                        "cvr_parent_cvo_id": 452637,
                                        "cvr_child_cvo_id": 452638,
                                        "controlled_vocab": {
                                            "cvo_id": 452638,
                                            "cvo_title": "111501 Basic Pharmacology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3771,
                                        "cvr_parent_cvo_id": 452637,
                                        "cvr_child_cvo_id": 452639,
                                        "controlled_vocab": {
                                            "cvo_id": 452639,
                                            "cvo_title": "111502 Clinical Pharmacology and Therapeutics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3772,
                                        "cvr_parent_cvo_id": 452637,
                                        "cvr_child_cvo_id": 452640,
                                        "controlled_vocab": {
                                            "cvo_id": 452640,
                                            "cvo_title": "111503 Clinical Pharmacy and Pharmacy Practice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3773,
                                        "cvr_parent_cvo_id": 452637,
                                        "cvr_child_cvo_id": 452641,
                                        "controlled_vocab": {
                                            "cvo_id": 452641,
                                            "cvo_title": "111504 Pharmaceutical Sciences",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3774,
                                        "cvr_parent_cvo_id": 452637,
                                        "cvr_child_cvo_id": 452642,
                                        "controlled_vocab": {
                                            "cvo_id": 452642,
                                            "cvo_title": "111505 Pharmacogenomics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3775,
                                        "cvr_parent_cvo_id": 452637,
                                        "cvr_child_cvo_id": 452643,
                                        "controlled_vocab": {
                                            "cvo_id": 452643,
                                            "cvo_title": "111506 Toxicology (incl.Clinical Toxicology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3776,
                                        "cvr_parent_cvo_id": 452637,
                                        "cvr_child_cvo_id": 452644,
                                        "controlled_vocab": {
                                            "cvo_id": 452644,
                                            "cvo_title": "111599 Pharmacology and Pharmaceutical Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3777,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452645,
                            "controlled_vocab": {
                                "cvo_id": 452645,
                                "cvo_title": "1116 Medical Physiology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1116",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3778,
                                        "cvr_parent_cvo_id": 452645,
                                        "cvr_child_cvo_id": 452646,
                                        "controlled_vocab": {
                                            "cvo_id": 452646,
                                            "cvo_title": "111601 Cell Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3779,
                                        "cvr_parent_cvo_id": 452645,
                                        "cvr_child_cvo_id": 452647,
                                        "controlled_vocab": {
                                            "cvo_id": 452647,
                                            "cvo_title": "111602 Human Biophysics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3780,
                                        "cvr_parent_cvo_id": 452645,
                                        "cvr_child_cvo_id": 452648,
                                        "controlled_vocab": {
                                            "cvo_id": 452648,
                                            "cvo_title": "111603 Systems Physiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3781,
                                        "cvr_parent_cvo_id": 452645,
                                        "cvr_child_cvo_id": 452649,
                                        "controlled_vocab": {
                                            "cvo_id": 452649,
                                            "cvo_title": "111699 Medical Physiology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3782,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452650,
                            "controlled_vocab": {
                                "cvo_id": 452650,
                                "cvo_title": "1117 Public Health and Health Services",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1117",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3783,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452651,
                                        "controlled_vocab": {
                                            "cvo_id": 452651,
                                            "cvo_title": "111701 Aboriginal and Torres Strait Islander Health",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3784,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452652,
                                        "controlled_vocab": {
                                            "cvo_id": 452652,
                                            "cvo_title": "111702 Aged Health Care",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3785,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452653,
                                        "controlled_vocab": {
                                            "cvo_id": 452653,
                                            "cvo_title": "111703 Care for Disabled",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3786,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452654,
                                        "controlled_vocab": {
                                            "cvo_id": 452654,
                                            "cvo_title": "111704 Community Child Health",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111704",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3787,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452655,
                                        "controlled_vocab": {
                                            "cvo_id": 452655,
                                            "cvo_title": "111705 Environmental and Occupational Health and Safety",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111705",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3788,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452656,
                                        "controlled_vocab": {
                                            "cvo_id": 452656,
                                            "cvo_title": "111706 Epidemiology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111706",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3789,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452657,
                                        "controlled_vocab": {
                                            "cvo_id": 452657,
                                            "cvo_title": "111707 Family Care",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111707",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3790,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452658,
                                        "controlled_vocab": {
                                            "cvo_id": 452658,
                                            "cvo_title": "111708 Health and Community Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111708",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3791,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452659,
                                        "controlled_vocab": {
                                            "cvo_id": 452659,
                                            "cvo_title": "111709 Health Care Administration",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111709",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3792,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452660,
                                        "controlled_vocab": {
                                            "cvo_id": 452660,
                                            "cvo_title": "111710 Health Counselling",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111710",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3793,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452661,
                                        "controlled_vocab": {
                                            "cvo_id": 452661,
                                            "cvo_title": "111711 Health Information Systems (incl. Surveillance)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111711",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3794,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452662,
                                        "controlled_vocab": {
                                            "cvo_id": 452662,
                                            "cvo_title": "111712 Health Promotion",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111712",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3795,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452663,
                                        "controlled_vocab": {
                                            "cvo_id": 452663,
                                            "cvo_title": "111713 Maori Health",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111713",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3796,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452664,
                                        "controlled_vocab": {
                                            "cvo_id": 452664,
                                            "cvo_title": "111714 Mental Health",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111714",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3797,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452665,
                                        "controlled_vocab": {
                                            "cvo_id": 452665,
                                            "cvo_title": "111715 Pacific Peoples Health",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111715",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3798,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452666,
                                        "controlled_vocab": {
                                            "cvo_id": 452666,
                                            "cvo_title": "111716 Preventive Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111716",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3799,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452667,
                                        "controlled_vocab": {
                                            "cvo_id": 452667,
                                            "cvo_title": "111717 Primary Health Care",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111717",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3800,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452668,
                                        "controlled_vocab": {
                                            "cvo_id": 452668,
                                            "cvo_title": "111718 Residential Client Care",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111718",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3801,
                                        "cvr_parent_cvo_id": 452650,
                                        "cvr_child_cvo_id": 452669,
                                        "controlled_vocab": {
                                            "cvo_id": 452669,
                                            "cvo_title": "111799 Public Health and Health Services not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "111799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3802,
                            "cvr_parent_cvo_id": 452511,
                            "cvr_child_cvo_id": 452670,
                            "controlled_vocab": {
                                "cvo_id": 452670,
                                "cvo_title": "1199 Other Medical and Health Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1199",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3803,
                                        "cvr_parent_cvo_id": 452670,
                                        "cvr_child_cvo_id": 452671,
                                        "controlled_vocab": {
                                            "cvo_id": 452671,
                                            "cvo_title": "119999 Medical and Health Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "119999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3804,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452672,
                "controlled_vocab": {
                    "cvo_id": 452672,
                    "cvo_title": "12 Built Environment and Design",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "12",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3805,
                            "cvr_parent_cvo_id": 452672,
                            "cvr_child_cvo_id": 452673,
                            "controlled_vocab": {
                                "cvo_id": 452673,
                                "cvo_title": "1201 Architecture",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1201",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3806,
                                        "cvr_parent_cvo_id": 452673,
                                        "cvr_child_cvo_id": 452674,
                                        "controlled_vocab": {
                                            "cvo_id": 452674,
                                            "cvo_title": "120101 Architectural Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3807,
                                        "cvr_parent_cvo_id": 452673,
                                        "cvr_child_cvo_id": 452675,
                                        "controlled_vocab": {
                                            "cvo_id": 452675,
                                            "cvo_title": "120102 Architectural Heritage and Conservation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3808,
                                        "cvr_parent_cvo_id": 452673,
                                        "cvr_child_cvo_id": 452676,
                                        "controlled_vocab": {
                                            "cvo_id": 452676,
                                            "cvo_title": "120103 Architectural History and Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3809,
                                        "cvr_parent_cvo_id": 452673,
                                        "cvr_child_cvo_id": 452677,
                                        "controlled_vocab": {
                                            "cvo_id": 452677,
                                            "cvo_title": "120104 Architectural Science and Technology (incl. Acoustics, Lighting, Structure and Ecologically Sustainable Design)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3810,
                                        "cvr_parent_cvo_id": 452673,
                                        "cvr_child_cvo_id": 452678,
                                        "controlled_vocab": {
                                            "cvo_id": 452678,
                                            "cvo_title": "120105 Architecture Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3811,
                                        "cvr_parent_cvo_id": 452673,
                                        "cvr_child_cvo_id": 452679,
                                        "controlled_vocab": {
                                            "cvo_id": 452679,
                                            "cvo_title": "120106 Interior Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3812,
                                        "cvr_parent_cvo_id": 452673,
                                        "cvr_child_cvo_id": 452680,
                                        "controlled_vocab": {
                                            "cvo_id": 452680,
                                            "cvo_title": "120107 Landscape Architecture",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3813,
                                        "cvr_parent_cvo_id": 452673,
                                        "cvr_child_cvo_id": 452681,
                                        "controlled_vocab": {
                                            "cvo_id": 452681,
                                            "cvo_title": "120199 Architecture not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3814,
                            "cvr_parent_cvo_id": 452672,
                            "cvr_child_cvo_id": 452682,
                            "controlled_vocab": {
                                "cvo_id": 452682,
                                "cvo_title": "1202 Building",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1202",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3815,
                                        "cvr_parent_cvo_id": 452682,
                                        "cvr_child_cvo_id": 452683,
                                        "controlled_vocab": {
                                            "cvo_id": 452683,
                                            "cvo_title": "120201 Building Construction Management and Project Planning",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3816,
                                        "cvr_parent_cvo_id": 452682,
                                        "cvr_child_cvo_id": 452684,
                                        "controlled_vocab": {
                                            "cvo_id": 452684,
                                            "cvo_title": "120202 Building Science and Techniques",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3817,
                                        "cvr_parent_cvo_id": 452682,
                                        "cvr_child_cvo_id": 452685,
                                        "controlled_vocab": {
                                            "cvo_id": 452685,
                                            "cvo_title": "120203 Quantity Surveying",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3818,
                                        "cvr_parent_cvo_id": 452682,
                                        "cvr_child_cvo_id": 452686,
                                        "controlled_vocab": {
                                            "cvo_id": 452686,
                                            "cvo_title": "120299 Building not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3819,
                            "cvr_parent_cvo_id": 452672,
                            "cvr_child_cvo_id": 452687,
                            "controlled_vocab": {
                                "cvo_id": 452687,
                                "cvo_title": "1203 Design Practice and Management",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1203",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3820,
                                        "cvr_parent_cvo_id": 452687,
                                        "cvr_child_cvo_id": 452688,
                                        "controlled_vocab": {
                                            "cvo_id": 452688,
                                            "cvo_title": "120301 Design History and Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3821,
                                        "cvr_parent_cvo_id": 452687,
                                        "cvr_child_cvo_id": 452689,
                                        "controlled_vocab": {
                                            "cvo_id": 452689,
                                            "cvo_title": "120302 Design Innovation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3822,
                                        "cvr_parent_cvo_id": 452687,
                                        "cvr_child_cvo_id": 452690,
                                        "controlled_vocab": {
                                            "cvo_id": 452690,
                                            "cvo_title": "120303 Design Management and Studio and Professional Practice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3823,
                                        "cvr_parent_cvo_id": 452687,
                                        "cvr_child_cvo_id": 452691,
                                        "controlled_vocab": {
                                            "cvo_id": 452691,
                                            "cvo_title": "120304 Digital and Interaction Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3824,
                                        "cvr_parent_cvo_id": 452687,
                                        "cvr_child_cvo_id": 452692,
                                        "controlled_vocab": {
                                            "cvo_id": 452692,
                                            "cvo_title": "120305 Industrial Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3825,
                                        "cvr_parent_cvo_id": 452687,
                                        "cvr_child_cvo_id": 452693,
                                        "controlled_vocab": {
                                            "cvo_id": 452693,
                                            "cvo_title": "120306 Textile and Fashion Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3826,
                                        "cvr_parent_cvo_id": 452687,
                                        "cvr_child_cvo_id": 452694,
                                        "controlled_vocab": {
                                            "cvo_id": 452694,
                                            "cvo_title": "120307 Visual Communication Design (incl. Graphic Design)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3827,
                                        "cvr_parent_cvo_id": 452687,
                                        "cvr_child_cvo_id": 452695,
                                        "controlled_vocab": {
                                            "cvo_id": 452695,
                                            "cvo_title": "120399 Design Practice and Management not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3828,
                            "cvr_parent_cvo_id": 452672,
                            "cvr_child_cvo_id": 452696,
                            "controlled_vocab": {
                                "cvo_id": 452696,
                                "cvo_title": "1204 Engineering Design",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1204",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3829,
                                        "cvr_parent_cvo_id": 452696,
                                        "cvr_child_cvo_id": 452697,
                                        "controlled_vocab": {
                                            "cvo_id": 452697,
                                            "cvo_title": "120401 Engineering Design Empirical Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3830,
                                        "cvr_parent_cvo_id": 452696,
                                        "cvr_child_cvo_id": 452698,
                                        "controlled_vocab": {
                                            "cvo_id": 452698,
                                            "cvo_title": "120402 Engineering Design Knowledge",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3831,
                                        "cvr_parent_cvo_id": 452696,
                                        "cvr_child_cvo_id": 452699,
                                        "controlled_vocab": {
                                            "cvo_id": 452699,
                                            "cvo_title": "120403 Engineering Design Methods",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3832,
                                        "cvr_parent_cvo_id": 452696,
                                        "cvr_child_cvo_id": 452700,
                                        "controlled_vocab": {
                                            "cvo_id": 452700,
                                            "cvo_title": "120404 Engineering Systems Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3833,
                                        "cvr_parent_cvo_id": 452696,
                                        "cvr_child_cvo_id": 452701,
                                        "controlled_vocab": {
                                            "cvo_id": 452701,
                                            "cvo_title": "120405 Models of Engineering Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3834,
                                        "cvr_parent_cvo_id": 452696,
                                        "cvr_child_cvo_id": 452702,
                                        "controlled_vocab": {
                                            "cvo_id": 452702,
                                            "cvo_title": "120499 Engineering Design not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3835,
                            "cvr_parent_cvo_id": 452672,
                            "cvr_child_cvo_id": 452703,
                            "controlled_vocab": {
                                "cvo_id": 452703,
                                "cvo_title": "1205 Urban and Regional Planning",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1205",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3836,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452704,
                                        "controlled_vocab": {
                                            "cvo_id": 452704,
                                            "cvo_title": "120501 Community Planning",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3837,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452705,
                                        "controlled_vocab": {
                                            "cvo_id": 452705,
                                            "cvo_title": "120502 History and Theory of the Built Environment (excl. Architecture)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3838,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452706,
                                        "controlled_vocab": {
                                            "cvo_id": 452706,
                                            "cvo_title": "120503 Housing Markets, Development, Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3839,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452707,
                                        "controlled_vocab": {
                                            "cvo_id": 452707,
                                            "cvo_title": "120504 Land Use and Environmental Planning",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3840,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452708,
                                        "controlled_vocab": {
                                            "cvo_id": 452708,
                                            "cvo_title": "120505 Regional Analysis and Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3841,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452709,
                                        "controlled_vocab": {
                                            "cvo_id": 452709,
                                            "cvo_title": "120506 Transport Planning",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3842,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452710,
                                        "controlled_vocab": {
                                            "cvo_id": 452710,
                                            "cvo_title": "120507 Urban Analysis and Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3843,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452711,
                                        "controlled_vocab": {
                                            "cvo_id": 452711,
                                            "cvo_title": "120508 Urban Design",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120508",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3844,
                                        "cvr_parent_cvo_id": 452703,
                                        "cvr_child_cvo_id": 452712,
                                        "controlled_vocab": {
                                            "cvo_id": 452712,
                                            "cvo_title": "120599 Urban and Regional Planning not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "120599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3845,
                            "cvr_parent_cvo_id": 452672,
                            "cvr_child_cvo_id": 452713,
                            "controlled_vocab": {
                                "cvo_id": 452713,
                                "cvo_title": "1299 Other Built Environment and Design",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1299",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3846,
                                        "cvr_parent_cvo_id": 452713,
                                        "cvr_child_cvo_id": 452714,
                                        "controlled_vocab": {
                                            "cvo_id": 452714,
                                            "cvo_title": "129999 Built Environment and Design not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "129999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3847,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452715,
                "controlled_vocab": {
                    "cvo_id": 452715,
                    "cvo_title": "13 Education",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "13",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3848,
                            "cvr_parent_cvo_id": 452715,
                            "cvr_child_cvo_id": 452716,
                            "controlled_vocab": {
                                "cvo_id": 452716,
                                "cvo_title": "1301 Education Systems",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1301",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3849,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452717,
                                        "controlled_vocab": {
                                            "cvo_id": 452717,
                                            "cvo_title": "130101 Continuing and Community Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3850,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452718,
                                        "controlled_vocab": {
                                            "cvo_id": 452718,
                                            "cvo_title": "130102 Early Childhood Education (excl. Maori)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3851,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452719,
                                        "controlled_vocab": {
                                            "cvo_id": 452719,
                                            "cvo_title": "130103 Higher Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3852,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452720,
                                        "controlled_vocab": {
                                            "cvo_id": 452720,
                                            "cvo_title": "130104 Kura Kaupapa Maori (Maori Primary Education)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3853,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452721,
                                        "controlled_vocab": {
                                            "cvo_id": 452721,
                                            "cvo_title": "130105 Primary Education (excl. Maori)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3854,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452722,
                                        "controlled_vocab": {
                                            "cvo_id": 452722,
                                            "cvo_title": "130106 Secondary Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3855,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452723,
                                        "controlled_vocab": {
                                            "cvo_id": 452723,
                                            "cvo_title": "130107 Te Whariki (Maori Early Childhood Education)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3856,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452724,
                                        "controlled_vocab": {
                                            "cvo_id": 452724,
                                            "cvo_title": "130108 Technical, Further and Workplace Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3857,
                                        "cvr_parent_cvo_id": 452716,
                                        "cvr_child_cvo_id": 452725,
                                        "controlled_vocab": {
                                            "cvo_id": 452725,
                                            "cvo_title": "130199 Education systems not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3858,
                            "cvr_parent_cvo_id": 452715,
                            "cvr_child_cvo_id": 452726,
                            "controlled_vocab": {
                                "cvo_id": 452726,
                                "cvo_title": "1302 Curriculum and Pedagogy",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1302",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3859,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452727,
                                        "controlled_vocab": {
                                            "cvo_id": 452727,
                                            "cvo_title": "130201 Creative Arts, Media and Communication Curriculum and Pedagogy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3860,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452728,
                                        "controlled_vocab": {
                                            "cvo_id": 452728,
                                            "cvo_title": "130202 Curriculum and Pedagogy Theory and Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3861,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452729,
                                        "controlled_vocab": {
                                            "cvo_id": 452729,
                                            "cvo_title": "130203 Economics, Business and Management Curriculum and Pedagogy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3862,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452730,
                                        "controlled_vocab": {
                                            "cvo_id": 452730,
                                            "cvo_title": "130204 English and Literacy Curriculum and Pedagogy (excl. LOTE, ESL and TESOL)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3863,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452731,
                                        "controlled_vocab": {
                                            "cvo_id": 452731,
                                            "cvo_title": "130205 Humanities and Social Sciences Curriculum and Pedagogy (excl. Economics, Business and Management)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3864,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452732,
                                        "controlled_vocab": {
                                            "cvo_id": 452732,
                                            "cvo_title": "130206 Kohanga Reo (Maori Language Curriculum and Pedagogy)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3865,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452733,
                                        "controlled_vocab": {
                                            "cvo_id": 452733,
                                            "cvo_title": "130207 LOTE, ESL and TESOL Curriculum and Pedagogy (excl. Maori)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3866,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452734,
                                        "controlled_vocab": {
                                            "cvo_id": 452734,
                                            "cvo_title": "130208 Mathematics and Numeracy Curriculum and Pedagogy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130208",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3867,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452735,
                                        "controlled_vocab": {
                                            "cvo_id": 452735,
                                            "cvo_title": "130209 Medicine, Nursing and Health Curriculum and Pedagogy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130209",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3868,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452736,
                                        "controlled_vocab": {
                                            "cvo_id": 452736,
                                            "cvo_title": "130210 Physical Education and Development Curriculum and Pedagogy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130210",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3869,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452737,
                                        "controlled_vocab": {
                                            "cvo_id": 452737,
                                            "cvo_title": "130211 Religion Curriculum and Pedagogy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130211",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3870,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452738,
                                        "controlled_vocab": {
                                            "cvo_id": 452738,
                                            "cvo_title": "130212 Science, Technology and Engineering Curriculum and Pedagogy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130212",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3871,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452739,
                                        "controlled_vocab": {
                                            "cvo_id": 452739,
                                            "cvo_title": "130213 Vocational Education and Training Curriculum and Pedagogy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130213",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3872,
                                        "cvr_parent_cvo_id": 452726,
                                        "cvr_child_cvo_id": 452740,
                                        "controlled_vocab": {
                                            "cvo_id": 452740,
                                            "cvo_title": "130299 Curriculum and Pedagogy not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3873,
                            "cvr_parent_cvo_id": 452715,
                            "cvr_child_cvo_id": 452741,
                            "controlled_vocab": {
                                "cvo_id": 452741,
                                "cvo_title": "1303 Specialist Studies in Education",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1303",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3874,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452742,
                                        "controlled_vocab": {
                                            "cvo_id": 452742,
                                            "cvo_title": "130301 Aboriginal and Torres Strait Islander Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3875,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452743,
                                        "controlled_vocab": {
                                            "cvo_id": 452743,
                                            "cvo_title": "130302 Comparative and Cross-Cultural Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3876,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452744,
                                        "controlled_vocab": {
                                            "cvo_id": 452744,
                                            "cvo_title": "130303 Education Assessment and Evaluation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3877,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452745,
                                        "controlled_vocab": {
                                            "cvo_id": 452745,
                                            "cvo_title": "130304 Educational Administration, Management and Leadership",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3878,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452746,
                                        "controlled_vocab": {
                                            "cvo_id": 452746,
                                            "cvo_title": "130305 Educational Counselling",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3879,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452747,
                                        "controlled_vocab": {
                                            "cvo_id": 452747,
                                            "cvo_title": "130306 Educational Technology and Computing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3880,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452748,
                                        "controlled_vocab": {
                                            "cvo_id": 452748,
                                            "cvo_title": "130307 Ethnic Education (excl. Aboriginal and Torres Strait Islander, Maori and Pacific Peoples)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3881,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452749,
                                        "controlled_vocab": {
                                            "cvo_id": 452749,
                                            "cvo_title": "130308 Gender, Sexuality and Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3882,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452750,
                                        "controlled_vocab": {
                                            "cvo_id": 452750,
                                            "cvo_title": "130309 Learning Sciences",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3883,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452751,
                                        "controlled_vocab": {
                                            "cvo_id": 452751,
                                            "cvo_title": "130310 Maori Education (excl. Early Childhood and Primary Education)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130310",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3884,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452752,
                                        "controlled_vocab": {
                                            "cvo_id": 452752,
                                            "cvo_title": "130311 Pacific Peoples Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130311",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3885,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452753,
                                        "controlled_vocab": {
                                            "cvo_id": 452753,
                                            "cvo_title": "130312 Special Education and Disability",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130312",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3886,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452754,
                                        "controlled_vocab": {
                                            "cvo_id": 452754,
                                            "cvo_title": "130313 Teacher Education and Professional Development of Educators",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130313",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3887,
                                        "cvr_parent_cvo_id": 452741,
                                        "cvr_child_cvo_id": 452755,
                                        "controlled_vocab": {
                                            "cvo_id": 452755,
                                            "cvo_title": "130399 Specialist Studies in Education not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "130399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3888,
                            "cvr_parent_cvo_id": 452715,
                            "cvr_child_cvo_id": 452756,
                            "controlled_vocab": {
                                "cvo_id": 452756,
                                "cvo_title": "1399 Other Education",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1399",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3889,
                                        "cvr_parent_cvo_id": 452756,
                                        "cvr_child_cvo_id": 452757,
                                        "controlled_vocab": {
                                            "cvo_id": 452757,
                                            "cvo_title": "139999 Education not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "139999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3890,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452758,
                "controlled_vocab": {
                    "cvo_id": 452758,
                    "cvo_title": "14 Economics",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "14",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3891,
                            "cvr_parent_cvo_id": 452758,
                            "cvr_child_cvo_id": 452759,
                            "controlled_vocab": {
                                "cvo_id": 452759,
                                "cvo_title": "1401 Economic Theory",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1401",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3892,
                                        "cvr_parent_cvo_id": 452759,
                                        "cvr_child_cvo_id": 452760,
                                        "controlled_vocab": {
                                            "cvo_id": 452760,
                                            "cvo_title": "140101 History of Economic Thought",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3893,
                                        "cvr_parent_cvo_id": 452759,
                                        "cvr_child_cvo_id": 452761,
                                        "controlled_vocab": {
                                            "cvo_id": 452761,
                                            "cvo_title": "140102 Macroeconomic Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3894,
                                        "cvr_parent_cvo_id": 452759,
                                        "cvr_child_cvo_id": 452762,
                                        "controlled_vocab": {
                                            "cvo_id": 452762,
                                            "cvo_title": "140103 Mathematical Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3895,
                                        "cvr_parent_cvo_id": 452759,
                                        "cvr_child_cvo_id": 452763,
                                        "controlled_vocab": {
                                            "cvo_id": 452763,
                                            "cvo_title": "140104 Microeconomic Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3896,
                                        "cvr_parent_cvo_id": 452759,
                                        "cvr_child_cvo_id": 452764,
                                        "controlled_vocab": {
                                            "cvo_id": 452764,
                                            "cvo_title": "140199 Economic Theory not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3897,
                            "cvr_parent_cvo_id": 452758,
                            "cvr_child_cvo_id": 452765,
                            "controlled_vocab": {
                                "cvo_id": 452765,
                                "cvo_title": "1402 Applied Economics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1402",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3898,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452766,
                                        "controlled_vocab": {
                                            "cvo_id": 452766,
                                            "cvo_title": "140201 Agricultural Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3899,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452767,
                                        "controlled_vocab": {
                                            "cvo_id": 452767,
                                            "cvo_title": "140202 Economic Development and Growth",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3900,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452768,
                                        "controlled_vocab": {
                                            "cvo_id": 452768,
                                            "cvo_title": "140203 Economic History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3901,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452769,
                                        "controlled_vocab": {
                                            "cvo_id": 452769,
                                            "cvo_title": "140204 Economics of Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3902,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452770,
                                        "controlled_vocab": {
                                            "cvo_id": 452770,
                                            "cvo_title": "140205 Environment and Resource Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3903,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452771,
                                        "controlled_vocab": {
                                            "cvo_id": 452771,
                                            "cvo_title": "140206 Experimental Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3904,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452772,
                                        "controlled_vocab": {
                                            "cvo_id": 452772,
                                            "cvo_title": "140207 Financial Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3905,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452773,
                                        "controlled_vocab": {
                                            "cvo_id": 452773,
                                            "cvo_title": "140208 Health Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140208",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3906,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452774,
                                        "controlled_vocab": {
                                            "cvo_id": 452774,
                                            "cvo_title": "140209 Industry Economics and Industrial Organisation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140209",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3907,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452775,
                                        "controlled_vocab": {
                                            "cvo_id": 452775,
                                            "cvo_title": "140210 International Economics and International Finance",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140210",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3908,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452776,
                                        "controlled_vocab": {
                                            "cvo_id": 452776,
                                            "cvo_title": "140211 Labour Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140211",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3909,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452777,
                                        "controlled_vocab": {
                                            "cvo_id": 452777,
                                            "cvo_title": "140212 Macroeconomics (incl. Monetary and Fiscal Theory)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140212",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3910,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452778,
                                        "controlled_vocab": {
                                            "cvo_id": 452778,
                                            "cvo_title": "140213 Public Economics-Public Choice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140213",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3911,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452779,
                                        "controlled_vocab": {
                                            "cvo_id": 452779,
                                            "cvo_title": "140214 Public Economics-Publically Provided Goods",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140214",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3912,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452780,
                                        "controlled_vocab": {
                                            "cvo_id": 452780,
                                            "cvo_title": "140215 Public Economics-Taxation and Revenue",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140215",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3913,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452781,
                                        "controlled_vocab": {
                                            "cvo_id": 452781,
                                            "cvo_title": "140216 Tourism Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140216",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3914,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452782,
                                        "controlled_vocab": {
                                            "cvo_id": 452782,
                                            "cvo_title": "140217 Transport Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140217",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3915,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452783,
                                        "controlled_vocab": {
                                            "cvo_id": 452783,
                                            "cvo_title": "140218 Urban and Regional Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140218",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3916,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452784,
                                        "controlled_vocab": {
                                            "cvo_id": 452784,
                                            "cvo_title": "140219 Welfare Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140219",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3917,
                                        "cvr_parent_cvo_id": 452765,
                                        "cvr_child_cvo_id": 452785,
                                        "controlled_vocab": {
                                            "cvo_id": 452785,
                                            "cvo_title": "140299 Applied Economics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3918,
                            "cvr_parent_cvo_id": 452758,
                            "cvr_child_cvo_id": 452786,
                            "controlled_vocab": {
                                "cvo_id": 452786,
                                "cvo_title": "1403 Econometrics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1403",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3919,
                                        "cvr_parent_cvo_id": 452786,
                                        "cvr_child_cvo_id": 452787,
                                        "controlled_vocab": {
                                            "cvo_id": 452787,
                                            "cvo_title": "140301 Cross-Sectional Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3920,
                                        "cvr_parent_cvo_id": 452786,
                                        "cvr_child_cvo_id": 452788,
                                        "controlled_vocab": {
                                            "cvo_id": 452788,
                                            "cvo_title": "140302 Econometric and Statistical Methods",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3921,
                                        "cvr_parent_cvo_id": 452786,
                                        "cvr_child_cvo_id": 452789,
                                        "controlled_vocab": {
                                            "cvo_id": 452789,
                                            "cvo_title": "140303 Economic Models and Forecasting",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3922,
                                        "cvr_parent_cvo_id": 452786,
                                        "cvr_child_cvo_id": 452790,
                                        "controlled_vocab": {
                                            "cvo_id": 452790,
                                            "cvo_title": "140304 Panel Data Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3923,
                                        "cvr_parent_cvo_id": 452786,
                                        "cvr_child_cvo_id": 452791,
                                        "controlled_vocab": {
                                            "cvo_id": 452791,
                                            "cvo_title": "140305 Time-Series Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3924,
                                        "cvr_parent_cvo_id": 452786,
                                        "cvr_child_cvo_id": 452792,
                                        "controlled_vocab": {
                                            "cvo_id": 452792,
                                            "cvo_title": "140399 Econometrics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "140399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3925,
                            "cvr_parent_cvo_id": 452758,
                            "cvr_child_cvo_id": 452793,
                            "controlled_vocab": {
                                "cvo_id": 452793,
                                "cvo_title": "1499 Other Economics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1499",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3926,
                                        "cvr_parent_cvo_id": 452793,
                                        "cvr_child_cvo_id": 452794,
                                        "controlled_vocab": {
                                            "cvo_id": 452794,
                                            "cvo_title": "149901 Comparative Economic Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "149901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3927,
                                        "cvr_parent_cvo_id": 452793,
                                        "cvr_child_cvo_id": 452795,
                                        "controlled_vocab": {
                                            "cvo_id": 452795,
                                            "cvo_title": "149902 Ecological Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "149902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3928,
                                        "cvr_parent_cvo_id": 452793,
                                        "cvr_child_cvo_id": 452796,
                                        "controlled_vocab": {
                                            "cvo_id": 452796,
                                            "cvo_title": "149903 Heterodox Economics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "149903",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3929,
                                        "cvr_parent_cvo_id": 452793,
                                        "cvr_child_cvo_id": 452797,
                                        "controlled_vocab": {
                                            "cvo_id": 452797,
                                            "cvo_title": "149999 Economics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "149999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3930,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452798,
                "controlled_vocab": {
                    "cvo_id": 452798,
                    "cvo_title": "15 Commerce, Management, Tourism and Services",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "15",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3931,
                            "cvr_parent_cvo_id": 452798,
                            "cvr_child_cvo_id": 452799,
                            "controlled_vocab": {
                                "cvo_id": 452799,
                                "cvo_title": "1501 Accounting, Auditing and Accountability",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1501",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3932,
                                        "cvr_parent_cvo_id": 452799,
                                        "cvr_child_cvo_id": 452800,
                                        "controlled_vocab": {
                                            "cvo_id": 452800,
                                            "cvo_title": "150101 Accounting Theory and Standards",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3933,
                                        "cvr_parent_cvo_id": 452799,
                                        "cvr_child_cvo_id": 452801,
                                        "controlled_vocab": {
                                            "cvo_id": 452801,
                                            "cvo_title": "150102 Auditing and Accountability",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3934,
                                        "cvr_parent_cvo_id": 452799,
                                        "cvr_child_cvo_id": 452802,
                                        "controlled_vocab": {
                                            "cvo_id": 452802,
                                            "cvo_title": "150103 Financial Accounting",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3935,
                                        "cvr_parent_cvo_id": 452799,
                                        "cvr_child_cvo_id": 452803,
                                        "controlled_vocab": {
                                            "cvo_id": 452803,
                                            "cvo_title": "150104 International Accounting",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3936,
                                        "cvr_parent_cvo_id": 452799,
                                        "cvr_child_cvo_id": 452804,
                                        "controlled_vocab": {
                                            "cvo_id": 452804,
                                            "cvo_title": "150105 Management Accounting",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3937,
                                        "cvr_parent_cvo_id": 452799,
                                        "cvr_child_cvo_id": 452805,
                                        "controlled_vocab": {
                                            "cvo_id": 452805,
                                            "cvo_title": "150106 Sustainability Accounting and Reporting",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3938,
                                        "cvr_parent_cvo_id": 452799,
                                        "cvr_child_cvo_id": 452806,
                                        "controlled_vocab": {
                                            "cvo_id": 452806,
                                            "cvo_title": "150107 Taxation Accounting",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3939,
                                        "cvr_parent_cvo_id": 452799,
                                        "cvr_child_cvo_id": 452807,
                                        "controlled_vocab": {
                                            "cvo_id": 452807,
                                            "cvo_title": "150199 Accounting, Auditing and Accountability not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3940,
                            "cvr_parent_cvo_id": 452798,
                            "cvr_child_cvo_id": 452808,
                            "controlled_vocab": {
                                "cvo_id": 452808,
                                "cvo_title": "1502 Banking, Finance and Investment",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1502",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3941,
                                        "cvr_parent_cvo_id": 452808,
                                        "cvr_child_cvo_id": 452809,
                                        "controlled_vocab": {
                                            "cvo_id": 452809,
                                            "cvo_title": "150201 Finance",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3942,
                                        "cvr_parent_cvo_id": 452808,
                                        "cvr_child_cvo_id": 452810,
                                        "controlled_vocab": {
                                            "cvo_id": 452810,
                                            "cvo_title": "150202 Financial Econometrics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3943,
                                        "cvr_parent_cvo_id": 452808,
                                        "cvr_child_cvo_id": 452811,
                                        "controlled_vocab": {
                                            "cvo_id": 452811,
                                            "cvo_title": "150203 Financial Institutions (incl. Banking)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3944,
                                        "cvr_parent_cvo_id": 452808,
                                        "cvr_child_cvo_id": 452812,
                                        "controlled_vocab": {
                                            "cvo_id": 452812,
                                            "cvo_title": "150204 Insurance Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3945,
                                        "cvr_parent_cvo_id": 452808,
                                        "cvr_child_cvo_id": 452813,
                                        "controlled_vocab": {
                                            "cvo_id": 452813,
                                            "cvo_title": "150205 Investment and Risk Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3946,
                                        "cvr_parent_cvo_id": 452808,
                                        "cvr_child_cvo_id": 452814,
                                        "controlled_vocab": {
                                            "cvo_id": 452814,
                                            "cvo_title": "150299 Banking, Finance and Investment not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3947,
                            "cvr_parent_cvo_id": 452798,
                            "cvr_child_cvo_id": 452815,
                            "controlled_vocab": {
                                "cvo_id": 452815,
                                "cvo_title": "1503 Business and Management",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1503",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3948,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452816,
                                        "controlled_vocab": {
                                            "cvo_id": 452816,
                                            "cvo_title": "150301 Business Information Management (incl. Records, Knowledge and Information Management, and Intelligence)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3949,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452817,
                                        "controlled_vocab": {
                                            "cvo_id": 452817,
                                            "cvo_title": "150302 Business Information Systems",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3950,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452818,
                                        "controlled_vocab": {
                                            "cvo_id": 452818,
                                            "cvo_title": "150303 Corporate Governance and Stakeholder Engagement",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3951,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452819,
                                        "controlled_vocab": {
                                            "cvo_id": 452819,
                                            "cvo_title": "150304 Entrepreneurship",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3952,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452820,
                                        "controlled_vocab": {
                                            "cvo_id": 452820,
                                            "cvo_title": "150305 Human Resources Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3953,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452821,
                                        "controlled_vocab": {
                                            "cvo_id": 452821,
                                            "cvo_title": "150306 Industrial Relations",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3954,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452822,
                                        "controlled_vocab": {
                                            "cvo_id": 452822,
                                            "cvo_title": "150307 Innovation and Technology Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3955,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452823,
                                        "controlled_vocab": {
                                            "cvo_id": 452823,
                                            "cvo_title": "150308 International Business",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3956,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452824,
                                        "controlled_vocab": {
                                            "cvo_id": 452824,
                                            "cvo_title": "150309 Logistics and Supply Chain Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3957,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452825,
                                        "controlled_vocab": {
                                            "cvo_id": 452825,
                                            "cvo_title": "150310 Organisation and Management Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150310",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3958,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452826,
                                        "controlled_vocab": {
                                            "cvo_id": 452826,
                                            "cvo_title": "150311 Organisational Behaviour",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150311",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3959,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452827,
                                        "controlled_vocab": {
                                            "cvo_id": 452827,
                                            "cvo_title": "150312 Organisational Planning and Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150312",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3960,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452828,
                                        "controlled_vocab": {
                                            "cvo_id": 452828,
                                            "cvo_title": "150313 Quality Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150313",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3961,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452829,
                                        "controlled_vocab": {
                                            "cvo_id": 452829,
                                            "cvo_title": "150314 Small Business Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150314",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3962,
                                        "cvr_parent_cvo_id": 452815,
                                        "cvr_child_cvo_id": 452830,
                                        "controlled_vocab": {
                                            "cvo_id": 452830,
                                            "cvo_title": "150399 Business and Management not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3963,
                            "cvr_parent_cvo_id": 452798,
                            "cvr_child_cvo_id": 452831,
                            "controlled_vocab": {
                                "cvo_id": 452831,
                                "cvo_title": "1504 Commercial Services",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1504",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3964,
                                        "cvr_parent_cvo_id": 452831,
                                        "cvr_child_cvo_id": 452832,
                                        "controlled_vocab": {
                                            "cvo_id": 452832,
                                            "cvo_title": "150401 Food and Hospitality Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3965,
                                        "cvr_parent_cvo_id": 452831,
                                        "cvr_child_cvo_id": 452833,
                                        "controlled_vocab": {
                                            "cvo_id": 452833,
                                            "cvo_title": "150402 Hospitality Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3966,
                                        "cvr_parent_cvo_id": 452831,
                                        "cvr_child_cvo_id": 452834,
                                        "controlled_vocab": {
                                            "cvo_id": 452834,
                                            "cvo_title": "150403 Real Estate and Valuation Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3967,
                                        "cvr_parent_cvo_id": 452831,
                                        "cvr_child_cvo_id": 452835,
                                        "controlled_vocab": {
                                            "cvo_id": 452835,
                                            "cvo_title": "150404 Sport and Leisure Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3968,
                                        "cvr_parent_cvo_id": 452831,
                                        "cvr_child_cvo_id": 452836,
                                        "controlled_vocab": {
                                            "cvo_id": 452836,
                                            "cvo_title": "150499 Commercial Services not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3969,
                            "cvr_parent_cvo_id": 452798,
                            "cvr_child_cvo_id": 452837,
                            "controlled_vocab": {
                                "cvo_id": 452837,
                                "cvo_title": "1505 Marketing",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1505",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3970,
                                        "cvr_parent_cvo_id": 452837,
                                        "cvr_child_cvo_id": 452838,
                                        "controlled_vocab": {
                                            "cvo_id": 452838,
                                            "cvo_title": "150501 Consumer-Oriented Productor Service Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3971,
                                        "cvr_parent_cvo_id": 452837,
                                        "cvr_child_cvo_id": 452839,
                                        "controlled_vocab": {
                                            "cvo_id": 452839,
                                            "cvo_title": "150502 Marketing Communications",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3972,
                                        "cvr_parent_cvo_id": 452837,
                                        "cvr_child_cvo_id": 452840,
                                        "controlled_vocab": {
                                            "cvo_id": 452840,
                                            "cvo_title": "150503 Marketing Management (incl. Strategy and Customer Relations)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3973,
                                        "cvr_parent_cvo_id": 452837,
                                        "cvr_child_cvo_id": 452841,
                                        "controlled_vocab": {
                                            "cvo_id": 452841,
                                            "cvo_title": "150504 Marketing Measurement",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3974,
                                        "cvr_parent_cvo_id": 452837,
                                        "cvr_child_cvo_id": 452842,
                                        "controlled_vocab": {
                                            "cvo_id": 452842,
                                            "cvo_title": "150505 Marketing Research Methodology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3975,
                                        "cvr_parent_cvo_id": 452837,
                                        "cvr_child_cvo_id": 452843,
                                        "controlled_vocab": {
                                            "cvo_id": 452843,
                                            "cvo_title": "150506 Marketing Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3976,
                                        "cvr_parent_cvo_id": 452837,
                                        "cvr_child_cvo_id": 452844,
                                        "controlled_vocab": {
                                            "cvo_id": 452844,
                                            "cvo_title": "150507 Pricing (incl. Consumer Value Estimation)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3977,
                                        "cvr_parent_cvo_id": 452837,
                                        "cvr_child_cvo_id": 452845,
                                        "controlled_vocab": {
                                            "cvo_id": 452845,
                                            "cvo_title": "150599 Marketing not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3978,
                            "cvr_parent_cvo_id": 452798,
                            "cvr_child_cvo_id": 452846,
                            "controlled_vocab": {
                                "cvo_id": 452846,
                                "cvo_title": "1506 Tourism",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1506",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3979,
                                        "cvr_parent_cvo_id": 452846,
                                        "cvr_child_cvo_id": 452847,
                                        "controlled_vocab": {
                                            "cvo_id": 452847,
                                            "cvo_title": "150601 Impacts of Tourism",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3980,
                                        "cvr_parent_cvo_id": 452846,
                                        "cvr_child_cvo_id": 452848,
                                        "controlled_vocab": {
                                            "cvo_id": 452848,
                                            "cvo_title": "150602 Tourism Forecasting",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3981,
                                        "cvr_parent_cvo_id": 452846,
                                        "cvr_child_cvo_id": 452849,
                                        "controlled_vocab": {
                                            "cvo_id": 452849,
                                            "cvo_title": "150603 Tourism Management",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3982,
                                        "cvr_parent_cvo_id": 452846,
                                        "cvr_child_cvo_id": 452850,
                                        "controlled_vocab": {
                                            "cvo_id": 452850,
                                            "cvo_title": "150604 Tourism Marketing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3983,
                                        "cvr_parent_cvo_id": 452846,
                                        "cvr_child_cvo_id": 452851,
                                        "controlled_vocab": {
                                            "cvo_id": 452851,
                                            "cvo_title": "150605 Tourism Resource Appraisal",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150605",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3984,
                                        "cvr_parent_cvo_id": 452846,
                                        "cvr_child_cvo_id": 452852,
                                        "controlled_vocab": {
                                            "cvo_id": 452852,
                                            "cvo_title": "150606 Tourist Behaviour and Visitor Experience",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150606",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3985,
                                        "cvr_parent_cvo_id": 452846,
                                        "cvr_child_cvo_id": 452853,
                                        "controlled_vocab": {
                                            "cvo_id": 452853,
                                            "cvo_title": "150699 Tourism not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3986,
                            "cvr_parent_cvo_id": 452798,
                            "cvr_child_cvo_id": 452854,
                            "controlled_vocab": {
                                "cvo_id": 452854,
                                "cvo_title": "1507 Transportation and Freight Services",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1507",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3987,
                                        "cvr_parent_cvo_id": 452854,
                                        "cvr_child_cvo_id": 452855,
                                        "controlled_vocab": {
                                            "cvo_id": 452855,
                                            "cvo_title": "150701 Air Transportation and Freight Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3988,
                                        "cvr_parent_cvo_id": 452854,
                                        "cvr_child_cvo_id": 452856,
                                        "controlled_vocab": {
                                            "cvo_id": 452856,
                                            "cvo_title": "150702 Rail Transportation and Freight Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3989,
                                        "cvr_parent_cvo_id": 452854,
                                        "cvr_child_cvo_id": 452857,
                                        "controlled_vocab": {
                                            "cvo_id": 452857,
                                            "cvo_title": "150703 Road Transportation and Freight Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3990,
                                        "cvr_parent_cvo_id": 452854,
                                        "cvr_child_cvo_id": 452858,
                                        "controlled_vocab": {
                                            "cvo_id": 452858,
                                            "cvo_title": "150799 Transportation and Freight Services not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "150799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 3991,
                            "cvr_parent_cvo_id": 452798,
                            "cvr_child_cvo_id": 452859,
                            "controlled_vocab": {
                                "cvo_id": 452859,
                                "cvo_title": "1599 Other Commerce, Management, Tourism and Services",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1599",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3992,
                                        "cvr_parent_cvo_id": 452859,
                                        "cvr_child_cvo_id": 452860,
                                        "controlled_vocab": {
                                            "cvo_id": 452860,
                                            "cvo_title": "159999 Commerce, Management, Tourism and Services not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "159999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 3993,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452861,
                "controlled_vocab": {
                    "cvo_id": 452861,
                    "cvo_title": "16 Studies in Human Society",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "16",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 3994,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452862,
                            "controlled_vocab": {
                                "cvo_id": 452862,
                                "cvo_title": "1601 Anthropology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1601",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 3995,
                                        "cvr_parent_cvo_id": 452862,
                                        "cvr_child_cvo_id": 452863,
                                        "controlled_vocab": {
                                            "cvo_id": 452863,
                                            "cvo_title": "160101 Anthropology of Development",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3996,
                                        "cvr_parent_cvo_id": 452862,
                                        "cvr_child_cvo_id": 452864,
                                        "controlled_vocab": {
                                            "cvo_id": 452864,
                                            "cvo_title": "160102 Biological (Physical) Anthropology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3997,
                                        "cvr_parent_cvo_id": 452862,
                                        "cvr_child_cvo_id": 452865,
                                        "controlled_vocab": {
                                            "cvo_id": 452865,
                                            "cvo_title": "160103 Linguistic Anthropology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3998,
                                        "cvr_parent_cvo_id": 452862,
                                        "cvr_child_cvo_id": 452866,
                                        "controlled_vocab": {
                                            "cvo_id": 452866,
                                            "cvo_title": "160104 Social and Cultural Anthropology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 3999,
                                        "cvr_parent_cvo_id": 452862,
                                        "cvr_child_cvo_id": 452867,
                                        "controlled_vocab": {
                                            "cvo_id": 452867,
                                            "cvo_title": "160199 Anthropology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4000,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452868,
                            "controlled_vocab": {
                                "cvo_id": 452868,
                                "cvo_title": "1602 Criminology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1602",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4001,
                                        "cvr_parent_cvo_id": 452868,
                                        "cvr_child_cvo_id": 452869,
                                        "controlled_vocab": {
                                            "cvo_id": 452869,
                                            "cvo_title": "160201 Causes and Prevention of Crime",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4002,
                                        "cvr_parent_cvo_id": 452868,
                                        "cvr_child_cvo_id": 452870,
                                        "controlled_vocab": {
                                            "cvo_id": 452870,
                                            "cvo_title": "160202 Correctional Theory, Offender Treatment and Rehabilitation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4003,
                                        "cvr_parent_cvo_id": 452868,
                                        "cvr_child_cvo_id": 452871,
                                        "controlled_vocab": {
                                            "cvo_id": 452871,
                                            "cvo_title": "160203 Courts and Sentencing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4004,
                                        "cvr_parent_cvo_id": 452868,
                                        "cvr_child_cvo_id": 452872,
                                        "controlled_vocab": {
                                            "cvo_id": 452872,
                                            "cvo_title": "160204 Criminological Theories",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4005,
                                        "cvr_parent_cvo_id": 452868,
                                        "cvr_child_cvo_id": 452873,
                                        "controlled_vocab": {
                                            "cvo_id": 452873,
                                            "cvo_title": "160205 Police Administration, Procedures and Practice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4006,
                                        "cvr_parent_cvo_id": 452868,
                                        "cvr_child_cvo_id": 452874,
                                        "controlled_vocab": {
                                            "cvo_id": 452874,
                                            "cvo_title": "160206 Private Policing and Security Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4007,
                                        "cvr_parent_cvo_id": 452868,
                                        "cvr_child_cvo_id": 452875,
                                        "controlled_vocab": {
                                            "cvo_id": 452875,
                                            "cvo_title": "160299 Criminology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4008,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452876,
                            "controlled_vocab": {
                                "cvo_id": 452876,
                                "cvo_title": "1603 Demography",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1603",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4009,
                                        "cvr_parent_cvo_id": 452876,
                                        "cvr_child_cvo_id": 452877,
                                        "controlled_vocab": {
                                            "cvo_id": 452877,
                                            "cvo_title": "160301 Family and Household Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4010,
                                        "cvr_parent_cvo_id": 452876,
                                        "cvr_child_cvo_id": 452878,
                                        "controlled_vocab": {
                                            "cvo_id": 452878,
                                            "cvo_title": "160302 Fertility",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4011,
                                        "cvr_parent_cvo_id": 452876,
                                        "cvr_child_cvo_id": 452879,
                                        "controlled_vocab": {
                                            "cvo_id": 452879,
                                            "cvo_title": "160303 Migration",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4012,
                                        "cvr_parent_cvo_id": 452876,
                                        "cvr_child_cvo_id": 452880,
                                        "controlled_vocab": {
                                            "cvo_id": 452880,
                                            "cvo_title": "160304 Mortality",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4013,
                                        "cvr_parent_cvo_id": 452876,
                                        "cvr_child_cvo_id": 452881,
                                        "controlled_vocab": {
                                            "cvo_id": 452881,
                                            "cvo_title": "160305 Population Trends and Policies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4014,
                                        "cvr_parent_cvo_id": 452876,
                                        "cvr_child_cvo_id": 452882,
                                        "controlled_vocab": {
                                            "cvo_id": 452882,
                                            "cvo_title": "160399 Demography not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4015,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452883,
                            "controlled_vocab": {
                                "cvo_id": 452883,
                                "cvo_title": "1604 Human Geography",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1604",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4016,
                                        "cvr_parent_cvo_id": 452883,
                                        "cvr_child_cvo_id": 452884,
                                        "controlled_vocab": {
                                            "cvo_id": 452884,
                                            "cvo_title": "160401 Economic Geography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4017,
                                        "cvr_parent_cvo_id": 452883,
                                        "cvr_child_cvo_id": 452885,
                                        "controlled_vocab": {
                                            "cvo_id": 452885,
                                            "cvo_title": "160402 Recreation, Leisure and Tourism Geography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4018,
                                        "cvr_parent_cvo_id": 452883,
                                        "cvr_child_cvo_id": 452886,
                                        "controlled_vocab": {
                                            "cvo_id": 452886,
                                            "cvo_title": "160403 Social and Cultural Geography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4019,
                                        "cvr_parent_cvo_id": 452883,
                                        "cvr_child_cvo_id": 452887,
                                        "controlled_vocab": {
                                            "cvo_id": 452887,
                                            "cvo_title": "160404 Urban and Regional Studies (excl. Planning)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4020,
                                        "cvr_parent_cvo_id": 452883,
                                        "cvr_child_cvo_id": 452888,
                                        "controlled_vocab": {
                                            "cvo_id": 452888,
                                            "cvo_title": "160499 Human Geography not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4021,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452889,
                            "controlled_vocab": {
                                "cvo_id": 452889,
                                "cvo_title": "1605 Policy and Administration",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1605",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4022,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452890,
                                        "controlled_vocab": {
                                            "cvo_id": 452890,
                                            "cvo_title": "160501 Aboriginal and Torres Strait Islander Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4023,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452891,
                                        "controlled_vocab": {
                                            "cvo_id": 452891,
                                            "cvo_title": "160502 Arts and Cultural Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4024,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452892,
                                        "controlled_vocab": {
                                            "cvo_id": 452892,
                                            "cvo_title": "160503 Communications and Media Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4025,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452893,
                                        "controlled_vocab": {
                                            "cvo_id": 452893,
                                            "cvo_title": "160504 Crime Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4026,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452894,
                                        "controlled_vocab": {
                                            "cvo_id": 452894,
                                            "cvo_title": "160505 Economic Development Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4027,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452895,
                                        "controlled_vocab": {
                                            "cvo_id": 452895,
                                            "cvo_title": "160506 Education Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4028,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452896,
                                        "controlled_vocab": {
                                            "cvo_id": 452896,
                                            "cvo_title": "160507 Environment Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4029,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452897,
                                        "controlled_vocab": {
                                            "cvo_id": 452897,
                                            "cvo_title": "160508 Health Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160508",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4030,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452898,
                                        "controlled_vocab": {
                                            "cvo_id": 452898,
                                            "cvo_title": "160509 Public Administration",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160509",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4031,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452899,
                                        "controlled_vocab": {
                                            "cvo_id": 452899,
                                            "cvo_title": "160510 Public Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160510",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4032,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452900,
                                        "controlled_vocab": {
                                            "cvo_id": 452900,
                                            "cvo_title": "160511 Research, Science and Technology Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160511",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4033,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452901,
                                        "controlled_vocab": {
                                            "cvo_id": 452901,
                                            "cvo_title": "160512 Social Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160512",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4034,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452902,
                                        "controlled_vocab": {
                                            "cvo_id": 452902,
                                            "cvo_title": "160513 Tourism Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160513",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4035,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452903,
                                        "controlled_vocab": {
                                            "cvo_id": 452903,
                                            "cvo_title": "160514 Urban Policy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160514",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4036,
                                        "cvr_parent_cvo_id": 452889,
                                        "cvr_child_cvo_id": 452904,
                                        "controlled_vocab": {
                                            "cvo_id": 452904,
                                            "cvo_title": "160599 Policy and Administration not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4037,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452905,
                            "controlled_vocab": {
                                "cvo_id": 452905,
                                "cvo_title": "1606 Political Science",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1606",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4038,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452906,
                                        "controlled_vocab": {
                                            "cvo_id": 452906,
                                            "cvo_title": "160601 Australian Government and Politics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160601",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4039,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452907,
                                        "controlled_vocab": {
                                            "cvo_id": 452907,
                                            "cvo_title": "160602 Citizenship",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160602",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4040,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452908,
                                        "controlled_vocab": {
                                            "cvo_id": 452908,
                                            "cvo_title": "160603 Comparative Government and Politics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160603",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4041,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452909,
                                        "controlled_vocab": {
                                            "cvo_id": 452909,
                                            "cvo_title": "160604 Defence Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160604",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4042,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452910,
                                        "controlled_vocab": {
                                            "cvo_id": 452910,
                                            "cvo_title": "160605 Environmental Politics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160605",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4043,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452911,
                                        "controlled_vocab": {
                                            "cvo_id": 452911,
                                            "cvo_title": "160606 Government and Politics of Asia and the Pacific",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160606",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4044,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452912,
                                        "controlled_vocab": {
                                            "cvo_id": 452912,
                                            "cvo_title": "160607 International Relations",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160607",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4045,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452913,
                                        "controlled_vocab": {
                                            "cvo_id": 452913,
                                            "cvo_title": "160608 New Zealand Government and Politics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160608",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4046,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452914,
                                        "controlled_vocab": {
                                            "cvo_id": 452914,
                                            "cvo_title": "160609 Political Theory and Political Philosophy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160609",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4047,
                                        "cvr_parent_cvo_id": 452905,
                                        "cvr_child_cvo_id": 452915,
                                        "controlled_vocab": {
                                            "cvo_id": 452915,
                                            "cvo_title": "160699 Political Science not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160699",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4048,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452916,
                            "controlled_vocab": {
                                "cvo_id": 452916,
                                "cvo_title": "1607 Social Work",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1607",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4049,
                                        "cvr_parent_cvo_id": 452916,
                                        "cvr_child_cvo_id": 452917,
                                        "controlled_vocab": {
                                            "cvo_id": 452917,
                                            "cvo_title": "160701 Clinical Social Work Practice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160701",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4050,
                                        "cvr_parent_cvo_id": 452916,
                                        "cvr_child_cvo_id": 452918,
                                        "controlled_vocab": {
                                            "cvo_id": 452918,
                                            "cvo_title": "160702 Counselling, Welfare and Community Services",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160702",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4051,
                                        "cvr_parent_cvo_id": 452916,
                                        "cvr_child_cvo_id": 452919,
                                        "controlled_vocab": {
                                            "cvo_id": 452919,
                                            "cvo_title": "160703 Social Program Evaluation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160703",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4052,
                                        "cvr_parent_cvo_id": 452916,
                                        "cvr_child_cvo_id": 452920,
                                        "controlled_vocab": {
                                            "cvo_id": 452920,
                                            "cvo_title": "160799 Social Work not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160799",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4053,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452921,
                            "controlled_vocab": {
                                "cvo_id": 452921,
                                "cvo_title": "1608 Sociology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1608",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4054,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452922,
                                        "controlled_vocab": {
                                            "cvo_id": 452922,
                                            "cvo_title": "160801 Applied Sociology, Program Evaluation and Social Impact Assessment",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160801",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4055,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452923,
                                        "controlled_vocab": {
                                            "cvo_id": 452923,
                                            "cvo_title": "160802 Environmental Sociology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160802",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4056,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452924,
                                        "controlled_vocab": {
                                            "cvo_id": 452924,
                                            "cvo_title": "160803 Race and Ethnic Relations",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160803",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4057,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452925,
                                        "controlled_vocab": {
                                            "cvo_id": 452925,
                                            "cvo_title": "160804 Rural Sociology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160804",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4058,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452926,
                                        "controlled_vocab": {
                                            "cvo_id": 452926,
                                            "cvo_title": "160805 Social Change",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160805",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4059,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452927,
                                        "controlled_vocab": {
                                            "cvo_id": 452927,
                                            "cvo_title": "160806 Social Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160806",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4060,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452928,
                                        "controlled_vocab": {
                                            "cvo_id": 452928,
                                            "cvo_title": "160807 Sociological Methodology and Research Methods",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160807",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4061,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452929,
                                        "controlled_vocab": {
                                            "cvo_id": 452929,
                                            "cvo_title": "160808 Sociology and Social Studies of Science and Technology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160808",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4062,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452930,
                                        "controlled_vocab": {
                                            "cvo_id": 452930,
                                            "cvo_title": "160809 Sociology of Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160809",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4063,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452931,
                                        "controlled_vocab": {
                                            "cvo_id": 452931,
                                            "cvo_title": "160810 Urban Sociology and Community Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160810",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4064,
                                        "cvr_parent_cvo_id": 452921,
                                        "cvr_child_cvo_id": 452932,
                                        "controlled_vocab": {
                                            "cvo_id": 452932,
                                            "cvo_title": "160899 Sociology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "160899",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4065,
                            "cvr_parent_cvo_id": 452861,
                            "cvr_child_cvo_id": 452933,
                            "controlled_vocab": {
                                "cvo_id": 452933,
                                "cvo_title": "1699 Other Studies in Human Society",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1699",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4066,
                                        "cvr_parent_cvo_id": 452933,
                                        "cvr_child_cvo_id": 452934,
                                        "controlled_vocab": {
                                            "cvo_id": 452934,
                                            "cvo_title": "169901 Gender Specific Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "169901",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4067,
                                        "cvr_parent_cvo_id": 452933,
                                        "cvr_child_cvo_id": 452935,
                                        "controlled_vocab": {
                                            "cvo_id": 452935,
                                            "cvo_title": "169902 Studies of Aboriginal and Torres Strait Islander Society",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "169902",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4068,
                                        "cvr_parent_cvo_id": 452933,
                                        "cvr_child_cvo_id": 452936,
                                        "controlled_vocab": {
                                            "cvo_id": 452936,
                                            "cvo_title": "169903 Studies of Asian Society",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "169903",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4069,
                                        "cvr_parent_cvo_id": 452933,
                                        "cvr_child_cvo_id": 452937,
                                        "controlled_vocab": {
                                            "cvo_id": 452937,
                                            "cvo_title": "169904 Studies of Maori Society",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "169904",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4070,
                                        "cvr_parent_cvo_id": 452933,
                                        "cvr_child_cvo_id": 452938,
                                        "controlled_vocab": {
                                            "cvo_id": 452938,
                                            "cvo_title": "169905 Studies of Pacific Peoples' Societies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "169905",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4071,
                                        "cvr_parent_cvo_id": 452933,
                                        "cvr_child_cvo_id": 452939,
                                        "controlled_vocab": {
                                            "cvo_id": 452939,
                                            "cvo_title": "169999 Studies in Human Society not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "169999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 4072,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452940,
                "controlled_vocab": {
                    "cvo_id": 452940,
                    "cvo_title": "17 Psychology and Cognitive Sciences",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "17",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 4073,
                            "cvr_parent_cvo_id": 452940,
                            "cvr_child_cvo_id": 452941,
                            "controlled_vocab": {
                                "cvo_id": 452941,
                                "cvo_title": "1701 Psychology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1701",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4074,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452942,
                                        "controlled_vocab": {
                                            "cvo_id": 452942,
                                            "cvo_title": "170101 Biological Psychology (Neuropsychology, Psychopharmacology, Physiological Psychology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4075,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452943,
                                        "controlled_vocab": {
                                            "cvo_id": 452943,
                                            "cvo_title": "170102 Developmental Psychology and Ageing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4076,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452944,
                                        "controlled_vocab": {
                                            "cvo_id": 452944,
                                            "cvo_title": "170103 Educational Psychology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4077,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452945,
                                        "controlled_vocab": {
                                            "cvo_id": 452945,
                                            "cvo_title": "170104 Forensic Psychology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4078,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452946,
                                        "controlled_vocab": {
                                            "cvo_id": 452946,
                                            "cvo_title": "170105 Gender Psychology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4079,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452947,
                                        "controlled_vocab": {
                                            "cvo_id": 452947,
                                            "cvo_title": "170106 Health, Clinical and Counselling Psychology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4080,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452948,
                                        "controlled_vocab": {
                                            "cvo_id": 452948,
                                            "cvo_title": "170107 Industrial and Organisational Psychology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4081,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452949,
                                        "controlled_vocab": {
                                            "cvo_id": 452949,
                                            "cvo_title": "170108 Kaupapa Maori Psychology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4082,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452950,
                                        "controlled_vocab": {
                                            "cvo_id": 452950,
                                            "cvo_title": "170109 Personality, Abilities and Assessment",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170109",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4083,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452951,
                                        "controlled_vocab": {
                                            "cvo_id": 452951,
                                            "cvo_title": "170110 Psychological Methodology, Design and Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170110",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4084,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452952,
                                        "controlled_vocab": {
                                            "cvo_id": 452952,
                                            "cvo_title": "170111 Psychology of Religion",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170111",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4085,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452953,
                                        "controlled_vocab": {
                                            "cvo_id": 452953,
                                            "cvo_title": "170112 Sensory Processes, Perception and Performance",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170112",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4086,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452954,
                                        "controlled_vocab": {
                                            "cvo_id": 452954,
                                            "cvo_title": "170113 Social and Community Psychology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170113",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4087,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452955,
                                        "controlled_vocab": {
                                            "cvo_id": 452955,
                                            "cvo_title": "170114 Sport and Exercise Psychology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170114",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4088,
                                        "cvr_parent_cvo_id": 452941,
                                        "cvr_child_cvo_id": 452956,
                                        "controlled_vocab": {
                                            "cvo_id": 452956,
                                            "cvo_title": "170199 Psychology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4089,
                            "cvr_parent_cvo_id": 452940,
                            "cvr_child_cvo_id": 452957,
                            "controlled_vocab": {
                                "cvo_id": 452957,
                                "cvo_title": "1702 Cognitive Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1702",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4090,
                                        "cvr_parent_cvo_id": 452957,
                                        "cvr_child_cvo_id": 452958,
                                        "controlled_vocab": {
                                            "cvo_id": 452958,
                                            "cvo_title": "170201 Computer Perception, Memory and Attention",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4091,
                                        "cvr_parent_cvo_id": 452957,
                                        "cvr_child_cvo_id": 452959,
                                        "controlled_vocab": {
                                            "cvo_id": 452959,
                                            "cvo_title": "170202 Decision Making",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4092,
                                        "cvr_parent_cvo_id": 452957,
                                        "cvr_child_cvo_id": 452960,
                                        "controlled_vocab": {
                                            "cvo_id": 452960,
                                            "cvo_title": "170203 Knowledge Representation and Machine Learning",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4093,
                                        "cvr_parent_cvo_id": 452957,
                                        "cvr_child_cvo_id": 452961,
                                        "controlled_vocab": {
                                            "cvo_id": 452961,
                                            "cvo_title": "170204 Linguistic Processes (incl. Speech Production and Comprehension)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4094,
                                        "cvr_parent_cvo_id": 452957,
                                        "cvr_child_cvo_id": 452962,
                                        "controlled_vocab": {
                                            "cvo_id": 452962,
                                            "cvo_title": "170205 Neurocognitive Patterns and Neural Networks",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4095,
                                        "cvr_parent_cvo_id": 452957,
                                        "cvr_child_cvo_id": 452963,
                                        "controlled_vocab": {
                                            "cvo_id": 452963,
                                            "cvo_title": "170299 Cognitive Science not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "170299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4096,
                            "cvr_parent_cvo_id": 452940,
                            "cvr_child_cvo_id": 452964,
                            "controlled_vocab": {
                                "cvo_id": 452964,
                                "cvo_title": "1799 Other Psychology and Cognitive Sciences",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1799",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4097,
                                        "cvr_parent_cvo_id": 452964,
                                        "cvr_child_cvo_id": 452965,
                                        "controlled_vocab": {
                                            "cvo_id": 452965,
                                            "cvo_title": "179999 Psychology and Cognitive Sciences not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "179999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 4098,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 452966,
                "controlled_vocab": {
                    "cvo_id": 452966,
                    "cvo_title": "18 Law and Legal Studies",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "18",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 4099,
                            "cvr_parent_cvo_id": 452966,
                            "cvr_child_cvo_id": 452967,
                            "controlled_vocab": {
                                "cvo_id": 452967,
                                "cvo_title": "1801 Law",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1801",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4100,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452968,
                                        "controlled_vocab": {
                                            "cvo_id": 452968,
                                            "cvo_title": "180101 Aboriginal and Torres Strait Islander Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4101,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452969,
                                        "controlled_vocab": {
                                            "cvo_id": 452969,
                                            "cvo_title": "180102 Access to Justice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4102,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452970,
                                        "controlled_vocab": {
                                            "cvo_id": 452970,
                                            "cvo_title": "180103 Administrative Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4103,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452971,
                                        "controlled_vocab": {
                                            "cvo_id": 452971,
                                            "cvo_title": "180104 Civil Law and Procedure",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4104,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452972,
                                        "controlled_vocab": {
                                            "cvo_id": 452972,
                                            "cvo_title": "180105 Commercial and Contract Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4105,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452973,
                                        "controlled_vocab": {
                                            "cvo_id": 452973,
                                            "cvo_title": "180106 Comparative Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4106,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452974,
                                        "controlled_vocab": {
                                            "cvo_id": 452974,
                                            "cvo_title": "180107 Conflict of Laws (Private International Law)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4107,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452975,
                                        "controlled_vocab": {
                                            "cvo_id": 452975,
                                            "cvo_title": "180108 Constitutional Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4108,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452976,
                                        "controlled_vocab": {
                                            "cvo_id": 452976,
                                            "cvo_title": "180109 Corporations and Associations Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180109",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4109,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452977,
                                        "controlled_vocab": {
                                            "cvo_id": 452977,
                                            "cvo_title": "180110 Criminal Law and Procedure",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180110",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4110,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452978,
                                        "controlled_vocab": {
                                            "cvo_id": 452978,
                                            "cvo_title": "180111 Environmental and Natural Resources Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180111",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4111,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452979,
                                        "controlled_vocab": {
                                            "cvo_id": 452979,
                                            "cvo_title": "180112 Equity and Trusts Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180112",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4112,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452980,
                                        "controlled_vocab": {
                                            "cvo_id": 452980,
                                            "cvo_title": "180113 Family Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180113",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4113,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452981,
                                        "controlled_vocab": {
                                            "cvo_id": 452981,
                                            "cvo_title": "180114 Human Rights Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180114",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4114,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452982,
                                        "controlled_vocab": {
                                            "cvo_id": 452982,
                                            "cvo_title": "180115 Intellectual Property Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180115",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4115,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452983,
                                        "controlled_vocab": {
                                            "cvo_id": 452983,
                                            "cvo_title": "180116 International Law (excl. International Trade Law)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180116",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4116,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452984,
                                        "controlled_vocab": {
                                            "cvo_id": 452984,
                                            "cvo_title": "180117 International Trade Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180117",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4117,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452985,
                                        "controlled_vocab": {
                                            "cvo_id": 452985,
                                            "cvo_title": "180118 Labour Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180118",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4118,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452986,
                                        "controlled_vocab": {
                                            "cvo_id": 452986,
                                            "cvo_title": "180119 Law and Society",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180119",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4119,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452987,
                                        "controlled_vocab": {
                                            "cvo_id": 452987,
                                            "cvo_title": "180120 Legal Institutions (incl. Courts and Justice Systems)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180120",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4120,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452988,
                                        "controlled_vocab": {
                                            "cvo_id": 452988,
                                            "cvo_title": "180121 Legal Practice, Lawyering and the Legal Profession",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180121",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4121,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452989,
                                        "controlled_vocab": {
                                            "cvo_id": 452989,
                                            "cvo_title": "180122 Legal Theory, Jurisprudence and Legal Interpretation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180122",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4122,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452990,
                                        "controlled_vocab": {
                                            "cvo_id": 452990,
                                            "cvo_title": "180123 Litigation, Adjudication and Dispute Resolution",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180123",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4123,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452991,
                                        "controlled_vocab": {
                                            "cvo_id": 452991,
                                            "cvo_title": "180124 Property Law (excl. Intellectual Property Law)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180124",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4124,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452992,
                                        "controlled_vocab": {
                                            "cvo_id": 452992,
                                            "cvo_title": "180125 Taxation Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180125",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4125,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452993,
                                        "controlled_vocab": {
                                            "cvo_id": 452993,
                                            "cvo_title": "180126 Tort Law",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180126",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4126,
                                        "cvr_parent_cvo_id": 452967,
                                        "cvr_child_cvo_id": 452994,
                                        "controlled_vocab": {
                                            "cvo_id": 452994,
                                            "cvo_title": "180199 Law not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4127,
                            "cvr_parent_cvo_id": 452966,
                            "cvr_child_cvo_id": 452995,
                            "controlled_vocab": {
                                "cvo_id": 452995,
                                "cvo_title": "1802 Maori Law",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1802",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4128,
                                        "cvr_parent_cvo_id": 452995,
                                        "cvr_child_cvo_id": 452996,
                                        "controlled_vocab": {
                                            "cvo_id": 452996,
                                            "cvo_title": "180201 Nga Tikanga Maori (Maori Customary Law)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4129,
                                        "cvr_parent_cvo_id": 452995,
                                        "cvr_child_cvo_id": 452997,
                                        "controlled_vocab": {
                                            "cvo_id": 452997,
                                            "cvo_title": "180202 Te Maori Whakahaere Rauemi (Maori Resource Law))",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4130,
                                        "cvr_parent_cvo_id": 452995,
                                        "cvr_child_cvo_id": 452998,
                                        "controlled_vocab": {
                                            "cvo_id": 452998,
                                            "cvo_title": "180203 Te Tiritio Waitangi (The Treaty of Waitangi)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4131,
                                        "cvr_parent_cvo_id": 452995,
                                        "cvr_child_cvo_id": 452999,
                                        "controlled_vocab": {
                                            "cvo_id": 452999,
                                            "cvo_title": "180204 Te Ture Whenua (Maori Land Law)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4132,
                                        "cvr_parent_cvo_id": 452995,
                                        "cvr_child_cvo_id": 453000,
                                        "controlled_vocab": {
                                            "cvo_id": 453000,
                                            "cvo_title": "180299 Maori Law not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "180299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4133,
                            "cvr_parent_cvo_id": 452966,
                            "cvr_child_cvo_id": 453001,
                            "controlled_vocab": {
                                "cvo_id": 453001,
                                "cvo_title": "1899 Other Law and Legal Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1899",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4134,
                                        "cvr_parent_cvo_id": 453001,
                                        "cvr_child_cvo_id": 453002,
                                        "controlled_vocab": {
                                            "cvo_id": 453002,
                                            "cvo_title": "189999 Law and Legal Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "189999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 4135,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 453003,
                "controlled_vocab": {
                    "cvo_id": 453003,
                    "cvo_title": "19 Studies in Creative Arts and Writing",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "19",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 4136,
                            "cvr_parent_cvo_id": 453003,
                            "cvr_child_cvo_id": 453004,
                            "controlled_vocab": {
                                "cvo_id": 453004,
                                "cvo_title": "1901 Art Theory and Criticism",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1901",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4137,
                                        "cvr_parent_cvo_id": 453004,
                                        "cvr_child_cvo_id": 453005,
                                        "controlled_vocab": {
                                            "cvo_id": 453005,
                                            "cvo_title": "190101 Art Criticism",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4138,
                                        "cvr_parent_cvo_id": 453004,
                                        "cvr_child_cvo_id": 453006,
                                        "controlled_vocab": {
                                            "cvo_id": 453006,
                                            "cvo_title": "190102 Art History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4139,
                                        "cvr_parent_cvo_id": 453004,
                                        "cvr_child_cvo_id": 453007,
                                        "controlled_vocab": {
                                            "cvo_id": 453007,
                                            "cvo_title": "190103 Art Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4140,
                                        "cvr_parent_cvo_id": 453004,
                                        "cvr_child_cvo_id": 453008,
                                        "controlled_vocab": {
                                            "cvo_id": 453008,
                                            "cvo_title": "190104 Visual Cultures",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4141,
                                        "cvr_parent_cvo_id": 453004,
                                        "cvr_child_cvo_id": 453009,
                                        "controlled_vocab": {
                                            "cvo_id": 453009,
                                            "cvo_title": "190199 Art Theory and Criticism not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4142,
                            "cvr_parent_cvo_id": 453003,
                            "cvr_child_cvo_id": 453010,
                            "controlled_vocab": {
                                "cvo_id": 453010,
                                "cvo_title": "1902 Film, Television and Digital Media",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1902",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4143,
                                        "cvr_parent_cvo_id": 453010,
                                        "cvr_child_cvo_id": 453011,
                                        "controlled_vocab": {
                                            "cvo_id": 453011,
                                            "cvo_title": "190201 Cinema Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4144,
                                        "cvr_parent_cvo_id": 453010,
                                        "cvr_child_cvo_id": 453012,
                                        "controlled_vocab": {
                                            "cvo_id": 453012,
                                            "cvo_title": "190202 Computer Gaming and Animation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4145,
                                        "cvr_parent_cvo_id": 453010,
                                        "cvr_child_cvo_id": 453013,
                                        "controlled_vocab": {
                                            "cvo_id": 453013,
                                            "cvo_title": "190203 Electronic Media Art",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4146,
                                        "cvr_parent_cvo_id": 453010,
                                        "cvr_child_cvo_id": 453014,
                                        "controlled_vocab": {
                                            "cvo_id": 453014,
                                            "cvo_title": "190204 Film and Television",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4147,
                                        "cvr_parent_cvo_id": 453010,
                                        "cvr_child_cvo_id": 453015,
                                        "controlled_vocab": {
                                            "cvo_id": 453015,
                                            "cvo_title": "190205 Interactive Media",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4148,
                                        "cvr_parent_cvo_id": 453010,
                                        "cvr_child_cvo_id": 453016,
                                        "controlled_vocab": {
                                            "cvo_id": 453016,
                                            "cvo_title": "190299 Film, Television and Digital Media not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4149,
                            "cvr_parent_cvo_id": 453003,
                            "cvr_child_cvo_id": 453017,
                            "controlled_vocab": {
                                "cvo_id": 453017,
                                "cvo_title": "1903 Journalism and Professional Writing",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1903",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4150,
                                        "cvr_parent_cvo_id": 453017,
                                        "cvr_child_cvo_id": 453018,
                                        "controlled_vocab": {
                                            "cvo_id": 453018,
                                            "cvo_title": "190301 Journalism Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4151,
                                        "cvr_parent_cvo_id": 453017,
                                        "cvr_child_cvo_id": 453019,
                                        "controlled_vocab": {
                                            "cvo_id": 453019,
                                            "cvo_title": "190302 Professional Writing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4152,
                                        "cvr_parent_cvo_id": 453017,
                                        "cvr_child_cvo_id": 453020,
                                        "controlled_vocab": {
                                            "cvo_id": 453020,
                                            "cvo_title": "190303 Technical Writing",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4153,
                                        "cvr_parent_cvo_id": 453017,
                                        "cvr_child_cvo_id": 453021,
                                        "controlled_vocab": {
                                            "cvo_id": 453021,
                                            "cvo_title": "190399 Journalism and Professional Writing not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4154,
                            "cvr_parent_cvo_id": 453003,
                            "cvr_child_cvo_id": 453022,
                            "controlled_vocab": {
                                "cvo_id": 453022,
                                "cvo_title": "1904 Performing Arts and Creative Writing",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1904",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4155,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453023,
                                        "controlled_vocab": {
                                            "cvo_id": 453023,
                                            "cvo_title": "190401 Aboriginal and Torres Strait Islander Performing Arts",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4156,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453024,
                                        "controlled_vocab": {
                                            "cvo_id": 453024,
                                            "cvo_title": "190402 Creative Writing (incl. Playwriting)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4157,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453025,
                                        "controlled_vocab": {
                                            "cvo_id": 453025,
                                            "cvo_title": "190403 Dance",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4158,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453026,
                                        "controlled_vocab": {
                                            "cvo_id": 453026,
                                            "cvo_title": "190404 Drama, Theatre and Performance Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4159,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453027,
                                        "controlled_vocab": {
                                            "cvo_id": 453027,
                                            "cvo_title": "190405 Maori Performing Arts",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4160,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453028,
                                        "controlled_vocab": {
                                            "cvo_id": 453028,
                                            "cvo_title": "190406 Music Composition",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4161,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453029,
                                        "controlled_vocab": {
                                            "cvo_id": 453029,
                                            "cvo_title": "190407 Music Performance",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190407",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4162,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453030,
                                        "controlled_vocab": {
                                            "cvo_id": 453030,
                                            "cvo_title": "190408 Music Therapy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190408",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4163,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453031,
                                        "controlled_vocab": {
                                            "cvo_id": 453031,
                                            "cvo_title": "190409 Musicology and Ethnomusicology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190409",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4164,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453032,
                                        "controlled_vocab": {
                                            "cvo_id": 453032,
                                            "cvo_title": "190410 Pacific Peoples Performing Arts",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190410",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4165,
                                        "cvr_parent_cvo_id": 453022,
                                        "cvr_child_cvo_id": 453033,
                                        "controlled_vocab": {
                                            "cvo_id": 453033,
                                            "cvo_title": "190499 Performing Arts and Creative Writing not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4166,
                            "cvr_parent_cvo_id": 453003,
                            "cvr_child_cvo_id": 453034,
                            "controlled_vocab": {
                                "cvo_id": 453034,
                                "cvo_title": "1905 Visual Arts and Crafts",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1905",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4167,
                                        "cvr_parent_cvo_id": 453034,
                                        "cvr_child_cvo_id": 453035,
                                        "controlled_vocab": {
                                            "cvo_id": 453035,
                                            "cvo_title": "190501 Crafts",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4168,
                                        "cvr_parent_cvo_id": 453034,
                                        "cvr_child_cvo_id": 453036,
                                        "controlled_vocab": {
                                            "cvo_id": 453036,
                                            "cvo_title": "190502 Fine Arts (incl. Sculpture and Painting)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4169,
                                        "cvr_parent_cvo_id": 453034,
                                        "cvr_child_cvo_id": 453037,
                                        "controlled_vocab": {
                                            "cvo_id": 453037,
                                            "cvo_title": "190503 Lens-based Practice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4170,
                                        "cvr_parent_cvo_id": 453034,
                                        "cvr_child_cvo_id": 453038,
                                        "controlled_vocab": {
                                            "cvo_id": 453038,
                                            "cvo_title": "190504 Performance and Installation Art",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4171,
                                        "cvr_parent_cvo_id": 453034,
                                        "cvr_child_cvo_id": 453039,
                                        "controlled_vocab": {
                                            "cvo_id": 453039,
                                            "cvo_title": "190599 Visual Arts and Crafts not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "190599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4172,
                            "cvr_parent_cvo_id": 453003,
                            "cvr_child_cvo_id": 453040,
                            "controlled_vocab": {
                                "cvo_id": 453040,
                                "cvo_title": "1999 Other Studies in Creative Arts and Writing",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "1999",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4173,
                                        "cvr_parent_cvo_id": 453040,
                                        "cvr_child_cvo_id": 453041,
                                        "controlled_vocab": {
                                            "cvo_id": 453041,
                                            "cvo_title": "199999 Studies in Creative Arts and Writing not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "199999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 4174,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 453042,
                "controlled_vocab": {
                    "cvo_id": 453042,
                    "cvo_title": "20 Language, Communication and Culture",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "20",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 4175,
                            "cvr_parent_cvo_id": 453042,
                            "cvr_child_cvo_id": 453043,
                            "controlled_vocab": {
                                "cvo_id": 453043,
                                "cvo_title": "2001 Communication and Media Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2001",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4176,
                                        "cvr_parent_cvo_id": 453043,
                                        "cvr_child_cvo_id": 453044,
                                        "controlled_vocab": {
                                            "cvo_id": 453044,
                                            "cvo_title": "200101 Communication Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4177,
                                        "cvr_parent_cvo_id": 453043,
                                        "cvr_child_cvo_id": 453045,
                                        "controlled_vocab": {
                                            "cvo_id": 453045,
                                            "cvo_title": "200102 Communication Technology and Digital Media Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4178,
                                        "cvr_parent_cvo_id": 453043,
                                        "cvr_child_cvo_id": 453046,
                                        "controlled_vocab": {
                                            "cvo_id": 453046,
                                            "cvo_title": "200103 International and Development Communication",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4179,
                                        "cvr_parent_cvo_id": 453043,
                                        "cvr_child_cvo_id": 453047,
                                        "controlled_vocab": {
                                            "cvo_id": 453047,
                                            "cvo_title": "200104 Media Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4180,
                                        "cvr_parent_cvo_id": 453043,
                                        "cvr_child_cvo_id": 453048,
                                        "controlled_vocab": {
                                            "cvo_id": 453048,
                                            "cvo_title": "200105 Organisational, Interpersonal and Intercultural Communication",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4181,
                                        "cvr_parent_cvo_id": 453043,
                                        "cvr_child_cvo_id": 453049,
                                        "controlled_vocab": {
                                            "cvo_id": 453049,
                                            "cvo_title": "200199 Communication and Media Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4182,
                            "cvr_parent_cvo_id": 453042,
                            "cvr_child_cvo_id": 453050,
                            "controlled_vocab": {
                                "cvo_id": 453050,
                                "cvo_title": "2002 Cultural Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2002",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4183,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453051,
                                        "controlled_vocab": {
                                            "cvo_id": 453051,
                                            "cvo_title": "200201 Aboriginal and Torres Strait Islander Cultural Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4184,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453052,
                                        "controlled_vocab": {
                                            "cvo_id": 453052,
                                            "cvo_title": "200202 Asian Cultural Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4185,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453053,
                                        "controlled_vocab": {
                                            "cvo_id": 453053,
                                            "cvo_title": "200203 Consumption and Everyday Life",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4186,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453054,
                                        "controlled_vocab": {
                                            "cvo_id": 453054,
                                            "cvo_title": "200204 Cultural Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4187,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453055,
                                        "controlled_vocab": {
                                            "cvo_id": 453055,
                                            "cvo_title": "200205 Culture, Gender, Sexuality",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4188,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453056,
                                        "controlled_vocab": {
                                            "cvo_id": 453056,
                                            "cvo_title": "200206 Globalisation and Culture",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4189,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453057,
                                        "controlled_vocab": {
                                            "cvo_id": 453057,
                                            "cvo_title": "200207 Maori Cultural Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4190,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453058,
                                        "controlled_vocab": {
                                            "cvo_id": 453058,
                                            "cvo_title": "200208 Migrant Cultural Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200208",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4191,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453059,
                                        "controlled_vocab": {
                                            "cvo_id": 453059,
                                            "cvo_title": "200209 Multicultural, Intercultural and Cross-cultural Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200209",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4192,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453060,
                                        "controlled_vocab": {
                                            "cvo_id": 453060,
                                            "cvo_title": "200210 Pacific Cultural Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200210",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4193,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453061,
                                        "controlled_vocab": {
                                            "cvo_id": 453061,
                                            "cvo_title": "200211 Postcolonial Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200211",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4194,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453062,
                                        "controlled_vocab": {
                                            "cvo_id": 453062,
                                            "cvo_title": "200212 Screen and Media Culture",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200212",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4195,
                                        "cvr_parent_cvo_id": 453050,
                                        "cvr_child_cvo_id": 453063,
                                        "controlled_vocab": {
                                            "cvo_id": 453063,
                                            "cvo_title": "200299 Cultural Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4196,
                            "cvr_parent_cvo_id": 453042,
                            "cvr_child_cvo_id": 453064,
                            "controlled_vocab": {
                                "cvo_id": 453064,
                                "cvo_title": "2003 Language Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2003",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4197,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453065,
                                        "controlled_vocab": {
                                            "cvo_id": 453065,
                                            "cvo_title": "200301 Early English Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4198,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453066,
                                        "controlled_vocab": {
                                            "cvo_id": 453066,
                                            "cvo_title": "200302 English Language",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4199,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453067,
                                        "controlled_vocab": {
                                            "cvo_id": 453067,
                                            "cvo_title": "200303 English as a Second Language",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4200,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453068,
                                        "controlled_vocab": {
                                            "cvo_id": 453068,
                                            "cvo_title": "200304 Central and Eastern European Languages (incl. Russian)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4201,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453069,
                                        "controlled_vocab": {
                                            "cvo_id": 453069,
                                            "cvo_title": "200305 Latin and Classical Greek Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4202,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453070,
                                        "controlled_vocab": {
                                            "cvo_id": 453070,
                                            "cvo_title": "200306 French Language",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4203,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453071,
                                        "controlled_vocab": {
                                            "cvo_id": 453071,
                                            "cvo_title": "200307 German Language",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4204,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453072,
                                        "controlled_vocab": {
                                            "cvo_id": 453072,
                                            "cvo_title": "200308 Iberian Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4205,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453073,
                                        "controlled_vocab": {
                                            "cvo_id": 453073,
                                            "cvo_title": "200309 Italian Language",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4206,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453074,
                                        "controlled_vocab": {
                                            "cvo_id": 453074,
                                            "cvo_title": "200310 Other European Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200310",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4207,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453075,
                                        "controlled_vocab": {
                                            "cvo_id": 453075,
                                            "cvo_title": "200311 Chinese Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200311",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4208,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453076,
                                        "controlled_vocab": {
                                            "cvo_id": 453076,
                                            "cvo_title": "200312 Japanese Language",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200312",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4209,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453077,
                                        "controlled_vocab": {
                                            "cvo_id": 453077,
                                            "cvo_title": "200313 Indonesian Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200313",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4210,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453078,
                                        "controlled_vocab": {
                                            "cvo_id": 453078,
                                            "cvo_title": "200314 South-East Asian Languages (excl. Indonesian)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200314",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4211,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453079,
                                        "controlled_vocab": {
                                            "cvo_id": 453079,
                                            "cvo_title": "200315 Indian Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200315",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4212,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453080,
                                        "controlled_vocab": {
                                            "cvo_id": 453080,
                                            "cvo_title": "200316 Korean Language",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200316",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4213,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453081,
                                        "controlled_vocab": {
                                            "cvo_id": 453081,
                                            "cvo_title": "200317 Other Asian Languages (excl. South-East Asian)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200317",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4214,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453082,
                                        "controlled_vocab": {
                                            "cvo_id": 453082,
                                            "cvo_title": "200318 Middle Eastern Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200318",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4215,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453083,
                                        "controlled_vocab": {
                                            "cvo_id": 453083,
                                            "cvo_title": "200319 Aboriginal and Torres Strait Islander Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200319",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4216,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453084,
                                        "controlled_vocab": {
                                            "cvo_id": 453084,
                                            "cvo_title": "200320 Pacific Languages",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200320",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4217,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453085,
                                        "controlled_vocab": {
                                            "cvo_id": 453085,
                                            "cvo_title": "200321 Te Reo Maori (Maori Language)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200321",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4218,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453086,
                                        "controlled_vocab": {
                                            "cvo_id": 453086,
                                            "cvo_title": "200322 Comparative Language Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200322",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4219,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453087,
                                        "controlled_vocab": {
                                            "cvo_id": 453087,
                                            "cvo_title": "200323 Translation and Interpretation Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200323",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4220,
                                        "cvr_parent_cvo_id": 453064,
                                        "cvr_child_cvo_id": 453088,
                                        "controlled_vocab": {
                                            "cvo_id": 453088,
                                            "cvo_title": "200399 Language Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4221,
                            "cvr_parent_cvo_id": 453042,
                            "cvr_child_cvo_id": 453089,
                            "controlled_vocab": {
                                "cvo_id": 453089,
                                "cvo_title": "2004 Linguistics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2004",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4222,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453090,
                                        "controlled_vocab": {
                                            "cvo_id": 453090,
                                            "cvo_title": "200401 Applied Linguistics and Educational Linguistics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4223,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453091,
                                        "controlled_vocab": {
                                            "cvo_id": 453091,
                                            "cvo_title": "200402 Computational Linguistics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4224,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453092,
                                        "controlled_vocab": {
                                            "cvo_id": 453092,
                                            "cvo_title": "200403 Discourse and Pragmatics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4225,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453093,
                                        "controlled_vocab": {
                                            "cvo_id": 453093,
                                            "cvo_title": "200404 Laboratory Phonetics and Speech Science",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4226,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453094,
                                        "controlled_vocab": {
                                            "cvo_id": 453094,
                                            "cvo_title": "200405 Language in Culture and Society (Sociolinguistics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4227,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453095,
                                        "controlled_vocab": {
                                            "cvo_id": 453095,
                                            "cvo_title": "200406 Language in Time and Space (incl. Historical Linguistics, Dialectology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4228,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453096,
                                        "controlled_vocab": {
                                            "cvo_id": 453096,
                                            "cvo_title": "200407 Lexicography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200407",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4229,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453097,
                                        "controlled_vocab": {
                                            "cvo_id": 453097,
                                            "cvo_title": "200408 Linguistic Structures (incl. Grammar, Phonology, Lexicon, Semantics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200408",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4230,
                                        "cvr_parent_cvo_id": 453089,
                                        "cvr_child_cvo_id": 453098,
                                        "controlled_vocab": {
                                            "cvo_id": 453098,
                                            "cvo_title": "200499 Linguistics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4231,
                            "cvr_parent_cvo_id": 453042,
                            "cvr_child_cvo_id": 453099,
                            "controlled_vocab": {
                                "cvo_id": 453099,
                                "cvo_title": "2005 Literary Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2005",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4232,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453100,
                                        "controlled_vocab": {
                                            "cvo_id": 453100,
                                            "cvo_title": "200501 Aboriginal and Torres Strait Islander Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200501",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4233,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453101,
                                        "controlled_vocab": {
                                            "cvo_id": 453101,
                                            "cvo_title": "200502 Australian Literature (excl. Aboriginal and Torres Strait Islander Literature)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200502",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4234,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453102,
                                        "controlled_vocab": {
                                            "cvo_id": 453102,
                                            "cvo_title": "200503 British and Irish Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200503",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4235,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453103,
                                        "controlled_vocab": {
                                            "cvo_id": 453103,
                                            "cvo_title": "200504 Maori Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200504",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4236,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453104,
                                        "controlled_vocab": {
                                            "cvo_id": 453104,
                                            "cvo_title": "200505 New Zealand Literature (excl. Maori Literature)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200505",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4237,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453105,
                                        "controlled_vocab": {
                                            "cvo_id": 453105,
                                            "cvo_title": "200506 North American Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200506",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4238,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453106,
                                        "controlled_vocab": {
                                            "cvo_id": 453106,
                                            "cvo_title": "200507 Pacific Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200507",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4239,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453107,
                                        "controlled_vocab": {
                                            "cvo_id": 453107,
                                            "cvo_title": "200508 Other Literatures in English",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200508",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4240,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453108,
                                        "controlled_vocab": {
                                            "cvo_id": 453108,
                                            "cvo_title": "200509 Central and Eastern European Literature (incl. Russian)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200509",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4241,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453109,
                                        "controlled_vocab": {
                                            "cvo_id": 453109,
                                            "cvo_title": "200510 Latin and Classical Greek Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200510",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4242,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453110,
                                        "controlled_vocab": {
                                            "cvo_id": 453110,
                                            "cvo_title": "200511 Literature in French",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200511",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4243,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453111,
                                        "controlled_vocab": {
                                            "cvo_id": 453111,
                                            "cvo_title": "200512 Literature in German",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200512",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4244,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453112,
                                        "controlled_vocab": {
                                            "cvo_id": 453112,
                                            "cvo_title": "200513 Literature in Italian",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200513",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4245,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453113,
                                        "controlled_vocab": {
                                            "cvo_id": 453113,
                                            "cvo_title": "200514 Literature in Spanish and Portuguese",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200514",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4246,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453114,
                                        "controlled_vocab": {
                                            "cvo_id": 453114,
                                            "cvo_title": "200515 Other European Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200515",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4247,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453115,
                                        "controlled_vocab": {
                                            "cvo_id": 453115,
                                            "cvo_title": "200516 Indonesian Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200516",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4248,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453116,
                                        "controlled_vocab": {
                                            "cvo_id": 453116,
                                            "cvo_title": "200517 Literature in Chinese",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200517",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4249,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453117,
                                        "controlled_vocab": {
                                            "cvo_id": 453117,
                                            "cvo_title": "200518 Literature in Japanese",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200518",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4250,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453118,
                                        "controlled_vocab": {
                                            "cvo_id": 453118,
                                            "cvo_title": "200519 South-East Asian Literature (excl. Indonesian)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200519",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4251,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453119,
                                        "controlled_vocab": {
                                            "cvo_id": 453119,
                                            "cvo_title": "200520 Indian Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200520",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4252,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453120,
                                        "controlled_vocab": {
                                            "cvo_id": 453120,
                                            "cvo_title": "200521 Korean Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200521",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4253,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453121,
                                        "controlled_vocab": {
                                            "cvo_id": 453121,
                                            "cvo_title": "200522 Other Asian Literature (excl. South-East Asian)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200522",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4254,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453122,
                                        "controlled_vocab": {
                                            "cvo_id": 453122,
                                            "cvo_title": "200523 Middle Eastern Literature",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200523",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4255,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453123,
                                        "controlled_vocab": {
                                            "cvo_id": 453123,
                                            "cvo_title": "200524 Comparative Literature Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200524",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4256,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453124,
                                        "controlled_vocab": {
                                            "cvo_id": 453124,
                                            "cvo_title": "200525 Literary Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200525",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4257,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453125,
                                        "controlled_vocab": {
                                            "cvo_id": 453125,
                                            "cvo_title": "200526 Stylistics and Textual Analysis",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200526",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4258,
                                        "cvr_parent_cvo_id": 453099,
                                        "cvr_child_cvo_id": 453126,
                                        "controlled_vocab": {
                                            "cvo_id": 453126,
                                            "cvo_title": "200599 Literary Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "200599",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4259,
                            "cvr_parent_cvo_id": 453042,
                            "cvr_child_cvo_id": 453127,
                            "controlled_vocab": {
                                "cvo_id": 453127,
                                "cvo_title": "2099 Other Language, Communication and Culture",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2099",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4260,
                                        "cvr_parent_cvo_id": 453127,
                                        "cvr_child_cvo_id": 453128,
                                        "controlled_vocab": {
                                            "cvo_id": 453128,
                                            "cvo_title": "209999 Language, Communication and Culture not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "209999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 4261,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 453129,
                "controlled_vocab": {
                    "cvo_id": 453129,
                    "cvo_title": "21 History and Archaeology",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "21",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 4262,
                            "cvr_parent_cvo_id": 453129,
                            "cvr_child_cvo_id": 453130,
                            "controlled_vocab": {
                                "cvo_id": 453130,
                                "cvo_title": "2101 Archaeology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2101",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4263,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453131,
                                        "controlled_vocab": {
                                            "cvo_id": 453131,
                                            "cvo_title": "210101 Aboriginal and Torres Strait Islander Archaeology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4264,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453132,
                                        "controlled_vocab": {
                                            "cvo_id": 453132,
                                            "cvo_title": "210102 Archaeological Science",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4265,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453133,
                                        "controlled_vocab": {
                                            "cvo_id": 453133,
                                            "cvo_title": "210103 Archaeology of Asia, Africa and the Americas",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4266,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453134,
                                        "controlled_vocab": {
                                            "cvo_id": 453134,
                                            "cvo_title": "210104 Archaeology of Australia (excl. Aboriginal and Torres Strait Islander)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4267,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453135,
                                        "controlled_vocab": {
                                            "cvo_id": 453135,
                                            "cvo_title": "210105 Archaeology of Europe, the Mediterranean and the Levant",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4268,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453136,
                                        "controlled_vocab": {
                                            "cvo_id": 453136,
                                            "cvo_title": "210106 Archaeology of New Guinea and Pacific Islands (excl. New Zealand)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4269,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453137,
                                        "controlled_vocab": {
                                            "cvo_id": 453137,
                                            "cvo_title": "210107 Archaeology of New Zealand (excl. Maori)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4270,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453138,
                                        "controlled_vocab": {
                                            "cvo_id": 453138,
                                            "cvo_title": "210108 Historical Archaeology (incl. Industrial Archaeology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210108",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4271,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453139,
                                        "controlled_vocab": {
                                            "cvo_id": 453139,
                                            "cvo_title": "210109 Maori Archaeology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210109",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4272,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453140,
                                        "controlled_vocab": {
                                            "cvo_id": 453140,
                                            "cvo_title": "210110 Maritime Archaeology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210110",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4273,
                                        "cvr_parent_cvo_id": 453130,
                                        "cvr_child_cvo_id": 453141,
                                        "controlled_vocab": {
                                            "cvo_id": 453141,
                                            "cvo_title": "210199 Archaeology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4274,
                            "cvr_parent_cvo_id": 453129,
                            "cvr_child_cvo_id": 453142,
                            "controlled_vocab": {
                                "cvo_id": 453142,
                                "cvo_title": "2102 Curatorial and Related Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2102",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4275,
                                        "cvr_parent_cvo_id": 453142,
                                        "cvr_child_cvo_id": 453143,
                                        "controlled_vocab": {
                                            "cvo_id": 453143,
                                            "cvo_title": "210201 Archival, Repository and Related Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4276,
                                        "cvr_parent_cvo_id": 453142,
                                        "cvr_child_cvo_id": 453144,
                                        "controlled_vocab": {
                                            "cvo_id": 453144,
                                            "cvo_title": "210202 Heritage and Cultural Conservation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4277,
                                        "cvr_parent_cvo_id": 453142,
                                        "cvr_child_cvo_id": 453145,
                                        "controlled_vocab": {
                                            "cvo_id": 453145,
                                            "cvo_title": "210203 Materials Conservation",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4278,
                                        "cvr_parent_cvo_id": 453142,
                                        "cvr_child_cvo_id": 453146,
                                        "controlled_vocab": {
                                            "cvo_id": 453146,
                                            "cvo_title": "210204 Museum Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4279,
                                        "cvr_parent_cvo_id": 453142,
                                        "cvr_child_cvo_id": 453147,
                                        "controlled_vocab": {
                                            "cvo_id": 453147,
                                            "cvo_title": "210299 Curatorial and Related Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4280,
                            "cvr_parent_cvo_id": 453129,
                            "cvr_child_cvo_id": 453148,
                            "controlled_vocab": {
                                "cvo_id": 453148,
                                "cvo_title": "2103 Historical Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2103",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4281,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453149,
                                        "controlled_vocab": {
                                            "cvo_id": 453149,
                                            "cvo_title": "210301 Aboriginal and Torres Strait Islander History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4282,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453150,
                                        "controlled_vocab": {
                                            "cvo_id": 453150,
                                            "cvo_title": "210302 Asian History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4283,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453151,
                                        "controlled_vocab": {
                                            "cvo_id": 453151,
                                            "cvo_title": "210303 Australian History (excl. Aboriginal and Torres Strait Islander History)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4284,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453152,
                                        "controlled_vocab": {
                                            "cvo_id": 453152,
                                            "cvo_title": "210304 Biography",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4285,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453153,
                                        "controlled_vocab": {
                                            "cvo_id": 453153,
                                            "cvo_title": "210305 British History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4286,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453154,
                                        "controlled_vocab": {
                                            "cvo_id": 453154,
                                            "cvo_title": "210306 Classical Greek and Roman History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4287,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453155,
                                        "controlled_vocab": {
                                            "cvo_id": 453155,
                                            "cvo_title": "210307 European History (excl. British, Classical Greek and Roman)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4288,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453156,
                                        "controlled_vocab": {
                                            "cvo_id": 453156,
                                            "cvo_title": "210308 Latin American History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4289,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453157,
                                        "controlled_vocab": {
                                            "cvo_id": 453157,
                                            "cvo_title": "210309 Maori History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4290,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453158,
                                        "controlled_vocab": {
                                            "cvo_id": 453158,
                                            "cvo_title": "210310 Middle Eastern and African History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210310",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4291,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453159,
                                        "controlled_vocab": {
                                            "cvo_id": 453159,
                                            "cvo_title": "210311 New Zealand History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210311",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4292,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453160,
                                        "controlled_vocab": {
                                            "cvo_id": 453160,
                                            "cvo_title": "210312 North American History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210312",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4293,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453161,
                                        "controlled_vocab": {
                                            "cvo_id": 453161,
                                            "cvo_title": "210313 Pacific History (excl. New Zealand and Maori)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210313",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4294,
                                        "cvr_parent_cvo_id": 453148,
                                        "cvr_child_cvo_id": 453162,
                                        "controlled_vocab": {
                                            "cvo_id": 453162,
                                            "cvo_title": "210399 Historical Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "210399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4295,
                            "cvr_parent_cvo_id": 453129,
                            "cvr_child_cvo_id": 453163,
                            "controlled_vocab": {
                                "cvo_id": 453163,
                                "cvo_title": "2199 Other History and Archaeology",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2199",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4296,
                                        "cvr_parent_cvo_id": 453163,
                                        "cvr_child_cvo_id": 453164,
                                        "controlled_vocab": {
                                            "cvo_id": 453164,
                                            "cvo_title": "219999 History and Archaeology not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "219999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "cvr_id": 4297,
                "cvr_parent_cvo_id": 451780,
                "cvr_child_cvo_id": 453165,
                "controlled_vocab": {
                    "cvo_id": 453165,
                    "cvo_title": "22 Philosophy and Religious Studies",
                    "cvo_desc": "",
                    "cvo_image_filename": null,
                    "cvo_external_id": "22",
                    "cvo_hide": 0,
                    "cvo_order": null,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": null,
                    "controlled_vocab_children": [
                        {
                            "cvr_id": 4298,
                            "cvr_parent_cvo_id": 453165,
                            "cvr_child_cvo_id": 453166,
                            "controlled_vocab": {
                                "cvo_id": 453166,
                                "cvo_title": "2201 Applied Ethics",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2201",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4299,
                                        "cvr_parent_cvo_id": 453166,
                                        "cvr_child_cvo_id": 453167,
                                        "controlled_vocab": {
                                            "cvo_id": 453167,
                                            "cvo_title": "220101 Bioethics (human and animal)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220101",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4300,
                                        "cvr_parent_cvo_id": 453166,
                                        "cvr_child_cvo_id": 453168,
                                        "controlled_vocab": {
                                            "cvo_id": 453168,
                                            "cvo_title": "220102 Business Ethics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220102",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4301,
                                        "cvr_parent_cvo_id": 453166,
                                        "cvr_child_cvo_id": 453169,
                                        "controlled_vocab": {
                                            "cvo_id": 453169,
                                            "cvo_title": "220103 Ethical Use of New Technology (e.g. Nanotechnology, Biotechnology)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220103",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4302,
                                        "cvr_parent_cvo_id": 453166,
                                        "cvr_child_cvo_id": 453170,
                                        "controlled_vocab": {
                                            "cvo_id": 453170,
                                            "cvo_title": "220104 Human Rights and Justice Issues",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220104",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4303,
                                        "cvr_parent_cvo_id": 453166,
                                        "cvr_child_cvo_id": 453171,
                                        "controlled_vocab": {
                                            "cvo_id": 453171,
                                            "cvo_title": "220105 Legal Ethics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220105",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4304,
                                        "cvr_parent_cvo_id": 453166,
                                        "cvr_child_cvo_id": 453172,
                                        "controlled_vocab": {
                                            "cvo_id": 453172,
                                            "cvo_title": "220106 Medical Ethics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220106",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4305,
                                        "cvr_parent_cvo_id": 453166,
                                        "cvr_child_cvo_id": 453173,
                                        "controlled_vocab": {
                                            "cvo_id": 453173,
                                            "cvo_title": "220107 Professional Ethics (incl. police and research ethics)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220107",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4306,
                                        "cvr_parent_cvo_id": 453166,
                                        "cvr_child_cvo_id": 453174,
                                        "controlled_vocab": {
                                            "cvo_id": 453174,
                                            "cvo_title": "220199 Applied Ethics not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220199",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4307,
                            "cvr_parent_cvo_id": 453165,
                            "cvr_child_cvo_id": 453175,
                            "controlled_vocab": {
                                "cvo_id": 453175,
                                "cvo_title": "2202 History and Philosophy of Specific Fields",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2202",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4308,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453176,
                                        "controlled_vocab": {
                                            "cvo_id": 453176,
                                            "cvo_title": "220201 Business and Labour History",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220201",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4309,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453177,
                                        "controlled_vocab": {
                                            "cvo_id": 453177,
                                            "cvo_title": "220202 History and Philosophy of Education",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220202",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4310,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453178,
                                        "controlled_vocab": {
                                            "cvo_id": 453178,
                                            "cvo_title": "220203 History and Philosophy of Engineering and Technology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220203",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4311,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453179,
                                        "controlled_vocab": {
                                            "cvo_id": 453179,
                                            "cvo_title": "220204 History and Philosophy of Law and Justice",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220204",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4312,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453180,
                                        "controlled_vocab": {
                                            "cvo_id": 453180,
                                            "cvo_title": "220205 History and Philosophy of Medicine",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220205",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4313,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453181,
                                        "controlled_vocab": {
                                            "cvo_id": 453181,
                                            "cvo_title": "220206 History and Philosophy of Science (incl. Non-historical Philosophy of Science)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220206",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4314,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453182,
                                        "controlled_vocab": {
                                            "cvo_id": 453182,
                                            "cvo_title": "220207 History and Philosophy of the Humanities",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220207",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4315,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453183,
                                        "controlled_vocab": {
                                            "cvo_id": 453183,
                                            "cvo_title": "220208 History and Philosophy of the Social Sciences",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220208",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4316,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453184,
                                        "controlled_vocab": {
                                            "cvo_id": 453184,
                                            "cvo_title": "220209 History of Ideas",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220209",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4317,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453185,
                                        "controlled_vocab": {
                                            "cvo_id": 453185,
                                            "cvo_title": "220210 History of Philosophy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220210",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4318,
                                        "cvr_parent_cvo_id": 453175,
                                        "cvr_child_cvo_id": 453186,
                                        "controlled_vocab": {
                                            "cvo_id": 453186,
                                            "cvo_title": "220299 History and Philosophy of Specific Fields not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220299",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4319,
                            "cvr_parent_cvo_id": 453165,
                            "cvr_child_cvo_id": 453187,
                            "controlled_vocab": {
                                "cvo_id": 453187,
                                "cvo_title": "2203 Philosophy",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2203",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4320,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453188,
                                        "controlled_vocab": {
                                            "cvo_id": 453188,
                                            "cvo_title": "220301 Aesthetics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220301",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4321,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453189,
                                        "controlled_vocab": {
                                            "cvo_id": 453189,
                                            "cvo_title": "220302 Decision Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220302",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4322,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453190,
                                        "controlled_vocab": {
                                            "cvo_id": 453190,
                                            "cvo_title": "220303 Environmental Philosophy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220303",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4323,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453191,
                                        "controlled_vocab": {
                                            "cvo_id": 453191,
                                            "cvo_title": "220304 Epistemology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220304",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4324,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453192,
                                        "controlled_vocab": {
                                            "cvo_id": 453192,
                                            "cvo_title": "220305 Ethical Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220305",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4325,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453193,
                                        "controlled_vocab": {
                                            "cvo_id": 453193,
                                            "cvo_title": "220306 Feminist Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220306",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4326,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453194,
                                        "controlled_vocab": {
                                            "cvo_id": 453194,
                                            "cvo_title": "220307 Hermeneutic and Critical Theory",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220307",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4327,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453195,
                                        "controlled_vocab": {
                                            "cvo_id": 453195,
                                            "cvo_title": "220308 Logic",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220308",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4328,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453196,
                                        "controlled_vocab": {
                                            "cvo_id": 453196,
                                            "cvo_title": "220309 Metaphysics",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220309",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4329,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453197,
                                        "controlled_vocab": {
                                            "cvo_id": 453197,
                                            "cvo_title": "220310 Phenomenology",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220310",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4330,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453198,
                                        "controlled_vocab": {
                                            "cvo_id": 453198,
                                            "cvo_title": "220311 Philosophical Psychology (incl. Moral Psychology and Philosophy of Action)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220311",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4331,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453199,
                                        "controlled_vocab": {
                                            "cvo_id": 453199,
                                            "cvo_title": "220312 Philosophy of Cognition",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220312",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4332,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453200,
                                        "controlled_vocab": {
                                            "cvo_id": 453200,
                                            "cvo_title": "220313 Philosophy of Language",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220313",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4333,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453201,
                                        "controlled_vocab": {
                                            "cvo_id": 453201,
                                            "cvo_title": "220314 Philosophy of Mind (excl. Cognition)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220314",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4334,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453202,
                                        "controlled_vocab": {
                                            "cvo_id": 453202,
                                            "cvo_title": "220315 Philosophy of Religion",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220315",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4335,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453203,
                                        "controlled_vocab": {
                                            "cvo_id": 453203,
                                            "cvo_title": "220316 Philosophy of Specific Cultures (incl. Comparative Philosophy)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220316",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4336,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453204,
                                        "controlled_vocab": {
                                            "cvo_id": 453204,
                                            "cvo_title": "220317 Poststructuralism",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220317",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4337,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453205,
                                        "controlled_vocab": {
                                            "cvo_id": 453205,
                                            "cvo_title": "220318 Psychoanalytic Philosophy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220318",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4338,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453206,
                                        "controlled_vocab": {
                                            "cvo_id": 453206,
                                            "cvo_title": "220319 Social Philosophy",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220319",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4339,
                                        "cvr_parent_cvo_id": 453187,
                                        "cvr_child_cvo_id": 453207,
                                        "controlled_vocab": {
                                            "cvo_id": 453207,
                                            "cvo_title": "220399 Philosophy not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220399",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4340,
                            "cvr_parent_cvo_id": 453165,
                            "cvr_child_cvo_id": 453208,
                            "controlled_vocab": {
                                "cvo_id": 453208,
                                "cvo_title": "2204 Religion and Religious Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2204",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4341,
                                        "cvr_parent_cvo_id": 453208,
                                        "cvr_child_cvo_id": 453209,
                                        "controlled_vocab": {
                                            "cvo_id": 453209,
                                            "cvo_title": "220401 Christian Studies (incl. Biblical Studies and Church History)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220401",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4342,
                                        "cvr_parent_cvo_id": 453208,
                                        "cvr_child_cvo_id": 453210,
                                        "controlled_vocab": {
                                            "cvo_id": 453210,
                                            "cvo_title": "220402 Comparative Religious Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220402",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4343,
                                        "cvr_parent_cvo_id": 453208,
                                        "cvr_child_cvo_id": 453211,
                                        "controlled_vocab": {
                                            "cvo_id": 453211,
                                            "cvo_title": "220403 Islamic Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220403",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4344,
                                        "cvr_parent_cvo_id": 453208,
                                        "cvr_child_cvo_id": 453212,
                                        "controlled_vocab": {
                                            "cvo_id": 453212,
                                            "cvo_title": "220404 Jewish Studies",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220404",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4345,
                                        "cvr_parent_cvo_id": 453208,
                                        "cvr_child_cvo_id": 453213,
                                        "controlled_vocab": {
                                            "cvo_id": 453213,
                                            "cvo_title": "220405 Religion and Society",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220405",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4346,
                                        "cvr_parent_cvo_id": 453208,
                                        "cvr_child_cvo_id": 453214,
                                        "controlled_vocab": {
                                            "cvo_id": 453214,
                                            "cvo_title": "220406 Studies in Eastern Religious Traditions",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220406",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4347,
                                        "cvr_parent_cvo_id": 453208,
                                        "cvr_child_cvo_id": 453215,
                                        "controlled_vocab": {
                                            "cvo_id": 453215,
                                            "cvo_title": "220407 Studies in Religious Traditions (excl. Eastern, Jewish, Christian and Islamic Traditions)",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220407",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    },
                                    {
                                        "cvr_id": 4348,
                                        "cvr_parent_cvo_id": 453208,
                                        "cvr_child_cvo_id": 453216,
                                        "controlled_vocab": {
                                            "cvo_id": 453216,
                                            "cvo_title": "220499 Religion and Religious Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "220499",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "cvr_id": 4349,
                            "cvr_parent_cvo_id": 453165,
                            "cvr_child_cvo_id": 453217,
                            "controlled_vocab": {
                                "cvo_id": 453217,
                                "cvo_title": "2299 Other Philosophy and Religious Studies",
                                "cvo_desc": "",
                                "cvo_image_filename": null,
                                "cvo_external_id": "2299",
                                "cvo_hide": 0,
                                "cvo_order": null,
                                "cvo_lat": null,
                                "cvo_long": null,
                                "cvo_policy": null,
                                "controlled_vocab_children": [
                                    {
                                        "cvr_id": 4350,
                                        "cvr_parent_cvo_id": 453217,
                                        "cvr_child_cvo_id": 453218,
                                        "controlled_vocab": {
                                            "cvo_id": 453218,
                                            "cvo_title": "229999 Philosophy and Religious Studies not elsewhere classified",
                                            "cvo_desc": "",
                                            "cvo_image_filename": null,
                                            "cvo_external_id": "229999",
                                            "cvo_hide": 0,
                                            "cvo_order": null,
                                            "cvo_lat": null,
                                            "cvo_long": null,
                                            "cvo_policy": null,
                                            "controlled_vocab_children": []
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ],
        "filters": []
    },
    [AIATSIS_CODES_VOCAB_ID]: {
        "total": 46,
        "data": [
            {
                "cvr_id": 4785,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453670,
                "controlled_vocab": {
                    "cvo_id": 453670,
                    "cvo_title": "Yukulta / Ganggalidda language G34",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "G34",
                    "cvo_hide": 0,
                    "cvo_order": 50,
                    "cvo_lat": "-17.341231808393",
                    "cvo_long": " 138.751317122600",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4786,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453671,
                "controlled_vocab": {
                    "cvo_id": 453671,
                    "cvo_title": "Garrwa language N155",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "N155",
                    "cvo_hide": 0,
                    "cvo_order": 10,
                    "cvo_lat": "-16.856777918351",
                    "cvo_long": " 137.273777445990",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4787,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453672,
                "controlled_vocab": {
                    "cvo_id": 453672,
                    "cvo_title": "Kukatj language G28",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "G28",
                    "cvo_hide": 0,
                    "cvo_order": 22,
                    "cvo_lat": "-18.132101457043",
                    "cvo_long": " 140.365115450410",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4788,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453673,
                "controlled_vocab": {
                    "cvo_id": 453673,
                    "cvo_title": "Gooreng Gooreng language E32",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "E32",
                    "cvo_hide": 0,
                    "cvo_order": 12,
                    "cvo_lat": "-24.904800693499",
                    "cvo_long": " 151.790315018140",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4789,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453674,
                "controlled_vocab": {
                    "cvo_id": 453674,
                    "cvo_title": "Guugu Yimidhirr language Y82",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y82",
                    "cvo_hide": 0,
                    "cvo_order": 17,
                    "cvo_lat": "-15.085217541197",
                    "cvo_long": " 144.942794609150",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4790,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453675,
                "controlled_vocab": {
                    "cvo_id": 453675,
                    "cvo_title": "Bidjara / Bidyara language E37",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "E37",
                    "cvo_hide": 0,
                    "cvo_order": 6,
                    "cvo_lat": "-24.903186585911",
                    "cvo_long": " 147.121306914220",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4791,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453676,
                "controlled_vocab": {
                    "cvo_id": 453676,
                    "cvo_title": "Gunggay language Y114",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y114",
                    "cvo_hide": 0,
                    "cvo_order": 16,
                    "cvo_lat": "-16.961763753403",
                    "cvo_long": " 145.914814557540",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4792,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453677,
                "controlled_vocab": {
                    "cvo_id": 453677,
                    "cvo_title": "Gunggari language D37",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "D37",
                    "cvo_hide": 0,
                    "cvo_order": 14,
                    "cvo_lat": "-27.368098481067",
                    "cvo_long": " 146.960212410130",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4793,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453678,
                "controlled_vocab": {
                    "cvo_id": 453678,
                    "cvo_title": "Kuku Buyunji language Y87",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y87",
                    "cvo_hide": 0,
                    "cvo_order": 24,
                    "cvo_lat": "-16.219343481309",
                    "cvo_long": " 145.342479943290",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4794,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453679,
                "controlled_vocab": {
                    "cvo_id": 453679,
                    "cvo_title": "Kuku-warra language Y80",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y80",
                    "cvo_hide": 0,
                    "cvo_order": 28,
                    "cvo_lat": "-15.094032406780",
                    "cvo_long": " 144.612261112310",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4795,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453680,
                "controlled_vocab": {
                    "cvo_id": 453680,
                    "cvo_title": "Kuku Nyungkul language Y90",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y90",
                    "cvo_hide": 0,
                    "cvo_order": 26,
                    "cvo_lat": "-15.750294371885",
                    "cvo_long": " 145.251321133450",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4796,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453681,
                "controlled_vocab": {
                    "cvo_id": 453681,
                    "cvo_title": "Kuku Yalanji language Y78",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y78",
                    "cvo_hide": 0,
                    "cvo_order": 30,
                    "cvo_lat": "-16.497447852508",
                    "cvo_long": " 144.629375153500",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4797,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453682,
                "controlled_vocab": {
                    "cvo_id": 453682,
                    "cvo_title": "Kurtjar language G33",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "G33",
                    "cvo_hide": 0,
                    "cvo_order": 32,
                    "cvo_lat": "-16.747297706702",
                    "cvo_long": " 141.417552365670",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4798,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453683,
                "controlled_vocab": {
                    "cvo_id": 453683,
                    "cvo_title": "Kala Lagaw Ya language Y1",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y1",
                    "cvo_hide": 0,
                    "cvo_order": 18,
                    "cvo_lat": "-10.111847445993",
                    "cvo_long": " 142.192889188600",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4799,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453684,
                "controlled_vocab": {
                    "cvo_id": 453684,
                    "cvo_title": "Wakka Wakka language E28",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "E28",
                    "cvo_hide": 0,
                    "cvo_order": 40,
                    "cvo_lat": "-26.451334117667",
                    "cvo_long": " 151.903028992920",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4800,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453685,
                "controlled_vocab": {
                    "cvo_id": 453685,
                    "cvo_title": "Waanyi language G23",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "G23",
                    "cvo_hide": 0,
                    "cvo_order": 38,
                    "cvo_lat": "-18.313241639387",
                    "cvo_long": " 137.620870640300",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4801,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453686,
                "controlled_vocab": {
                    "cvo_id": 453686,
                    "cvo_title": "Wik Mungkan language Y57",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y57",
                    "cvo_hide": 0,
                    "cvo_order": 42,
                    "cvo_lat": "-13.722681824321",
                    "cvo_long": " 142.326254994400",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4802,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453687,
                "controlled_vocab": {
                    "cvo_id": 453687,
                    "cvo_title": "Yanyuwa language N153",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "N153",
                    "cvo_hide": 0,
                    "cvo_order": 46,
                    "cvo_lat": "-15.802081068674",
                    "cvo_long": " 136.807861124000",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4803,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453688,
                "controlled_vocab": {
                    "cvo_id": 453688,
                    "cvo_title": "Yidinj language Y117",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y117",
                    "cvo_hide": 0,
                    "cvo_order": 48,
                    "cvo_lat": "-17.087085354442",
                    "cvo_long": " 145.765603342690",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4804,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453689,
                "controlled_vocab": {
                    "cvo_id": 453689,
                    "cvo_title": "Kuuk Thaayorre language Y69",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y69",
                    "cvo_hide": 0,
                    "cvo_order": 34,
                    "cvo_lat": "-14.909010990228",
                    "cvo_long": " 141.787014796640",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4805,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453690,
                "controlled_vocab": {
                    "cvo_id": 453690,
                    "cvo_title": "Uw Olkola language Y73",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y73",
                    "cvo_hide": 0,
                    "cvo_order": 36,
                    "cvo_lat": "-15.347937544471",
                    "cvo_long": " 142.698642780210",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 4813,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 453699,
                "controlled_vocab": {
                    "cvo_id": 453699,
                    "cvo_title": "Djabugay / Tjapukai language Y106",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y106",
                    "cvo_hide": 0,
                    "cvo_order": 8,
                    "cvo_lat": "-16.737168486512",
                    "cvo_long": " 145.508454269140",
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5186,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454058,
                "controlled_vocab": {
                    "cvo_id": 454058,
                    "cvo_title": "Uw Oykangand language Y101",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y101",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-15.599672237506",
                    "cvo_long": " 142.021218612650",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5187,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454059,
                "controlled_vocab": {
                    "cvo_id": 454059,
                    "cvo_title": "Mbarrumbathama language Y136",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y136",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-14.881623859293",
                    "cvo_long": " 143.834283278320",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5167,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454060,
                "controlled_vocab": {
                    "cvo_id": 454060,
                    "cvo_title": "Kuuku Ya'u language Y22",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y22",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-12.541741200854",
                    "cvo_long": " 143.033874013390",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5168,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454061,
                "controlled_vocab": {
                    "cvo_id": 454061,
                    "cvo_title": "Thaynakwith language Y24",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y24",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-12.558152841278",
                    "cvo_long": " 141.932427887080",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5169,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454062,
                "controlled_vocab": {
                    "cvo_id": 454062,
                    "cvo_title": "Meriam Mir language Y3",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y3",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-9.683089797572",
                    "cvo_long": " 143.775425112090",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5170,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454063,
                "controlled_vocab": {
                    "cvo_id": 454063,
                    "cvo_title": "Ngkoth language Y36",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y36",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-12.827602221663",
                    "cvo_long": " 142.052132197700",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5171,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454064,
                "controlled_vocab": {
                    "cvo_id": 454064,
                    "cvo_title": "Kaanju / Kaantju language Y44",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y44",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-13.398096294576",
                    "cvo_long": " 142.971983309830",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5172,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454065,
                "controlled_vocab": {
                    "cvo_id": 454065,
                    "cvo_title": "Umpila language Y45",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y45",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-13.644200870240",
                    "cvo_long": " 143.431435748010",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5173,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454066,
                "controlled_vocab": {
                    "cvo_id": 454066,
                    "cvo_title": "Umpithamu language Y50",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y50",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-14.035568345802",
                    "cvo_long": " 143.448854287500",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5174,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454067,
                "controlled_vocab": {
                    "cvo_id": 454067,
                    "cvo_title": "Morrobolam language Y55",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y55",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-14.251907119434",
                    "cvo_long": " 143.534959598100",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5175,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454068,
                "controlled_vocab": {
                    "cvo_id": 454068,
                    "cvo_title": "Ayapathu language Y60",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y60",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-14.418513875449",
                    "cvo_long": " 143.239924726740",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5176,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454069,
                "controlled_vocab": {
                    "cvo_id": 454069,
                    "cvo_title": "Pakanh language Y64",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y64",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-14.591437067426",
                    "cvo_long": " 142.347730117470",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5177,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454070,
                "controlled_vocab": {
                    "cvo_id": 454070,
                    "cvo_title": "Aghu Tharnggala language Y65",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y65",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-14.922659058108",
                    "cvo_long": " 144.135831238050",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5178,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454071,
                "controlled_vocab": {
                    "cvo_id": 454071,
                    "cvo_title": "Flinders Island language language Y67",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y67",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "",
                    "cvo_long": "",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5179,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454072,
                "controlled_vocab": {
                    "cvo_id": 454072,
                    "cvo_title": "Gugu Rarmul language Y71",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y71",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-14.767501438225",
                    "cvo_long": " 144.109683861120",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5180,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454073,
                "controlled_vocab": {
                    "cvo_id": 454073,
                    "cvo_title": "Yir Yoront language Y72",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y72",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-15.125737544726",
                    "cvo_long": " 141.712047029000",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5181,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454074,
                "controlled_vocab": {
                    "cvo_id": 454074,
                    "cvo_title": "Gugu Yawa language Y74",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y74",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-15.380189880207",
                    "cvo_long": " 144.031378882250",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5182,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454075,
                "controlled_vocab": {
                    "cvo_id": 454075,
                    "cvo_title": "Kunjen language Y83",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y83",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-15.723816026628",
                    "cvo_long": " 142.654334025280",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5183,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454076,
                "controlled_vocab": {
                    "cvo_id": 454076,
                    "cvo_title": "Kuku Thaypan language Y84",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y84",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-14.660317992231",
                    "cvo_long": " 143.914338863680",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5184,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454077,
                "controlled_vocab": {
                    "cvo_id": 454077,
                    "cvo_title": "Koko Bera language Y85",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y85",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-15.634918096202",
                    "cvo_long": " 141.620618557350",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5185,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454078,
                "controlled_vocab": {
                    "cvo_id": 454078,
                    "cvo_title": "Koko Dhawa language Y93",
                    "cvo_desc": null,
                    "cvo_image_filename": null,
                    "cvo_external_id": "Y93",
                    "cvo_hide": 1,
                    "cvo_order": null,
                    "cvo_lat": "-16.050900536399",
                    "cvo_long": " 141.806153256440",
                    "cvo_policy": null,
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5191,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454090,
                "controlled_vocab": {
                    "cvo_id": 454090,
                    "cvo_title": "Atampaya language Y183",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y183",
                    "cvo_hide": 0,
                    "cvo_order": 1,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5192,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454091,
                "controlled_vocab": {
                    "cvo_id": 454091,
                    "cvo_title": "Rimanggudinhma language Y195",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y195",
                    "cvo_hide": 0,
                    "cvo_order": 35,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            },
            {
                "cvr_id": 5193,
                "cvr_parent_cvo_id": 453669,
                "cvr_child_cvo_id": 454092,
                "controlled_vocab": {
                    "cvo_id": 454092,
                    "cvo_title": "Yangathimri language Y14",
                    "cvo_desc": "",
                    "cvo_image_filename": "",
                    "cvo_external_id": "Y14",
                    "cvo_hide": 0,
                    "cvo_order": 44,
                    "cvo_lat": null,
                    "cvo_long": null,
                    "cvo_policy": "",
                    "controlled_vocab_children": []
                }
            }
        ],
        "filters": []
    },
    [SUSTAINABLE_DEVELOPMENT_GOAL_VOCAB_ID]: {
        total: 17,
        data: [
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 456994,
                controlled_vocab: {
                    cvo_title: "01 - No poverty",
                    cvo_desc: "End poverty in all its forms everywhere.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 456994,
                            cvr_child_cvo_id: 457015,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456994,
                            cvr_child_cvo_id: 457031,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456994,
                            cvr_child_cvo_id: 457050,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 456995,
                controlled_vocab: {
                    cvo_title: "02 - Zero Hunger",
                    cvo_desc:
                        "End hunger, achieve food security and improved nutrition and promote sustainable agriculture.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 456995,
                            cvr_child_cvo_id: 457016,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456995,
                            cvr_child_cvo_id: 457032,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456995,
                            cvr_child_cvo_id: 457051,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 456996,
                controlled_vocab: {
                    cvo_title: "03 - Good health and well-being",
                    cvo_desc:
                        "Ensure healthy lives and promote well-being for all at all ages.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 456996,
                            cvr_child_cvo_id: 457017,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456996,
                            cvr_child_cvo_id: 457033,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456996,
                            cvr_child_cvo_id: 457052,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 456997,
                controlled_vocab: {
                    cvo_title: "04 - Quality education",
                    cvo_desc:
                        "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 456997,
                            cvr_child_cvo_id: 457018,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456997,
                            cvr_child_cvo_id: 457034,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456997,
                            cvr_child_cvo_id: 457053,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 456998,
                controlled_vocab: {
                    cvo_title: "05 - Gender Equality",
                    cvo_desc:
                        "Achieve gender equality and empower all women and girls.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 456998,
                            cvr_child_cvo_id: 457035,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456998,
                            cvr_child_cvo_id: 457048,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456998,
                            cvr_child_cvo_id: 457054,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 456999,
                controlled_vocab: {
                    cvo_title: "06 - Clean water and sanitation",
                    cvo_desc:
                        "Ensure availability and sustainable management of water and sanitation for all.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 456999,
                            cvr_child_cvo_id: 457019,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456999,
                            cvr_child_cvo_id: 457036,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 456999,
                            cvr_child_cvo_id: 457055,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457000,
                controlled_vocab: {
                    cvo_title: "07 - Affordable and clean energy",
                    cvo_desc:
                        "Ensure access to affordable, reliable, sustainable and modern energy for all.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457000,
                            cvr_child_cvo_id: 457020,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457000,
                            cvr_child_cvo_id: 457037,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457000,
                            cvr_child_cvo_id: 457056,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457001,
                controlled_vocab: {
                    cvo_title: "08 - Decent work and economic growth",
                    cvo_desc:
                        "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457001,
                            cvr_child_cvo_id: 457021,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457001,
                            cvr_child_cvo_id: 457038,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457001,
                            cvr_child_cvo_id: 457057,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457002,
                controlled_vocab: {
                    cvo_title: "09 - Industry, innovation and infrastructure",
                    cvo_desc:
                        "Build resilient infrastructure, promote inclusive and sustainable industrialisation and foster innovation.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457002,
                            cvr_child_cvo_id: 457022,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457002,
                            cvr_child_cvo_id: 457039,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457002,
                            cvr_child_cvo_id: 457058,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457003,
                controlled_vocab: {
                    cvo_title: "10 - Reduced inequalities",
                    cvo_desc: "Reduce inequality within and among countries.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457003,
                            cvr_child_cvo_id: 457023,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457003,
                            cvr_child_cvo_id: 457040,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457003,
                            cvr_child_cvo_id: 457059,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457004,
                controlled_vocab: {
                    cvo_title: "11 - Sustainable cities and communities",
                    cvo_desc:
                        "Make cities and human settlements inclusive, safe, resilient and sustainable.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457004,
                            cvr_child_cvo_id: 457024,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457004,
                            cvr_child_cvo_id: 457041,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457004,
                            cvr_child_cvo_id: 457060,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457005,
                controlled_vocab: {
                    cvo_title: "12 - Responsible consumption and production",
                    cvo_desc:
                        "Ensure sustainable consumption and production patterns.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457005,
                            cvr_child_cvo_id: 457025,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457005,
                            cvr_child_cvo_id: 457042,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457005,
                            cvr_child_cvo_id: 457061,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457006,
                controlled_vocab: {
                    cvo_title: "13 - Climate action",
                    cvo_desc:
                        "Take urgent action to combat climate change and its impacts.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457006,
                            cvr_child_cvo_id: 457026,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457006,
                            cvr_child_cvo_id: 457043,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457006,
                            cvr_child_cvo_id: 457062,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457007,
                controlled_vocab: {
                    cvo_title: "14 - Life Below Water",
                    cvo_desc:
                        "Conserve and sustainably use the oceans, seas and marine resources for sustainable development.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457007,
                            cvr_child_cvo_id: 457027,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457007,
                            cvr_child_cvo_id: 457044,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457007,
                            cvr_child_cvo_id: 457063,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457008,
                controlled_vocab: {
                    cvo_title: "15 - Life on land",
                    cvo_desc:
                        "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457008,
                            cvr_child_cvo_id: 457028,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457008,
                            cvr_child_cvo_id: 457045,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457008,
                            cvr_child_cvo_id: 457064,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457009,
                controlled_vocab: {
                    cvo_title: "16 - Peace, justice and strong institutions",
                    cvo_desc:
                        "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457009,
                            cvr_child_cvo_id: 457029,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457009,
                            cvr_child_cvo_id: 457046,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457009,
                            cvr_child_cvo_id: 457065,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
            {
                cvr_parent_cvo_id: 456993,
                cvr_child_cvo_id: 457010,
                controlled_vocab: {
                    cvo_title: "17 - Partnerships for the goals",
                    cvo_desc:
                        "Strengthen the means of implementation and revitalise the Global Partnership for Sustainable Development.",
                    cvo_hide: 0,
                    controlled_vocab_children: [
                        {
                            cvr_parent_cvo_id: 457010,
                            cvr_child_cvo_id: 457030,
                            controlled_vocab: {
                                cvo_title: "Dimensions",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457010,
                            cvr_child_cvo_id: 457047,
                            controlled_vocab: {
                                cvo_title: "WOS",
                            },
                        },
                        {
                            cvr_parent_cvo_id: 457010,
                            cvr_child_cvo_id: 457066,
                            controlled_vocab: {
                                cvo_title: "Elsevier",
                            },
                        },
                    ],
                },
            },
        ],
        filters: [],
    },
};