import { hydrateMock } from '../../../helpers/general';

const incompleteNTRORecordUQ352045 = {
    'rek_pid': 'UQ:352045',
    'rek_title': 'Il Sogno: Topology and the Brodsky Quartet play Elvis Costello',
    'rek_display_type': 313,
    'rek_display_type_lookup': 'Creative Work',
    'rek_date': '2013-07-18T00:00:00Z',
    'rek_depositor': 8058,
    'rek_created_date': '2015-02-20T14:26:25Z',
    'rek_updated_date': '2019-02-16T01:44:50Z',
    'rek_formatted_title': null,
    'rek_formatted_abstract': 'Curated and directed by Robert Davidson, this concert is a collaboration by London\'s Brodsky Quartet and the Australian quintet Topology, focusing on Davidson\'s rescoring of Elvis Costello\'s ballet "Il Sogno " and arrangements of Elvis Costello songs, along with new works by Robert Davidson, John Babbage, Andrew Ford and Bernard Hoey.<br \/><br \/> The collaboration was intensive and thoroughgoing, and was used as the basis of a study, published in Davidson, "Collaborating across musical style boundaries " in Barrett (ed), Collaborative Creative Thought and Practice in Music, Ashgate, 2014.<br \/>',
    'rek_description': 'description',
    'rek_depositor_affiliation': 826,
    'rek_subtype': 'Live Performance of Creative Work - Music',
    'fez_record_search_key_author': [
        {
            'rek_author': 'Topology',
            'rek_author_order': 1,
        },
        {
            'rek_author': 'Davidson, Robert',
           'rek_author_order': 2,
        },
        {
            'rek_author': 'Babbage, John',
            'rek_author_order': 3,
        },
        {
            'rek_author': 'The Brodsky Quartet',
            'rek_author_order': 4,
        },
    ],
    'fez_record_search_key_author_id': [
        {
            'rek_author_id': 0,
            'rek_author_id_order': 1,
        },
        {
            'rek_author_id': 79324,
            'rek_author_id_lookup': 'Robert Davidson',
            'rek_author_id_order': 2,
        },
        {
            'rek_author_id': 78691,
            'rek_author_id_lookup': 'John Babbage',
            'rek_author_id_order': 3,
        },
        {
            'rek_author_id': 0,
            'rek_author_id_order': 4,
        },
    ],
    'fez_record_search_key_file_attachment_name': [
        'ERAUQ352045_il_Sogno.mp3',
        'ERAUQ352045_NewLaceSleeves.mp3',
        'ERAUQ352045_ThreeMenAndABlonde.mp3',
        'ERAUQ352045_VagueMemories.mp3',
        'FezACML_ERAUQ352045_il_Sogno.mp3.xml',
        'FezACML_ERAUQ352045_NewLaceSleeves.mp3.xml',
        'FezACML_ERAUQ352045_ThreeMenAndABlonde.mp3.xml',
        'FezACML_ERAUQ352045_VagueMemories.mp3.xml',
        'FezACML_HCA15UQ352045_press.pdf.xml',
        'FezACML_UQ_352045.xml',
        'HCA15UQ352045_press.pdf',
        'presmd_HCA15UQ352045_press.xml',
        'stream_ERAUQ352045_il_Sogno.flv',
        'stream_ERAUQ352045_NewLaceSleeves.flv',
        'stream_ERAUQ352045_ThreeMenAndABlonde.flv',
        'stream_ERAUQ352045_VagueMemories.flv',
    ],
    'fez_record_search_key_language': ['eng'],
    'fez_record_search_key_series': 'Queensland Music Festival',
    'fez_record_search_key_total_pages': '1 musical performance',
    'fez_record_search_key_quality_indicator': null, // needed for code coverage on MyIncompleteRecordContainer
    'fez_datastream_info': [{
        'dsi_pid': 'UQ:352045',
        'dsi_dsid': 'ERAUQ352045_il_Sogno.mp3',
        'dsi_embargo_date': null,
        'dsi_open_access': null,
        'dsi_label': 'Full-text - not publicly available',
        'dsi_mimetype': 'audio\/mpeg',
        'dsi_copyright': null,
        'dsi_state': 'A',
        'dsi_size': 21457982,
    },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'ERAUQ352045_InterimFile.mp3',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'Full-text - not publicly available',
            'dsi_mimetype': 'audio\/mpeg',
            'dsi_copyright': null,
            'dsi_state': 'D',
            'dsi_size': 22801248,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'ERAUQ352045_NewLaceSleeves.mp3',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'Full-text - not publicly available',
            'dsi_mimetype': 'audio\/mpeg',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 8555060,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'ERAUQ352045_ThreeMenAndABlonde.mp3',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'Full-text - not publicly available',
            'dsi_mimetype': 'audio\/mpeg',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 9433819,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'ERAUQ352045_VagueMemories.mp3',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'Full-text - not publicly available',
            'dsi_mimetype': 'audio\/mpeg',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 21279891,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'FezACML_ERAUQ352045_il_Sogno.mp3.xml',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'FezACML security for datastream - ERAUQ352045_il_Sogno.mp3',
            'dsi_mimetype': 'text\/xml',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 3261,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'FezACML_ERAUQ352045_InterimFile.mp3.xml',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'FezACML security for datastream - ERAUQ352045_InterimFile.mp3',
            'dsi_mimetype': 'text\/xml',
            'dsi_copyright': null,
            'dsi_state': 'D',
            'dsi_size': 3261,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'FezACML_ERAUQ352045_NewLaceSleeves.mp3.xml',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'FezACML security for datastream - ERAUQ352045_NewLaceSleeves.mp3',
            'dsi_mimetype': 'text\/xml',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 3261,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'FezACML_ERAUQ352045_ThreeMenAndABlonde.mp3.xml',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'FezACML security for datastream - ERAUQ352045_ThreeMenAndABlonde.mp3',
            'dsi_mimetype': 'text\/xml',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 3261,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'FezACML_ERAUQ352045_VagueMemories.mp3.xml',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'FezACML security for datastream - ERAUQ352045_VagueMemories.mp3',
            'dsi_mimetype': 'text\/xml',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 3261,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'FezACML_HCA15UQ352045_press.pdf.xml',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'FezACML security for datastream - HCA15UQ352045_press.pdf',
            'dsi_mimetype': 'text\/xml',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 3097,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'FezACML_UQ_352045.xml',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'FezACML security for PID - UQ:352045',
            'dsi_mimetype': 'text\/xml',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 3705,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'HCA15UQ352045_press.pdf',
            'dsi_embargo_date': null,
            'dsi_open_access': 1,
            'dsi_label': 'Full text - not publicly available',
            'dsi_mimetype': 'application\/pdf',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 90504,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'presmd_HCA15UQ352045_press.xml',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': '',
            'dsi_mimetype': 'application\/xml',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 1268,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'stream_ERAUQ352045_il_Sogno.flv',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'stream_ERAUQ352045_il_Sogno.flv',
            'dsi_mimetype': 'video\/x-flv',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 17822670,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'stream_ERAUQ352045_InterimFile.flv',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'stream_ERAUQ352045_InterimFile.flv',
            'dsi_mimetype': 'video\/x-flv',
            'dsi_copyright': null,
            'dsi_state': 'D',
            'dsi_size': 31565290,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'stream_ERAUQ352045_NewLaceSleeves.flv',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'stream_ERAUQ352045_NewLaceSleeves.flv',
            'dsi_mimetype': 'video\/x-flv',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 7105180,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'stream_ERAUQ352045_ThreeMenAndABlonde.flv',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'stream_ERAUQ352045_ThreeMenAndABlonde.flv',
            'dsi_mimetype': 'video\/x-flv',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 7835100,
        },
        {
            'dsi_pid': 'UQ:352045',
            'dsi_dsid': 'stream_ERAUQ352045_VagueMemories.flv',
            'dsi_embargo_date': null,
            'dsi_open_access': null,
            'dsi_label': 'stream_ERAUQ352045_VagueMemories.flv',
            'dsi_mimetype': 'video\/x-flv',
            'dsi_copyright': null,
            'dsi_state': 'A',
            'dsi_size': 14727673,
        },
    ],
};
export default hydrateMock(incompleteNTRORecordUQ352045);
