import { hydrateMockSearchList } from '../../../helpers/general';
import { sdgSKs } from '../testing/records';

const publicationTypeListJournalArticle = {
    'total': 2,
    'took': 1,
    'per_page': 999,
    'current_page': 1,
    'from': 1,
    'to': 2,
    'data': [
        {
            'rek_pid': 'UQ:288291',
            'rek_title': 'Moral distress in nursing: contributing factors, outcomes and interventions',
            'rek_description': 'Moral distress has been widely reviewed across many care contexts and among a range of disciplines. Interest in this area has produced a plethora of studies, commentary and critique. An overview of the literature around moral distress reveals a commonality about factors contributing to moral distress, the attendant outcomes of this distress and a core set of interventions recommended to address these. Interventions at both personal and organizational levels have been proposed. The relevance of this overview resides in the implications moral distress has on the nurse and the nursing workforce: particularly in regard to quality of care, diminished workplace satisfaction and physical health of staff and increased problems with staff retention.',
            'rek_display_type': 179,
            'rek_status': 2,
            'rek_date': '2013-05-01T00:00:00Z',
            'rek_object_type': 3,
            'rek_depositor': 891,
            'rek_created_date': '2013-01-07T09:44:08Z',
            'rek_updated_date': '2019-07-13T10:17:30Z',
            'rek_genre': 'Journal Article',
            'rek_genre_type': 'Critical review of research, literature review, critical commentary',
            'rek_depositor_affiliation': 1031,
            'rek_thomson_citation_count': 75,
            'rek_subtype': 'Article (original research)',
            'rek_scopus_citation_count': 92,
            'rek_scopus_doc_type': 're',
            'rek_wok_doc_type': '@',
            'rek_pubmed_doc_type': 'Review',
            'rek_security_inherited': 1,
            'rek_altmetric_score': 1,
            'rek_altmetric_id': 10528685,
            'rek_copyright': 'on',
            'rek_security_policy': 1,
            'fez_record_search_key_author': ['Burston, Adam S.', 'Tuckett, Anthony G.'],
            "fez_author_affiliation": [
                {
                    "af_id": 478894,
                    "af_pid": "UQ:288291",
                    "af_author_id": 80316,
                    "af_percent_affiliation": 50000,
                    "af_org_id": 881,
                    "af_status": 1,
                    "fez_author": {
                        "aut_id": 80316,
                        "aut_display_name": "Adam Burston"
                    },
                    "fez_org_structure": 
                        {
                            "org_id": 881,
                            "org_title": "School of Chemistry and Molecular Biosciences"
                        }
                    ,
                },
                {
                    "af_id": 478895,
                    "af_pid": "UQ:288291",
                    "af_author_id": 80316,
                    "af_percent_affiliation": 40000,
                    "af_org_id": 968,
                    "af_status": 1,
                    "fez_author": {
                        "aut_id": 80316,
                        "aut_display_name": "Adam Burston"
                    },
                    "fez_org_structure": 
                        {
                            "org_id": 968,
                            "org_title": "Institute for Molecular Bioscience"
                        }
                    
                },
            ],
            'fez_record_search_key_author_id': [
                {
                    'rek_author_id': 80316,
                    'rek_author_id_lookup': 'Adam Burston',
                    'author': {
                        'aut_org_username': 'uqaburs',
                        'aut_student_username': null,
                        'aut_id': 80316,
                        'aut_orcid_id': '0000-0002-6051-4253',
                        'aut_people_australia_id': null,
                        'aut_title': 'Dr',
                    }
                },
                {
                    'rek_author_id': 3223,
                    'rek_author_id_lookup': 'Tuckett, Anthony G.',
                    'author': {
                        'aut_org_username': 'uqatuck',
                        'aut_student_username': null,
                        'aut_id': 3223,
                        'aut_orcid_id': '0000-0002-6051-4252',
                        'aut_people_australia_id': null,
                        'aut_title': 'Dr',
                    }
                },
            ],
            'fez_record_search_key_corresponding_country': ['aus'],
            'fez_record_search_key_corresponding_email': ['a.burston@uq.edu.au'],
            'fez_record_search_key_corresponding_name': ['Burston A. S.'],
            'fez_record_search_key_date_available': '2012-01-01T00:00:00Z',
            'fez_record_search_key_doi': {
                'rek_doi': '10.1177/0969733012462049',
                'fez_altmetric': {
                    'as_id': 55921,
                    'as_amid': 10528685,
                    'as_doi': '10.1177/0969733012462049',
                    'as_score': 1,
                    'as_created': '1471897271',
                    'as_last_checked': '1563733738',
                    'as_1d': 0,
                    'as_2d': 0,
                    'as_3d': 0,
                    'as_4d': 0,
                    'as_5d': 0,
                    'as_6d': 0,
                    'as_1w': 0,
                    'as_1m': 0,
                    'as_3m': 0,
                    'as_6m': 0,
                    'as_1y': 0,
                    'as_total_posts_count': 1,
                    'as_facebook_posts_count': 0,
                    'as_policy_posts_count': 0,
                    'as_blogs_posts_count': 0,
                    'as_googleplus_posts_count': 0,
                    'as_news_posts_count': 0,
                    'as_reddit_posts_count': 0,
                    'as_twitter_posts_count': 1,
                    'as_syllabi_posts_count': 0,
                    'as_video_posts_count': 0,
                    'as_weibo_posts_count': 0,
                    'as_qa_posts_count': 0,
                    'as_f1000_posts_count': 0,
                    'as_wikipedia_posts_count': 0,
                    'as_pinterest_posts_count': 0,
                    'as_linkedin_posts_count': 0,
                    'as_peer_reviews_posts_count': 0,
                    'as_citation_url': 'http://www.altmetric.com/details.php?citation_id=10528685',
                },
            },
            'fez_record_search_key_end_page': '324',
            'fez_record_search_key_herdc_code': {
                'rek_herdc_code': 450009,
                'rek_herdc_code_lookup': 'C1',
            },
            'fez_record_search_key_herdc_status': {
                'rek_herdc_status': 453221,
                'rek_herdc_status_lookup': 'Confirmed Code',
            },
            'fez_record_search_key_institutional_status': {
                'rek_institutional_status': 453223,
                'rek_institutional_status_lookup': 'UQ',
            },
            'fez_record_search_key_isi_loc': {
                'rek_isi_loc': '000341633900007',
                'fez_thomson_citations': null,
            },
            'fez_record_search_key_ismemberof': [
                {
                    'rek_ismemberof': 'UQ:3835',
                    'parent': {
                        'rek_pid': 'UQ:3835',
                        'rek_security_policy': 5,
                        'rek_datastream_policy': 5,
                    },
                    'rek_ismemberof_lookup': 'School of Nursing, Midwifery and Social Work Publications',
                },
                {
                    'rek_ismemberof': 'UQ:247259',
                    'parent': {
                        'rek_pid': 'UQ:247259',
                        'rek_security_policy': 5,
                        'rek_datastream_policy': 5,
                    },
                    'rek_ismemberof_lookup': 'Official 2013 Collection',
                },
            ],
            'fez_record_search_key_issn': [
                {
                    'rek_issn': '0969-7330',
                    'fez_journal_issns': [
                        {
                            'jni_id': 3535,
                            'jni_jnl_id': 2236,
                            'jni_issn': '0969-7330',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 2236,
                                'jnl_journal_name': 'Nursing Ethics: an international journal for health care professionals',
                                'jnl_era_id': 13726,
                                'jnl_era_year': 2010,
                                'jnl_created_date': '2010-11-19 00:00:00',
                                'jnl_updated_date': '2010-11-19 00:00:00',
                                'jnl_rank': 'B',
                                'jnl_foreign_name': null,
                            },
                        },
                        {
                            'jni_id': 48931,
                            'jni_jnl_id': 29670,
                            'jni_issn': '0969-7330',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 29670,
                                'jnl_journal_name': 'Nursing Ethics: an international journal for health care professionals',
                                'jnl_era_id': 13726,
                                'jnl_era_year': 2012,
                                'jnl_created_date': '2011-10-28 00:00:00',
                                'jnl_updated_date': '2011-10-28 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 76524,
                            'jni_jnl_id': 46937,
                            'jni_issn': '0969-7330',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 46937,
                                'jnl_journal_name': 'Nursing Ethics: an international journal for health care professionals',
                                'jnl_era_id': 13726,
                                'jnl_era_year': 2015,
                                'jnl_created_date': '2014-07-16 00:00:00',
                                'jnl_updated_date': '2014-07-16 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 164337,
                            'jni_jnl_id': 99890,
                            'jni_issn': '0969-7330',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 99890,
                                'jnl_journal_name': 'Nursing Ethics: an international journal for health care professionals',
                                'jnl_era_id': 13726,
                                'jnl_era_year': 2018,
                                'jnl_created_date': '2017-09-06 00:00:00',
                                'jnl_updated_date': '2017-09-06 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                    ],
                    'fez_sherpa_romeo': {
                        'srm_id': 4231,
                        'srm_issn': '0969-7330',
                        'srm_journal_name': 'Nursing Ethics',
                        'srm_journal_link': 'http://example.com'
                    },
                    'fez_ulrichs': {
                        'ulr_issn': '0969-7330',
                        'ulr_title_id': '233801',
                        'ulr_title': 'Nursing Ethics',
                    },
                    'rek_issn_lookup': '',
                },
                {
                    'rek_issn': '1477-0989',
                    'fez_journal_issns': [
                        {
                            'jni_id': 3536,
                            'jni_jnl_id': 2236,
                            'jni_issn': '1477-0989',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 2236,
                                'jnl_journal_name': 'Nursing Ethics: an international journal for health care professionals',
                                'jnl_era_id': 13726,
                                'jnl_era_year': 2010,
                                'jnl_created_date': '2010-11-19 00:00:00',
                                'jnl_updated_date': '2010-11-19 00:00:00',
                                'jnl_rank': 'B',
                                'jnl_foreign_name': null,
                            },
                        },
                        {
                            'jni_id': 48932,
                            'jni_jnl_id': 29670,
                            'jni_issn': '1477-0989',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 29670,
                                'jnl_journal_name': 'Nursing Ethics: an international journal for health care professionals',
                                'jnl_era_id': 13726,
                                'jnl_era_year': 2012,
                                'jnl_created_date': '2011-10-28 00:00:00',
                                'jnl_updated_date': '2011-10-28 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 76525,
                            'jni_jnl_id': 46937,
                            'jni_issn': '1477-0989',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 46937,
                                'jnl_journal_name': 'Nursing Ethics: an international journal for health care professionals',
                                'jnl_era_id': 13726,
                                'jnl_era_year': 2015,
                                'jnl_created_date': '2014-07-16 00:00:00',
                                'jnl_updated_date': '2014-07-16 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 164338,
                            'jni_jnl_id': 99890,
                            'jni_issn': '1477-0989',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 99890,
                                'jnl_journal_name': 'Nursing Ethics: an international journal for health care professionals',
                                'jnl_era_id': 13726,
                                'jnl_era_year': 2018,
                                'jnl_created_date': '2017-09-06 00:00:00',
                                'jnl_updated_date': '2017-09-06 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                    ],
                    'fez_sherpa_romeo': {
                        'srm_id': 4232,
                        'srm_issn': '1477-0989',
                        'srm_journal_name': 'Nursing Ethics',
                        'srm_journal_link': 'http://example.com',
                    },
                    'fez_ulrichs': {
                        'ulr_issn': '1477-0989',
                        'ulr_title_id': '233802',
                        'ulr_title': 'Nursing Ethics',
                    },
                    'rek_issn_lookup': '',
                },
            ],
            'fez_record_search_key_issue_number': '3',
            'fez_record_search_key_journal_name': 'Nursing Ethics',
            'fez_record_search_key_keywords': ['Literature review', 'Moral distress', 'Nursing', 'Retention', 'Workforce'],
            'fez_record_search_key_language': ['eng'],
            'fez_record_search_key_notes': 'Published online before print: 28 December 2012.',
            'fez_record_search_key_oa_status': {
                'rek_oa_status': 453696,
                'rek_oa_status_lookup': 'File (Author Post-print)',
            },
            'fez_record_search_key_oa_status_type': {
                'rek_oa_status_type': 454120,
                'rek_oa_status_type_lookup': 'Green',
            },
            'fez_record_search_key_place_of_publication': 'London, United Kingdom',
            'fez_record_search_key_publisher': 'Sage',
            'fez_record_search_key_pubmed_id': '23275458',
            'fez_record_search_key_pubmed_central_id': 'PMC3518562',
            'fez_record_search_key_refereed': 1,
            'fez_record_search_key_refereed_source': {
                'rek_refereed_source': '453635',
                'rek_refereed_source_lookup': 'Ulrichs',
            },
            'fez_record_search_key_scopus_id': {
                'rek_scopus_id': '2-s2.0-84876981072',
                'fez_scopus_citations': null,
            },
            'fez_record_search_key_start_page': '312',
            'fez_record_search_key_subject': [
                {
                    'rek_subject': 453486,
                    'rek_subject_lookup': '2910 Issues, ethics and legal aspects',
                },
            ],
            ...sdgSKs,
            'fez_record_search_key_total_pages': '13',
            'fez_record_search_key_volume_number': '20',
            'fez_datastream_info': [
                {
                    'dsi_pid': 'UQ:288291',
                    'dsi_dsid': 'FezACML_UQ_288291.xml',
                    'dsi_embargo_date': null,
                    'dsi_open_access': null,
                    'dsi_label': 'FezACML security for PID - UQ:288291',
                    'dsi_mimetype': 'text/xml',
                    'dsi_copyright': null,
                    'dsi_state': 'A',
                    'dsi_size': 3705,
                    'dsi_security_policy': 1,
                    'dsi_security_inherited': 0,
                },
            ],
            'fez_matched_journals': {
                "mtj_pid": "UQ:288291",
                "mtj_jnl_id": 10412,
                "mtj_status": "A",
                "fez_journal": {
                    "jnl_jid": 10412,
                    "jnl_title": "Musculoskeletal Science & Practice",
                    "jnl_publisher": "Elsevier Ltd",
                    "jnl_created_date": "2020-11-16 07:55:32",
                    "jnl_updated_date": "2021-03-02 23:37:12",
                    "jnl_era_source_id": "16579",
                    "fez_journal_era": [
                        {
                            "jnl_era_id": 75673,
                            "jnl_era_source_id": "16579",
                            "jnl_era_source_year": 2010,
                            "jnl_era_title": ""
                        },
                        {
                            "jnl_era_id": 59748,
                            "jnl_era_source_id": "16579",
                            "jnl_era_source_year": 2012,
                            "jnl_era_title": ""
                        },
                        {
                            "jnl_era_id": 30791,
                            "jnl_era_source_id": "16579",
                            "jnl_era_source_year": 2015,
                            "jnl_era_title": ""
                        },
                        {
                            "jnl_era_id": 10412,
                            "jnl_era_source_id": "16579",
                            "jnl_era_source_year": 2018,
                            "jnl_era_title": "Manual Therapy"
                        }
                    ]
                }
            },
            'rek_display_type_lookup': 'Journal Article',
            'rek_object_type_lookup': 'Record',
            'rek_status_lookup': 'Published',
            'fez_internal_notes': {
                'ain_id': 42684,
                'ain_pid': 'UQ:288291',
                'ain_detail': 'Not yet indexed in Scopus/ISI 3/5/13',
            },
            'fez_record_search_key_author_affiliation_country': ['Australia', 'Australia'],
            'fez_record_search_key_author_affiliation_full_address': [
                'Univ Queensland, St Lucia, Qld 4305, Australia',
                'Univ Queensland, St Lucia, Qld 4305, Australia',
            ],
            'fez_record_search_key_author_affiliation_name': [
                'Univ Queensland',
                'Univ Queensland',
            ],
            'fez_record_search_key_security_policy': [5],
        },
        {
            'rek_pid': 'UQ:696826',
            'rek_title': 'In vivo bone strain and finite element modeling of a rhesus macaque mandible during mastication',
            'rek_description': 'Finite element analysis (FEA) is a commonly used tool in musculoskeletal biomechanics and vertebrate paleontology. The accuracy and precision of finite element models (FEMs) are reliant on accurate data on bone geometry, muscle forces, boundary conditions and tissue material properties. Simplified modeling assumptions, due to lack of in vivo experimental data on material properties and muscle activation patterns, may introduce analytical errors in analyses where quantitative accuracy is critical for obtaining rigorous results. A subject-specific FEM of a rhesus macaque mandible was constructed, loaded and validated using in vivo data from the same animal. In developing the model, we assessed the impact on model behavior of variation in (i) material properties of the mandibular trabecular bone tissue and teeth; (ii) constraints at the temporomandibular joint and bite point; and (iii) the timing of the muscle activity used to estimate the external forces acting on the model. The best match between the FEA simulation and the in vivo experimental data resulted from modeling the trabecular tissue with an isotropic and homogeneous Young\'s modulus and Poisson\'s value of 10GPa and 0.3, respectively; constraining translations along X,Y, Z axes in the chewing (left) side temporomandibular joint, the premolars and the m1; constraining the balancing (right) side temporomandibular joint in the anterior-posterior and superior-inferior axes, and using the muscle force estimated at time of maximum strain magnitude in the lower lateral gauge. The relative strain magnitudes in this model were similar to those recorded in vivo for all strain locations. More detailed analyses of mandibular strain patterns during the power stroke at different times in the chewing cycle are needed.',
            'rek_display_type': 179,
            'rek_status': 2,
            'rek_date': '2017-09-01T00:00:00Z',
            'rek_object_type': 3,
            'rek_created_date': '2017-11-15T02:12:54Z',
            'rek_updated_date': '2019-06-14T01:49:45Z',
            'rek_genre': 'Journal Article',
            'rek_genre_type': 'Article (original research)',
            'rek_depositor_affiliation': 878,
            'rek_thomson_citation_count': 2,
            'rek_subtype': 'Article (original research)',
            'rek_scopus_citation_count': 4,
            'rek_scopus_doc_type': 'ar',
            'rek_wok_doc_type': '@',
            'rek_pubmed_doc_type': 'Journal Article',
            'rek_security_inherited': 1,
            'rek_altmetric_score': 5,
            'rek_altmetric_id': 27814093,
            'rek_copyright': 'on',
            'rek_security_policy': 1,
            'fez_record_search_key_author': [
                'Panagiotopoulou, Olga',
                'Iriarte-Diaz, José',
                'Wilshin, Simon',
                'Dechow, Paul C.',
                'Taylor, Andrea B.',
                'Mehari Abraha, Hyab',
                'Aljunid, Sharifah F.',
                'Ross, Callum F.',
            ],
            'fez_record_search_key_author_id': [
                {
                    'rek_author_id': 97333,
                    'rek_author_id_lookup': 'Olga Panagiotopoulou',
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 2710438,
                    'rek_author_id_lookup': 'Mehari Abraha, Hyab',
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 0,
                },
            ],
            'fez_record_search_key_corresponding_country': ['usa'],
            'fez_record_search_key_corresponding_email': ['rossc@uchicago.edu'],
            'fez_record_search_key_corresponding_name': ['Ross C.F.'],
            'fez_record_search_key_corresponding_organisation': [
                'Univ Queensland',
                'Monash Univ',
                'Univ Illinois',
                'Royal Vet Coll',
                'Texas A&M Univ',
                'Touro Univ',
                'Menara OBYU',
                'Univ Chicago',
            ],
            'fez_record_search_key_date_available': '2017-01-01T00:00:00Z',
            'fez_record_search_key_doi': {
                'rek_doi': '10.1016/j.zool.2017.08.010',
                'fez_altmetric': {
                    'as_id': 86518,
                    'as_amid': 27814093,
                    'as_doi': '10.1016/j.zool.2017.08.010',
                    'as_score': 5,
                    'as_created': '1510835743',
                    'as_last_checked': '1563608853',
                    'as_1d': 0,
                    'as_2d': 0,
                    'as_3d': 0,
                    'as_4d': 0,
                    'as_5d': 0,
                    'as_6d': 0,
                    'as_1w': 0,
                    'as_1m': 0,
                    'as_3m': 0,
                    'as_6m': 0,
                    'as_1y': 0,
                    'as_total_posts_count': 7,
                    'as_facebook_posts_count': 1,
                    'as_policy_posts_count': 0,
                    'as_blogs_posts_count': 0,
                    'as_googleplus_posts_count': 0,
                    'as_news_posts_count': 0,
                    'as_reddit_posts_count': 0,
                    'as_twitter_posts_count': 6,
                    'as_syllabi_posts_count': 0,
                    'as_video_posts_count': 0,
                    'as_weibo_posts_count': 0,
                    'as_qa_posts_count': 0,
                    'as_f1000_posts_count': 0,
                    'as_wikipedia_posts_count': 0,
                    'as_pinterest_posts_count': 0,
                    'as_linkedin_posts_count': 0,
                    'as_peer_reviews_posts_count': 0,
                    'as_citation_url': 'http://www.altmetric.com/details.php?citation_id=27814093',
                },
            },
            'fez_record_search_key_end_page': '29',
            'fez_record_search_key_file_attachment_name': ['FezACML_UQ696826_OA.pdf.xml', 'UQ696826_OA.pdf'],
            'fez_record_search_key_grant_acronym': ['BRF', 'NSF', 'CIHR', 'NIH', 'NIH'],
            'fez_record_search_key_grant_agency': [
                'Brain Research Foundation',
                'National Science Foundation',
                'Canadian Institutes of Health Research',
                'National Institutes of Health',
                'National Institutes of Health',
            ],
            'fez_record_search_key_grant_agency_id': [
                '10.13039/501100000024',
                '10.13039/100000002',
                '10.13039/100000882',
                '10.13039/100000001',
            ],
            'fez_record_search_key_grant_id': [
                'MOP-4918',
                'RO1DE023816',
                null,
                'BCS0962677',
                'ERG-MACACA 267207',
                '606441',
                'R24 HD050837-01',
            ],
            'fez_record_search_key_grant_text': [
                'We thank Dr. Vivian Allen for technical support with the EMG data analysis; Dr. Leslie Pryor for technical assistance during material testing; and the staff of the University of Chicago Animal Resources Center for their assistance with animal care and procedures. We also are grateful to Prof. Ian Grosse for useful discussions and our two anonymous reviewers for their feedback. The in vivo experimental analysis was supported by CIHR Grant MOP-4918, the National Institutes of Health (NIH RO1DE023816), and a grant from the Brain Research Foundation to C.F.R. The ex vivo material properties testing and all simulation analyses were funded by a Marie Curie European Re-integration Grant (ERG-MACACA 267207) to O.P. Funding for the purchase and maintenance of the FEA and virtual reconstruction software was also secured via a start-up grant (606441) from the School of Biomedical Sciences, The University of Queensland to O.P. Funding for jaw muscle fiber architecture was via grants from the National Science Foundation (BCS0962677) and an NIH (R24 HD050837-01) grant to A.B.T.',
            ],
            'fez_record_search_key_herdc_code': {
                'rek_herdc_code': 450009,
                'rek_herdc_code_lookup': 'C1',
            },
            'fez_record_search_key_herdc_status': {
                'rek_herdc_status': 453220,
                'rek_herdc_status_lookup': 'Provisional Code',
            },
            'fez_record_search_key_institutional_status': {
                'rek_institutional_status': 453223,
                'rek_institutional_status_lookup': 'UQ',
            },
            'fez_record_search_key_isi_loc': {
                'rek_isi_loc': '000422701000003',
                'fez_thomson_citations': null,
            },
            'fez_record_search_key_ismemberof': [
                {
                    'rek_ismemberof': 'UQ:3823',
                    'parent': {
                        'rek_pid': 'UQ:3823',
                        'rek_security_policy': 5,
                        'rek_datastream_policy': 5,
                    },
                    'rek_ismemberof_lookup': 'School of Biomedical Sciences Publications',
                },
                {
                    'rek_ismemberof': 'UQ:218311',
                    'parent': {
                        'rek_pid': 'UQ:218311',
                        'rek_security_policy': 5,
                        'rek_datastream_policy': 5,
                    },
                    'rek_ismemberof_lookup': 'HERDC Pre-Audit',
                },
            ],
            'fez_record_search_key_issn': [
                {
                    'rek_issn': '1873-2720',
                    'fez_journal_issns': [
                        {
                            'jni_id': 14740,
                            'jni_jnl_id': 8463,
                            'jni_issn': '1873-2720',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 8463,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2010,
                                'jnl_created_date': '2010-11-19 00:00:00',
                                'jnl_updated_date': '2010-11-19 00:00:00',
                                'jnl_rank': 'B',
                                'jnl_foreign_name': null,
                            },
                        },
                        {
                            'jni_id': 38071,
                            'jni_jnl_id': 22918,
                            'jni_issn': '1873-2720',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 22918,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2012,
                                'jnl_created_date': '2011-10-28 00:00:00',
                                'jnl_updated_date': '2011-10-28 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 90376,
                            'jni_jnl_id': 54790,
                            'jni_issn': '1873-2720',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 54790,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2015,
                                'jnl_created_date': '2014-07-16 00:00:00',
                                'jnl_updated_date': '2014-07-16 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 153611,
                            'jni_jnl_id': 93348,
                            'jni_issn': '1873-2720',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 93348,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2018,
                                'jnl_created_date': '2017-09-06 00:00:00',
                                'jnl_updated_date': '2017-09-06 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                    ],
                    'fez_sherpa_romeo': {
                        'srm_id': 15433,
                        'srm_issn': '1873-2720',
                        'srm_journal_name': 'Zoology',
                        'srm_journal_link': 'http://example.com',
                    },
                    'fez_ulrichs': {
                        'ulr_issn': '1873-2720',
                        'ulr_title_id': '387171',
                        'ulr_title': 'Zoology',
                    },
                    'rek_issn_lookup': '',
                },
                {
                    'rek_issn': '0944-2006',
                    'fez_journal_issns': [
                        {
                            'jni_id': 14739,
                            'jni_jnl_id': 8463,
                            'jni_issn': '0944-2006',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 8463,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2010,
                                'jnl_created_date': '2010-11-19 00:00:00',
                                'jnl_updated_date': '2010-11-19 00:00:00',
                                'jnl_rank': 'B',
                                'jnl_foreign_name': null,
                            },
                        },
                        {
                            'jni_id': 38070,
                            'jni_jnl_id': 22918,
                            'jni_issn': '0944-2006',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 22918,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2012,
                                'jnl_created_date': '2011-10-28 00:00:00',
                                'jnl_updated_date': '2011-10-28 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 90375,
                            'jni_jnl_id': 54790,
                            'jni_issn': '0944-2006',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 54790,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2015,
                                'jnl_created_date': '2014-07-16 00:00:00',
                                'jnl_updated_date': '2014-07-16 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 153610,
                            'jni_jnl_id': 93348,
                            'jni_issn': '0944-2006',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 93348,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2018,
                                'jnl_created_date': '2017-09-06 00:00:00',
                                'jnl_updated_date': '2017-09-06 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                    ],
                    'fez_sherpa_romeo': {
                        'srm_id': 15432,
                        'srm_issn': '0944-2006',
                        'srm_journal_name': 'Zoology',
                        'srm_journal_link': 'http://example.com',
                    },
                    'fez_ulrichs': {
                        'ulr_issn': '0944-2006',
                        'ulr_title_id': '24252',
                        'ulr_title': 'Zoology',
                    },
                    'rek_issn_lookup': '',
                },
            ],
            'fez_record_search_key_journal_name': 'Zoology (Jena, Germany)',
            'fez_record_search_key_keywords': [
                'Bone material properties',
                'Bone strain gauges',
                'Chewing',
                'Finite element analysis',
                'Musculoskeletal modeling',
            ],
            'fez_record_search_key_language': ['eng'],
            "fez_record_search_key_license": {
                "rek_license": 454104,
                "rek_license_lookup": "Permitted Re-Use with Commercial Use Restriction"
            },
            'fez_record_search_key_oa_status': {
                'rek_oa_status': 453696,
                'rek_oa_status_lookup': 'File (Author Post-print)',
            },
            'fez_record_search_key_place_of_publication': 'Muenchen, Germany',
            'fez_record_search_key_publisher': 'Elsevier',
            'fez_record_search_key_pubmed_id': '29037463',
            'fez_record_search_key_refereed': 1,
            'fez_record_search_key_refereed_source': {
                'rek_refereed_source': '453635',
                'rek_refereed_source_lookup': 'Ulrichs',
            },
            'fez_record_search_key_retracted': 0,
            'fez_record_search_key_scopus_id': {
                'rek_scopus_id': '2-s2.0-85031404832',
                'fez_scopus_citations': null,
            },
            'fez_record_search_key_start_page': '13',
            'fez_record_search_key_subject': [
                {
                    'rek_subject': 453265,
                    'rek_subject_lookup': '1103 Animal Science and Zoology',
                },
            ],
            'fez_record_search_key_total_pages': '17',
            'fez_record_search_key_volume_number': '124',
            'fez_record_search_key_wok_doc_types': ['Article'],
            'fez_datastream_info': [
                {
                    'dsi_id': 1,
                    'dsi_pid': 'UQ:696826',
                    'dsi_dsid': 'FezACML_UQ696826_OA.pdf.xml',
                    'dsi_embargo_date': null,
                    'dsi_open_access': null,
                    'dsi_label': 'FezACML security for datastream - UQ696826_OA.pdf',
                    'dsi_mimetype': 'text/xml',
                    'dsi_copyright': null,
                    'dsi_state': 'A',
                    'dsi_size': 359,
                    'dsi_security_policy': 1,
                    'dsi_security_inherited': 0,
                },
                {
                    'dsi_id': 2,
                    'dsi_pid': 'UQ:696826',
                    'dsi_dsid': 'UQ696826_OA.pdf',
                    'dsi_embargo_date': '2099-10-31',
                    'dsi_open_access': null,
                    'dsi_label': 'Full text (open access)',
                    'dsi_mimetype': 'application/pdf',
                    'dsi_copyright': null,
                    'dsi_state': 'A',
                    'dsi_size': 1852931,
                    'dsi_security_policy': 1,
                    'dsi_security_inherited': 0,
                },
            ],
            'fez_matched_journals': {
                'mtj_pid': 'UQ:696826',
                'mtj_jnl_id': 8463,
                'mtj_status': 'M',
            },
            'rek_display_type_lookup': 'Journal Article',
            'rek_object_type_lookup': 'Record',
            'rek_status_lookup': 'Published',
            'fez_record_search_key_author_affiliation_country': [
                'aus',
                'usa',
                'gbr',
                'usa',
                'usa',
                'aus',
                'mys',
                'usa',
            ],
            'fez_record_search_key_author_affiliation_full_address': [
                'St Lucia,Brisbane,QLD',
                '801 S. Paulina St.,Chicago,IL',
                'Hawkshead Lane, North Mymms, Hatfield, Hertfordshire',
                '3302 Gaston Ave.,Dallas,TX',
                '1310 Club Drive, Mare Island,Vellejo,CA',
                'St Lucia,Brisbane,QLD',
                'No. 4, Jalan PJU 8/8A, Damansara Perdana,Petaling Jaya,Selangor',
                '1027 E. 57th St.,Chicago,IL',
            ],
            'fez_record_search_key_author_affiliation_name': [
                'Moving Morphology & Functional Mechanics Laboratory,School of Biomedical Sciences,The University of Queensland',
                'Department of Oral Biology,University of Illinois',
                'Department of Biomedical Sciences,The Royal Veterinary College',
                'Department of Biomedical Sciences,College of Dentistry,Texas A&M University',
                'Department of Basic Science,Touro University',
                'Moving Morphology & Functional Mechanics Laboratory,School of Biomedical Sciences,The University of Queensland',
                'Materialise Unit 5-01,Menara OBYU',
                'Department of Organismal Biology and Anatomy,University of Chicago',
            ],
            'fez_record_search_key_security_policy': [5],
        },
        {
            'rek_pid': 'UQ:696827',
            'rek_title': 'In vivo bone strain and finite element modeling of a rhesus macaque mandible during mastication',
            'rek_description': 'Finite element analysis (FEA) is a commonly used tool in musculoskeletal biomechanics and vertebrate paleontology. The accuracy and precision of finite element models (FEMs) are reliant on accurate data on bone geometry, muscle forces, boundary conditions and tissue material properties. Simplified modeling assumptions, due to lack of in vivo experimental data on material properties and muscle activation patterns, may introduce analytical errors in analyses where quantitative accuracy is critical for obtaining rigorous results. A subject-specific FEM of a rhesus macaque mandible was constructed, loaded and validated using in vivo data from the same animal. In developing the model, we assessed the impact on model behavior of variation in (i) material properties of the mandibular trabecular bone tissue and teeth; (ii) constraints at the temporomandibular joint and bite point; and (iii) the timing of the muscle activity used to estimate the external forces acting on the model. The best match between the FEA simulation and the in vivo experimental data resulted from modeling the trabecular tissue with an isotropic and homogeneous Young\'s modulus and Poisson\'s value of 10GPa and 0.3, respectively; constraining translations along X,Y, Z axes in the chewing (left) side temporomandibular joint, the premolars and the m1; constraining the balancing (right) side temporomandibular joint in the anterior-posterior and superior-inferior axes, and using the muscle force estimated at time of maximum strain magnitude in the lower lateral gauge. The relative strain magnitudes in this model were similar to those recorded in vivo for all strain locations. More detailed analyses of mandibular strain patterns during the power stroke at different times in the chewing cycle are needed.',
            'rek_display_type': 179,
            'rek_status': 2,
            'rek_date': '2017-09-01T00:00:00Z',
            'rek_object_type': 3,
            'rek_created_date': '2017-11-15T02:12:54Z',
            'rek_updated_date': '2019-06-14T01:49:45Z',
            'rek_genre': 'Journal Article',
            'rek_genre_type': 'Article (original research)',
            'rek_depositor_affiliation': 878,
            'rek_thomson_citation_count': 2,
            'rek_subtype': 'Article (original research)',
            'rek_scopus_citation_count': 4,
            'rek_scopus_doc_type': 'ar',
            'rek_wok_doc_type': '@',
            'rek_pubmed_doc_type': 'Journal Article',
            'rek_security_inherited': 1,
            'rek_altmetric_score': 5,
            'rek_altmetric_id': 27814093,
            'rek_copyright': 'on',
            'rek_security_policy': 1,
            'fez_record_search_key_author': [
                'Panagiotopoulou, Olga',
                'Iriarte-Diaz, José',
                'Wilshin, Simon',
                'Dechow, Paul C.',
                'Taylor, Andrea B.',
                'Mehari Abraha, Hyab',
                'Aljunid, Sharifah F.',
                'Ross, Callum F.',
            ],
            'fez_record_search_key_author_id': [
                {
                    'rek_author_id': 97333,
                    'rek_author_id_lookup': 'Olga Panagiotopoulou',
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 2710438,
                    'rek_author_id_lookup': 'Mehari Abraha, Hyab',
                },
                {
                    'rek_author_id': 0,
                },
                {
                    'rek_author_id': 0,
                },
            ],
            'fez_record_search_key_corresponding_country': ['usa'],
            'fez_record_search_key_corresponding_email': ['rossc@uchicago.edu'],
            'fez_record_search_key_corresponding_name': ['Ross C.F.'],
            'fez_record_search_key_corresponding_organisation': [
                'Univ Queensland',
                'Monash Univ',
                'Univ Illinois',
                'Royal Vet Coll',
                'Texas A&M Univ',
                'Touro Univ',
                'Menara OBYU',
                'Univ Chicago',
            ],
            'fez_record_search_key_date_available': '2017-01-01T00:00:00Z',
            'fez_record_search_key_doi': {
                'rek_doi': '10.1016/j.zool.2017.08.010',
                'fez_altmetric': {
                    'as_id': 86518,
                    'as_amid': 27814093,
                    'as_doi': '10.1016/j.zool.2017.08.010',
                    'as_score': 5,
                    'as_created': '1510835743',
                    'as_last_checked': '1563608853',
                    'as_1d': 0,
                    'as_2d': 0,
                    'as_3d': 0,
                    'as_4d': 0,
                    'as_5d': 0,
                    'as_6d': 0,
                    'as_1w': 0,
                    'as_1m': 0,
                    'as_3m': 0,
                    'as_6m': 0,
                    'as_1y': 0,
                    'as_total_posts_count': 7,
                    'as_facebook_posts_count': 1,
                    'as_policy_posts_count': 0,
                    'as_blogs_posts_count': 0,
                    'as_googleplus_posts_count': 0,
                    'as_news_posts_count': 0,
                    'as_reddit_posts_count': 0,
                    'as_twitter_posts_count': 6,
                    'as_syllabi_posts_count': 0,
                    'as_video_posts_count': 0,
                    'as_weibo_posts_count': 0,
                    'as_qa_posts_count': 0,
                    'as_f1000_posts_count': 0,
                    'as_wikipedia_posts_count': 0,
                    'as_pinterest_posts_count': 0,
                    'as_linkedin_posts_count': 0,
                    'as_peer_reviews_posts_count': 0,
                    'as_citation_url': 'http://www.altmetric.com/details.php?citation_id=27814093',
                },
            },
            'fez_record_search_key_end_page': '29',
            'fez_record_search_key_file_attachment_name': [
                "FezACML_My_UQ_eSpace_researcher_guidelines_2012.pdf.xml",
                "FezACML_My_UQ_eSpace_researcher_guidelines_2014.pdf.xml",
                "FezACML_My_UQ_eSpace_UPO_guidelines.pdf.xml",
                "FezACML_My_UQ_eSpace_UPO_guidelines_2013.pdf.xml",
                "FezACML_My_UQ_eSpace_UPO_guidelines_2016.pdf.xml",
                "FezACML_UQ_396321.xml",
                "FezACML_UQ_eSpace_My_Research_091119.pdf.xml",
                "FezACML_UQ_eSpace_My_Research_091119_old.pdf.xml",
                "MyUQeSpace_Researcher_Guidelines_current.pdf",
                "My_UQ_eSpace_researcher_guidelines_2012.pdf",
                "My_UQ_eSpace_researcher_guidelines_2014.pdf",
                "My_UQ_eSpace_UPO_guidelines.pdf",
                "My_UQ_eSpace_UPO_guidelines_2013.pdf",
                "My_UQ_eSpace_UPO_guidelines_2016.pdf",
                "presmd_MyUQeSpaceResearcherGuidelines_compressed.xml",
                "presmd_MyUQeSpace_Researcher_Guidelines.xml",
                "presmd_MyUQeSpace_Researcher_Guidelines_current.xml",
                "presmd_My_UQ_eSpace_researcher_guidelines.xml",
                "presmd_My_UQ_eSpace_researcher_guidelines_2012.xml",
                "presmd_My_UQ_eSpace_researcher_guidelines_2014.xml",
                "presmd_My_UQ_eSpace_UPO_guidelines.xml",
                "presmd_My_UQ_eSpace_UPO_guidelines_2013.xml",
                "presmd_My_UQ_eSpace_UPO_guidelines_2016.xml",
                "presmd_UQ_eSpace_My_Research_091119.xml",
                "presmd_UQ_eSpace_My_Research_091119_old.xml",
                "UQ_eSpace_My_Research_091119.pdf",
                "UQ_eSpace_My_Research_091119_old.pdf",
            ],
            'fez_record_search_key_grant_acronym': ['BRF', 'NSF', 'CIHR', 'NIH', 'NIH'],
            'fez_record_search_key_grant_agency': [
                'Brain Research Foundation',
                'National Science Foundation',
                'Canadian Institutes of Health Research',
                'National Institutes of Health',
                'National Institutes of Health',
            ],
            'fez_record_search_key_grant_agency_id': [
                '10.13039/501100000024',
                '10.13039/100000002',
                '10.13039/100000882',
                '10.13039/100000001',
            ],
            'fez_record_search_key_grant_id': [
                'MOP-4918',
                'RO1DE023816',
                null,
                'BCS0962677',
                'ERG-MACACA 267207',
                '606441',
                'R24 HD050837-01',
            ],
            'fez_record_search_key_grant_text': [
                'We thank Dr. Vivian Allen for technical support with the EMG data analysis; Dr. Leslie Pryor for technical assistance during material testing; and the staff of the University of Chicago Animal Resources Center for their assistance with animal care and procedures. We also are grateful to Prof. Ian Grosse for useful discussions and our two anonymous reviewers for their feedback. The in vivo experimental analysis was supported by CIHR Grant MOP-4918, the National Institutes of Health (NIH RO1DE023816), and a grant from the Brain Research Foundation to C.F.R. The ex vivo material properties testing and all simulation analyses were funded by a Marie Curie European Re-integration Grant (ERG-MACACA 267207) to O.P. Funding for the purchase and maintenance of the FEA and virtual reconstruction software was also secured via a start-up grant (606441) from the School of Biomedical Sciences, The University of Queensland to O.P. Funding for jaw muscle fiber architecture was via grants from the National Science Foundation (BCS0962677) and an NIH (R24 HD050837-01) grant to A.B.T.',
            ],
            'fez_record_search_key_herdc_code': {
                'rek_herdc_code': 450009,
                'rek_herdc_code_lookup': 'C1',
            },
            'fez_record_search_key_herdc_status': {
                'rek_herdc_status': 453220,
                'rek_herdc_status_lookup': 'Provisional Code',
            },
            'fez_record_search_key_institutional_status': {
                'rek_institutional_status': 453223,
                'rek_institutional_status_lookup': 'UQ',
            },
            'fez_record_search_key_isi_loc': {
                'rek_isi_loc': '000422701000003',
                'fez_thomson_citations': null,
            },
            'fez_record_search_key_ismemberof': [
                {
                    'rek_ismemberof': 'UQ:3823',
                    'parent': {
                        'rek_pid': 'UQ:3823',
                        'rek_security_policy': 5,
                        'rek_datastream_policy': 5,
                    },
                    'rek_ismemberof_lookup': 'School of Biomedical Sciences Publications',
                },
                {
                    'rek_ismemberof': 'UQ:218311',
                    'parent': {
                        'rek_pid': 'UQ:218311',
                        'rek_security_policy': 5,
                        'rek_datastream_policy': 5,
                    },
                    'rek_ismemberof_lookup': 'HERDC Pre-Audit',
                },
            ],
            'fez_record_search_key_issn': [
                {
                    'rek_issn': '1873-2720',
                    'fez_journal_issns': [
                        {
                            'jni_id': 14740,
                            'jni_jnl_id': 8463,
                            'jni_issn': '1873-2720',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 8463,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2010,
                                'jnl_created_date': '2010-11-19 00:00:00',
                                'jnl_updated_date': '2010-11-19 00:00:00',
                                'jnl_rank': 'B',
                                'jnl_foreign_name': null,
                            },
                        },
                        {
                            'jni_id': 38071,
                            'jni_jnl_id': 22918,
                            'jni_issn': '1873-2720',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 22918,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2012,
                                'jnl_created_date': '2011-10-28 00:00:00',
                                'jnl_updated_date': '2011-10-28 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 90376,
                            'jni_jnl_id': 54790,
                            'jni_issn': '1873-2720',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 54790,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2015,
                                'jnl_created_date': '2014-07-16 00:00:00',
                                'jnl_updated_date': '2014-07-16 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 153611,
                            'jni_jnl_id': 93348,
                            'jni_issn': '1873-2720',
                            'jni_issn_order': 2,
                            'fez_journal': {
                                'jnl_id': 93348,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2018,
                                'jnl_created_date': '2017-09-06 00:00:00',
                                'jnl_updated_date': '2017-09-06 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                    ],
                    'fez_sherpa_romeo': {
                        'srm_id': 15433,
                        'srm_issn': '1873-2720',
                        'srm_journal_name': 'Zoology',
                        'srm_journal_link': 'http://example.com',
                    },
                    'fez_ulrichs': {
                        'ulr_issn': '1873-2720',
                        'ulr_title_id': '387171',
                        'ulr_title': 'Zoology',
                    },
                    'rek_issn_lookup': '',
                },
                {
                    'rek_issn': '0944-2006',
                    'fez_journal_issns': [
                        {
                            'jni_id': 14739,
                            'jni_jnl_id': 8463,
                            'jni_issn': '0944-2006',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 8463,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2010,
                                'jnl_created_date': '2010-11-19 00:00:00',
                                'jnl_updated_date': '2010-11-19 00:00:00',
                                'jnl_rank': 'B',
                                'jnl_foreign_name': null,
                            },
                        },
                        {
                            'jni_id': 38070,
                            'jni_jnl_id': 22918,
                            'jni_issn': '0944-2006',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 22918,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2012,
                                'jnl_created_date': '2011-10-28 00:00:00',
                                'jnl_updated_date': '2011-10-28 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 90375,
                            'jni_jnl_id': 54790,
                            'jni_issn': '0944-2006',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 54790,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2015,
                                'jnl_created_date': '2014-07-16 00:00:00',
                                'jnl_updated_date': '2014-07-16 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                        {
                            'jni_id': 153610,
                            'jni_jnl_id': 93348,
                            'jni_issn': '0944-2006',
                            'jni_issn_order': 1,
                            'fez_journal': {
                                'jnl_id': 93348,
                                'jnl_journal_name': 'Zoology',
                                'jnl_era_id': 3037,
                                'jnl_era_year': 2018,
                                'jnl_created_date': '2017-09-06 00:00:00',
                                'jnl_updated_date': '2017-09-06 00:00:00',
                                'jnl_rank': null,
                                'jnl_foreign_name': '',
                            },
                        },
                    ],
                    'fez_sherpa_romeo': {
                        'srm_id': 15432,
                        'srm_issn': '0944-2006',
                        'srm_journal_name': 'Zoology',
                        'srm_journal_link': 'http://example.com',
                    },
                    'fez_ulrichs': {
                        'ulr_issn': '0944-2006',
                        'ulr_title_id': '24252',
                        'ulr_title': 'Zoology',
                    },
                    'rek_issn_lookup': '',
                },
            ],
            'fez_record_search_key_journal_name': 'Zoology (Jena, Germany)',
            'fez_record_search_key_keywords': [
                'Bone material properties',
                'Bone strain gauges',
                'Chewing',
                'Finite element analysis',
                'Musculoskeletal modeling',
            ],
            'fez_record_search_key_language': ['eng'],
            "fez_record_search_key_license": {
                "rek_license": 454104,
                "rek_license_lookup": "Permitted Re-Use with Commercial Use Restriction"
            },
            'fez_record_search_key_oa_status': {
                'rek_oa_status': 453696,
                'rek_oa_status_lookup': 'File (Author Post-print)',
            },
            'fez_record_search_key_place_of_publication': 'Muenchen, Germany',
            'fez_record_search_key_publisher': 'Elsevier',
            'fez_record_search_key_pubmed_id': '29037463',
            'fez_record_search_key_refereed': 1,
            'fez_record_search_key_refereed_source': {
                'rek_refereed_source': '453635',
                'rek_refereed_source_lookup': 'Ulrichs',
            },
            'fez_record_search_key_retracted': 0,
            'fez_record_search_key_scopus_id': {
                'rek_scopus_id': '2-s2.0-85031404832',
                'fez_scopus_citations': null,
            },
            'fez_record_search_key_start_page': '13',
            'fez_record_search_key_subject': [
                {
                    'rek_subject': 453265,
                    'rek_subject_lookup': '1103 Animal Science and Zoology',
                },
            ],
            'fez_record_search_key_total_pages': '17',
            'fez_record_search_key_volume_number': '124',
            'fez_record_search_key_wok_doc_types': ['Article'],
            'fez_datastream_info': [
                {
                    "dsi_id": 1,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "FezACML_My_UQ_eSpace_researcher_guidelines_2012.pdf.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "FezACML security for datastream - My_UQ_eSpace_researcher_guidelines_2012.pdf",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 3097,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1,
                    "dsi_order": 1
                },
                {
                    "dsi_id": 2,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "FezACML_My_UQ_eSpace_researcher_guidelines_2014.pdf.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": 1,
                    "dsi_label": "FezACML security for datastream - My_UQ_eSpace_researcher_guidelines_2014.pdf",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 3097,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1,
                    "dsi_order": 2
                },
                {
                    "dsi_id": 3,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "FezACML_My_UQ_eSpace_UPO_guidelines.pdf.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": 0,
                    "dsi_label": "FezACML security for datastream - My_UQ_eSpace_UPO_guidelines.pdf",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 3092,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1,
                    "dsi_order": 3
                },
                {
                    "dsi_id": 4,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "FezACML_My_UQ_eSpace_UPO_guidelines_2013.pdf.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "FezACML security for datastream - My_UQ_eSpace_UPO_guidelines_2013.pdf",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 3097,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1,
                    "dsi_order": 4
                },
                {
                    "dsi_id": 5,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "FezACML_My_UQ_eSpace_UPO_guidelines_2016.pdf.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "FezACML security for datastream - My_UQ_eSpace_UPO_guidelines_2016.pdf",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 3097,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1,
                    "dsi_order": 4
                },
                {
                    "dsi_id": 6,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "FezACML_UQ_396321.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "FezACML security for PID - UQ:396321",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 3705,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1,
                    "dsi_order": 3
                },
                {
                    "dsi_id": 7,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "FezACML_UQ_eSpace_My_Research_091119.pdf.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "FezACML security for datastream - UQ_eSpace_My_Research_091119.pdf",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 3097,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1,
                    "dsi_order": null
                },
                {
                    "dsi_id": 8,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "FezACML_UQ_eSpace_My_Research_091119_old.pdf.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "FezACML security for datastream - UQ_eSpace_My_Research_091119_old.pdf",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 3097,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 9,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "MyUQeSpaceResearcherGuidelines_compressed.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "Full text (open access)",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "D",
                    "dsi_size": 322698,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 10,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "MyUQeSpace_Researcher_Guidelines.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "Full text (open access)",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "D",
                    "dsi_size": 499269,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 11,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "MyUQeSpace_Researcher_Guidelines_current.pdf",
                    "dsi_embargo_date": '2099-01-01',
                    "dsi_open_access": null,
                    "dsi_label": "Full text (open access)",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 499269,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 12,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "My_UQ_eSpace_researcher_guidelines.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "Full text (open access)",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "D",
                    "dsi_size": 322698,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 13,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "My_UQ_eSpace_researcher_guidelines_2012.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": 1,
                    "dsi_label": "My UQ eSpace - Researcher Guide",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1099946,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 14,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "My_UQ_eSpace_researcher_guidelines_2014.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": 1,
                    "dsi_label": "My UQ eSpace - Researcher Guide",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 273861,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 15,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "My_UQ_eSpace_UPO_guidelines.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "Full text (open access)",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1082235,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 16,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "My_UQ_eSpace_UPO_guidelines_2013.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "UPO Guide v.3",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1335981,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 17,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "My_UQ_eSpace_UPO_guidelines_2016.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "UPO Guide v.4",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 587005,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 18,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_MyUQeSpaceResearcherGuidelines_compressed.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1247,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 19,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_MyUQeSpace_Researcher_Guidelines.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1295,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 20,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_MyUQeSpace_Researcher_Guidelines_current.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1311,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 21,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_My_UQ_eSpace_researcher_guidelines.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1247,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 22,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_My_UQ_eSpace_researcher_guidelines_2012.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 124519,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 23,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_My_UQ_eSpace_researcher_guidelines_2014.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 68060,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 24,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_My_UQ_eSpace_UPO_guidelines.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "PresMD for datastream - My_UQ_eSpace_UPO_guidelines_current.pdf",
                    "dsi_mimetype": "text/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 786,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 25,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_My_UQ_eSpace_UPO_guidelines_2013.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1296,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 26,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_My_UQ_eSpace_UPO_guidelines_2016.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 1285,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 27,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_UQ_eSpace_My_Research_091119.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 96466,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 28,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "presmd_UQ_eSpace_My_Research_091119_old.xml",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "",
                    "dsi_mimetype": "application/xml",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 24362,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 29,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "UPO_Contacts.xlsx",
                    "dsi_embargo_date": null,
                    "dsi_open_access": 1,
                    "dsi_label": "UPO_Contacts.xlsx",
                    "dsi_mimetype": "application/octet-stream",
                    "dsi_copyright": null,
                    "dsi_state": "D",
                    "dsi_size": 25607,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 30,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "UPO_Contacts.xlsx1376542008",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "UPO_Contacts.xlsx1376542008",
                    "dsi_mimetype": "application/octet-stream",
                    "dsi_copyright": null,
                    "dsi_state": "D",
                    "dsi_size": 25607,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 31,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "UQ_eSpace_My_Research_091119.pdf",
                    "dsi_embargo_date": null,
                    "dsi_open_access": null,
                    "dsi_label": "UPO Guide - v.1",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 924170,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 32,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "UQ_eSpace_My_Research_091119_old.pdf",
                    "dsi_embargo_date": '2000-01-01',
                    "dsi_open_access": null,
                    "dsi_label": "Full text",
                    "dsi_mimetype": "application/pdf",
                    "dsi_copyright": null,
                    "dsi_state": "A",
                    "dsi_size": 86383,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                },
                {
                    "dsi_id": 33,
                    "dsi_pid": "UQ:396321",
                    "dsi_dsid": "URLLink.acsm",
                    "dsi_embargo_date": null,
                    "dsi_open_access": 1,
                    "dsi_label": "URLLink.acsm",
                    "dsi_mimetype": "application/octet-stream",
                    "dsi_copyright": null,
                    "dsi_state": "D",
                    "dsi_size": 1617,
                    "dsi_security_inherited": 1,
                    "dsi_security_policy": 1
                }
            ],
            'fez_matched_journals': {
                'mtj_pid': 'UQ:696827',
                'mtj_jnl_id': 8463,
                'mtj_status': 'M',
            },
            'rek_display_type_lookup': 'Journal Article',
            'rek_object_type_lookup': 'Record',
            'rek_status_lookup': 'Published',
            'fez_record_search_key_author_affiliation_country': [
                'aus',
                'usa',
                'gbr',
                'usa',
                'usa',
                'aus',
                'mys',
                'usa',
            ],
            'fez_record_search_key_author_affiliation_full_address': [
                'St Lucia,Brisbane,QLD',
                '801 S. Paulina St.,Chicago,IL',
                'Hawkshead Lane, North Mymms, Hatfield, Hertfordshire',
                '3302 Gaston Ave.,Dallas,TX',
                '1310 Club Drive, Mare Island,Vellejo,CA',
                'St Lucia,Brisbane,QLD',
                'No. 4, Jalan PJU 8/8A, Damansara Perdana,Petaling Jaya,Selangor',
                '1027 E. 57th St.,Chicago,IL',
            ],
            'fez_record_search_key_author_affiliation_name': [
                'Moving Morphology & Functional Mechanics Laboratory,School of Biomedical Sciences,The University of Queensland',
                'Department of Oral Biology,University of Illinois',
                'Department of Biomedical Sciences,The Royal Veterinary College',
                'Department of Biomedical Sciences,College of Dentistry,Texas A&M University',
                'Department of Basic Science,Touro University',
                'Moving Morphology & Functional Mechanics Laboratory,School of Biomedical Sciences,The University of Queensland',
                'Materialise Unit 5-01,Menara OBYU',
                'Department of Organismal Biology and Anatomy,University of Chicago',
            ],
            'fez_record_search_key_security_policy': [5],
        },
    ],
};
export default hydrateMockSearchList(publicationTypeListJournalArticle);
