import { hydrateMock } from '../../../helpers/general';

const recordWithDatastreams = {
    "rek_pid": "UQ:252236",
    "rek_title": "My UQ eSpace - Instructions for depositing, checking and claiming publications",
    "rek_display_type": 183,
    "rek_status": 2,
    "rek_date": "2011-09-19T00:00:00Z",
    "rek_object_type": 3,
    "rek_depositor": 187,
    "rek_created_date": "2011-09-19T12:18:29Z",
    "rek_updated_date": "2019-03-04T05:49:37Z",
    "rek_genre": "Working Paper",
    "rek_depositor_affiliation": 1164,
    "rek_security_inherited": 1,
    "rek_copyright": "on",
    "rek_security_policy": 1,
    "fez_record_search_key_assigned_user_id": [187],
    "fez_record_search_key_author": ["Heath, Andrew", "Marrington, Mary-Anne"],
    "fez_record_search_key_author_id": [
        {
            "rek_author_id": 6168,
            "rek_author_id_lookup": "Andrew P. Heath"
        },
        {
            "rek_author_id": 84025,
            "rek_author_id_lookup": "Marrington, Mary-Anne"
        }
    ],
    "fez_record_search_key_file_attachment_name": [
        "FezACML_My_UQ_eSpace_researcher_guidelines_2012.pdf.xml",
        "FezACML_My_UQ_eSpace_researcher_guidelines_2014.pdf.xml",
        "FezACML_My_UQ_eSpace_UPO_guidelines.pdf.xml",
        "FezACML_My_UQ_eSpace_UPO_guidelines_2013.pdf.xml",
        "FezACML_My_UQ_eSpace_UPO_guidelines_2016.pdf.xml",
        "FezACML_UQ_252236.xml",
        "FezACML_UQ_eSpace_My_Research_091119.pdf.xml",
        "FezACML_UQ_eSpace_My_Research_091119_old.pdf.xml",
        "MyUQeSpace_Researcher_Guidelines_current.pdf",
        "My_UQ_eSpace_researcher_guidelines_2012.pdf",
        "My_UQ_eSpace_researcher_guidelines_2014.pdf",
        "My_UQ_eSpace_UPO_guidelines.pdf",
        "My_UQ_eSpace_UPO_guidelines_2013.pdf",
        "My_UQ_eSpace_UPO_guidelines_2016.pdf",
        "presmd_MyUQeSpaceResearcherGuidelines_compressed.xml",
        "presmd_MyUQeSpace_Researcher_Guidelines.xml",
        "presmd_MyUQeSpace_Researcher_Guidelines_current.xml",
        "presmd_My_UQ_eSpace_researcher_guidelines.xml",
        "presmd_My_UQ_eSpace_researcher_guidelines_2012.xml",
        "presmd_My_UQ_eSpace_researcher_guidelines_2014.xml",
        "presmd_My_UQ_eSpace_UPO_guidelines.xml",
        "presmd_My_UQ_eSpace_UPO_guidelines_2013.xml",
        "presmd_My_UQ_eSpace_UPO_guidelines_2016.xml",
        "presmd_UQ_eSpace_My_Research_091119.xml",
        "presmd_UQ_eSpace_My_Research_091119_old.xml",
        "UQ_eSpace_My_Research_091119.pdf",
        "UQ_eSpace_My_Research_091119_old.pdf",
    ],
    "fez_record_search_key_institutional_status": {
        "rek_institutional_status": 453224,
        "rek_institutional_status_lookup": "Non-UQ"
    },
    "fez_record_search_key_ismemberof": [
        {
            "parent": {
                "rek_pid": "UQ:3935",
                "rek_datastream_policy": 1,
                "rek_security_policy": 5
            },
            "rek_ismemberof": "UQ:3935",
            "rek_ismemberof_lookup": "System Documentation and Instructions"
        },
        {
            "parent": {
                "rek_pid": "UQ:230658",
                "rek_datastream_policy": 2,
                "rek_security_policy": 3
            },
            "rek_ismemberof": "UQ:230658",
            "rek_ismemberof_lookup": "UQ Library - Scholarly Communication and Digitisation Services"
        }
    ],
    "fez_record_search_key_oa_status": {
        "rek_oa_status": 453697,
        "rek_oa_status_lookup": "Other"
    },
    "fez_record_search_key_refereed": 0,
    "fez_record_search_key_refereed_source": {
        "rek_refereed_source": "453638",
        "rek_refereed_source_lookup": "Not yet assessed"
    },
    "fez_record_search_key_security_policy": [
        {
            "rek_security_policy": 5,
            "fez_policy": {
                "pol_id": 5,
                "pol_title": "Public",
                "pol_order": 100
            }
        }
    ],
    "fez_datastream_info": [
        {
            "dsi_id": 1,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "FezACML_My_UQ_eSpace_researcher_guidelines_2012.pdf.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "FezACML security for datastream - My_UQ_eSpace_researcher_guidelines_2012.pdf",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 3097,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1,
            "dsi_order": 1
        },
        {
            "dsi_id": 2,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "FezACML_My_UQ_eSpace_researcher_guidelines_2014.pdf.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": 1,
            "dsi_label": "FezACML security for datastream - My_UQ_eSpace_researcher_guidelines_2014.pdf",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 3097,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1,
            "dsi_order": 2
        },
        {
            "dsi_id": 3,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "FezACML_My_UQ_eSpace_UPO_guidelines.pdf.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": 0,
            "dsi_label": "FezACML security for datastream - My_UQ_eSpace_UPO_guidelines.pdf",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 3092,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1,
            "dsi_order": 3
        },
        {
            "dsi_id": 4,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "FezACML_My_UQ_eSpace_UPO_guidelines_2013.pdf.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "FezACML security for datastream - My_UQ_eSpace_UPO_guidelines_2013.pdf",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 3097,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1,
            "dsi_order": 4
        },
        {
            "dsi_id": 5,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "FezACML_My_UQ_eSpace_UPO_guidelines_2016.pdf.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "FezACML security for datastream - My_UQ_eSpace_UPO_guidelines_2016.pdf",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 3097,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1,
            "dsi_order": 4
        },
        {
            "dsi_id": 6,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "FezACML_UQ_252236.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "FezACML security for PID - UQ:252236",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 3705,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1,
            "dsi_order": 3
        },
        {
            "dsi_id": 7,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "FezACML_UQ_eSpace_My_Research_091119.pdf.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "FezACML security for datastream - UQ_eSpace_My_Research_091119.pdf",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 3097,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1,
            "dsi_order": null
        },
        {
            "dsi_id": 8,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "FezACML_UQ_eSpace_My_Research_091119_old.pdf.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "FezACML security for datastream - UQ_eSpace_My_Research_091119_old.pdf",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 3097,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 9,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "MyUQeSpaceResearcherGuidelines_compressed.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "Full text (open access)",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "D",
            "dsi_size": 322698,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 10,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "MyUQeSpace_Researcher_Guidelines.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "Full text (open access)",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "D",
            "dsi_size": 499269,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 11,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "MyUQeSpace_Researcher_Guidelines_current.pdf",
            "dsi_embargo_date": '2099-01-01',
            "dsi_open_access": null,
            "dsi_label": "Full text (open access)",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 499269,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 12,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "My_UQ_eSpace_researcher_guidelines.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "Full text (open access)",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "D",
            "dsi_size": 322698,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 13,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "My_UQ_eSpace_researcher_guidelines_2012.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": 1,
            "dsi_label": "My UQ eSpace - Researcher Guide",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1099946,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 14,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "My_UQ_eSpace_researcher_guidelines_2014.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": 1,
            "dsi_label": "My UQ eSpace - Researcher Guide",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 273861,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 15,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "My_UQ_eSpace_UPO_guidelines.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "Full text (open access)",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1082235,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 16,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "My_UQ_eSpace_UPO_guidelines_2013.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "UPO Guide v.3",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1335981,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 17,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "My_UQ_eSpace_UPO_guidelines_2016.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "UPO Guide v.4",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 587005,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 18,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_MyUQeSpaceResearcherGuidelines_compressed.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1247,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 19,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_MyUQeSpace_Researcher_Guidelines.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1295,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 20,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_MyUQeSpace_Researcher_Guidelines_current.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1311,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 21,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_My_UQ_eSpace_researcher_guidelines.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1247,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 22,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_My_UQ_eSpace_researcher_guidelines_2012.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 124519,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 23,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_My_UQ_eSpace_researcher_guidelines_2014.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 68060,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 24,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_My_UQ_eSpace_UPO_guidelines.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "PresMD for datastream - My_UQ_eSpace_UPO_guidelines_current.pdf",
            "dsi_mimetype": "text/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 786,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 25,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_My_UQ_eSpace_UPO_guidelines_2013.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1296,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 26,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_My_UQ_eSpace_UPO_guidelines_2016.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 1285,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 27,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_UQ_eSpace_My_Research_091119.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 96466,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 28,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "presmd_UQ_eSpace_My_Research_091119_old.xml",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "",
            "dsi_mimetype": "application/xml",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 24362,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 29,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "UPO_Contacts.xlsx",
            "dsi_embargo_date": null,
            "dsi_open_access": 1,
            "dsi_label": "UPO_Contacts.xlsx",
            "dsi_mimetype": "application/octet-stream",
            "dsi_copyright": null,
            "dsi_state": "D",
            "dsi_size": 25607,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 30,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "UPO_Contacts.xlsx1376542008",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "UPO_Contacts.xlsx1376542008",
            "dsi_mimetype": "application/octet-stream",
            "dsi_copyright": null,
            "dsi_state": "D",
            "dsi_size": 25607,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 31,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "UQ_eSpace_My_Research_091119.pdf",
            "dsi_embargo_date": null,
            "dsi_open_access": null,
            "dsi_label": "UPO Guide - v.1",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 924170,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 32,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "UQ_eSpace_My_Research_091119_old.pdf",
            "dsi_embargo_date": '2000-01-01',
            "dsi_open_access": null,
            "dsi_label": "Full text",
            "dsi_mimetype": "application/pdf",
            "dsi_copyright": null,
            "dsi_state": "A",
            "dsi_size": 86383,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        },
        {
            "dsi_id": 33,
            "dsi_pid": "UQ:252236",
            "dsi_dsid": "URLLink.acsm",
            "dsi_embargo_date": null,
            "dsi_open_access": 1,
            "dsi_label": "URLLink.acsm",
            "dsi_mimetype": "application/octet-stream",
            "dsi_copyright": null,
            "dsi_state": "D",
            "dsi_size": 1617,
            "dsi_security_inherited": 1,
            "dsi_security_policy": 1
        }
    ],
    "rek_display_type_lookup": "Working Paper",
    "rek_object_type_lookup": "Record",
    "rek_status_lookup": "Published",
};
export default hydrateMock(recordWithDatastreams);
