import { hydrateMockSearchList } from '../../../helpers/general';

const collectionSearchList = {
    "total": 392,
    "took": 74,
    "per_page": 999,
    "current_page": 1,
    "from": 1,
    "to": 392,
    "data": [
        {
            "rek_pid": "UQ:120743",
            "rek_title": "16th Australasian Fluid Mechanics Conference",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:217410",
            "rek_title": "2004 Higher Education Research Data Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:217419",
            "rek_title": "2005 Higher Education Research Data Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:217422",
            "rek_title": "2006 Higher Education Research Data Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:217423",
            "rek_title": "2007 Higher Education Research Data Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:23835",
            "rek_title": "2008 Higher Education Research Data Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:138536",
            "rek_title": "2009 Higher Education Research Data Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:174061",
            "rek_title": "2010 Higher Education Research Data Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:131735",
            "rek_title": "5th Australasian Congress on Applied Mechanics",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:290441",
            "rek_title": "6th International Conference of Animal Health Information Specialists (ICAHIS) 2009",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:318429",
            "rek_title": "7th International Conference of Animal Health Information Specialists (ICAHIS) 2013",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:1d85cff",
            "rek_title": "Aboriginal and Torres Strait Islander Activism and Resistance",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:8890cfe",
            "rek_title": "Aboriginal and Torres Strait Islander Authors",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:f760352",
            "rek_title": "Aboriginal and Torres Strait Islander Images",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:0ab7dc0",
            "rek_title": "Aboriginal and Torres Strait Islander Languages",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:11398",
            "rek_title": "Aboriginal and Torres Strait Islander Studies Unit Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:774620b",
            "rek_title": "Aboriginal and Torres Strait Islander Theses ",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:d3bb472",
            "rek_title": "Aboriginal and Torres Strait Islander Yarns and Spoken Memories",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:11404",
            "rek_title": "Aboriginal Environments Research Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7557",
            "rek_title": "Advanced Computational Modelling Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7565",
            "rek_title": "Advanced Water Management Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:212969",
            "rek_title": "AEDA Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:353000",
            "rek_title": "Ahrens, Prue and Helmrich, Michele and Whitlock, Gillian - Waiting for Asylum and Collaborative Witness: Two Curated Public Exhibitions",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:151282",
            "rek_title": "A National Entomology Curriculum for Australia Project (A project funded by the Carrick Institute)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:209865",
            "rek_title": "Archive CD Books (Open Access)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:381162",
            "rek_title": "Archive CD Books (UQ Staff and Students only)",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:290315",
            "rek_title": "Art Forms in Brisbane",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:224604",
            "rek_title": "Assessment policy and impact on practice – sharpening the policy review process in Australian universities",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:287231",
            "rek_title": "Assets, Ageing and Intergenerational Transfers Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12837",
            "rek_title": "ATCH (Architecture Theory History Criticism) Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:120136",
            "rek_title": "AustLit Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7541",
            "rek_title": "Australasian Centre on Ageing Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7561",
            "rek_title": "Australian Centre for Complementary Medicine, Education and Research Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:253506",
            "rek_title": "Australian Centre for Ecogenomics",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:201165",
            "rek_title": "Australian Centre for International & Tropical Health",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7567",
            "rek_title": "Australian Centre for Minerals Extension and Research Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7555",
            "rek_title": "Australian Equine Genetics Research Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3860",
            "rek_title": "Australian Institute for Bioengineering and Nanotechnology Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351119",
            "rek_title": "Australian Institute for Business and Economics",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:124204",
            "rek_title": "Australian Literature Teaching Survey (Private Research Collection)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12816",
            "rek_title": "Australian Newspaper History Group Newsletter Archive",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:119942",
            "rek_title": "Australian Popular Theatre",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:734952",
            "rek_title": "Awaiting Identification",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:152846",
            "rek_title": "Bachelor of Science Curriculum Review",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:350647",
            "rek_title": "Barikin, Amelia - Large Screens and the Transnational Public Sphere: Curating for Public Spaces",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:219664",
            "rek_title": "Brisbane City",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:288818",
            "rek_title": "Brisbane Suburbs",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:719686",
            "rek_title": "Brown, Shaun - Baritone vocal issues in dramatic repertoire",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:350401",
            "rek_title": "Butler, Rex - Art after deconstruction",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:696704",
            "rek_title": "Butler, Rex - Contemporary Art Writing and Criticism",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:350566",
            "rek_title": "Butler, Sally - Contemporary Queensland Art and Exhibitions",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:350564",
            "rek_title": "Butler, Sally - Documenting Contemporary Indigenous Artists: Catalogue Entries",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:170742",
            "rek_title": "Cancer Prevention Research Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:292856",
            "rek_title": "Cane Toad Times",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:720609",
            "rek_title": "Carleton, Stephen – Bastard Territory: identity formation, belonging, and culturally diverse families",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:720608",
            "rek_title": "Carleton, Stephen – The Turquoise Elephant: contemporary political farce and ecological challenges",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3521",
            "rek_title": "Carter Brown Book Covers",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:23606",
            "rek_title": "Catalyst Writing Syndicate",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:213093",
            "rek_title": "CEED Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3927",
            "rek_title": "Centre for Advanced Imaging Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351429",
            "rek_title": "Centre for Ageing Dementia Research Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3913",
            "rek_title": "Centre for Critical and Cultural Studies Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:677351",
            "rek_title": "Centre for Health Services Research Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7553",
            "rek_title": "Centre for Integrated Preclinical Drug Development Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:137139",
            "rek_title": "Centre for Integrative Legume Research Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7534",
            "rek_title": "Centre for Marine Studies Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3919",
            "rek_title": "Centre for Microscopy and Microanalysis Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7559",
            "rek_title": "Centre for Military and Veterans' Health Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:11397",
            "rek_title": "Centre for Mined Land Rehabilitation Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7536",
            "rek_title": "Centre for Nanotechnology and Biomaterials Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351430",
            "rek_title": "Centre for Neurogenetics and Statistical Genomics Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7549",
            "rek_title": "Centre for Nutrition and Food Sciences Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3925",
            "rek_title": "Centre for Online Health Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:151964",
            "rek_title": "Centre for Organic Photonics and Electronics",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:733949",
            "rek_title": "Centre for Policy Futures",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:177161",
            "rek_title": "Centre for Quantum Computer Technology Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:340262",
            "rek_title": "Centre for Research in Geriatric Medicine Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7539",
            "rek_title": "Centre for Social Research in Communication - Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:135607",
            "rek_title": "Centre for Social Responsibility in Mining Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:734813",
            "rek_title": "Centre for the Business and Economics of Health",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:3915",
            "rek_title": "Centre for the History of European Discourses Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7569",
            "rek_title": "Centre for Theoretical and Computational Molecular Science - Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:195157",
            "rek_title": "Centre for Water in the Minerals Industry",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351431",
            "rek_title": "Centre for Youth Substance Abuse Research Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:347699",
            "rek_title": "Chalabi, Adam - Curating and Conducting from the Orchestra: the Mostly Mozart Concert Series",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717272",
            "rek_title": "Chalabi, Adam - Interdisciplinary Creative Collaboration: string quartet with spoken voice and songwriting",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717770",
            "rek_title": "Chalabi, Adam - New Insights into Early String Quartet Writing: Tinalley and Haydn Opus 20",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717769",
            "rek_title": "Chalabi, Adam - String Quartet Performance Practice: Tinalley and the Australian String Quartet",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:284159",
            "rek_title": "Charles Rennie Mackintosh",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:387775",
            "rek_title": "Child Health Research Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7573",
            "rek_title": "Clinical Medical Virology Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12787",
            "rek_title": "Contemporary Pacific Art",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:639325",
            "rek_title": "Crossref Import",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:415754",
            "rek_title": "Curated Collection: UQ Then and Now",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352152",
            "rek_title": "Davidson, Robert - Beyond Style: Creative Collaboration across Boundaries",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:266584",
            "rek_title": "Davidson, Robert - Composition and performance using mixed-methodology collaboration",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352136",
            "rek_title": "Davidson, Robert - Indie-Classical: new developments in chamber, choral and orchestral composition",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:266585",
            "rek_title": "Davidson, Robert - Voice portraiture compositions, performances and investigations",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13625",
            "rek_title": "Degnan Laboratory",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351906",
            "rek_title": "de Manincor, John - Criticism and Writing",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:732805",
            "rek_title": "Disability Inclusion and Accessibility",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:167709",
            "rek_title": "Discipline of General Practice Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13197",
            "rek_title": "Discussion Papers (School of Economics)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:185193",
            "rek_title": "Dissemination of Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:423120",
            "rek_title": "Dorothy Hill Collection, School of Earth and Environmental Sciences",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:265484",
            "rek_title": "Duwell, Martin - Australian Poetry Review",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:ed9baa1",
            "rek_title": "[E2E_testing] Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7563",
            "rek_title": "Earth Systems Science Computational Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3923",
            "rek_title": "Ecology Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13201",
            "rek_title": "Economic Issues (School of Economics)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178038",
            "rek_title": "Enhanced Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:254105",
            "rek_title": "ERA 2012 Admin Only",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:256976",
            "rek_title": "ERA White List Items",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:179580",
            "rek_title": "ERE: Environmental Research Event 2009",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:152010",
            "rek_title": "eResearch Australasia 2008",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:186782",
            "rek_title": "eResearch Australasia 2009",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:237927",
            "rek_title": "eSpace Followup",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:152266",
            "rek_title": "Excellence in Research Australia (ERA) - Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12334",
            "rek_title": "Faculty of Business, Economics and Law -- Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13075",
            "rek_title": "Faculty of Business, Economics and Law -- Student Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12335",
            "rek_title": "Faculty of Engineering, Architecture and Information Technology Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13076",
            "rek_title": "Faculty of Engineering, Architecture and Information Technology -- Student Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12336",
            "rek_title": "Faculty of Health and Behavioural Sciences -- Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13078",
            "rek_title": "Faculty of Health and Behavioural Sciences -- Student Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:11599",
            "rek_title": "Faculty of Humanities and Social Sciences - Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13080",
            "rek_title": "Faculty of Humanities and Social Sciences -- Student Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:335740",
            "rek_title": "Faculty of Medicine",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12332",
            "rek_title": "Faculty of Science Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13074",
            "rek_title": "Faculty of Science Student Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178040",
            "rek_title": "Family Transitions Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12339",
            "rek_title": "Former UQ Staff and Postgraduate Students' Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:278419",
            "rek_title": "Fryer Library",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:725423",
            "rek_title": "Fryer Library manuscript permissions, copyright etc (Restricted)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:413806",
            "rek_title": "Fryer Library (mediated access)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:734742",
            "rek_title": "Fryer Library multimedia (restricted)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:361995",
            "rek_title": "Fryer Library (Restricted)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:717792",
            "rek_title": "Furphy, Susana - Portfolio of short creative works in Spanish",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:195152",
            "rek_title": "Global Change Institute Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:267313",
            "rek_title": "Glover, Stuart - Short pieces on the state of the publishing industry",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3585",
            "rek_title": "Gooreng Gooreng Cultural Heritage Project",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:219124",
            "rek_title": "Graduate Certificate in Higher Education",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352070",
            "rek_title": "Grinberg, Anna - Reclaiming Lost Expressive Devices in Romantic and Early Modern Chamber Music",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178041",
            "rek_title": "Group Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:155729",
            "rek_title": "HABS Professional Doctorates - UQ staff and students only",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:177957",
            "rek_title": "Healthy Communities Research Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:697984",
            "rek_title": "Helmrich, Michele - Curating Australian Art",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:218311",
            "rek_title": "HERDC Pre-Audit",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:734960",
            "rek_title": "Hydraulics of open channel flow: practical experiments at the University of Queensland, Australia",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:328950",
            "rek_title": "Hydraulic Structures and Society - Engineering Challenges and Extremes: Proceedings of the 5th IAHR International Symposium on Hydraulic Structures (ISHS2014)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:734834",
            "rek_title": "Indigenous Languages recorded in the Queensland Speech Survey (fully restricted)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:357492",
            "rek_title": "Indigenous Languages recorded in the Queensland Speech Survey (open)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:357493",
            "rek_title": "Indigenous Languages recorded in the Queensland Speech Survey (secure)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:178042",
            "rek_title": "Indigenous Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:374917",
            "rek_title": "Institute for Advanced Studies in the Humanities",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3858",
            "rek_title": "Institute for Molecular Bioscience - Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:151785",
            "rek_title": "Institute for Social Science Research - Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:361428",
            "rek_title": "Institute for Teaching and Learning Innovation Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:152014",
            "rek_title": "Institute of Modern Languages - Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:396582",
            "rek_title": "International Conference on Performance-based and Life-cycle Structural Engineering (PLSE)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351990",
            "rek_title": "Jacobs, Jason - Explorations in Screen Aesthetics: Blogs for Critical Studies in Television",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:718134",
            "rek_title": "Janaczewska, Noelle - Contemporary Playwrighting Exposing Historical Gaps",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:127516",
            "rek_title": "Journal Article Import (ISI/CVs)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:11401",
            "rek_title": "Julius Kruttschnitt Mineral Research Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:722737",
            "rek_title": "Kaempf, Sebastian - Exploring the intersection between information technology, global media, and violent conflict",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:347687",
            "rek_title": "Kaji-O'Grady, Sandra - Critical Writing",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:347689",
            "rek_title": "Keys, Catherine - Ceramics and Visual Arts",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:720254",
            "rek_title": "Klein, Eve - Body Inscriptions: Layering Site and Audience Histories in Performance Works (2013-2015)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:720251",
            "rek_title": "Lea, Bronwyn - Bronwyn Lea collection of poems",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:350332",
            "rek_title": "Lea, Bronwyn - Literary Journalism: Reviews and Commentary",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178033",
            "rek_title": "Level 1 Evidence",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178034",
            "rek_title": "Level 2 Evidence",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178035",
            "rek_title": "Level 3 Evidence",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178036",
            "rek_title": "Level 4 Evidence",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178037",
            "rek_title": "Level 5 Evidence",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:361983",
            "rek_title": "Life Course Centre Working Papers Series",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178043",
            "rek_title": "Lifestyle Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:238624",
            "rek_title": "LTS Testing Collection",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:400974",
            "rek_title": "LTS Testing Copy",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:152776",
            "rek_title": "MAPS Identity Management Workshop and Working Group",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:375313",
            "rek_title": "Marks-Hirschfeld Museum of Medical History",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:719555",
            "rek_title": "Martin, Richard et al. - Applied Anthropological Research on Australian Indigenous Land Claims and Cultural Heritage. Series A",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:719562",
            "rek_title": "Martin, Richard et al. - Applied Anthropological Research on Australian Indigenous Land Claims and Cultural Heritage. Series B",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13601",
            "rek_title": "Mater Health Services Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13602",
            "rek_title": "Mater Research Institute-UQ (MRI-UQ)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:111028",
            "rek_title": "Mater-University of Queensland Study of Pregnancy",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:350222",
            "rek_title": "Maynard, Margaret - Queensland Fashion, 1859-Present",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:219034",
            "rek_title": "MBA reports",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:716791",
            "rek_title": "McWilliam, Janette et al. - RD Milns Antiquities Museum",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3586",
            "rek_title": "Mill Point Archaeological Project",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:167696",
            "rek_title": "Minerals Industry Safety and Health Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351835",
            "rek_title": "Morton, Graeme - Developing an Apparatus for Engagement with Contemporary Choral Art Music",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351857",
            "rek_title": "Morton, Graeme - Establishing Relevance and Meaning in Choral Experience",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351839",
            "rek_title": "Morton, Graeme - Giving Voice to Australian Choral Music",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351511",
            "rek_title": "Morton, Graeme - Revisiting Performer - Creator Connectivity",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:267265",
            "rek_title": "Murphy, Patrick - Analysis Through Performance: a Cellist’s Chamber Music Contribution",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351795",
            "rek_title": "Murphy, Patrick - Chamber Music in Collaboration with Theatre and Dance",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352187",
            "rek_title": "Murphy, Patrick - Solo Cello Performance: Constraints and Affordances",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:177479",
            "rek_title": "National and Institutional Perspectives on Metrics-Based Research Evaluation",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7551",
            "rek_title": "National Research Centre for Environmental Toxicology Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:213096",
            "rek_title": "NERP Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:195446",
            "rek_title": "NHMRC reports",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:237156",
            "rek_title": "Non HERDC",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:74168bd",
            "rek_title": " Non-Indigenous representations of Aboriginal and Torres Strait Islander culture and peoples",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:255125",
            "rek_title": "Non-Traditional Research Outputs (individual items)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:265382",
            "rek_title": "Non-UQ Theses",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:676357",
            "rek_title": "Not Yet Publicly Available",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:734014",
            "rek_title": "NTRO to Process",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:4002",
            "rek_title": "Nursing Practice Development Unit Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:599493",
            "rek_title": "Ochsner Clinical School Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:687847",
            "rek_title": "Office of the Deputy Vice-Chancellor (External Engagement)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:307389",
            "rek_title": "Office of the Vice-Chancellor",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:228284",
            "rek_title": "Official 2011 Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:240423",
            "rek_title": "Official 2012 Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:247259",
            "rek_title": "Official 2013 Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:283553",
            "rek_title": "Official 2014 Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:320785",
            "rek_title": "Official 2015 Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:343251",
            "rek_title": "Official 2016 Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:383507",
            "rek_title": "Official 2017 Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:355780",
            "rek_title": "Official Audit",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:264726",
            "rek_title": "O'Loghlin, Michael - First Editions of Chamber Music Works of the Berlin School (1740-1770)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352071",
            "rek_title": "O'Loghlin, Michael - Historically Informed Performances of Baroque Music",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351913",
            "rek_title": "O'Loghlin, Michael - Scholarly Introductions to Music Editions of Berlin School",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:727866",
            "rek_title": "ORCID Author Back Catalogue",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:244548",
            "rek_title": "Out of Circulation",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:693105",
            "rek_title": "Out of Circulation - Suspected Duplicate Records",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:23900",
            "rek_title": "Parenting and Family Support Centre (Triple P) - Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178044",
            "rek_title": "Pathways Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:718130",
            "rek_title": "Pensalfini, Rob - Queensland Shakespeare Ensemble",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352492",
            "rek_title": "Pensalfini, Rob - Shakespeare Prison Project",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:124163",
            "rek_title": "Poets on Record",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:257879",
            "rek_title": "Pollett, Patricia - Creative Partnerships, Premieres and New Works involving Viola",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:257165",
            "rek_title": "Pollett, Patricia - Original Interpretations, New Insights",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717114",
            "rek_title": "Potter, Warwick - Conducting Approaches in Youth and Community Orchestra Contexts",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178045",
            "rek_title": "Primary Care Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:179364",
            "rek_title": "Proceedings of the 10th International Congress on Medical Librarianship (ICML) 2009",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:1dcbeeb",
            "rek_title": "Proceedings of the 11th International Conference on Structures in Fire (SiF2020)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:318428",
            "rek_title": "Proceedings of the 11th International Congress on Medical Librarianship (ICML) 2013",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:ffbf03a",
            "rek_title": "Proceedings of the 22nd Australasian Fluid Mechanics Conference (AFMC2020)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:735004",
            "rek_title": "Proceedings of the 8th IAHR International Symposium on Hydraulic Structures ISHS2020",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:287559",
            "rek_title": "Professional Doctorates - Open Access",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:151710",
            "rek_title": "Professional Doctorates - UQ staff and students only",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:380877",
            "rek_title": "Project Manta",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:688533",
            "rek_title": "Pubmed Import",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:290198",
            "rek_title": "QAAFI Biological Information Technology (QBIT) Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:246627",
            "rek_title": "Queensland Alliance for Agriculture and Food Innovation",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:541428",
            "rek_title": "Queensland Alliance for Environmental Health Sciences (QAEHS) Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:23912",
            "rek_title": "Queensland Brain Institute Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:179309",
            "rek_title": "Queensland Centre for Health Data Services",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:398265",
            "rek_title": "Queensland Cerebral Palsy and Rehabilitation Research Centre",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:335701",
            "rek_title": "Queensland Children's Medical Research Institute Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3903",
            "rek_title": "Queensland Contemporary Architects",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3683",
            "rek_title": "Queensland Country Towns",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:179407",
            "rek_title": "Queensland Past Online (QPO)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:289097",
            "rek_title": "Research Data Collections",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:183940",
            "rek_title": "ResearcherID Downloads",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:682195",
            "rek_title": "ResearcherID Downloads - Archived",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:673633",
            "rek_title": "Retracted publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3929",
            "rek_title": "Rotary Centre for International Studies in Peace and Conflict Resolution Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:599488",
            "rek_title": "Rural Clinical School Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:350595",
            "rek_title": "Salisbury, Chris - Queensland Historical Atlas - Remaking the Queensland Political Landscape",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:350033",
            "rek_title": "Salisbury, Christopher - Queensland Government Cabinet Minutes: Background and Decisions, 1979-1981",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:240731",
            "rek_title": "School of Agriculture and Food Sciences",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3804",
            "rek_title": "School of Architecture Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3805",
            "rek_title": "School of Biological Sciences Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3823",
            "rek_title": "School of Biomedical Sciences Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:195549",
            "rek_title": "School of Chemical Engineering Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3825",
            "rek_title": "School of Chemistry and Molecular Biosciences",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:195545",
            "rek_title": "School of Civil Engineering Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:599486",
            "rek_title": "School of Clinical Medicine Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3803",
            "rek_title": "School of Communication and Arts Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3808",
            "rek_title": "School of Dentistry Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:533111",
            "rek_title": "School of Earth and Environmental Sciences",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:292967",
            "rek_title": "School of Earth Sciences Papers",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:161068",
            "rek_title": "School of Earth Sciences Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3817",
            "rek_title": "School of Economics Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3856",
            "rek_title": "School of Education Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3802",
            "rek_title": "School of Engineering Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:161208",
            "rek_title": "School of Geography, Planning and Environmental Management Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3827",
            "rek_title": "School of Health and Rehabilitation Sciences Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3810",
            "rek_title": "School of Historical and Philosophical Inquiry",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3829",
            "rek_title": "School of Human Movement and Nutrition Sciences Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3801",
            "rek_title": "School of Information Technology and Electrical Engineering Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3838",
            "rek_title": "School of Languages and Cultures Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:185355",
            "rek_title": "School of Languages and Cultures -- Student Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:161065",
            "rek_title": "School of Mathematics and Physics",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:195548",
            "rek_title": "School of Mechanical & Mining Engineering Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:614615",
            "rek_title": "School of Mechanical & Mining Engineering - Student Publications - Open Access",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:614614",
            "rek_title": "School of Mechanical & Mining Engineering - Student Publications - Staff and students only",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:3831",
            "rek_title": "School of Medicine Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3813",
            "rek_title": "School of Music Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3835",
            "rek_title": "School of Nursing, Midwifery and Social Work Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3833",
            "rek_title": "School of Pharmacy Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3806",
            "rek_title": "School of Physical Sciences Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3850",
            "rek_title": "School of Political Science and International Studies Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3852",
            "rek_title": "School of Psychology Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3751",
            "rek_title": "School of Public Health Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3854",
            "rek_title": "School of Social Science Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3840",
            "rek_title": "School of Veterinary Science Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351425",
            "rek_title": "Science of Learning Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:308300",
            "rek_title": "Scopus Import",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:682344",
            "rek_title": "Scopus Import - Archived",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:151266",
            "rek_title": "Security and Surveillance Collection",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12343",
            "rek_title": "Selected Papers of the 2006 Annual Meeting of the Australian Linguistic Society",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178046",
            "rek_title": "Selected Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:185192",
            "rek_title": "Self Directed Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:152799",
            "rek_title": "Semper Floreat",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13605",
            "rek_title": "Semper Floreat Digital Archive",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7543",
            "rek_title": "Social Research Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:212972",
            "rek_title": "Spatial Ecology Lab Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178047",
            "rek_title": "Standard Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:348666",
            "rek_title": "Stead, Naomi - Equity and Diversity in the Australian Architectural Profession",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178048",
            "rek_title": "Stepping Stones Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3862",
            "rek_title": "Sustainable Minerals Institute Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:288581",
            "rek_title": "Sydney Olympics 2000",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3935",
            "rek_title": "System Documentation and Instructions",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3819",
            "rek_title": "TC Beirne School of Law Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:23759",
            "rek_title": "Teaching and Learning Projects",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3917",
            "rek_title": "Technology and Innovation Management Centre Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178049",
            "rek_title": "Teen Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:253670",
            "rek_title": "Temporary Review",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:99821a3",
            "rek_title": "Testing a new collection",
            "rek_datastream_policy": null
        },
        {
            "rek_pid": "UQ:12841",
            "rek_title": "The Future of Tobacco Control",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:185191",
            "rek_title": "Theoretical Development of Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178032",
            "rek_title": "The Triple P Evidence-Base",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:151347",
            "rek_title": "Thorn Laboratory Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:289115",
            "rek_title": "Toby Dix St George Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:86dd452",
            "rek_title": "Torres Strait Islands",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178050",
            "rek_title": "Triple P Media and Communication",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178051",
            "rek_title": "Triple P Programs at a Glance",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:178052",
            "rek_title": "Triple P Video Blog",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:5b71550",
            "rek_title": "University of Queensland Archives - Buildings, Scenes and People",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:ff5f965",
            "rek_title": "University of Queensland Archives - Heron Island Research Station images",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:406017",
            "rek_title": "University of Queensland Archives - Moving Images",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:678478",
            "rek_title": "University of Queensland Archives (Restricted)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:288620",
            "rek_title": "University of Queensland Buildings",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:119822",
            "rek_title": "University of Queensland Library Images",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12136",
            "rek_title": "University of Queensland Library Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:723084",
            "rek_title": "University of Queensland Library Sound and Vision",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3884",
            "rek_title": "University of Queensland Library Staff Publications and Presentations",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:12952",
            "rek_title": "University of Queensland Working Papers in Linguistics",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:218198",
            "rek_title": "Unprocessed Records",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:185146",
            "rek_title": "UQ Art Museum",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:7547",
            "rek_title": "UQ Boilerhouse Community Engagement Centre - Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3815",
            "rek_title": "UQ Business School Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:171723",
            "rek_title": "UQ Centre for Clinical Research Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:351071",
            "rek_title": "UQ Centre for Natural Gas",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:152015",
            "rek_title": "UQ Cultural History Project",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:3931",
            "rek_title": "UQ Diamantina Institute Publications",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:698676",
            "rek_title": "UQ Gatton Past Students' Association",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:211157",
            "rek_title": "UQ Library - Digitised Materials - Metadata only",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:209864",
            "rek_title": "UQ Library - Digitised Materials - open access",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:275689",
            "rek_title": "UQ Library - Digitised Materials - Out of Print - UQ Staff and Students Only",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:261912",
            "rek_title": "UQ Library - Digitised Materials - UQ staff and students only",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:682642",
            "rek_title": "UQ Library Facilities (Restricted)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:230658",
            "rek_title": "UQ Library - Scholarly Communication and Digitisation Services",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:405801",
            "rek_title": "UQ Poche Centre for Indigenous Health",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:207608",
            "rek_title": "UQ Press - administration only",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:11395",
            "rek_title": "UQ Press - books, book chapters and bibliographies",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:124187",
            "rek_title": "UQ Sound and Vision",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:11408",
            "rek_title": "UQ Theses - Citation only",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:342107",
            "rek_title": "UQ Theses (HDR) - Citation only (Full-text embargoed)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:335745",
            "rek_title": "UQ Theses (HDR) - Embargoed (eSpace Admin only)",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:210175",
            "rek_title": "UQ Theses (HDR) - Official",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:216495",
            "rek_title": "UQ Theses (HDR) - Open Access",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:130846",
            "rek_title": "UQ Theses (HDR) - UQ staff and students only",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:525542",
            "rek_title": "UQ Theses (Higher Doctorate) - UQ staff and students only",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:216496",
            "rek_title": "UQ Theses (non-HDR) - Open Access",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:183974",
            "rek_title": "UQ Theses (non-HDR) - UQ staff and students only",
            "rek_datastream_policy": 4
        },
        {
            "rek_pid": "UQ:379c476",
            "rek_title": "UQ Theses RDM Submission and Review",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:152694",
            "rek_title": "UQ Theses Submission and Review",
            "rek_datastream_policy": 2
        },
        {
            "rek_pid": "UQ:223949e",
            "rek_title": "UQ works with a DOI - Awaiting Metadata Release",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:717773",
            "rek_title": "Viney, Liam and Grinberg, Anna – Dance Dialogues: Commissioning a new four-hand piano sonata and collaborating with the Queensland Ballet",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717779",
            "rek_title": "Viney, Liam and Grinberg, Anna - Expanding International Duo Pianism: New and Traditional Works",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352054",
            "rek_title": "Viney, Liam and Grinberg, Anna - Innovating Australian Two-Piano Repertoire and Performance Through Commissions",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352057",
            "rek_title": "Viney, Liam and Grinberg, Anna - New Approaches to Piano Duet Performance",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:352056",
            "rek_title": "Viney, Liam and Grinberg, Anna - Performing Two-Piano Innovations: Dialogic Relations",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717776",
            "rek_title": "Viney, Liam and Grinberg, Anna - Piano Spheres: Collaborative Duo Piano Performance and the Pianist as Critic",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717777",
            "rek_title": "Viney, Liam and Grinberg, Anna - Two Pianos Plus: Pianists Collaborating with Ensembles and Orchestras",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717774",
            "rek_title": "Viney, Liam et al. - White Halo Ensemble: New Directions in Collaborative Music Making",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:266465",
            "rek_title": "Viney, Liam - Innovation and Tradition: 20th Century Chamber Music",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:718309",
            "rek_title": "Viney, Liam - Music criticism: culture and politics",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:697530",
            "rek_title": "Watson, Don et al. - Encyclopedia of Australian Architecture",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:195158",
            "rek_title": "W.H. Bryan Mining Geology Research Centre",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717793",
            "rek_title": "White, Jessica - Portfolio of short fiction",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:717794",
            "rek_title": "Wilkins, Kim - Portfolio of short fiction",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:13199",
            "rek_title": "Working Papers (School of Economics)",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:185194",
            "rek_title": "Workplace Triple P",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:180159",
            "rek_title": "WoS Import",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:682345",
            "rek_title": "WoS Import - Archived",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:229001",
            "rek_title": "W T Collection",
            "rek_datastream_policy": 1
        },
        {
            "rek_pid": "UQ:259725",
            "rek_title": "Zhukov, Katie - Piano music by Australian Women composers - giving voice to women composers",
            "rek_datastream_policy": 5
        },
        {
            "rek_pid": "UQ:259726",
            "rek_title": "Zhukov, Katie - Wirripang Australian Piano Anthology: New Repertoire for Piano Pedagogy",
            "rek_datastream_policy": 5
        }
    ],
    "filters": {
        "facets": {
            "Scopus document type": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": []
            },
            "Display type": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": [
                    {
                        "key": 9,
                        "doc_count": 392
                    }
                ]
            },
            "Keywords": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 432,
                "buckets": [
                    {
                        "key": "Triple P",
                        "doc_count": 24
                    },
                    {
                        "key": "parenting",
                        "doc_count": 14
                    },
                    {
                        "key": "ERA Portfolio",
                        "doc_count": 12
                    },
                    {
                        "key": "BATCH_IMPORT:san_image_import",
                        "doc_count": 8
                    },
                    {
                        "key": "Torres Strait Islanders",
                        "doc_count": 8
                    }
                ]
            },
            "Scopus document type (lookup)": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": []
            },
            "Subject (lookup)": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": []
            },
            "Collection (lookup)": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 210,
                "buckets": [
                    {
                        "key": "ERA Non-Traditional Works - Portfolios",
                        "doc_count": 68
                    },
                    {
                        "key": "Faculty of Science",
                        "doc_count": 35
                    },
                    {
                        "key": "The University of Queensland Library",
                        "doc_count": 33
                    },
                    {
                        "key": "Faculty of Humanities and Social Sciences",
                        "doc_count": 24
                    },
                    {
                        "key": "The Triple P Clearing House",
                        "doc_count": 24
                    }
                ]
            },
            "Year published": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 3,
                "buckets": [
                    {
                        "key": "2021",
                        "doc_count": 13
                    },
                    {
                        "key": "2020",
                        "doc_count": 6
                    },
                    {
                        "key": "2006",
                        "doc_count": 2
                    },
                    {
                        "key": "2010",
                        "doc_count": 1
                    },
                    {
                        "key": "2011",
                        "doc_count": 1
                    }
                ]
            },
            "Subject": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": []
            },
            "Journal name": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": []
            },
            "Collection": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 211,
                "buckets": [
                    {
                        "key": "UQ:253538",
                        "doc_count": 68
                    },
                    {
                        "key": "UQ:12325",
                        "doc_count": 35
                    },
                    {
                        "key": "UQ:3883",
                        "doc_count": 33
                    },
                    {
                        "key": "UQ:12324",
                        "doc_count": 24
                    },
                    {
                        "key": "UQ:178031",
                        "doc_count": 24
                    }
                ]
            },
            "Author": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": []
            },
            "Genre": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": []
            },
            "Subtype": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": []
            },
            "Display type (lookup)": {
                "doc_count_error_upper_bound": 0,
                "sum_other_doc_count": 0,
                "buckets": [
                    {
                        "key": "Collection",
                        "doc_count": 392
                    }
                ]
            }
        }
    }
};
export default hydrateMockSearchList(collectionSearchList);
